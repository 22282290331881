import React, { Component } from 'react'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

// import AdditionalForm from './additional_form';
import Form from './form'

import './client_history.css'

const wellnessMetricsKeyNameList = [
  { key: 'bodyShapeRating', name: 'BSR' },
  { key: 'height', name: 'Height' },
  { key: 'weight', name: 'Weight' },
  { key: 'bfp', name: 'BFP' },
  { key: 'wc', name: 'Waist Circumference' },
  { key: 'whr', name: 'Waist Hip Ratio' },
  { key: 'fatMass', name: 'Fat Mass' },
  { key: 'leanMass', name: 'Lean Mass' },
  { key: 'trunkLegVolRatio', name: 'Trunk Leg Vol Ratio' },
  { key: 'bmr', name: 'BMR' },
  { key: 'bmi', name: 'BMI' },
]

export default class Metrics extends Component {
  constructor(props) {
    super(props)

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.getDefaultValue = this.getDefaultValue.bind(this)
    this.updateInputData = this.updateInputData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    const { wellnessMetrics } = props.rowData.wellnessMetrics
    const height = wellnessMetrics ? wellnessMetrics.height.toString() : null
    const weight = wellnessMetrics ? wellnessMetrics.weight.toString() : null

    this.state = {
      height,
      weight,
      wellnessMetrics: this.props.rowData.wellnessMetrics.wellnessMetrics,
      modalIsOpen: false,
      inputData: {},
      updatedCellKey: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { message, wellnessMetrics } = nextProps.rowData.wellnessMetrics
    const { updatedCellKey } = this.state

    let currentHeight, nextPropsHeight, currentWeight, nextPropsWeight

    if (
      this.props.value.get('wellnessMetrics') &&
      nextProps.value.get('wellnessMetrics')
    ) {
      currentHeight = this.props.value.get('wellnessMetrics').get('height')
      nextPropsHeight = nextProps.value.get('wellnessMetrics').get('height')
      currentWeight = this.props.value.get('wellnessMetrics').get('weight')
      nextPropsWeight = nextProps.value.get('wellnessMetrics').get('weight')
    }

    if (
      wellnessMetrics &&
      Number.isInteger(updatedCellKey) &&
      updatedCellKey === nextProps.griddleKey &&
      (currentHeight !== nextPropsHeight || currentWeight !== nextPropsWeight)
    ) {
      this.setState({
        height: wellnessMetrics.height,
        weight: wellnessMetrics.weight,
        wellnessMetrics,
      })
    }
    if (message && message === 'success') {
      this.closeModal()
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.props.cellProperties.clearMessage()
    this.setState({ modalIsOpen: false })
  }

  parseComma(string) {
    return string ? string.toString().replace(/,/g, '.') : string
  }

  handleSubmit() {
    const { recordId } = this.props.rowData.stats
    const data = this.state.inputData

    const height = data.height ? data.height : this.state.height
    const weight = data.weight ? data.weight : this.state.weight

    this.setState({ updatedCellKey: this.props.griddleKey })

    this.props.cellProperties.updateHeightWeight({
      userId: this.props.cellProperties.userId,
      recordId,
      values: {
        height: parseFloat(this.parseComma(height)),
        weight: parseFloat(this.parseComma(weight)),
      },
    })
  }

  createMetricsList() {
    const {
      user: { units },
    } = this.props.cellProperties

    const heightUnits = units === 'US' ? 'Inches' : 'cm'
    const weightUnits = units === 'US' ? 'lbs' : 'kg'

    return [
      {
        typeId: 'height',
        name: 'Height',
        label: 'height',
        units: heightUnits,
      },
      {
        typeId: 'weight',
        name: 'Weight',
        label: 'weight',
        units: weightUnits,
      },
    ]
  }

  updateInputData(type, value) {
    const { inputData } = this.state
    inputData[type] = value
    // this.setState({ inputData });
  }

  getDefaultValue(metric) {
    return this.state.wellnessMetrics[metric.label]
  }

  renderModal() {
    const { inputData, modalIsOpen, wellnessMetrics } = this.state
    const { message, measurementLoading } = this.props.rowData.wellnessMetrics

    const handleSubmit = this.handleSubmit

    if (wellnessMetrics) {
      return (
        <Modal isOpen={modalIsOpen} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>Height/Weight</ModalHeader>
          <ModalBody>
            <div className="mb-3">
              Leaving a field blank when submitting will not update the metric.
            </div>
            <Form
              inputData={inputData}
              getDefaultValue={this.getDefaultValue}
              metrics={this.createMetricsList()}
              message={message}
              isUpdating={measurementLoading}
              updateInputData={this.updateInputData}
              handleSubmit={handleSubmit}
              closeModal={this.closeModal}
            />
          </ModalBody>
        </Modal>
      )
    }
    return null
  }

  renderRow(metric) {
    const { wellnessMetrics } = this.state
    let rowTitle = metric.name
    let rowValue = wellnessMetrics[metric.key]
    const { height, weight } = this.state

    const handleOpen = this.openModal

    if (rowTitle === 'Height' || rowTitle === 'Weight') {
      rowValue = rowTitle === 'Height' ? height : weight
      rowTitle = (
        <div>
          {rowTitle}
          <span
            className="icon icon-edit ml-4 download-scan-model"
            onClick={handleOpen}
          />
        </div>
      )
    }
    return (
      <tr key={_.uniqueId()}>
        <td style={{ width: '50%' }}>{rowTitle}</td>
        <td style={{ width: '50%' }}>{rowValue}</td>
      </tr>
    )
  }

  render() {
    const { wellnessMetrics } = this.state
    let stats = <div key={_.uniqueId()}>No available wellness metrics</div>
    if (wellnessMetrics) {
      const tableContents = wellnessMetricsKeyNameList.map(
        this.renderRow.bind(this)
      )
      stats = (
        <div key={_.uniqueId()}>
          <table className="table">
            <tbody>{tableContents}</tbody>
          </table>
        </div>
      )
    }

    return (
      <div>
        {stats}
        {this.renderModal()}
      </div>
    )
  }
}

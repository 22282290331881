import React, { Component } from 'react'
import _ from 'lodash'

import './client_history.css'

export default class Measurements extends Component {
  constructor(props) {
    super(props)

    this.measurementKeyNameList = [
      { key: 'neckCollarGirth', name: 'Neck' },
      { key: 'bicepsLeftGirth', name: 'Biceps (L)' },
      { key: 'bicepsRightGirth', name: 'Biceps (R)' },
      { key: 'chestAtBladesGirth', name: 'Chest' },
      { key: 'bustGirth', name: 'Bust' },
      { key: 'waistSmallBackGirth', name: 'Waist' },
      { key: 'seatGirth', name: 'Hips' },
      { key: 'thighLeftMaxGirth', name: 'Thigh Max (L)' },
      { key: 'thighRightMaxGirth', name: 'Thigh Max (R)' },
    ]

    const { gender } = props.rowData.stats
    const indexToRemove = gender === 'Female' ? 3 : 4

    this.measurementKeyNameList.splice(indexToRemove, 1)
  }

  renderRow(measurement) {
    const { measurements } = this.props.rowData.standardMetrics
    const { name, key } = measurement
    return (
      <tr key={_.uniqueId()}>
        <td style={{ width: '50%' }}>{name}</td>
        <td style={{ width: '50%' }}>{measurements[key]}</td>
      </tr>
    )
  }

  render() {
    const { measurements } = this.props.rowData.standardMetrics
    let stats = <div key={_.uniqueId()}>No available measurements</div>
    if (measurements) {
      const tableContents = this.measurementKeyNameList.map(
        this.renderRow.bind(this)
      )
      stats = (
        <div key={_.uniqueId()}>
          <table className="table">
            <tbody>{tableContents}</tbody>
          </table>
        </div>
      )
    }
    return <div>{stats}</div>
  }
}

import { createSelector } from 'reselect'
import { convertUTC } from '../functions/text_format'

export default createSelector(
  (state) => state.coach.get('client'),
  (records) => {
    if (!records) {
      return undefined
    }
    return records
      .filter((record) => {
        return record.get('status').toLowerCase() === 'complete'
      })
      .sortBy((record) => convertUTC(record.get('recordDate')))
      .reverse()
  }
)

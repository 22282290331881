import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BalanceCell extends Component {
  // constructor(props) {
  //   super(props);
  //   this.updateSelected = this.updateSelected.bind(this);
  // }
  //
  // updateSelected() {
  //   const { name, value, updateSelected } = this.props;
  //   updateSelected(name, value);
  // }

  render() {
    // const { name, value, selected } = this.props;
    const { name, value } = this.props
    // const isSelected = selected && name === selected.name && value === selected.value;
    // const selectedStyle = { background: '#1686b0', color: 'white' };
    return (
      // <td
      //   key={ name }
      //   onClick={ this.updateSelected }
      //   style={ isSelected ? selectedStyle : null }>{ value }</td>
      <td key={name}>{value}</td>
    )
  }
}

BalanceCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  selected: PropTypes.object,
  updateSelected: PropTypes.func,
}

import React, { Component } from 'react'
import { Provider } from 'react-redux'
// import { persistStore } from 'redux-persist';
// import immutableTransform from 'redux-persist-transform-immutable';
import { Router, Route } from 'react-router-dom'
// import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history'

import store from './store'

import App from './containers/app'
// import Loading from '../components/scenes/utilities/loading';

export default class Root extends Component {
  // constructor() {
  //   super();
  //   this.state = { rehydrated: false };
  // }
  //
  // componentWillMount() {
  //   const { store } = this.props;
  //   const persistConfig = {
  //     whitelist: ['search', 'datasets', 'admin', 'adminPosture', 'qc'],
  //     transforms: [immutableTransform({})]
  //   };
  //
  //   persistStore(store, persistConfig, () => {
  //     this.setState({ rehydrated: true });
  //   });
  // }

  render() {
    const history = createBrowserHistory()
    // if (!this.state.rehydrated) {
    //   return <Loading />;
    // }
    return (
      <Provider store={store}>
        <Router history={history}>
          <Route path="/" component={App} />
        </Router>
      </Provider>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Pagination from './pagination'

import { capitalize } from '../../functions/text_format'

export const griddleWrapper = (
  WrappedComponent,
  key,
  selectData,
  filterData
) => {
  class GriddleWrapper extends Component {
    constructor(props) {
      super(props)

      this.setPage = this.setPage.bind(this)
      this.setPageSize = this.setPageSize.bind(this)

      let data
      if (props[key] && selectData(props[key])) {
        data = this.getData(props[key])
      }

      const maxPages = data ? this.getMaxPages(data) : 10

      this.state = {
        currentPage: 0,
        pageSize: 10,
        maxPages,
        data,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps[key] && selectData(nextProps[key])) {
        const data = this.getData(nextProps[key])
        const maxPages = this.getMaxPages(data)

        this.setState({ data, maxPages })
      }
    }

    // Get data from state. If a filter method is passed from wrapped component, apply it
    getData(dataObject) {
      return filterData ? filterData(dataObject) : selectData(dataObject)
    }

    getMaxPages(data) {
      const count = data.size
      const pageSize = this.state ? this.state.pageSize : 10 // need to do this check bc this function is called in constructor before state.pageSize is set
      return Math.ceil(count / pageSize)
    }

    capitalize(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
    }

    setPageSize(size) {
      const { data, currentPage } = this.state
      const count = data.size
      const maxPages = Math.ceil(count / size)
      const newCurrentPage = currentPage > maxPages ? maxPages - 1 : currentPage

      this.setState({
        pageSize: parseInt(size, 10),
        maxPages,
        currentPage: newCurrentPage,
      })
    }

    setPage(index) {
      const newIndex =
        index > this.state.maxPages
          ? this.state.maxPages
          : index < 1
          ? 1
          : index + 1
      this.setState({ currentPage: newIndex - 1 })
    }

    render() {
      const { data, currentPage, pageSize, maxPages } = this.state

      const pageProperties = {
        currentPage: currentPage + 1,
        pageSize,
        maxPages,
      }

      const paginationProps = {
        setPageSize: this.setPageSize,
        setPage: this.setPage,
        currentPage: currentPage + 1,
        pageSize,
      }

      const components = {
        Pagination: () => (
          <Pagination
            setPageSize={this.setPageSize}
            setPage={this.setPage}
            currentPage={currentPage + 1}
            pageSize={pageSize}
          />
        ),
        SettingsToggle: () => <span />,
      }

      const styleConfig = {
        classNames: {
          Filter: 'griddle-filter form-control mb-3',
          Table: 'table table-bordered table-hover',
        },
      }

      return (
        <WrappedComponent
          {...this.props}
          data={data}
          pageProperties={pageProperties}
          paginationProps={paginationProps}
          components={components}
          styleConfig={styleConfig}
          capitalize={capitalize}
        />
      )
    }
  }

  const mapStateToProps = (state) => ({
    coach: state.coach,
    facility: state.facility,
    userCoaches: state.userCoaches,
  })

  return connect(mapStateToProps)(GriddleWrapper)
}

import { createSelector } from 'reselect'
import visibleRecordsSelector from './visible_records'

export default createSelector(
  (state) => state.current.get('currentScan'),
  (state) => visibleRecordsSelector(state),
  (currentScan, records) => {
    if (records.size === 0 || !currentScan) {
      return undefined
    }

    const filteredRecords = records.filter(
      (record) => record.get('id') === currentScan.get('id')
    )

    if (currentScan && filteredRecords.size > 0) {
      return filteredRecords.first() // filteredRecords.size should === 1
    } else {
      return records.first()
    }
  }
)

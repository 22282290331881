import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { metricData } from '../wellness/metric_data'
// import { fit3dWellnessData } from '../../../fit3d_data/data';
import Chart from '../wellness/chart'
// import GridHeader from '../wellness/grid_header';

export default class PrintWellness extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showExtraData: {
        bodyShape: false,
        bodyComp: false,
      },
    }
  }

  handleExtraDataRender(type) {
    const additions = { [type]: !this.state.showExtraData[type] }
    const newShowExtraData = Object.assign(
      {},
      this.state.showExtraData,
      additions
    )

    this.setState({ showExtraData: newShowExtraData })
  }

  renderHistogram(metric) {
    const { wellnessMetrics, healthRanges, units } = this.props
    const { name } = metricData(units)[metric]

    let data

    if (healthRanges.get(metric)) {
      data = healthRanges.get(metric).get('populationHistogram')
    }

    // const data = metric === 'bmr' ? {
    //   female: histograms.get('female').bmr,
    //   male: histograms.get('male').bmr
    // } : fit3dWellnessData[metric];

    if (data) {
      return (
        <div className="print-histogram">
          <Chart
            ranges={healthRanges.get(metric).get('ranges')}
            value={
              wellnessMetrics.get(metric) > 0 ? wellnessMetrics.get(metric) : 75
            }
            data={data}
            title={name}
          />
        </div>
      )
    }
    return null
  }

  renderTableContent(metrics) {
    return metrics.map((metric) => {
      const { name, units, description } = metricData(this.props.units)[metric]

      const titleStyle = {
        color: '#1686b0',
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: '16px',
        letterSpacing: '2px',
      }

      return (
        <tr id="metric-row" key={metric}>
          <td className="p-a" style={{ width: '400px' }}>
            <h4 className="m-b-0" style={titleStyle}>
              {name}
            </h4>
            <div>
              <span style={{ fontSize: '52px' }}>
                {this.props.wellnessMetrics.get(metric)}
              </span>
              <span style={{ fontSize: '28px' }}>{units}</span>
            </div>
            <div>
              <h4 style={{ fontWeight: 400 }}>What is it?</h4>
            </div>
            <div>{description}</div>
          </td>
          <td className="p-a" style={{ width: '350px' }}>
            {this.renderHistogram(metric)}
          </td>
        </tr>
      )
    })
  }

  render() {
    const bfpAccess = this.props.facility.get('bfpAccess')
    const categoryTitleStyle = {
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '2px',
    }

    return (
      <div className="mb-4">
        <div>
          <h2>Wellness Metrics</h2>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Wellness Metric</th>
              <th>How do I compare to other Fit3D users?</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ background: '#eee' }}>
              <td colSpan="4" className="text-center">
                <h4 className="text-uppercase" style={categoryTitleStyle}>
                  Body Shape
                </h4>
              </td>
            </tr>
            {this.renderTableContent(
              ['bodyShapeRating', 'wc', 'whr', 'trunkLegVolRatio'],
              'bodyShape'
            )}

            {bfpAccess !== 0 && (
              <tr style={{ background: '#eee' }}>
                <td colSpan="4" className="text-center">
                  <h4 className="text-uppercase" style={categoryTitleStyle}>
                    Body Composition
                  </h4>
                </td>
              </tr>
            )}
            {bfpAccess !== 0 &&
              this.renderTableContent(
                ['bfp', 'weight', 'fatMass', 'leanMass'],
                'bodyComp'
              )}

            <tr style={{ background: '#eee' }}>
              <td colSpan="4" className="text-center">
                <h4 className="text-uppercase" style={categoryTitleStyle}>
                  Fitness Level
                </h4>
              </td>
            </tr>
            {this.renderTableContent(['bmr'])}
          </tbody>
        </table>
      </div>
    )
  }
}

PrintWellness.propTypes = {
  histograms: PropTypes.object,
  healthRanges: PropTypes.object,
  wellnessMetrics: PropTypes.object,
  gender: PropTypes.string,
  units: PropTypes.string.isRequired,
  facility: PropTypes.object,
}

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const ethnicities = {
  0: 'Unspecified',
  1: 'Hispanic/Latino',
  2: 'American Indian or Alaska Native',
  3: 'Asian',
  4: 'Black or African American',
  5: 'Native Hawaiian or Other Pacific Islander',
  6: 'Caucasian',
}

const userInfoParsed = {
  firstName: 'First Name',
  lastName: 'Last Name',
  gender: 'Gender',
  age: 'Age',
  email: 'E-mail',
  birthDate: 'Birthday',
  timezone: 'Time Zone',
  facility: 'Facility',
  units: 'Units',
  ethnicity: 'Ethnicity',
  zipCode: 'Zip Code',
}

const ProfileCard = ({ userData, facility }) => (
  <div>
    <div className="mb-3">
      <h3>Profile Overview</h3>
    </div>
    <table className="table">
      <tbody>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>Name</b>
          </td>
          <td className="col-8">{`${userData.firstName} ${userData.lastName}`}</td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.gender}</b>
          </td>
          <td className="col-8">{userData.gender}</td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.birthDate}</b>
          </td>
          <td className="col-8">{userData.birthDate}</td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.ethnicity}</b>
          </td>
          <td className="col-8">
            {userData.ethnicity
              ? ethnicities[userData.ethnicity]
              : 'Unspecified'}
          </td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.email}</b>
          </td>
          <td className="col-8">{userData.email}</td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.facility}</b>
          </td>
          <td className="col-8">
            {facility ? facility.get('name') : 'No Facility'}
          </td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.units}</b>
          </td>
          <td className="col-8">{userData.units}</td>
        </tr>
        <tr key={_.uniqueId()}>
          <td className="col-4">
            <b>{userInfoParsed.timezone}</b>
          </td>
          <td className="col-8">{userData.timezone}</td>
        </tr>
        <tr key={_.uniqueId()} style={{ borderBottom: '1px #dedede solid' }}>
          <td className="col-4">
            <b>{userInfoParsed.zipCode}</b>
          </td>
          <td className="col-8">{userData.zipCode}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

ProfileCard.propTypes = {
  userData: PropTypes.object,
  facility: PropTypes.object,
}

export default ProfileCard

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { capitalize } from '../../../functions/text_format'

export default class PostureTable extends Component {
  parseData(num) {
    const negativeShift = this.props.view === 'side' ? 'B' : 'L'
    const positiveShift = this.props.view === 'side' ? 'F' : 'R'
    if (num < 0) {
      return `${Math.abs(num)} ${negativeShift}`
    } else if (num === '0.00' || num === 0) {
      return '0'
    }
    return `${Math.abs(num)} ${positiveShift}`
  }

  populateData(view, type) {
    const postureData = this.props.currentPosture
      .get('posture')
      .get(view)
      .get(type)
      .toJS()
    const baselinePostureData = this.props.baselinePosture
      .get('posture')
      .get(view)
      .get(type)
      .toJS()
    const mappedPostureData = []

    for (var name in postureData) {
      const diff = Math.max(
        ((postureData[name] - baselinePostureData[name]) * 10) / 10
      ).toFixed(1)
      mappedPostureData.push(
        <tr key={_.uniqueId()}>
          <td>{capitalize(name)}</td>
          <td>{this.parseData(baselinePostureData[name])}</td>
          <td>{this.parseData(postureData[name])}</td>
          <td>{this.parseData(diff)}</td>
        </tr>
      )
    }
    return mappedPostureData
  }

  // renderTableBody() {
  //   let { view } = this.props;
  //   if (view === 'back') { view = 'front'; }
  //
  //   return this.populateData(view, this.props.type);
  // }
  //
  // renderTable() {
  //   let units = this.props.user.get('user').get('units') === 'US' ? 'in' : 'cm';
  //       units = this.props.type === 'tilt' ? '\xB0' : units;
  //   return (
  //     <table className='table'>
  //       <thead>
  //         <tr>
  //           <th>Name</th>
  //           <th><span isolate='true'>Baseline</span><span isolate='true'> ({ units })</span></th>
  //           <th><span isolate='true'>Current</span><span isolate='true'> ({ units })</span></th>
  //           <th><span isolate='true'>Diff</span><span isolate='true'> ({ units })</span></th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         { this.renderTableBody() }
  //       </tbody>
  //     </table>
  //   );
  // }

  render() {
    let units = this.props.user.get('user').get('units') === 'US' ? 'in' : 'cm'
    units = this.props.type === 'tilt' ? '\xB0' : units

    let { view } = this.props
    if (view === 'back') view = 'front'

    const tableBody = this.populateData(view, this.props.type)

    return (
      <table
        className="table table-bordered table-hover"
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>
              <span isolate="true">Baseline</span>
              <span isolate="true"> ({units})</span>
            </th>
            <th>
              <span isolate="true">Current</span>
              <span isolate="true"> ({units})</span>
            </th>
            <th>
              <span isolate="true">Diff</span>
              <span isolate="true"> ({units})</span>
            </th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </table>
    )
  }
}

PostureTable.propTypes = {
  view: PropTypes.string,
  currentPosture: PropTypes.object,
  baselinePosture: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.object,
}

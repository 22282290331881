import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import visibleRecordsSelector from '../../selectors/visible_records'
import currentScanSelector from '../../selectors/current_scan'

import WellnessReport from './report'
import WellnessHistory from './history'

const Wellness = (props) => {
  const report = props.wellness.get('report')
  const reportAvailable =
    !!report && report.get('status').toLowerCase() === 'complete'

  return (
    <div>
      <div className="pt-3 pt-sm-4 px-3 px-sm-5">
        <div
          id="wellness-report-header"
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Your Wellness Report
          </div>
        </div>
        <div className="d-flex">
          <div
            style={{
              width:
                props.width > 768 && reportAvailable
                  ? 'calc(100% - 240px)'
                  : '100%',
            }}
          >
            <WellnessReport {...props} />
          </div>
          {props.width > 768 && props.currentScan && (
            <WellnessHistory {...props} />
          )}
        </div>
      </div>
    </div>
  )
}

Wellness.propTypes = {
  width: PropTypes.number,
  currentScan: PropTypes.object,
  wellness: ImmutablePropTypes.mapContains({
    report: ImmutablePropTypes.mapContains({
      status: PropTypes.string.isRequired,
    }),
  }),
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  fetchingRecords: state.records.get('loading'),
  records: visibleRecordsSelector(state),
  wellness: state.wellness,
  currentScan: currentScanSelector(state),
  datasets: state.datasets,
  user: state.user,
})

export default connect(mapStateToProps)(Wellness)

import {
  FETCH_POSTURE_DATA,
  FETCH_POSTURE_DATA_SUCCESS,
  FETCH_POSTURE_DATA_FAILURE,
} from '../actions/types'
import Immutable from 'immutable'

const reducers = {
  [FETCH_POSTURE_DATA]: (state) => {
    return state.set('loading', true)
  },
  [FETCH_POSTURE_DATA_SUCCESS]: (state, action) => {
    return state
      .set('postureData', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [FETCH_POSTURE_DATA_FAILURE]: (state) => {
    return state.set('loading', false)
  },
}

const defaultState = Immutable.Map({
  loading: false,
  postureData: new Immutable.List(),
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

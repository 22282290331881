import React, { Component } from 'react'
import PropTypes from 'prop-types'

const sliceKeyNameList = [
  { key: 'predictTopBack', name: 'Top Back' },
  { key: 'predictMiddleFront', name: 'Middle Front' },
  { key: 'predictMiddleBack', name: 'Middle Back' },
  { key: 'predictBottomFront', name: 'Bottom Front' },
  { key: 'predictBottomBack', name: 'Bottom Back' },
  { key: 'predictArmAngle', name: 'Arm Angle' },
]

const values = {
  0: 'N/A',
  1: 'Yes',
  2: 'No',
}

class AutoValidation extends Component {
  // static sliceKeyNameList = [
  //   { key: 'predictNeck', name: 'Neck' },
  //   { key: 'predictBust', name: 'Bust' },
  //   { key: 'predictUnderbust', name: 'Underbust' },
  //   { key: 'predictWaistNatural', name: 'Waist Natural' },
  //   { key: 'predictWaist', name: 'Waist' },
  //   { key: 'predictHips', name: 'Hips' },
  //   { key: 'predictThighMidLeft', name: 'Thigh Mid (L)' },
  //   { key: 'predictThighMidRight', name: 'Thigh Mid (R)' },
  //   { key: 'predictCalfLeft', name: 'Calf (L)' },
  //   { key: 'predictCalfRight', name: 'Calf (R)' },
  //   { key: 'predictAnkleLeft', name: 'Ankle (L)' },
  //   { key: 'predictAnkleRight', name: 'Ankle (R)' }
  // ];

  renderTableRows() {
    return sliceKeyNameList.map(({ key, name }) => {
      return (
        <tr key={key} style={{ height: '43px' }}>
          <td>{name}</td>
          <td>{values[this.props.value.get(key)] || 'N/A'}</td>
        </tr>
      )
    })
  }

  render() {
    if (this.props.value) {
      return (
        <table className="table" style={{ width: '225px' }}>
          <tbody>{this.renderTableRows()}</tbody>
        </table>
      )
    }
    return <div className="text-center">No values available.</div>
  }
}

AutoValidation.propTypes = {
  value: PropTypes.object,
}

export default AutoValidation

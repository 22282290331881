import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ReprocessModal from './ReprocessModal'
import DownloadModal from './DownloadModal'

const AdminRecordActions = ({ cellProperties, value }) => {
  const [isReprocessModalOpen, setReprocessModalOpen] = React.useState(false)
  const [isDownloadModalOpen, setDownloadModalOpen] = React.useState(false)

  const handleSelectedScan = () => {
    cellProperties.updateSelectedScan({
      scanPackageId: value.get('scanPackageId'),
      recordId: value.get('recordId'),
    })
  }

  const handleReprocessAction = (data) => {
    cellProperties.handleReprocess(value.get('scanPackageId'), data)
    setReprocessModalOpen(false)
  }

  const selectedScan = value.get('selectedScan')
  const selectedScanPackageId = selectedScan
    ? selectedScan.get('scanPackageId')
    : null
  const scanButtonColor =
    selectedScanPackageId === value.get('scanPackageId') ? 'blue' : 'black'

  return (
    <div>
      <ul className="list-inline text-center mb-0">
        <li className="list-inline-item">
          <span
            className="icon icon-man"
            role="button"
            onClick={handleSelectedScan}
            style={{ color: scanButtonColor }}
          />
        </li>
        <li className="list-inline-item">
          <span
            className="icon icon-cycle"
            role="button"
            onClick={() => setReprocessModalOpen(true)}
          />
        </li>
        <li className="list-inline-item">
          <span
            className="icon icon-download"
            role="button"
            onClick={() => setDownloadModalOpen(true)}
          />
        </li>
      </ul>
      <ReprocessModal
        handleReprocessAction={handleReprocessAction}
        isReprocessOpen={isReprocessModalOpen}
        closeReprocessModal={() => setReprocessModalOpen(false)}
      />
      <DownloadModal
        scanPackageId={value.get('scanPackageId')}
        isDownloadOpen={isDownloadModalOpen}
        closeDownloadModal={() => setDownloadModalOpen(false)}
      />
    </div>
  )
}

AdminRecordActions.propTypes = {
  value: ImmutablePropTypes.map,
  cellProperties: PropTypes.object.isRequired,
}

export default AdminRecordActions

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import WellnessTable from './wellness_table'
import WellnessChart from './wellness_chart'

class WellnessComparisonInfo extends Component {
  constructor(props) {
    super(props)

    this.changeMetricType = this.changeMetricType.bind(this)

    const unitSystem = this.props.user.get('user').get('units')
    const units = unitSystem === 'US' ? 'lbs' : 'kg'
    const bfpAccess = this.props.user
      .get('user')
      .get('facility')
      .get('bfpAccess')

    let bodyComp = [{ weight: 'Weight', units: `(${units})` }]
    if (bfpAccess !== 0) {
      bodyComp = [
        { bfp: 'Body Fat Percent', units: '%' },
        { weight: 'Weight', units: `(${units})` },
        { fatMass: 'Fat Mass', units: `(${units})` },
        { leanMass: 'Lean Mass', units: `(${units})` },
      ]
    }

    this.wellnessMetrics = {
      bodyShape: [
        { bodyShapeRating: 'Body Shape Rating', units: '' },
        { trunkLegVolRatio: 'Trunk to Leg Volume Ratio', units: '' },
      ],
      bodyComp,
    }

    this.state = { selected: 'bodyShape' }
  }

  changeMetricType(event) {
    window.ga(
      'send',
      'event',
      'Form Event',
      'Select Changed',
      'selected wellness comparison data type'
    )
    this.setState({ selected: event.target.value })
  }

  render() {
    const handleChange = this.changeMetricType
    return (
      <div className="m-xs-0" style={{ width: '100%' }}>
        <div
          className="mb-2"
          style={{
            color: '#137499',
            fontFamily: 'Lato, sans-serif',
            fontSize: '18px',
            fontWeight: 400,
          }}
        >
          Select a wellness group:
        </div>
        <select
          onChange={handleChange}
          className="custom-select"
          style={{ width: '100%', borderRadius: 0, marginBottom: '10px' }}
        >
          <option value="bodyShape">Body Shape</option>
          <option value="bodyComp">Body Composition</option>
        </select>
        <WellnessTable
          wellnessMetrics={this.wellnessMetrics}
          selected={this.state.selected}
          currentScan={this.props.currentScan}
          baselineScan={this.props.baselineScan}
          user={this.props.user}
          currentMetric={this.props.currentMetric}
          baselineMetric={this.props.baselineMetric}
        />
        <WellnessChart
          records={this.props.records}
          currentScan={this.props.currentScan}
          baselineScan={this.props.baselineScan}
          selectedMetric={this.state.selected}
        />
      </div>
    )
  }
}

WellnessComparisonInfo.propTypes = {
  records: PropTypes.object.isRequired,
  currentScan: PropTypes.object,
  baselineScan: PropTypes.object,
  currentMetric: PropTypes.object,
  baselineMetric: PropTypes.object,
  user: PropTypes.object,
}

export default WellnessComparisonInfo

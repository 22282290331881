import React from 'react'
import { connect } from 'react-redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import ScannerEditButton from './edit/Button'

import { sortName } from '../../griddle/utils/helper_functions'

const ScannersTable = ({
  scanners,
  pageSize,
  handleOpen,
  setScannerEditDefaults,
}) => {
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered',
    },
  }

  return (
    <Griddle
      data={scanners.map((scanner) => parseData(scanner))}
      plugins={[plugins.LocalPlugin]}
      pageProperties={{ pageSize }}
      components={components}
      styleConfig={styleConfig}
    >
      <RowDefinition>
        <ColumnDefinition id="scanner" title="Scanner" sortMethod={sortName} />
        <ColumnDefinition
          id="facility"
          title="Facility"
          sortMethod={sortName}
        />
        <ColumnDefinition id="hwVersion" title="HW" />
        <ColumnDefinition id="status" title="Reg. Status" />
        <ColumnDefinition id="systemId" title="System ID" />
        <ColumnDefinition id="sliderSn" title="Slider SN" />
        <ColumnDefinition id="turntableSn" title="Turntable SN" />
        <ColumnDefinition id="controlModuleId" title="CM ID" />
        <ColumnDefinition
          id="actions"
          title="Actions"
          onOpen={handleOpen}
          setScannerEditDefaults={setScannerEditDefaults}
          customComponent={enhancedWithRowData(ScannerEditButton)}
        />
      </RowDefinition>
    </Griddle>
  )
}

const parseData = (scanner) => {
  return {
    scanner: scanner.scannerCode,
    facility: scanner.facility ? scanner.facility.name : '',
    hwVersion: scanner.hwVersion,
    status: scanner.status,
    systemId: scanner.systemId,
    captureType: scanner.captureType,
    sliderSn: scanner.sliderSn,
    turntableSn: scanner.turntableSn,
    controlModuleId: scanner.controlModuleId,
    actions: {
      id: scanner.id,
      captureType: scanner.captureType,
      status: scanner.status,
      additionalMetricsEnabled: scanner.additionalMetricsEnabled,
    },
  }
}

const mapStateToProps = (state) => ({
  scanners: state.newAdmin.scanners.list,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) => ({
  handleOpen: () => dispatch({ type: 'OPEN_SCANNER_EDIT' }),
  setScannerEditDefaults: (params) =>
    dispatch({ type: 'UPDATE_FACILITY_SCANNER', payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScannersTable)

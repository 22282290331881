import React, { Component } from 'react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default class Remove extends Component {
  constructor(props) {
    super(props)

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      modalIsOpen: false,
      griddleKeyUpdated: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { griddleKeyUpdated } = this.state
    const manageCoachStatus = nextProps.value.get('manageCoachStatus')

    if (
      nextProps.griddleKey === griddleKeyUpdated &&
      manageCoachStatus === 'success'
    ) {
      this.props.cellProperties.updateSelected(this.props.value.get('userId'))
      this.closeModal()
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.props.cellProperties.clearStatus()
    this.setState({
      modalIsOpen: false,
      griddleKeyUpdated: null,
    })
  }

  handleSubmit() {
    const data = {
      userId: this.props.value.get('userId'),
      accessLevel: 'none',
    }

    this.props.cellProperties.manageCoach(data)
    this.setState({ griddleKeyUpdated: this.props.griddleKey })
  }

  renderStatus() {
    switch (this.props.value.get('manageCoachStatus')) {
      case 'failure':
        return (
          <span className="text-danger">
            Failure to remove coach. Please try again or contact support.
          </span>
        )
      case 'fetching':
        return (
          <div className="text-center">
            <strong style={{ color: '#FA9300' }}>Updating...</strong>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    const handleOpen = this.openModal
    const handleClose = this.closeModal
    const handleSubmit = this.handleSubmit
    return (
      <div className="text-center">
        <button
          type="button"
          className="btn btn-outline-danger btn-sm"
          onClick={handleOpen}
        >
          Remove
        </button>
        <Modal isOpen={this.state.modalIsOpen}>
          <ModalHeader closeButton>
            <h5>Confirm Remove Access</h5>
          </ModalHeader>
          <ModalBody>
            <p>
              Remove all access for coach? You can always add these coaches back
              later in the Add Coaches page.
            </p>
            <div>{this.renderStatus()}</div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-default" onClick={handleClose}>
              Close
            </button>
            <button className="btn btn-danger" onClick={handleSubmit}>
              Remove Access
            </button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

import Immutable from 'immutable'
import { FETCH_PRINT } from '../actions/types'

const reducers = {
  [FETCH_PRINT]: (state, action) => {
    return state.set('print', action.payload)
  },
}

const defaultPrintState = Immutable.Map({ print: '' })

export default function records(state = defaultPrintState, action) {
  if (typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

import React from 'react'
import PropTypes from 'prop-types'

import ScanImage from '../../scans/scan_image'

const ImageCol = ({ imageId, src }) => (
  <div>
    <div className="mb-3">
      <ScanImage style={{ width: '100%' }} imageId={imageId} src={src} />
    </div>
    <div className="mb-xs-3 mb-md-0 print-none">
      Click on value in the chart for a comparison against your demographic. The
      comparison will be shown on the right.
    </div>
  </div>
)

ImageCol.propTypes = {
  imageId: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default ImageCol

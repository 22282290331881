import React from 'react'
import PropTypes from 'prop-types'

const subPageText = '#657272'
const subPageTextSelected = '#4cb8c4'

const SubNav = (props) => (
  <ul className="mb-3 list-unstyled">
    {props.subNavProps.map(({ text, clickHandler, isSelected }) => (
      <li
        key={text}
        className="py-1"
        style={
          isSelected
            ? {
                paddingLeft: '2rem',
                color: subPageTextSelected,
                fontWeight: 400,
              }
            : { paddingLeft: '2rem', color: subPageText }
        }
        onClick={clickHandler}
      >
        {text}
      </li>
    ))}
  </ul>
)

SubNav.propTypes = {
  subNavProps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      clickHandler: PropTypes.func.isRequired,
      isSelected: PropTypes.bool,
    })
  ),
}

export default SubNav

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as AuthActions from '../../actions/auth'

class FileDownload extends Component {
  constructor(props) {
    super(props)

    this.fetchDownloadLink = this.fetchDownloadLink.bind(this)
    this.state = { fetchingLink: false }
  }

  fetchDownloadLink(src) {
    if (!this.state.fetchingLink) {
      this.setState({ fetchingLink: true })
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }

      return new Promise((resolve, reject) => {
        window
          .fetch(src, options)
          .then((response) => response.json())
          .then((responseJSON) => {
            if (responseJSON.status === 401) {
              this.props.oauth2Logout()
            } else if (!responseJSON.downloadUrl) {
              reject(new Error('No url returned'))
            } else {
              window.open(responseJSON.downloadUrl, '_blank')
              this.setState({ fetchingLink: false })

              resolve()
            }
          })
          .catch((err) => {
            this.setState({ fetchingLink: false })
            reject(err)
          })
      })
    }
    return null
  }

  render() {
    return (
      <div
        id={this.props.linkId}
        onClick={() => this.fetchDownloadLink(this.props.src)}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    )
  }
}

FileDownload.propTypes = {
  oauth2Logout: PropTypes.func.isRequired,
  children: PropTypes.node,
  linkId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => ({
  oauth2Logout: () => dispatch(AuthActions.oauth2Logout()),
})

export default connect(null, mapDispatchToProps)(FileDownload)

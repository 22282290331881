import React, { Component } from 'react'
import _ from 'lodash'

import './client_history.css'

export default class Form extends Component {
  showInputError(refName, value) {
    const error = document.getElementById(`${refName}Error`)
    const regex = /^(\d+)?([,.]?\d{0,2})?$/

    if (!regex.test(value)) {
      this.refs[refName].setCustomValidity(
        'Enter a number up to two decimal points'
      )
      error.textContent = 'Enter a number up to two decimal points.'
      return false
    } else {
      this.refs[refName].setCustomValidity('')
      error.textContent = ''
      return true
    }
  }

  updateInputData(type, event) {
    const { classList, name, value } = event.target
    classList.add('active')
    this.showInputError(name, value)
    this.props.updateInputData(type, value)
  }

  renderStatusText() {
    const { message, isUpdating } = this.props
    if (message && message === 'failure') {
      return (
        <div className="text-danger mb-3">
          'Failed to update additional metrics. Please try again or contact
          support.'
        </div>
      )
    } else if (message && message === 'success') {
      return <div className="text-success mb-3">Update successful!</div>
    } else if (isUpdating) {
      return (
        <div className="mb-3 text-center">
          <strong>Updating...</strong>
        </div>
      )
    } else {
      return null
    }
  }

  renderOptions() {
    return this.props.metrics.map((metric) => {
      const { typeId, name, units, label } = metric
      const { inputData, getDefaultValue, isUpdating } = this.props
      const defaultValue = inputData[label] || getDefaultValue(metric) || ''
      return (
        <div
          key={typeId}
          className="form-group"
          style={{ marginBottom: '5px', display: 'flex' }}
        >
          <label
            className="mr-3"
            style={{
              marginTop: '5px',
              fontWeight: 400,
              whiteSpace: 'nowrap',
              width: this.props.labelWidth || '250px',
            }}
          >
            {`${name} (${units})`}
          </label>
          <input
            defaultValue={defaultValue}
            onChange={this.updateInputData.bind(this, label)}
            ref={typeId}
            name={typeId}
            disabled={isUpdating}
            className="form-control input-sm"
            type="text"
          />
          <div
            id={`${typeId}Error`}
            className="col-xs-12 col-sm-4 text-danger"
          />
        </div>
      )
    })
  }

  render() {
    const handleClose = this.props.closeModal
    return (
      <form key={_.uniqueId()}>
        <div className="row mb-4 mx-0">{this.renderOptions()}</div>
        <div className="row d-flex justify-content-center mx-0">
          {this.renderStatusText()}
        </div>
        <div className="row mx-0 d-flex">
          <div className="ml-auto">
            <button
              className="btn btn-default btn-sm ml-auto mr-3"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.props.handleSubmit}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    )
  }
}

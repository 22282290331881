import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'

export default class FacilityStatChart extends Component {
  constructor(props) {
    super(props)

    this.config = {
      chart: {
        type: 'spline',
        height: 320,
        style: {
          fontFamily: 'Helvetica',
        },
        spacingLeft: 10,
      },
      title: {
        text: '',
      },
      plotOptions: {
        lineWidth: 2,
        startOnTick: false,
        minPadding: 0,
      },
      credits: {
        enabled: false,
      },
      legend: {
        verticalAlign: 'top',
        align: 'right',
        float: true,
        itemStyle: {
          color: '#888',
          fontWeight: '300',
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e, %Y',
        },
        title: {
          text: 'Month',
          style: {
            textTransform: 'uppercase',
            letterSpacing: 2,
            fontSize: '11px',
            fontWeight: 400,
          },
          x: -30,
          y: 10,
        },
      },
      yAxis: {
        title: {
          text: props.yTitle,
          style: {
            textTransform: 'uppercase',
            letterSpacing: 2,
            fontSize: '11px',
            fontWeight: 400,
          },
          x: -10,
        },
        style: {
          verticalAlign: 'middle',
        },
        gridZIndex: -10,
        tickInterval: 1,
      },
      series: props.series,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.config.series = nextProps.series
  }

  render() {
    return <ReactHighcharts config={this.config} />
  }
}

FacilityStatChart.propTypes = {
  yTitle: PropTypes.string,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
}

import React from 'react'
import ScanImage from '../../../scans/scan_image'

export default function PreviewImages({ scanPackageId }) {
  return (
    <div className="row mx-0 mt-3">
      <div className="col-sm-12 mb-3">
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/preview/0`}
          style={{ width: 360 }}
        />
      </div>
      <div className="col-sm-12 mb-3">
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/preview/1`}
          style={{ width: 360 }}
        />
      </div>
      <div className="col-sm-12 mb-3">
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/preview/2`}
          style={{ width: 360 }}
        />
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import NoTranslate from '../../../hocs/NoTranslate'
import ScanVideo from '../../../scans/scan_video'
import ScanControl from '../../../scans/scan_control'
import './images.css'

const ScanModal = ({
  status,
  clientId,
  recordId,
  name,
  isScanModalOpen,
  closeScanModal,
}) => (
  <Modal
    isOpen={isScanModalOpen}
    toggle={closeScanModal}
    dialogClassName="scanModal"
  >
    <ModalHeader toggle={closeScanModal} style={{ borderBottom: 'none' }}>
      <h3 className="text-center">
        <NoTranslate>{name}</NoTranslate>'s Scan
      </h3>
    </ModalHeader>
    <ModalBody>
      <div className="mx-auto" style={{ width: '400px' }}>
        <ScanVideo
          scanPackageId={recordId}
          animationStatus={status}
          videoId="v0"
          videoSourceMP4="currentSourceMP4"
          videoSourceWebm="currentSourceWebm"
          src={`/v1/facility/clients/${clientId}/records/${recordId}/scan`}
        />
        <ScanControl />
      </div>
    </ModalBody>
  </Modal>
)

ScanModal.propTypes = {
  status: PropTypes.object,
  clientId: PropTypes.string,
  recordId: PropTypes.string,
  name: PropTypes.string,
  isScanModalOpen: PropTypes.bool.isRequired,
  closeScanModal: PropTypes.func.isRequired,
}

export default ScanModal

import { createSelector } from 'reselect'
import visibleScanSelector from './visible_scans'
import Immutable from 'immutable'

const visibleScans = (state) => visibleScanSelector(state)
const scanMeasurementSelector = (state) =>
  state.entities.get('scanMeasurements')

const getValidMeasurements = (measurements, scans) => {
  var validMeasurementIds = []

  scans.forEach((scan) => validMeasurementIds.push(scan.get('scanMeasurement')))

  const validMeasurements = validMeasurementIds.map((id) => {
    return { id, measurements: measurements.get(id.toString()) }
  })

  return Immutable.fromJS(validMeasurements)
}

export default createSelector(
  scanMeasurementSelector,
  visibleScans,
  getValidMeasurements
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import NoTranslate from '../hocs/NoTranslate'
import Pagination from './pagination'
import ScanImage from '../scans/scan_image'
import Loading from '../utilities/loading'

import { formatDateTime } from '../../functions/text_format'
import { generateRecords } from './utils/generate_records'
import './history.css'

// Only need to change this part to update UI :)
export const SCANS_PER_PAGE = 8

const statusStyle = (status) => {
  const style = {
    borderRadius: '2px',
    background: '#f4c20d',
    color: 'white',
    fontWeight: 500,
  }

  const generalStatus = status.toLowerCase()
  if (generalStatus === 'complete' || generalStatus === 'visible') {
    style.background = 'rgb(86, 184, 184)'
  } else if (generalStatus === 'incomplete' || generalStatus === 'hidden') {
    style.background = '#E64759'
  }
  return style
}

class HistoryScans extends Component {
  constructor(props) {
    super(props)

    this.setPage = this.setPage.bind(this)

    this.state = {
      selectedScan: null,
      currentPage: 0,
      maxPages:
        props.records.size > 0
          ? Math.ceil(props.records.size / SCANS_PER_PAGE)
          : null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.records.size === 0 && nextProps.records.size !== 0) {
      this.setState({
        maxPages: Math.ceil(nextProps.records.size / SCANS_PER_PAGE),
      })
    }
  }

  setPage(index) {
    // clicking the next button, if index < 1, set page as 1. if index > maxPages, set it at maxPages, otherwise increase page by 1
    const newIndex =
      index > this.state.maxPages
        ? this.state.maxPages
        : index < 1
        ? 1
        : index + 1
    this.setState({ currentPage: newIndex - 1 })
  }

  render() {
    const { records, selectedScan } = this.props
    const { currentPage, maxPages } = this.state
    const pageProperties = {
      currentPage: currentPage + 1,
      maxPages,
    }

    if (this.props.fetchingRecords) {
      return <Loading />
    }

    const recordsDisplayed = generateRecords(records).get(
      currentPage.toString()
    )

    return (
      <div style={{ width: '100%' }}>
        <div className="row mx-0">
          {recordsDisplayed &&
            recordsDisplayed.map((record) => {
              const scanPackageId = record.get('scanPackageId')
              return (
                <div
                  key={record.get('id')}
                  style={{
                    width:
                      this.props.width >= 768
                        ? `${(1 / (SCANS_PER_PAGE / 2)) * 100}%`
                        : '50%',
                    float: 'left',
                  }}
                >
                  <div
                    className="card text-center mb-3 history-card"
                    style={{
                      margin: '5px',
                      boxShadow:
                        selectedScan &&
                        record.get('id') === selectedScan.get('id')
                          ? '0px 1px 4px rgba(0, 0, 0, 0.45)'
                          : null,
                    }}
                    onClick={() => this.props.scanClickHandler(record)}
                  >
                    <div className="mx-auto px-2">
                      <ScanImage
                        imageId={`img-${scanPackageId}`}
                        style={{ width: '100%' }}
                        src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${scanPackageId}/scan/img/0/type/blank`}
                      />
                    </div>
                    <div className="mb-2">
                      <NoTranslate>
                        {formatDateTime(record.get('recordDate'))}
                      </NoTranslate>
                    </div>
                    <div className="px-0 px-lg-2 pb-3">
                      <span
                        className="badge badge-pill mx-1 px-2 py-1"
                        style={statusStyle(record.get('status'))}
                      >
                        Status
                      </span>
                      <span
                        className="badge badge-pill mx-1 px-2 py-1"
                        style={statusStyle(record.get('postureStatus'))}
                      >
                        Posture
                      </span>
                      <span
                        className="badge badge-pill badge-warning mx-1 px-2 py-1"
                        style={statusStyle(record.get('visibility'))}
                      >
                        Visible
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        {records && records.size > SCANS_PER_PAGE && (
          <div className="row mx-0">
            <div className="ml-auto">
              <Pagination
                pageProperties={pageProperties}
                setPage={this.setPage}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

HistoryScans.propTypes = {
  width: PropTypes.number,
  fetchingRecords: PropTypes.bool.isRequired,
  records: PropTypes.object.isRequired,
  selectedScan: PropTypes.object,
  scanClickHandler: PropTypes.func,
}

const mapStateToProps = (state) => ({
  fetchingRecords: state.records.get('loading'),
})

export default connect(mapStateToProps)(HistoryScans)

export const parseValue = (value, direction) => {
  let trajectory // lmao, this is for left/right + forward/backward [direction is already used]

  // check first to see if value is negative. then check to see if it is positive. then apply proper values
  switch (direction) {
    case 'side':
      trajectory = value < 0 ? ' backward' : value > 0 ? ' forward' : ''
      break
    case 'front':
    case 'back':
    default:
      trajectory = value < 0 ? ' left' : value > 0 ? ' right' : ''
      break
  }

  return `${Math.abs(value)}${trajectory}`
}

import React from 'react'
import PropTypes from 'prop-types'

import '../parq.css'

const RatingsGroup = ({ input, options }) => {
  const { name } = input

  const radioButtons = options.map(({ label, value }) => {
    const id = `${name}-${label}-${value}`
    const checked = input.value === value
    const activeRatingsStyling = checked ? 'activeRatingsHolder' : ''

    return (
      <label
        key={id}
        className={`text-center ratingsHolder ${activeRatingsStyling}`}
      >
        <input type="radio" {...input} value={value} checked={checked} />
        {label}
      </label>
    )
  })

  return (
    <div className="input-row">
      <div>{radioButtons}</div>
    </div>
  )
}

RatingsGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

export default RatingsGroup

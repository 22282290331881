import React from 'react'
import { connect } from 'react-redux'

const DeprecateStatusMessage = ({ deprecatedScanner, deprecateStatus }) => {
  const statusMessage = {
    loading: 'Deprecating...',
    success: (
      <p>
        <span className="text-success">Successfully deprecated scanner!</span>
        <br />
        Deprecated scanner code:{' '}
        <b>{deprecatedScanner.deprecatedScannerCode}</b>
      </p>
    ),
    failure: <span className="text-danger">Failed to deprecate scanner</span>,
  }

  const message = statusMessage[deprecateStatus] || deprecateStatus || ''

  return <div className="mt-3">{message}</div>
}

const mapStateToProps = (state) => ({
  deprecatedScanner: state.newAdmin.scanners.deprecatedScanner,
  deprecateStatus: state.newAdmin.scanners.deprecateStatus,
})

export default connect(mapStateToProps)(DeprecateStatusMessage)

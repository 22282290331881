import Immutable from 'immutable'
import {
  // FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  // UPDATE_EMAIL_SUCCESS,
  CLEAR_MESSAGE,
} from '../actions/user/types'

const reducers = {
  // [FETCH_USER]: (state) => state.set('fetchingUser', true),
  [FETCH_USER_SUCCESS]: (state, action) => {
    return state
      .set('user', Immutable.fromJS(action.payload.data))
      .set('fetchingUser', false)
  },
  [FETCH_USER_FAILURE]: (state) => state.set('fetchingUser', false),
  // Update user profile
  [UPDATE_USER]: (state) => {
    return state.set('loadingUserUpdate', true)
  },
  [UPDATE_USER_SUCCESS]: (state, action) => {
    return state
      .set('user', Immutable.fromJS(action.payload.data))
      .set('loadingUserUpdate', false)
      .set('editMessage', 'success')
  },
  [UPDATE_USER_FAILURE]: (state) => {
    return state.set('loadingUserUpdate', false).set('editMessage', 'failure')
  },
  // Update user's password
  [UPDATE_PASSWORD]: (state) => state.set('loadingPasswordUpdate', true),
  [UPDATE_PASSWORD_SUCCESS]: (state, action) => {
    return state
      .set('user', Immutable.fromJS(action.payload.data))
      .set('loadingPasswordUpdate', false)
  },
  [UPDATE_PASSWORD_FAILURE]: (state) =>
    state.set('loadingPasswordUpdate', false),

  // [UPDATE_EMAIL_SUCCESS]: (state, action) => {
  //   console.log(action.payload.data);
  //   return state.set('user', Immutable.fromJS(action.payload.data))
  //           .set('loadingPasswordUpdate', false);
  // },

  [CLEAR_MESSAGE]: (state) => {
    return state.set('editMessage', null)
  },
}

// const token = window.localStorage.getItem('token');

const defaultState = new Immutable.Map({
  fetchingUser: true, // Default to loading, since app start = already fetching
  // auth: {
  //   isLoggedIn: !!(token && !(typeof token === 'string' && token === 'undefined'))
  // },
  loadingUserUpdate: false,
  loadingPassword: false,
  editMessage: null,
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

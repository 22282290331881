import React, { Component } from 'react'

export const ModalHOC = (WrappedComponent) => {
  class TestModal extends Component {
    constructor(props) {
      super(props)

      this.openModal = this.openModal.bind(this)
      this.closeModal = this.closeModal.bind(this)

      this.state = { isModalOpen: false }
    }

    openModal() {
      this.setState({ isModalOpen: true })
    }

    closeModal() {
      this.setState({ isModalOpen: false })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isModalOpen={this.state.isModalOpen}
          openModal={this.openModal}
          closeModal={this.closeModal}
        />
      )
    }
  }

  return TestModal
}

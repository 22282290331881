import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactHighcharts from 'react-highcharts'

// import ComparisonChart from './chart';
import ComparisonChartModal from './hocs/chart_modal'
import { capitalize, convertUTC } from '../../functions/text_format'

import { makeMarker } from '../../functions/chart'

class ChartContainer extends Component {
  constructor(props) {
    super(props)

    const units = this.props.units === 'US' ? 'in' : 'cm'

    this.config = {
      chart: {
        type: 'spline',
        height: 230,
        style: {
          fontFamily: 'Helvetica',
        },
        spacingLeft: 10,
      },
      title: {
        text: '',
      },
      plotOptions: {
        lineWidth: 2,
        startOnTick: false,
        minPadding: 0,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          const { x, y, series } = this.point
          return (
            `<b>${series.name}:</b> ${y} ${units}<br />` +
            moment(x)
              .utcOffset(moment().utcOffset())
              .format('YYYY-MM-DD (hh:mm A)')
          )
        },
      },
      legend: {
        verticalAlign: 'top',
        align: 'right',
        float: true,
        itemStyle: {
          color: '#888',
          fontWeight: '300',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      series: this.getData(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.config.series = this.getData(nextProps)
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.modalIsOpen !== this.state.modalIsOpen) { return true; }
  //   return (
  //     nextProps.data[0].name !== this.props.data[0].name ||
  //       this.props.currentScan.get('id') !== nextProps.currentScan.get('id') ||
  //         this.props.baselineScan.get('id') !== nextProps.baselineScan.get('id')
  //   );
  // }

  // getDates() {
  //   let arr = [];
  //   this.props.records.forEach(record => {
  //     if (record.get('scanMeasurement')) {
  //       arr.push(convertUTC(record.get('recordDate')));
  //     }
  //   });
  //   return arr;
  // }

  getMeasurements(records) {
    return records.filter((record) => !!record.get('scanMeasurement'))
  }

  getData(props) {
    const selectedSegment = props.bodySegments[props.selectedSegment]
    const recordsWithMeasurements = this.getMeasurements(props.records)
      .toJS()
      .reverse()
    const series = []

    for (let i = 0; i < selectedSegment.length; i++) {
      const bodySegmentData = []
      const bodySegmentsKey = Object.keys(selectedSegment[i])[0]

      for (let j = 0; j < recordsWithMeasurements.length; j++) {
        const date = convertUTC(recordsWithMeasurements[j].recordDate)
        const measurement =
          recordsWithMeasurements[j].scanMeasurement[bodySegmentsKey]

        if (convertUTC(props.currentScan.get('recordDate')) === date) {
          bodySegmentData.push(makeMarker(date, measurement, '#cc6666'))
        } else if (convertUTC(props.baselineScan.get('recordDate')) === date) {
          bodySegmentData.push(makeMarker(date, measurement, '#FF00FF'))
        } else {
          bodySegmentData.push([date, measurement])
        }
      }

      const name = selectedSegment[i][bodySegmentsKey]
      series.push({
        name,
        data: bodySegmentData,
      })
    }
    return series
  }

  render() {
    return (
      <ComparisonChartModal
        width={this.props.width}
        config={this.config}
        header={`${capitalize(this.props.selectedSegment)} Comparison`}
      >
        <ReactHighcharts config={this.config} />
      </ComparisonChartModal>
    )
  }
}

ChartContainer.propTypes = {
  records: PropTypes.object,
  currentScan: PropTypes.object,
  baselineScan: PropTypes.object,
  bodySegments: PropTypes.object,
  selectedSegment: PropTypes.string,
  units: PropTypes.string,
  width: PropTypes.number,
}

export default ChartContainer

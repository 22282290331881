import { createSelector } from 'reselect'
import { isValidRecord } from '../functions/records'

export default createSelector(
  (state) => state.records.get('records'),
  (records) => {
    return records.filter((record) => {
      return (
        isValidRecord(record) &&
        record.get('visibility') === 'visible' &&
        record.get('status').toLowerCase() === 'complete'
      )
    })
  }
)

import React from 'react'
import { connect } from 'react-redux'

import { fetchFacilities } from '../../../actions/admin/facilities'

import Loading from '../../utilities/loading'
import Pagination from '../Pagination'
import Search from '../Search'
import Header from './Header'
import FacilityTable from './FacilityTable'
import FacilityEdit from './edit'

const searchOptions = [{ value: 'name', label: 'Facility Name' }]

const AdminFacilities = ({ loading, fetchFacilities }) => {
  React.useEffect(() => {
    fetchFacilities({
      page: 0,
      size: 10,
    })
  }, [])

  const handleFetch = (params) => {
    fetchFacilities(params)
  }

  return (
    <div className="mx-4">
      <Header />
      <div className="mb-5 small-padding">
        <Search options={searchOptions} fetchData={handleFetch} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <FacilityTable />
            <FacilityEdit />
            <div className="row mx-0 d-flex justify-content-end">
              <Pagination fetchData={handleFetch} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchFacilities: (params) => dispatch(fetchFacilities(params)),
})

export default connect(null, mapDispatchToProps)(AdminFacilities)

import { parseComma } from '../../../../functions/text_format'

const regex = /^(\d+)?([,.]?\d{0,1})?$/

export const required = (value) =>
  !value || value === '' ? 'Value required' : undefined

export const oneDecimalPoint = (value) =>
  !regex.test(value) ? 'Enter a number up to one decimal point' : undefined

export const weightUS = (value) =>
  value &&
  (parseFloat(parseComma(value)) > 600 || parseFloat(parseComma(value)) < 40)
    ? 'Invalid weight value'
    : undefined

export const weightMetric = (value) =>
  value &&
  (parseFloat(parseComma(value)) > 272.1 ||
    parseFloat(parseComma(value)) < 18.1)
    ? 'Invalid weight value'
    : undefined

export const heightUS = (value) =>
  value &&
  (parseFloat(parseComma(value)) > 96 || parseFloat(parseComma(value)) < 36)
    ? 'Invalid height value'
    : undefined

export const heightMetric = (value) =>
  value &&
  (parseFloat(parseComma(value)) > 240 || parseFloat(parseComma(value)) < 91)
    ? 'Invalid height value'
    : undefined

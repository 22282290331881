import {
  FETCH_ADMIN_USERS_SEARCH_SUCCESS,
  CLEAR_USERS_SEARCHED,
} from '../../actions/admin/users'
import Immutable from 'immutable'

const reducers = {
  [FETCH_ADMIN_USERS_SEARCH_SUCCESS]: (state, action) => {
    return state.set('usersSearched', action.payload.data)
  },

  [CLEAR_USERS_SEARCHED]: (state) => state.set('usersSearched', null),
}

const defaultState = new Immutable.Map({
  loading: false,
  updatingData: false, // for individual modal loading UI
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

import { createSelector } from 'reselect'

import visibleRecordsSelector from './visible_records'

export default createSelector(
  (state) => state.current.get('baselineScan'),
  (state) => visibleRecordsSelector(state),
  (baselineScan, records) => {
    if (records.size === 0 || !baselineScan) {
      return undefined
    }

    if (records.find((record) => record.get('id') === baselineScan.get('id'))) {
      return baselineScan
    } else {
      return records.last()
    }
  }
)

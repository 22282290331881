import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import { withClients } from '../wrappers/with_clients';
import { fetchClientData, resetClientData } from '../../../actions/coach/client'
import { updateCurrentScan, updateBaselineScan } from '../../../actions/records'
import completeClientRecordsSelector from '../../../selectors/complete_client_records'
import clientBaselineSelector from '../../../selectors/client_baseline_scan'
import clientCurrentSelector from '../../../selectors/client_current_scan'

import ScanPanel from './scan_panel'
import ComboControl from '../../comparison/combo_control'
import WellnessComparisonInfo from '../../comparison/wellness_info'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

import '../../comparison/comparison.css'

class ClientComparison extends Component {
  componentDidMount() {
    const clientId = this.props.match.params.userId
    this.props.fetchClientData(clientId)

    if (
      (this.props.user.get('coach') || this.props.user.get('facilityAdmin')) &&
      window._cio &&
      window._cio.page
    ) {
      window._cio.page(`/staff/clients/${clientId}/comparison/wellness`)
    }
  }

  renderBaselineScanPanel(className) {
    const { userId } = this.props.match.params
    const recordId = this.props.baselineScan.get('id')
    const accessLevel = this.props.baselineScan.get('accessLevel')
    const clientProps = {
      accessLevel,
      clientId: userId,
      recordId,
    }

    let src = `/v1/facility/clients/${userId}/records/${recordId}/scan`
    if (accessLevel && accessLevel !== 'full') {
      src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/0/type/slice`
    }

    return (
      <div key="baseline" className={className}>
        <ScanPanel
          hideControl
          type="baseline"
          header="Baseline"
          videoId="v0"
          currentScan={this.props.baselineScan} // TODO: need to update this in state
          records={this.props.clientRecords}
          clientProps={clientProps}
          customStyle="measurement-scan-panel"
          updateScan={this.props.updateBaselineScan}
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  renderScanPanel(className) {
    const { userId } = this.props.match.params
    const recordId = this.props.currentScan.get('id')
    const accessLevel = this.props.currentScan.get('accessLevel')
    const clientProps = {
      accessLevel,
      clientId: userId,
      recordId,
    }

    let src = `/v1/facility/clients/${userId}/records/${recordId}/scan`
    if (accessLevel && accessLevel !== 'full') {
      src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/0/type/slice`
    }

    return (
      <div key="current" className={className}>
        <ScanPanel
          hideControl
          header="Current"
          videoId="v1"
          currentScan={this.props.currentScan} // TODO: need to update this in state
          records={this.props.clientRecords}
          clientProps={clientProps}
          customStyle="measurement-scan-panel"
          updateScan={this.props.updateCurrentScan}
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  render() {
    const {
      windowDimensions: { width },
      coach,
      clientRecords,
      currentScan,
      baselineScan,
    } = this.props

    if (coach.get('loading') || !clientRecords) {
      return <Loading />
    }

    if (clientRecords && clientRecords.size < 2) {
      return (
        <Unavailable title="Not enough valid or visible scans">
          <div>
            There needs to be at least 2 different validated/visible
            scans.&nbsp; Click{' '}
            <u
              onClick={() => this.props.history.push('/staff/clients/records')}
            >
              here
            </u>{' '}
            to return to all client records.
          </div>
        </Unavailable>
      )
    } else if (!currentScan || !baselineScan) {
      return <Loading />
    }

    return (
      <div className="pt-3 pt-sm-4 px-3 px-sm-5">
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Wellness Comparison
          </div>
        </div>
        <div className="row equal">
          <div className="col-lg-8 pl-0">
            <div className="row mx-0" style={{ marginBottom: '1.25rem' }}>
              {this.renderBaselineScanPanel('col-sm-6')}
              {this.renderScanPanel('col-sm-6')}
            </div>
            {(!width || width > 768) && (
              <div className="row mb-3 mx-0">
                <div
                  style={{
                    margin: '0 0.6rem',
                    padding: '15px 10px 15px 20px',
                    width: '100%',
                    border: '1px #ddd solid',
                  }}
                >
                  <ComboControl
                    currentScanId={currentScan.get('scanPackageId')}
                    baselineScanId={baselineScan.get('scanPackageId')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 pr-md-0">
            <div className="measurement-panel">
              <WellnessComparisonInfo
                currentScan={this.props.currentScan}
                baselineScan={this.props.baselineScan}
                currentMetric={this.props.currentScan.get('wellnessMetrics')}
                baselineMetric={this.props.baselineScan.get('wellnessMetrics')}
                user={this.props.user}
                records={this.props.clientRecords}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClientComparison.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
  }),
  coach: PropTypes.object,
  clientRecords: PropTypes.object,
  // clientData: PropTypes.object,
  baselineScan: PropTypes.object,
  currentScan: PropTypes.object,
  user: PropTypes.object,
  fetchClientData: PropTypes.func,
  resetClientData: PropTypes.func,
  updateCurrentScan: PropTypes.func.isRequired,
  updateBaselineScan: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    windowDimensions: state.windowDimensions,
    clientRecords: completeClientRecordsSelector(state),
    baselineScan: clientBaselineSelector(state),
    currentScan: clientCurrentSelector(state),
    coach: state.coach,
    user: state.user,
  }
}

const actions = {
  fetchClientData,
  resetClientData,
  updateCurrentScan,
  updateBaselineScan,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

// export default withClients(
//   connect(mapStateToProps, mapDispatchToProps)(ClientComparison),
//   'coach',
//   DataSource => DataSource.get('client')
// );
export default connect(mapStateToProps, mapDispatchToProps)(ClientComparison)

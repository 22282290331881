import axios from 'axios'

// const url = process.env.REACT_APP_API_BASEURL ? process.env.REACT_APP_API_BASEURL : '/';
// add endpoint here so we only have to change base url in one place

export const action = (
  { endpoint, method, data, actionTypes, meta },
  dispatch
) => {
  // TODO: consolidate with restAPI.js
  const url =
    actionTypes?.successType === 'DELETE_SCAN_RECORD'
      ? `${process.env.REACT_APP_USER_API_V2_BASEURL}${endpoint}`.replace(
          '/v1/',
          '/'
        )
      : `${process.env.REACT_APP_API_BASEURL}${endpoint}`

  const axiosParams = {
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (endpoint !== '/v1/auth/users/web') {
    axiosParams.headers.Authorization = `Bearer ${window.localStorage.getItem(
      'token'
    )}`
  }

  if (data && (method === 'POST' || method === 'DELETE')) {
    axiosParams.data = data
  }

  if (actionTypes.requestType) {
    dispatch({
      type: actionTypes.requestType,
      payload: 'requesting',
    })
  }

  return axios(axiosParams)
    .then((response) => {
      const dispatchObject = {
        type: actionTypes.successType,
        payload: response,
      }

      if (meta) dispatchObject.meta = meta
      return dispatch(dispatchObject)
    })
    .catch((err) => {
      console.log(err)
      return dispatch({
        type: actionTypes.failureType,
        payload: err,
      })
    })
}

export default action

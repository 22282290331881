import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './manage.css'

export default class Actions extends Component {
  constructor(props) {
    super(props)
    this.handleCoachChange = this.handleCoachChange.bind(this)
  }

  handleCoachChange() {
    const {
      type,
      actions: { userId },
    } = this.props.rowData
    const isCoach = !(type === 'Coach')
    const data = {
      isCoach,
      userId,
    }

    this.props.cellProperties.updateUser(data)
  }

  render() {
    const addCoach = (
      <button
        type="button"
        className="btn btn-sm btn-outline-primary"
        onClick={this.handleCoachChange}
      >
        <span className="icon icon-circle-with-plus" />
        &nbsp; Add as Coach
      </button>
    )
    const removeCoach = (
      <button
        type="button"
        className="btn btn-sm btn-danger-outline remove"
        onClick={this.handleCoachChange}
      >
        <span className="icon icon-circle-with-minus" />
        &nbsp; Remove as Coach
      </button>
    )

    return (
      <div className="mx-auto">
        {this.props.rowData.type === 'Coach' ? removeCoach : addCoach}
      </div>
    )
  }
}

Actions.propTypes = {
  rowData: PropTypes.object,
  cellProperties: PropTypes.object,
  updateFacilityUser: PropTypes.func,
}

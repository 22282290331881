import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NoTranslate extends Component {
  componentDidMount() {
    if (window.bablic) {
      window.bablic.selector('.notranslate').exclude().done()
    }
  }

  render() {
    return <div className="notranslate">{this.props.children}</div>
  }
}

NoTranslate.propTypes = {
  children: PropTypes.node,
}

export default NoTranslate

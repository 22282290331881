import React from 'react'
import PropTypes from 'prop-types'

import ScanPanelContainer from '../scans/panel_container'

const ScanPanel = (props) => {
  const notificationMessageProps = {
    ...props.notificationMessageProps,
    currentScan: props.currentScan,
    user: props.user,
  }

  const notificationProps = {
    showMessage: props.showMessage,
    hideMessage: props.hideMessage,
    isMessageShown: props.isMessageShown,
    currentScan: props.currentScan,
  }

  const scanDateProps = {
    scan: props.currentScan,
    records: props.records,
    type: props.type,
    updateScan: props.updateScan,
  }

  return (
    <ScanPanelContainer
      {...props}
      type={props.type}
      header={props.header}
      scan={props.currentScan}
      notificationProps={notificationProps}
      scanDateProps={scanDateProps}
      notificationMessageProps={notificationMessageProps}
      videoId={props.videoId}
    />
  )
}

ScanPanel.propTypes = {
  type: PropTypes.string,
  header: PropTypes.string,
  records: PropTypes.object,
  user: PropTypes.object,
  currentScan: PropTypes.object,
  updateScan: PropTypes.func.isRequired,
  status: PropTypes.object,
  history: PropTypes.object,
  notificationMessageProps: PropTypes.objectOf(PropTypes.any),
  isMessageShown: PropTypes.bool,
  showMessage: PropTypes.func,
  hideMessage: PropTypes.func,
  videoId: PropTypes.string.isRequired,
}

export default ScanPanel

export const updateScannerList = (list, updatedScanner) => {
  return list.map((scanner) => {
    if (scanner.id === updatedScanner.id) {
      return updatedScanner
    }
    return scanner
  })
}

export const updateDeprecatedScannerList = (
  list,
  oldScannerCode,
  deprecatedScannerCode
) => {
  return list.map((scanner) => {
    if (scanner.scannerCode === oldScannerCode) {
      return {
        ...scanner,
        scannerCode: deprecatedScannerCode,
      }
    }
    return scanner
  })
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  loadScanRecords,
  updateCurrentScan,
  updateBaselineScan,
} from '../../../actions/records'
import { loadPostureData } from '../../../actions/posture'

import postureRecordsSelector from '../../../selectors/posture/posture_records'
import visiblePostureSelector from '../../../selectors/posture/visible_posture_data'
// import currentScanSelector from '../../../selectors/posture/current_posture_scan';
// import baselineScanSelector from '../../../selectors/posture/baseline_posture_scan';
import currentPostureScanSelector from '../../../selectors/posture/current_posture_scan'
import baselinePostureScanSelector from '../../../selectors/posture/baseline_posture_scan'

// import BaselinePosturePanel from './baseline_posture_panel';
import PostureComparisonInfo from './posture_comparison_info'
import PosturePanel from '../posture_panel'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

class PostureComparison extends Component {
  constructor(props) {
    super(props)

    this.updateView = this.updateView.bind(this)
    this.state = {
      view: 'front',
      postureReady: false,
    }
  }

  componentDidMount() {
    if (
      this.props.user.get('user').get('facility') &&
      this.props.user.get('user').get('facility').get('postureAccess') !== 0
    ) {
      if (this.props.records.size > 0) {
        this.props.loadPostureData()
      } else {
        this.props.loadScanRecords().then(() => this.props.loadPostureData())
      }
    }
  }

  updateView(view) {
    window.ga('send', 'event', 'Button', 'click', `User viewed ${view} posture`)
    this.setState({ view })
  }

  renderBaselinePosturePanel() {
    return (
      <div className="col-sm-6">
        <PosturePanel
          title="Baseline"
          type="baseline"
          view={this.state.view}
          scan={this.props.baselineScan}
          updateScan={this.props.updateBaselineScan}
          records={this.props.postureRecords}
          history={this.props.history}
        />
      </div>
    )
  }

  renderPosturePanel() {
    return (
      <div className="col-sm-6">
        <PosturePanel
          title="Current"
          type="current"
          view={this.state.view}
          scan={this.props.currentScan}
          updateScan={this.props.updateCurrentScan}
          records={this.props.postureRecords}
          history={this.props.history}
        />
      </div>
    )
  }

  render() {
    const user = this.props.user.get('user')

    if (
      !user.get('facility') ||
      user.get('facility').get('postureAccess') !== 1
    ) {
      return (
        <Unavailable title="Unavailable for this facility">
          <div>
            Click <u onClick={() => this.props.history.push('/')}>here</u> to
            return home.
          </div>
        </Unavailable>
      )
    } else if (
      this.props.fetchingPosture ||
      !this.props.finishSettingScans ||
      !this.props.postureRecords
    ) {
      return <Loading />
    } else if (this.props.postureRecords.size < 2) {
      return (
        <Unavailable title="Not enough completed posture assessments">
          <div>
            Please take follow up scans to compare posture assessments. Click{' '}
            <u onClick={() => this.props.history.push('/posture')}>here</u> to
            return to the posture page.
          </div>
        </Unavailable>
      )
    }

    return (
      <div>
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Posture Comparison
          </div>
        </div>
        <div className="row equal">
          <div className="col-lg-8 pl-0">
            <div className="row mb-3 mx-0" style={{ marginBottom: '1.25rem' }}>
              {this.renderBaselinePosturePanel()}
              {this.renderPosturePanel()}
            </div>
          </div>
          <div className="col-lg-4 pr-md-0">
            <PostureComparisonInfo
              user={this.props.user}
              updateView={this.updateView}
              view={this.state.view}
              currentPosture={this.props.currentScan}
              baselinePosture={this.props.baselineScan}
              width={this.props.windowDimensions.width}
            />
          </div>
        </div>
      </div>
    )
  }
}

PostureComparison.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
  }),
  fetchingPosture: PropTypes.bool.isRequired,
  finishSettingScans: PropTypes.bool.isRequired,
  loadScanRecords: PropTypes.func,
  loadPostureData: PropTypes.func,
  records: PropTypes.object,
  postureRecords: PropTypes.object,
  postureData: PropTypes.object,
  currentScan: PropTypes.object,
  postureScans: PropTypes.object,
  baselineScan: PropTypes.object,
  updateCurrentScan: PropTypes.func.isRequired,
  updateBaselineScan: PropTypes.func.isRequired,
  route: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    windowDimensions: state.windowDimensions,
    records: state.records.get('records'),
    fetchingPosture: state.posture.get('loading'),
    finishSettingScans: state.current.get('finishSettingScans'),
    currentScan: currentPostureScanSelector(state),
    baselineScan: baselinePostureScanSelector(state),
    postureRecords: postureRecordsSelector(state),
    postureData: visiblePostureSelector(state),
    user: state.user,
  }
}

const actions = {
  loadScanRecords,
  loadPostureData,
  updateCurrentScan,
  updateBaselineScan,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PostureComparison)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deleteScanRecord } from '../../actions/records'

import { Modal, ModalBody } from 'reactstrap'

import { ModalHOC } from '../hocs/with_modal'
import './history.css'

class Delete extends Component {
  constructor(props) {
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete() {
    window.ga('send', 'event', 'Button', 'click', 'Delete Scan Record')
    const recordId = this.props.record.get('id')

    this.props.deleteScanRecord(recordId).then((response) => {
      if (response && response.payload && response.payload.status === 200) {
        this.props.closeModal()
      }
    })
  }

  render() {
    const handleOpen = this.props.openModal
    const handleClose = this.props.closeModal
    return (
      <div style={{ width: '100%' }}>
        <button
          className="btn btn-primary btn-block btn-square text-uppercase delete-button mx-auto mb-2"
          onClick={handleOpen}
        >
          Delete
        </button>
        <Modal isOpen={this.props.isModalOpen} toggle={this.props.closeModal}>
          <ModalBody>
            <div className="mb-3">
              Are you sure you want to <b className="text-danger">delete</b>{' '}
              your scan record? You will not be able to retrieve it in the
              future.
            </div>
            <div className="d-flex">
              <button
                onClick={handleClose}
                className="btn btn-default ml-auto mr-3"
              >
                <span>Cancel</span>
              </button>
              <button onClick={this.handleDelete} className="btn btn-danger">
                <span>Delete</span>
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

Delete.propTypes = {
  record: ImmutablePropTypes.contains({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deleteScanRecord: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteScanRecord }, dispatch)

export default ModalHOC(connect(null, mapDispatchToProps)(Delete))

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import UserCoach from '../components/userCoach'
import ManageUserCoach from '../components/userCoach/manage'

const UserCoachPage = () => (
  <div className="container">
    <Switch>
      <Route
        exact
        path="/user/coaches"
        component={(props) => <UserCoach {...props} />}
      />
      <Route
        exact
        path="/user/coaches/add"
        component={(props) => <ManageUserCoach {...props} add />}
      />
    </Switch>
  </div>
)

export default UserCoachPage

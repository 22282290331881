import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import FacilityDashboard from '../components/facility/dashboard'
import FacilityManage from '../components/facility/admin/manage'
import CoachActivity from '../components/facility/activity/coach'
// import FacilityActivity from '../components/facility/activity';

class FacilityPage extends Component {
  componentWillMount() {
    const superAdmin = this.props.user.get('user').get('superAdmin')
    const facilityAdmin = this.props.user.get('user').get('facilityAdmin')
    const coach = this.props.user.get('user').get('coach')

    if (!(superAdmin || facilityAdmin || coach)) {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <div className="container py-4">
        <Switch>
          <Route
            exact
            path="/facility/dashboard"
            component={FacilityDashboard}
          />
          <Route
            exact
            path="/facility/users"
            component={(props) => (
              <FacilityManage {...props} user={this.props.user.get('user')} />
            )}
          />
          <Route
            exact
            path="/facility/coach/activity"
            component={CoachActivity}
          />
          {/* <Route exact path='/facility/activity' component={ FacilityActivity } /> */}
        </Switch>
      </div>
    )
  }
}

FacilityPage.propTypes = {
  user: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

const mapStateToProps = (state) => ({ user: state.user })

export default connect(mapStateToProps, null)(FacilityPage)

import * as types from './types'
import action from '../middleware'

// NOTE: will change this to load all of the histogram data at the same time

export const fetchHistogramData = (gender) => (dispatch, getState) => {
  const histograms = getState().datasets.get('histograms')

  // don't dispatch action object to reducer if data for the gender already exists
  if (histograms && histograms.get(gender)) {
    return null
  }

  return action(
    {
      endpoint: `/v1/records/wellnessmetrics/histograms/${gender}`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_HISTOGRAM_DATA,
        successType: types.FETCH_HISTOGRAM_DATA_SUCCESS,
        failureType: types.FETCH_HISTOGRAM_DATA_FAILURE,
      },
      meta: { gender },
    },
    dispatch
  )
}

export const fetchHealthRanges = (clientId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/wellnessmetrics/metadata`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_HEALTH_RANGES,
        successType: types.FETCH_HEALTH_RANGES_SUCCESS,
        failureType: types.FETCH_HEALTH_RANGES_FAILURE,
      },
    },
    dispatch
  )

export const fetchWellnessHealthRanges = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/records/wellnessmetrics/metadata',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_WELLNESS_RANGES,
        successType: types.FETCH_WELLNESS_RANGES_SUCCESS,
        failureType: types.FETCH_WELLNESS_RANGES_FAILURE,
      },
    },
    dispatch
  )

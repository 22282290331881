import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PostureDataTable from './data_table'
import ImageCol from './image'
import ComparisonComponent from './comparison'

// import { capitalize } from '../../../functions/text_format';
import { parseValue } from './utils/parse_value'

export default class PostureRow extends Component {
  constructor(props) {
    super(props)

    this.updateSelected = this.updateSelected.bind(this)

    this.state = {
      selected: {
        direction: this.props.direction,
        metricType: 'head',
        orientation: 'shift',
        value: props.data.get('head').get('shift'),
      },
    }
  }

  updateSelected(metricType, orientation, value) {
    const selected = {
      direction: this.props.direction,
      metricType,
      orientation,
      value,
    }
    this.setState({ selected })
  }

  render() {
    const { recordId, degree, direction, gender, units } = this.props
    const { selected } = this.state

    const name = selected
      ? `${selected.metricType} (${selected.orientation})`
      : null
    const value = selected ? parseValue(selected.value, direction) : null

    return (
      <div key={recordId + degree + direction} className="p-3 pb-4">
        <div className="row mx-0">
          <div className="col-lg-5 col-xl-3">
            <ImageCol {...this.props} />
          </div>
          <div className="col-lg-7 col-xl-9 pl-3">
            <PostureDataTable
              {...this.props}
              selected={selected}
              updateSelected={this.updateSelected}
            />
            <ComparisonComponent
              selected={selected}
              name={name}
              value={value}
              gender={gender}
              units={units}
            />
          </div>
        </div>
      </div>
    )
  }
}

PostureRow.propTypes = {
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  degree: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  data: ImmutablePropTypes.mapContains({
    head: ImmutablePropTypes.map.isRequired,
    hip: ImmutablePropTypes.map.isRequired,
    knee: ImmutablePropTypes.map.isRequired,
    shoulder: ImmutablePropTypes.map.isRequired,
    underbust: ImmutablePropTypes.map,
  }),
  gender: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'

import ScanImage from '../scans/scan_image'

import './report.css'

const ComparisonImages = ({ baselineScan, currentScan }) => {
  return (
    <div className="comparison-images">
      <div className="comparison-img-container">
        <h5>Baseline</h5>
        <div>
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${baselineScan.get(
              'scanPackageId'
            )}/scan/img/0/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${baselineScan.get(
              'scanPackageId'
            )}/scan/img/120/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${baselineScan.get(
              'scanPackageId'
            )}/scan/img/270/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${baselineScan.get(
              'scanPackageId'
            )}/scan/img/315/type/blank`}
          />
        </div>
      </div>
      <div className="comparison-img-container">
        <h5>Current</h5>
        <div>
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${currentScan.get(
              'scanPackageId'
            )}/scan/img/0/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${currentScan.get(
              'scanPackageId'
            )}/scan/img/120/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${currentScan.get(
              'scanPackageId'
            )}/scan/img/270/type/blank`}
          />
          <ScanImage
            style={{ width: '100%' }}
            src={`${
              process.env.REACT_APP_API_BASEURL
            }/v1/records/${currentScan.get(
              'scanPackageId'
            )}/scan/img/315/type/blank`}
          />
        </div>
      </div>
    </div>
  )
}

ComparisonImages.propTypes = {
  baselineScan: PropTypes.object.isRequired,
  currentScan: PropTypes.object.isRequired,
}

export default ComparisonImages

import React from 'react'
import { connect } from 'react-redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { updateFacility } from '../../../actions/admin/facilities'
import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import FacilityEditButton from './edit/Button'

import { formatDateTime } from '../../../lib/formatText'
import { sortName } from '../../griddle/utils/helper_functions'

const FacilityTable = ({
  facilities,
  pageSize,
  updateForm,
  toggleEditModalOpen,
}) => {
  // Use our custom components instead of griddle
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered table-hover',
    },
  }

  return (
    <Griddle
      data={facilities.map((facility) => parseData(facility))}
      plugins={[plugins.LocalPlugin]}
      pageProperties={{ pageSize }}
      components={components}
      styleConfig={styleConfig}
    >
      <RowDefinition>
        <ColumnDefinition id="name" title="Facility" sortMethod={sortName} />
        <ColumnDefinition id="activationCode" title="Activation Code" />
        <ColumnDefinition id="addressZip" title="Zip" />
        <ColumnDefinition id="bfpAccess" title="BFP" />
        <ColumnDefinition id="postureAccess" title="Posture" />
        <ColumnDefinition id="setupStarted" title="Setup Started" />
        <ColumnDefinition id="defaultCoachFilter" title="Filter" />
        <ColumnDefinition id="processingPipeline" title="Processing" />
        <ColumnDefinition
          id="actions"
          title="Actions"
          onOpen={toggleEditModalOpen}
          setDefaultFormData={updateForm}
          customComponent={enhancedWithRowData(FacilityEditButton)}
        />
      </RowDefinition>
    </Griddle>
  )
}

const parseData = (facility) => {
  return {
    name: facility.name,
    status: facility.registrationStatus,
    activationCode: facility.activationCode,
    addressZip: facility.addressZip,
    bfpAccess: facility.bfpAccess,
    postureAccess: facility.postureAccess,
    scanner: facility.scannerCode,
    setupStarted: formatDateTime(facility.dateCreated),
    registrationDate: facility.dateCreatedScanner,
    defaultCoachFilter: facility.defaultCoachFilter,
    pipelinePriority: facility.pipelinePriority,
    processingPipeline:
      facility.pipelinePriority === 'standard' ? 'SPE v2' : 'SPE v1',
    actions: {
      id: facility.id,
      company: facility.company,
      addressCountry: facility.addressCountry,
      addressState: facility.addressState,
      addressCity: facility.addressCity,
    },
  }
}

const mapStateToProps = (state) => ({
  facilities: state.newAdmin.facilities.list,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) => ({
  updateFacility: (params) => dispatch(updateFacility(params)),
  updateForm: (params) =>
    dispatch({ type: 'UPDATE_ADMIN_FACILITY_FORM', payload: params }),
  toggleEditModalOpen: () => dispatch({ type: 'TOGGLE_FACILITY_EDIT_MODAL' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTable)

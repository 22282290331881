import * as types from './types'
import action from '../middleware'
// import { FETCH_ALL_COMPANIES, FETCH_REFERRAL_URL, FETCH_ALL_REFERRAL_URLS } from './types';
// import axios from 'axios';

export const fetchCompanies = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/partners',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_ALL_COMPANIES,
        successType: types.FETCH_ALL_COMPANIES_SUCCESS,
        failureType: types.FETCH_ALL_COMPANIES_FAILURE,
      },
    },
    dispatch
  )

// export function fetchCompanies() {
//   const response = axios.get('process.env.REACT_APP_API_BASEURL/v1/partners');
//   return {
//     type: FETCH_ALL_COMPANIES,
//     payload: response
//   };
// }

export const fetchReferralURL = (partnerId, recordId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/partners/${partnerId}/records/${recordId}`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_REFERRAL_URL,
        successType: types.FETCH_REFERRAL_URL_SUCCESS,
        failureType: types.FETCH_REFERRAL_URL_FAILURE,
      },
    },
    dispatch
  )

// export function fetchReferralURL(partnerId, recordId) {
//   // const response = axios({
//   //   method: 'post',
//   //   url: `process.env.REACT_APP_API_BASEURL/v1/partners/${partnerId}/records/${recordId}/`,
//   //   withCredentials: `${ process.env.INClUDE_CREDS }`,
//   //   data: {}
//   // });
//
//   const response = axios.get(`process.env.REACT_APP_API_BASEURL/v1/partners/${partnerId}/records/${recordId}`);
//   return {
//     type: FETCH_REFERRAL_URL,
//     payload: response
//   };
// }

export const fetchAllReferralURLs = (partnerId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/partners/${partnerId}/records/endpoints`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_ALL_REFERRAL_URLS,
        successType: types.FETCH_ALL_REFERRAL_URLS_SUCCESS,
        failureType: types.FETCH_ALL_REFERRAL_URLS_FAILURE,
      },
    },
    dispatch
  )

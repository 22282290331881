import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './mock_report.css'

const urls = {
  male: {
    report: '/staff/clients/sample-male/records/0/report',
    comparison: '/staff/clients/sample-male/records/0/report/comparison',
  },
  female: {
    report: '/staff/clients/sample-female/records/0/report',
    comparison: '/staff/clients/sample-female/records/0/report/comparison',
  },
}

export default class MockReport extends Component {
  constructor(props) {
    super(props)

    this.updateSelected = this.updateSelected.bind(this)
    this.state = { selectedGender: 'male' }
  }

  redirectMockPage(type) {
    const { selectedGender } = this.state
    if (selectedGender) {
      this.props.redirectPage(urls[selectedGender][type])
    }
  }

  updateSelected(event) {
    this.setState({ selectedGender: event.target.value })
  }

  render() {
    const handleSelect = this.updateSelected
    return (
      <div className="d-flex">
        <form className="ml-3">
          <div>
            <h4>Demo</h4>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                value="male"
                checked={this.state.selectedGender === 'male'}
                onChange={handleSelect}
              />
              Male
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                value="female"
                checked={this.state.selectedGender === 'female'}
                onChange={handleSelect}
              />
              Female
            </label>
          </div>
        </form>
        <div
          className="ml-3 thumbnailContainer"
          onClick={this.redirectMockPage.bind(this, 'report')}
        >
          <img
            className="thumbnail"
            src="https://s3.amazonaws.com/fit3d-cdn/demo/report-thumbnail.png"
            height="100px"
            alt=""
          />
          <div className="text-center thumbnailTitle">Sample Report</div>
        </div>
        <div
          className="ml-3 thumbnailContainer"
          onClick={this.redirectMockPage.bind(this, 'comparison')}
        >
          <img
            className="thumbnail"
            src="https://s3.amazonaws.com/fit3d-cdn/demo/report-comparison-thumbnail.png"
            height="100px"
            alt=""
          />
          <div className="text-center thumbnailTitle">Sample Comparison</div>
        </div>
      </div>
    )
  }
}

MockReport.propTypes = {
  redirectPage: PropTypes.func.isRequired,
}

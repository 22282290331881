import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BalanceImage from './image'
import BalanceDataTable from './data_table'
// import ComparisonComponent from '../../../newWellness/posture/comparison';
//
// const balanceNames = {
//   weightFrontLeft: 'front left balance',
//   weightFrontRight: 'front right balance',
//   weightBackLeft: 'back left balance',
//   weightBackRight: 'back right balance'
// };

export default class Balance extends Component {
  constructor(props) {
    super(props)

    this.getDecimalPercent = this.getDecimalPercent.bind(this)
    this.updateSelected = this.updateSelected.bind(this)

    this.state = {
      selected: {
        name: 'weightFrontLeft',
        value: props.balanceData.get('weightFrontLeftPercent'),
      },
    }
  }

  updateSelected(name, value) {
    const selected = { name, value }
    this.setState({ selected })
  }

  getDecimalPercent(value) {
    return value / 100
  }

  render() {
    const { balanceData } = this.props
    // const { gender, balanceData } = this.props;
    const { selected } = this.state

    // const name = selected ? selected.name : null;
    // const value = selected ? `${selected.value}%` : null;

    return (
      <div className="mb-5">
        <div>
          <h2>Balance</h2>
        </div>
        <div className="card card-default" style={{ borderRadius: 0 }}>
          <div className="card-body">
            <div className="row mx-0">
              <div className="col-lg-6 col-xl-4 mb-3 print-none">
                <BalanceImage
                  getDecimalPercent={this.getDecimalPercent}
                  balanceData={balanceData}
                />
              </div>
              <div className="col-lg-6 col-xl-8 pl-lg-4">
                <BalanceDataTable
                  updateSelected={this.updateSelected}
                  balanceData={balanceData}
                  selected={selected}
                />
              </div>
              {/* <div className='col-md-4'>
                <ComparisonComponent
                  selected={ selected }
                  name={ balanceNames[name] }
                  value={ value }
                  gender={ gender } />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Balance.propTypes = {
  balanceData: PropTypes.object,
  gender: PropTypes.string.isRequired,
}

import Immutable from 'immutable'

import * as types from '../actions/marketplace/types'

const reducers = {
  [types.FETCH_ALL_COMPANIES_SUCCESS]: (state, action) => {
    return state.set('companies', Immutable.fromJS(action.payload.data))
  },
  [types.FETCH_ALL_REFERRAL_URLS_SUCCESS]: (state, action) => {
    return state.set('companyUrls', Immutable.fromJS(action.payload.data))
  },
}

const defaultState = Immutable.Map({
  companies: new Immutable.List(),
  companyUrls: new Immutable.Map(),
})

export default function records(state = defaultState, action) {
  if (typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

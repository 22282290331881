export const measurementKeyNameList = {
  neckCollarGirth: 'Neck',
  chestAtBladesGirth: 'Chest',
  bustGirth: 'Bust',
  underbustGirth: 'Underbust',
  waistSmallBackGirth: 'Waist',
  seatGirth: 'Hips',
  bicepsLeftGirth: 'Left Biceps',
  bicepsRightGirth: 'Right Biceps',
  forearmLeftGirth: 'Left Forearm',
  forearmRightGirth: 'Right Forearm',
  thighLeftMaxGirth: 'Left Thigh',
  thighRightMaxGirth: 'Right Thigh',
  calfLeftGirth: 'Left Calf',
  calfRightGirth: 'Right Calf',
  ankleLeftGirth: 'Left Ankle',
  ankleRightGirth: 'Right Ankle',
  hipTopGirth: 'Top Hip',
  elbowLeftGirth: 'Left Elbow',
  elbowRightGirth: 'Right Elbow',
  wristLeftGirth: 'Left Wrist',
  wristRightGirth: 'Right Wrist',
  kneeLeftGirth: 'Left Knee',
  kneeRightGirth: 'Right Knee',
}

export const wellnessMetricsKeyNameList = {
  bfp: 'BF% V3.1',
  bfp31: 'BF% V3.1',
  bfp4: 'BF% V4',
  bmi: 'BMI',
  bmr: 'BMR',
  bodyShapeRating: 'BSR',
  fatMass: 'Fat Mass',
  height: 'Height',
  leanMass: 'Lean Mass',
  trunkLegVolRatio: 'TrunkToLeg',
  wc: 'Waist Circumference',
  weight: 'Weight',
  weightManual: 'Manual Weight',
  whr: 'Waist to Hip Ratio',
}

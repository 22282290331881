// import {
//   FETCH_USER_COACHES,
//   FETCH_USER_COACHES_SUCCESS,
//   FETCH_USER_COACHES_FAILURE,
//   MANAGE_USER_COACH,
//   MANAGE_USER_COACH_SUCCESS,
//   MANAGE_USER_COACH_FAILURE,
//   CLEAR_STATUS
// } from './types';
import * as types from './types'
import action from '../middleware'
// import axios from 'axios';

// export function fetchCoaches() {
//   return {
//     type: FETCH_USER_COACHES,
//     payload: 'fetching'
//   };
// }
//
// export function loadCoaches() {
//   return (dispatch) => {
//     // const coaches = getState().userCoaches.get('userCoaches');
//     //
//     // if (coaches) { return null; }
//
//     dispatch(fetchCoaches());
//     return axios.get(`${process.env.REACT_APP_API_BASEURL}/v1/coaches`)
//       .then(response => {
//         dispatch({
//           type: FETCH_USER_COACHES_SUCCESS,
//           payload: response
//         });
//       })
//       .catch(error => {
//         dispatch({
//           type: FETCH_USER_COACHES_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

export const loadCoaches = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/coaches',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_USER_COACHES,
        successType: types.FETCH_USER_COACHES_SUCCESS,
        failureType: types.FETCH_USER_COACHES_FAILURE,
      },
    },
    dispatch
  )

export const manageCoach = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/coaches/${params.userId}/access`,
      method: 'POST',
      data: params,
      actionTypes: {
        requestType: types.MANAGE_USER_COACH,
        successType: types.MANAGE_USER_COACH_SUCCESS,
        failureType: types.MANAGE_USER_COACH_FAILURE,
      },
      meta: {
        userId: params.userId,
      },
    },
    dispatch
  )

// function manageCoachRequest() {
//   return {
//     type: MANAGE_USER_COACH,
//     payload: 'fetching'
//   };
// }
//
// export function manageCoach(params) {
//   return (dispatch) => {
//     dispatch(manageCoachRequest());
//
//     return axios({
//       method: 'post',
//       url: `${process.env.REACT_APP_API_BASEURL}/v1/coaches/${params.userId}/access`,
//       data: params
//     }).then(response => {
//       dispatch({
//         type: MANAGE_USER_COACH_SUCCESS,
//         payload: response,
//         meta: {
//           userId: params.userId
//         }
//       });
//     })
//     .catch(error => {
//       dispatch({
//         type: MANAGE_USER_COACH_FAILURE,
//         payload: error
//       });
//     });
//   };
// }

export function clearStatus() {
  return {
    type: types.CLEAR_STATUS,
    payload: null,
  }
}

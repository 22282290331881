import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, ModalBody } from 'reactstrap'

import {
  fetchReferralURL,
  fetchAllReferralURLs,
} from '../../actions/marketplace/index.js'
import currentScanSelector from '../../selectors/current_scan'
import visibleRecordsSelector from '../../selectors/visible_records'

import { ModalHOC } from '../hocs/with_modal'
import ShopModal from './shop_modal'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

import './marketplace.css'

class StorePanel extends Component {
  constructor(props) {
    super(props)

    this.fetchReferralURL = this.fetchReferralURL.bind(this)
    this.getQueryHTMLValues = this.getQueryHTMLValues.bind(this)
    this.openShopModal = this.openShopModal.bind(this)

    this.state = {
      view: 'start',
      currentOpened: null,
    }
  }

  openShopModal() {
    window.ga(
      'send',
      'event',
      'Button',
      'click',
      `User opened shop modal with partnerId: ${this.props.id}`
    )
    const partnerId = this.props.id

    this.props.fetchAllReferralURLs(partnerId).then((response) => {
      if (response.payload.status === 200) {
        this.props.openModal()
        this.setState({ currentOpened: this.props.code })
      }
    })
  }

  fetchReferralURL() {
    const recordId = this.props.currentScan.get('id')
    const partnerId = this.props.id

    this.props.fetchReferralURL(partnerId, recordId).then((response) => {
      if (response && response.payload && response.payload.status === 200) {
        this.setState({ view: 'complete' })
      }
    })
  }

  getStantQueryValues(urls, currentScan) {
    if (urls.get(String(currentScan.get('id')))) {
      const queryString = urls
        .get(currentScan.get('id').toString())
        .split('?')[1]
      const queries = queryString.split('&')

      const queryValues = {}

      queries.forEach((query) => {
        const key = query.split('=')[0]
        const value = query.split('=')[1]
        queryValues[key] = value
      })

      return queryValues
    }
    return null
  }

  getQueryHTMLValues(values) {
    const queryHtmlValues = []

    for (const key in values) {
      queryHtmlValues.push(
        <input key={key} type="hidden" name={key} value={values[key]} />
      )
    }

    return queryHtmlValues
  }

  renderModalBody() {
    const { records, currentScan } = this.props
    const urls = this.props.marketplace.get('companyUrls')

    const handleClose = this.props.closeModal

    if (!this.props.currentScan || records.size === 0) {
      return <Unavailable title="There are no visible scans to view" />
    } else if (urls.size === 0) {
      return <Loading />
    } else if (this.state.view === 'complete') {
      const values = this.getStantQueryValues(urls, currentScan)
      const { chest, waist, sleeve } = values
      return (
        <div className="mt-3 mb-3">
          <h3>Thank You!</h3>
          <div className="mb-3">
            Thanks for using the Fit3D marketplace. If you have any questions,
            please contact support by using the support button!
          </div>
          {this.state.currentOpened === 'stantt' && values && (
            <p className="text-center mb-3">
              {values ? (
                <b>
                  Chest: {chest} | Waist: {waist} | Sleeve: {sleeve}
                </b>
              ) : null}
            </p>
          )}
          <div className="text-center">
            <button
              type="button"
              className="btn btn-square button-close"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      )
    }

    return (
      <ShopModal
        {...this.props}
        urls={urls}
        values={this.getStantQueryValues(urls, currentScan)}
        currentOpened={this.state.currentOpened}
        fetchReferralURL={this.fetchReferralURL}
        getQueryHTMLValues={this.getQueryHTMLValues}
        closeModal={this.props.closeModal}
      />
    )
  }

  render() {
    const { name, code, content } = this.props
    const handleOpen = this.openShopModal
    return (
      <div
        className="d-flex mx-auto mb-3 store-panel-container"
        key={code}
        style={{ width: '100%', background: '#fafafa' }}
      >
        <div className="picture-container">
          <img
            src={`https://s3.amazonaws.com/fit3d-cdn/img/partner/${code}-card.jpg`}
            alt="partner-img"
          />
        </div>
        <div className="text-center px-4 marketplace-info-container">
          <img
            className="mb-2"
            src={`https://s3.amazonaws.com/fit3d-cdn/img/partner/${code}-logo.png`}
            alt="partner-brand"
            style={{ maxWidth: '100%' }}
          />
          {content.get('tagline') && (
            <div
              className="text-uppercase py-3"
              style={{
                fontSize: '11px',
                fontWeight: 600,
                letterSpacing: '2px',
              }}
            >
              {content.get('tagline')}
            </div>
          )}
          {content.get('quote') && (
            <em className="mb-3">"{content.get('quote')}"</em>
          )}
          {content.get('quoteSource') && (
            <small>&nbsp;&mdash;{content.get('quoteSource')}</small>
          )}
          <div className="mt-2 mb-3">{content.get('description')}</div>
          <button
            className="btn btn-square text-uppercase px-3 marketplace-button"
            onClick={handleOpen}
          >
            Shop {name}
          </button>
          {content.get('footer') && (
            <div>
              <hr />
              <small style={{ fontSize: '11px' }}>
                {content.get('footer')}
              </small>
            </div>
          )}
        </div>
        <Modal
          isOpen={this.props.isModalOpen}
          className="marketplace-modal"
          toggle={this.props.closeModal}
        >
          <ModalBody>{this.renderModalBody()}</ModalBody>
        </Modal>
      </div>
    )
  }
}

StorePanel.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  records: PropTypes.object,
  currentScan: PropTypes.object,
  marketplace: PropTypes.object,
  fetchReferralURL: PropTypes.func,
  fetchAllReferralURLs: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  content: PropTypes.object,
  code: PropTypes.string,
}

const mapStateToProps = (state) => ({
  records: visibleRecordsSelector(state),
  currentScan: currentScanSelector(state),
  marketplace: state.marketplace,
  gender: state.user.get('user').get('gender'),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchReferralURL, fetchAllReferralURLs }, dispatch)

export default ModalHOC(
  connect(mapStateToProps, mapDispatchToProps)(StorePanel)
)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  loadCoaches,
  manageCoach,
  clearStatus,
} from '../../../actions/userCoach/index'

import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'
import { griddleWrapper } from '../../griddle/grid_wrapper'

import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import ManageAccess from '../access'
import AccessInstructions from '../../coach/access_instructions'
import Loading from '../../utilities/loading'

class Manage extends Component {
  constructor(props) {
    super(props)

    this.updateNotSelected = this.updateNotSelected.bind(this)

    const coaches = props.data ? props.data : null

    this.state = { coaches }
  }

  componentWillMount() {
    this.props.loadCoaches()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ coaches: nextProps.data })
    }
  }

  updateNotSelected(coachId) {
    const { coaches } = this.state
    const indexToRemove = coaches.findIndex(
      (coach) => coach.get('userId').toString() === coachId.toString()
    )
    const updatedCoachesList = coaches.delete(indexToRemove)

    this.setState({ coaches: updatedCoachesList })
  }

  parseData(coach) {
    return {
      firstName: this.props.capitalize(coach.get('firstName')),
      lastName: this.props.capitalize(coach.get('lastName')),
      coachAccessLevel: {
        userId: coach.get('userId'),
        manageCoachStatus: this.props.userCoaches.get('manageCoachStatus'),
      },
    }
  }

  render() {
    const { data, pageProperties, components, styleConfig } = this.props
    const { coaches } = this.state
    if (!data || !coaches) {
      return <Loading />
    }

    const results = coaches.map(this.parseData.bind(this)).toJS()

    return (
      <div>
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">My Coaches</h6>
            <h3 className="dashhead-title">Add Coaches</h3>
          </div>
          <div className="dashhead-toolbar">
            <button
              className="btn btn-outline-primary"
              onClick={() => this.props.history.push('/user/coaches')}
            >
              <span className="icon icon-back" />
              &nbsp; My Coaches
            </button>
          </div>
        </div>
        <hr className="mt-0 mb-4" />
        <div className="row">
          <div className="col-xs-12 col-sm-9">
            <Griddle
              data={results}
              pageProperties={pageProperties}
              plugins={[plugins.LocalPlugin]}
              components={components}
              styleConfig={styleConfig}
            >
              <RowDefinition>
                <ColumnDefinition id="firstName" title="First Name" />
                <ColumnDefinition id="lastName" title="Last Name" />
                <ColumnDefinition
                  id="coachAccessLevel"
                  title="Select Coach Type"
                  updateNotSelected={this.updateNotSelected}
                  isUserCoachAdd
                  manageCoach={this.props.manageCoach}
                  clearStatus={this.props.clearStatus}
                  capitalize={this.props.capitalize}
                  customComponent={enhancedWithRowData(ManageAccess)}
                />
              </RowDefinition>
            </Griddle>
          </div>
          <div className="text-center col-xs-12 col-sm-3">
            <AccessInstructions />
          </div>
        </div>
      </div>
    )
  }
}

const filterNotSelectedCoaches = (DataSource) => {
  return DataSource.get('userCoaches').filter((userCoach) => {
    return userCoach.get('accessLevel') === 'none'
  })
}

const mapStateToProps = (state) => ({ userCoaches: state.userCoaches })

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadCoaches, manageCoach, clearStatus }, dispatch)
}

export default griddleWrapper(
  connect(mapStateToProps, mapDispatchToProps)(Manage),
  'userCoaches',
  (DataSource) => DataSource.get('userCoaches'),
  filterNotSelectedCoaches
)

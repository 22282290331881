import React from 'react'
import { connect } from 'react-redux'

import { fetchScanners } from '../../../actions/admin/scanners'

import Loading from '../../utilities/loading'
import Search from '../Search'
import Pagination from '../Pagination'
import Header from './Header'
import ScannerTable from './ScannerTable'
import ScannerEditModal from './edit'
import ScannerDeprecate from './deprecate'

const searchOptions = [
  { value: 'scannerCode', label: 'Scanner Name' },
  { value: 'facilityName', label: 'Facility Name' },
]

const AdminScanners = ({ loading, fetchScanners }) => {
  React.useEffect(() => {
    fetchScanners({
      page: 0,
      size: 10,
    })
  }, [])

  const handleFetch = (params) => {
    fetchScanners(params)
  }

  return (
    <div className="mx-4">
      <Header />
      <div className="mb-5 small-padding">
        <Search options={searchOptions} fetchData={handleFetch} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <ScannerTable />
            <ScannerEditModal />
            <div className="row mx-0 d-flex justify-content-end">
              <Pagination fetchData={handleFetch} />
            </div>
          </>
        )}
      </div>
      <ScannerDeprecate />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchScanners: (params) => dispatch(fetchScanners(params)),
})

export default connect(null, mapDispatchToProps)(AdminScanners)

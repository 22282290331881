import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchCompanies } from '../../actions/marketplace/index.js'
import { loadScanRecords } from '../../actions/records'
import { loadMetrics } from '../../actions/wellness'

import StorePanel from './store_panel'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

export class Marketplace extends Component {
  componentDidMount() {
    this.props.fetchCompanies()
    this.props.loadScanRecords()
    this.props.loadMetrics()

    // window.localStorage.setItem('viewedMarketplace', true);
  }

  render() {
    const companies = this.props.marketplace.get('companies')
    const user = this.props.user.get('user')

    if (!companies || companies.size === 0) {
      return <Loading />
    } else if (
      user.get('facility') &&
      user.get('facility').get('servicesAccess') !== 1
    ) {
      return (
        <Unavailable title="Unavailable for this facility">
          <div>
            Click <u onClick={() => this.props.history.push('/')}>here</u> to
            return home.
          </div>
        </Unavailable>
      )
    }

    return (
      <div className="py-4">
        <div className="hr-divider header-container">
          <h1 className="hr-divider-content">Marketplace</h1>
        </div>
        <div className="container">
          {companies.map((company) => (
            <div key={company.get('id')} className="row">
              <StorePanel
                id={company.get('id')}
                name={company.get('name')}
                code={company.get('code')}
                content={company.get('content')}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Marketplace.propTypes = {
  fetchCompanies: PropTypes.func,
  loadScanRecords: PropTypes.func,
  loadMetrics: PropTypes.func,
  marketplace: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    marketplace: state.marketplace,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchCompanies, loadScanRecords, loadMetrics },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace)

import {
  ADMIN_FETCH_REFERRALS,
  ADMIN_FETCH_REFERRALS_SUCCESS,
  ADMIN_FETCH_REFERRALS_FAILURE,
  ADMIN_FETCH_RECORDS,
  ADMIN_FETCH_RECORDS_SUCCESS,
  ADMIN_FETCH_RECORDS_FAILURE,
  FETCH_ADMIN_RECORD_SUCCESS,
} from '../../actions/admin'
import { VALIDATE_RECORD_SUCCESS } from '../../actions/admin/validation'
import Immutable from 'immutable'
import parse from 'parse-link-header'

const reducers = {
  // Admin Referrals
  [ADMIN_FETCH_REFERRALS]: (state) => state.set('loading', true),

  [ADMIN_FETCH_REFERRALS_SUCCESS]: (state, action) => {
    const pageInfo = parse(action.payload.headers.link)
    return state
      .set('scanRecords', Immutable.fromJS(action.payload.data))
      .set('pageInfo', Immutable.fromJS(pageInfo))
      .set('loading', false)
  },

  [ADMIN_FETCH_REFERRALS_FAILURE]: (state) => state.set('loading', false),

  // Admin Records
  [ADMIN_FETCH_RECORDS]: (state) => state.set('loading', true),

  [ADMIN_FETCH_RECORDS_SUCCESS]: (state, action) => {
    const pageInfo = parse(action.payload.headers.link)
    return state
      .set('scanRecords', Immutable.fromJS(action.payload.data))
      .set('pageInfo', Immutable.fromJS(pageInfo))
      .set('loading', false)
  },

  [ADMIN_FETCH_RECORDS_FAILURE]: (state) => state.set('loading', false),

  // Admin Validation
  [VALIDATE_RECORD_SUCCESS]: (state, action) => {
    // Obtain index from list of admin records of element we want to change
    const index = state
      .get('scanRecords')
      .findIndex((item) => item.get('id') === action.meta.recordId)
    const updatedList = state.get('scanRecords').update(index, (item) => {
      // set particular field of element we want to change
      return item.set(
        'scanValidate',
        Immutable.fromJS(action.payload.data.scanValidate)
      )
    })

    return state.set('scanRecords', updatedList)
  },
  // Admin Record (1)
  [FETCH_ADMIN_RECORD_SUCCESS]: (state, action) => {
    return state.set('record', Immutable.fromJS(action.payload.data))
  },
}

const defaultState = new Immutable.Map({
  loading: false,
  errorMessage: null,
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { manageClient } from '../../actions/coach/client'
import * as PARQActions from '../../actions/coach/parq'
import { clearMessage } from '../../actions/coach/index'
// import moment from 'moment';

import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'
// import { griddleWrapper } from '../griddle/grid_wrapper';
import { adminGridWrapper } from '../admin/grid_wrapper'
// import MockReport from './mockReports';
// import EnhancedFilter from './filter_container';
import Filter from '../griddle/search'
import { enhancedWithRowData } from '../griddle/rowdata_wrapper'
import { CustomColumn } from '../griddle/table_components'
import ScanStatus from './scan_status'
import Actions from './actions'
import Loading from '../utilities/loading'

import { capitalize, formatDateTime } from '../../functions/text_format'

class Coach extends Component {
  constructor(props) {
    super(props)

    this.redirectPage = this.redirectPage.bind(this)
    this.handleModalDisplay = this.handleModalDisplay.bind(this)
    this.parseData = this.parseData.bind(this)

    this.state = {
      isActionOpen: false,
      isScanOpen: false,
      rowOpen: null,
    }
  }

  componentDidMount() {
    if (
      (this.props.user.get('coach') || this.props.user.get('facilityAdmin')) &&
      window._cio &&
      window._cio.page
    ) {
      window._cio.page('/staff/clients/records')
    }
  }

  redirectPage(route) {
    this.props.history.push(route)
  }

  handleModalDisplay(type, isModalOpen, griddleKey) {
    // isModalOpen can either be isActionOpen or isScanOpen
    const typeOpen = {
      actionMenu: 'isActionOpen',
      scanMenu: 'isScanOpen',
    }

    this.setState({
      [typeOpen[type]]: isModalOpen,
      rowOpen: griddleKey, // setting rowOpen to null means we're closing panels
    })
  }

  parseData(record) {
    return {
      name: `${capitalize(record.get('firstName'))} ${capitalize(
        record.get('lastName')
      )}`,
      email: record.get('email'),
      recordDate: formatDateTime(record.get('recordDate')),
      status: capitalize(record.get('status')),
      postureStatus: record.get('postureStatus'),
      accessLevel: capitalize(record.get('accessLevel')),
      parqStatus: record.get('parqDateCreated') ? 'Complete' : 'None',
      actions: {
        clientId: record.get('userId'),
        recordId: record.get('id'),
        hasPARQ: !!record.get('parqDateCreated'),
        accessRequestDate: record.get('accessRequestDate'),
        message: this.props.coach.get('message'),
      },
    }
  }

  render() {
    const { data, pageProperties, components, styleConfig } = this.props
    const { isActionOpen, isScanOpen, rowOpen } = this.state
    if (!data) {
      return <Loading />
    }

    const results = data.map(this.parseData).toJS()

    const actionMenuProps = { isActionOpen, isScanOpen, rowOpen }
    const completePageProperties = Object.assign(
      pageProperties,
      actionMenuProps
    )

    styleConfig.classNames.Table += ' table-hover'
    components.Filter = () => null

    const searchOptions = [
      { value: 'email', label: 'E-mail' },
      { value: 'firstName', label: 'First Name' },
      { value: 'lastName', label: 'Last Name' },
    ]

    const handleModalDisplay = this.handleModalDisplay

    return (
      <div className="container py-4">
        <div className="dashhead" style={{ borderBottom: 'none' }}>
          <div className="dashhead-titles mt-3">
            <h6 className="dashhead-subtitle">Success Hub</h6>
            <h3 className="dashhead-title">Client Scans</h3>
          </div>
        </div>
        <h4 className="mb-3">
          <i style={{ color: '#e47639' }}>
            View most recent scans at your facility - OR - search to filter by
            email/name.
          </i>
        </h4>
        <Filter
          submitFilteredSearch={this.props.submitFilteredSearch}
          search={this.props.search}
          searchOptions={searchOptions}
          resetSearchState={this.props.resetSearchState}
          placeholder="Search Clients (Name or E-mail)"
        />
        {this.props.loading ? (
          <Loading />
        ) : (
          <Griddle
            data={results}
            pageProperties={completePageProperties}
            plugins={[plugins.LocalPlugin]}
            components={components}
            styleConfig={styleConfig}
          >
            <RowDefinition>
              <ColumnDefinition
                id="name"
                title="Name"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="email"
                title="E-mail"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="recordDate"
                title="Scan Date"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="status"
                title="Scan Status"
                customComponent={ScanStatus}
              />
              {this.props.user
                .get('user')
                .get('facility')
                .get('postureAccess') === 1 && (
                <ColumnDefinition id="postureStatus" title="Posture Status" />
              )}
              <ColumnDefinition id="accessLevel" title="Access Level" />
              <ColumnDefinition id="parqStatus" title="PARQ" />
              <ColumnDefinition
                id="actions"
                title="Actions"
                isActionOpen={isActionOpen}
                actionMenuProps={actionMenuProps}
                redirectPage={this.redirectPage}
                handleModalDisplay={handleModalDisplay}
                getPARQInterest={this.props.getPARQInterest}
                updatePARQInterest={this.props.updatePARQInterest}
                manageClient={this.props.manageClient}
                clearMessage={this.props.clearMessage}
                customComponent={enhancedWithRowData(Actions)}
              />
            </RowDefinition>
          </Griddle>
        )}
      </div>
    )
  }
}

Coach.propTypes = {
  loading: PropTypes.bool.isRequired,
  coach: ImmutablePropTypes.mapContains({
    message: PropTypes.string,
  }),
  user: PropTypes.object,
  manageClient: PropTypes.func.isRequired,
  getPARQInterest: PropTypes.func,
  updatePARQInterest: PropTypes.func,
  clearMessage: PropTypes.func,
  submitFilteredSearch: PropTypes.func,
  search: PropTypes.shape({
    searchParam: PropTypes.string,
    searchString: PropTypes.string,
  }),
  resetSearchState: PropTypes.func.isRequired,
  data: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      accessLevel: PropTypes.string,
      accessRequestDate: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.number,
      parqDateCreated: PropTypes.string,
      postureStatus: PropTypes.string,
      recordDate: PropTypes.string,
      scanPackageId: PropTypes.string,
      status: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
  pageProperties: PropTypes.shape({
    pageSize: PropTypes.number.isRequired,
    maxPages: PropTypes.number.isRequired,
  }),
  components: PropTypes.shape({
    Pagination: PropTypes.func.isRequired,
    SettingsToggle: PropTypes.func.isRequired,
  }),
  styleConfig: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

// const filterData = (DataSource) => {
//   return DataSource.get('clients').filter(client => client.get('accessLevel') !== 'none');
// };

const mapStateToProps = (state) => ({
  coach: state.coach,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { manageClient, ...PARQActions, clearMessage },
    dispatch
  )
}

// export default griddleWrapper(
//   connect(null, mapDispatchToProps)(Coach),
//   'coach',
//   (DataSource) => DataSource.get('clients'),
//   filterData
// );

const gridOptions = {
  key: 'coach',
  recordName: 'clientRecords',
  newSearch: true,
}

export default adminGridWrapper(
  connect(mapStateToProps, mapDispatchToProps)(Coach),
  gridOptions
)

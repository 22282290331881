import React from 'react'
import PropTypes from 'prop-types'

const MeasurementImage = (props) => (
  <div className="panel">
    <div className="card-body d-flex align-items-center justify-content-center">
      <div className="text-center mx-auto">
        <h4 className="text-uppercase mb-3 nav-header">Actions</h4>
        <div>
          <div
            className="text-uppercase text-left nav-links"
            onClick={() => props.history.push('/comparison')}
          >
            <span className="icon icon-forward" />
            &nbsp; Go to comparison page
          </div>
          <div>
            {props.width && props.width > 590 && (
              <a
                className="text-uppercase text-left nav-links"
                href={`${process.env.REACT_APP_API_BASEURL}/v1/records/scans/measurements/download`}
              >
                <span className="icon icon-download" />
                &nbsp; Download Measurements
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

MeasurementImage.propTypes = {
  width: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

export default MeasurementImage

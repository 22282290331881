import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Scroll from 'react-scroll'

import { updateSelectedMetric } from '../../actions/wellness'

import ReportImages from './images'
import WellnessGrid from './wellness'
import PostureGrid from './posture/grid'
import Balance from '../coach/client_report/balance'
import Measurements from './measurements'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

class WellnessReport extends Component {
  componentDidMount() {
    this.handleScroll(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleScroll(nextProps)
  }

  componentWillUnmount() {
    this.props.updateSelectedMetric(null)
  }

  handleScroll(props) {
    if (props.selectedMetric) {
      const node = document.getElementById(props.selectedMetric)
      // console.log(node.offsetTop - 50);
      if (node) {
        Scroll.scroller.scrollTo(props.selectedMetric, {
          duration: 1000,
          delay: 100,
          smooth: true,
        })
      }
    }
  }

  render() {
    if (
      this.props.fetchingRecords ||
      this.props.wellness.get('fetchingWellnessReport') ||
      !this.props.current.get('finishSettingScans')
    ) {
      return <Loading />
    }

    const report = this.props.wellness.get('report')
    if (!report || report.get('status').toLowerCase() !== 'complete') {
      return <Unavailable title="Scan report is not available" />
    }

    const gender = this.props.user.get('user').get('gender')
    const units = this.props.user.get('user').get('units')
    const bfpAccess = this.props.user
      .get('user')
      .get('facility')
      .get('bfpAccess')

    const wellnessMetrics = report.get('wellnessMetrics')
    const posture = report.get('posture')
    const balance = report.get('balance')
    const measurements = report.get('measurements')

    return (
      <div>
        <div
          id="report-images-grid"
          ref={(images) => {
            this.images = images
          }}
        >
          <ReportImages
            recordId={report.get('id')}
            scanPackageId={report.get('scanPackageId')}
            gender={gender}
            currentScan={report}
          />
        </div>
        {wellnessMetrics && (
          <div
            id="report-wellness-grid"
            className="print-none"
            ref={(wellnessGrid) => {
              this.wellnessGrid = wellnessGrid
            }}
          >
            <WellnessGrid
              // histograms={ this.props.datasets.get('histograms') }
              recordId={report.get('id')}
              width={this.props.width}
              healthRanges={this.props.datasets.get('healthRanges')}
              wellnessMetrics={wellnessMetrics}
              scanPackageId={report.get('scanPackageId')}
              gender={gender}
              units={units}
              bfpAccess={bfpAccess}
            />
          </div>
        )}
        {posture && (
          <div
            id="report-posture-grid"
            ref={(postureGrid) => {
              this.postureGrid = postureGrid
            }}
          >
            <PostureGrid
              recordId={report.get('id')}
              report={report}
              gender={gender}
              units={units}
            />
          </div>
        )}
        {balance && (
          <div
            id="report-balance-grid"
            ref={(balanceGrid) => {
              this.balanceGrid = balanceGrid
            }}
          >
            <Balance balanceData={balance} gender={gender} />
          </div>
        )}
        {measurements && (
          <div
            id="report-measurements-grid"
            ref={(measurementGrid) => {
              this.measurementGrid = measurementGrid
            }}
          >
            <Measurements
              scanMeasurements={measurements}
              scanPackageId={report.get('scanPackageId')}
              gender={gender}
              units={units}
            />
          </div>
        )}
      </div>
    )
  }
}

WellnessReport.propTypes = {
  width: PropTypes.number,
  current: ImmutablePropTypes.contains({
    finishSettingScans: PropTypes.bool.isRequired,
  }),
  user: ImmutablePropTypes.map.isRequired,
  wellness: ImmutablePropTypes.contains({
    fetchingWellnessReport: PropTypes.bool.isRequired,
    report: ImmutablePropTypes.map,
  }).isRequired,
  datasets: ImmutablePropTypes.map,
  fetchingRecords: PropTypes.bool.isRequired,
  selectedMetric: PropTypes.string,
  updateSelectedMetric: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  current: state.current,
  selectedMetric: state.wellness.get('selectedMetric'),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateSelectedMetric }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WellnessReport)

import React from 'react'
import PropTypes from 'prop-types'

import VideoModal from '../../scans/hocs/video_modal'
import ScanImage from '../../scans/scan_image'

import { formatDateTime } from '../../../functions/text_format'

export const ReportImages = ({ currentScan, scanPackageId }) => {
  const images = [0, 45, 180, 270].map((degree) => (
    <div key={degree} className="col-sm-3 mx-auto print-image">
      <ScanImage
        imageId={`scan-img-${scanPackageId}`}
        style={{ width: '85%' }}
        src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${scanPackageId}/scan/img/${degree}/type/blank`}
      />
    </div>
  ))
  // }

  return (
    <VideoModal
      header={formatDateTime(currentScan.get('recordDate'))}
      videoId="report-video"
      scanPackageId={currentScan.get('scanPackageId')}
      shouldOpen
      src={`/v1/records/${currentScan.get('scanPackageId')}/scan`}
    >
      <div className="mb-5 px-3 py-3" style={{ border: '1px #cecece solid' }}>
        <div className="row text-center mb-3 print-image-container">
          {images}
        </div>
      </div>
    </VideoModal>
  )
}

ReportImages.propTypes = {
  gender: PropTypes.string.isRequired,
  currentScan: PropTypes.object,
  scanPackageId: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default ReportImages

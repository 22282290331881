import React from 'react'
import PropTypes from 'prop-types'

const highlightedText = '#3c4444'
const pageText = '#51565b'

// Child will be potential sub navs
const NavItem = ({ children, isSelected, text, clickHandler }) => (
  <li className="nav-item" style={{ color: pageText }}>
    <a
      className="nav-link d-flex"
      style={{
        paddingTop: '0.4rem',
        paddingBottom: !!children && isSelected ? '0.25rem' : '0.4rem',
      }}
      onClick={clickHandler}
    >
      <div style={{ fontSize: '1rem' }}>
        {/* <span className='fas fa-address-book' style={ { marginRight: '0.6rem', color: subIconColor } } /> */}
        <span
          style={
            isSelected ? { fontWeight: '500', color: highlightedText } : null
          }
        >
          {text}
        </span>
      </div>
      {!!children && (
        <span
          className={`ml-auto fas ${
            isSelected ? 'fa-angle-down' : 'fa-angle-right'
          }`}
          style={{ fontSize: '20px', paddingTop: '2px' }}
        />
      )}
    </a>
    {isSelected && children}
  </li>
)

NavItem.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
}

export default NavItem

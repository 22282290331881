import React from 'react'

import TwoPanelView from './TwoPanelView'
import ScanVideo from '../../../scans/scan_video'
import ComboControl from '../../../comparison/combo_control'

export default function ScanVideos({ scanPackageId }) {
  return (
    <>
      <TwoPanelView
        left={
          <ScanVideo
            scanPackageId={scanPackageId}
            videoId="v1"
            src={`/v1/admin/records/${scanPackageId}/scan/raw/type/blank`}
          />
        }
        right={
          <ScanVideo
            scanPackageId={scanPackageId}
            videoId="v2"
            src={`/v1/admin/records/${scanPackageId}/scan/watertight/type/blank`}
          />
        }
      />
      <div className="row mx-0">
        <ComboControl />
      </div>
    </>
  )
}

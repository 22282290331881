import React, { Component } from 'react'

import ScanImage from '../../scans/scan_image'
import SnapImage from '../../scans/snap_image'

import './client_history.css'

export default class Image extends Component {
  // renderValidNotification() {
  //   const { userValid } = this.props.rowData.scan;
  //   switch (userValid) {
  //     case 'unknown':
  //       return <div id='notification' className='notification-warning'>Has not taken survey yet</div>;
  //     case 'invalid':
  //       return <div id='notification' className='notification-danger'>Survey taken & Improper scan</div>;
  //     default:
  //       return <div id='notification' className='notification-success'>Survey taken & Proper scan</div>;
  //   }
  // }

  render() {
    const { accessLevel, userId } = this.props.cellProperties
    const { recordId, type, gender } = this.props.rowData.stats

    const fullImageUrl = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/0/type/blank`
    const silhouetteUrl = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/0/type/slice`
    const snapUrl = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/snap/img`

    let img = <ScanImage src={silhouetteUrl} />
    if (typeof type === 'string' && type.toLowerCase() === 'snap') {
      img = (
        <SnapImage
          src={snapUrl}
          placeholderSrc={`measurement-guide-${gender.toLowerCase()}.png`}
        />
      )
    } else if (accessLevel === 'full') {
      img = <ScanImage src={fullImageUrl} />
    }

    return (
      <div className="text-center image-container">
        <div className="mb-3">{img}</div>
        {/* { this.renderValidNotification() } */}
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

const ScanStatus = ({ recordStatus }) => {
  let infoText, textColor, instructionsText
  switch (recordStatus) {
    case 'Failed':
      infoText =
        'Client scan processing failed. Please retry scan again. Without a successful scan, the client report cannot be accessed.'
      instructionsText =
        "You can edit the PARQ survey or return to the list of all clients' records."
      textColor = 'text-danger'
      break
    case 'Incomplete':
      infoText =
        "Client scan is currently processing. Please wait for scan to finish processing before accessing the client report. You can edit the PARQ survey or return to the list of all clients' records."
      instructionsText =
        "You can edit the PARQ survey or return to the list of all clients' records."
      textColor = 'text-warning'
      break
    case 'Complete':
      infoText = 'Client scan has been complete!'
      instructionsText =
        "You can access the client report, edit the PARQ survey, or return to the list of all clients' records."
      textColor = 'text-success'
      break
    default:
      infoText = 'Unable to get retrieve information.'
      instructionsText = 'Please contact support for help.'
      textColor = 'text-warning'
      break
  }

  return (
    <div className="mb-4">
      <h5 className="text-center">
        <b>Scan Status:</b>&nbsp;
        <span className={textColor}>{recordStatus}</span>
      </h5>
      <p>{infoText}</p>
      <p>{instructionsText}</p>
    </div>
  )
}

ScanStatus.propTypes = {
  recordStatus: PropTypes.string.isRequired,
}

export default ScanStatus

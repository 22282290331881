import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import { Modal, ModalBody } from 'reactstrap'

import SurveyResults from './results'
import ScanStatus from './status'
import InterestLevel from '../../interest_level'
import NoTranslate from '../../../hocs/NoTranslate'
import { questions, clientIllnesses, formattedAnswers } from './key_mappings'

export default class SurveyCompleteModal extends Component {
  constructor(props) {
    super(props)

    this.updateInterestLevelSelected = this.updateInterestLevelSelected.bind(
      this
    )

    const interestLevelSelected =
      props.parqData &&
      props.parqData.get('status') &&
      props.parqData.get('status') !== 'unknown'
        ? props.parqData.get('status')
        : null
    this.state = { interestLevelSelected }
  }

  updateInterestLevelSelected(interestLevel) {
    const { clientId, recordId, updatePARQInterest } = this.props
    return () => {
      updatePARQInterest({
        clientId,
        recordId,
        data: { status: interestLevel },
      })
      this.setState({ interestLevelSelected: interestLevel })
    }
  }

  getPARQResults() {
    const answers = []
    for (const field in questions) {
      const answer = this.props.parqData.get('content').get(field)
      let formattedAnswer = 'N/A'

      if (answer && typeof answer === 'object' && answer.size > 0) {
        // format client illnesses
        formattedAnswer = answer
          .map((key) => clientIllnesses[key])
          .toJS()
          .join(', ')
      } else if (answer && typeof answer === 'string') {
        // need to format the radio button answers even more (i.e. yes => Yes)
        formattedAnswer = formattedAnswers[answer]
          ? formattedAnswers[answer]
          : answer
      }

      answers.push(
        <tr key={field}>
          <td style={{ fontWeight: 400 }}>{questions[field]}</td>
          <td>{formattedAnswer}</td>
        </tr>
      )
    }
    return answers
  }

  render() {
    const {
      redirectPage,
      isModalOpen,
      closeModal,
      clientId,
      recordId,
    } = this.props
    const recordStatus = this.props.parqData.get('recordStatus')

    return (
      <Modal size="lg" isOpen={isModalOpen} toggle={closeModal}>
        <ModalBody style={{ overflow: 'auto', display: 'flex' }}>
          <div className="col-8">
            <div className="p-t">
              <SurveyResults results={this.getPARQResults()} />
            </div>
          </div>

          <div
            className="col-4"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div>
              <h2 className="text-center mb-3">
                PARQ submission{' '}
                <span className="text-success">successful!</span>
              </h2>
              <div className="mb-4">
                <h5 className="text-center">
                  <NoTranslate>
                    {moment
                      .utc(Date.now())
                      .utcOffset(moment().utcOffset())
                      .format('YYYY-MM-DD (hh:mm A)')}
                  </NoTranslate>
                </h5>
              </div>
              <hr />

              <div className="mt-3">
                <ScanStatus recordStatus={recordStatus} />
              </div>
              <hr />

              <div className="text-center mb-3">
                <h5>
                  <b>Actions</b>
                </h5>
              </div>
              <button
                className="btn btn-primary btn-block"
                type="button"
                onClick={redirectPage(
                  `/staff/clients/${clientId}/records/${recordId}/report`
                )}
                disabled={recordStatus !== 'Complete'}
              >
                Go to Report
              </button>
              <button
                className="btn btn-outline-primary btn-block"
                type="button"
                onClick={closeModal}
              >
                Edit PARQ Survey
              </button>
              <button
                className="btn btn-outline-primary btn-block"
                type="button"
                onClick={redirectPage('/staff/clients/records')}
              >
                Return to All Client Records
              </button>
              <hr />

              <div className="text-center mb-3">
                <h5>
                  <b>Interest Level</b>
                </h5>
              </div>
              <div className="text-center mt-3 mb-3">
                <InterestLevel
                  interestLevelSelected={this.state.interestLevelSelected}
                  updateInterestLevelSelected={this.updateInterestLevelSelected}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

SurveyCompleteModal.propTypes = {
  updatePARQInterest: PropTypes.func,
  redirectPage: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  parqData: ImmutablePropTypes.mapContains({
    content: ImmutablePropTypes.map.isRequired,
    recordStatus: PropTypes.string.isRequired,
  }),
  clientId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'

const MockComparison = (props) => {
  if (
    (props.user.get('coach') || props.user.get('facilityAdmin')) &&
    window._cio &&
    window._cio.page
  ) {
    window._cio.page(props.match.url)
  }
  return (
    <div className="p-4">
      <div className="dashhead mt-4">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">Success Hub</h6>
          <h3 className="dashhead-title">Mock Client Comparison</h3>
          <h4 style={{ color: '#e47639' }}>
            <i>
              Mock client comparison page provides two <b>screenshots</b> (front
              & side) the client comparison page.
            </i>
          </h4>
        </div>
        <div className="dashhead-toolbar" style={{ marginTop: '40px' }}>
          <div
            className="btn btn-primary"
            onClick={() => props.history.push('/staff/clients/records')}
          >
            Return to All Scans
          </div>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="mb-4">
        <h3>Front View</h3>
        <img
          // src={ `https://s3.amazonaws.com/fit3d-cdn/demo/mock-comparison-${gender}-front.png` }
          src="/images/comparison-0.png"
          style={{ width: '100%', border: '1px #ccc solid' }}
          alt=""
        />
      </div>
      <div>
        <h3>Side View</h3>
        <img
          // src={ `https://s3.amazonaws.com/fit3d-cdn/demo/mock-comparison-${gender}-side.png` }
          src="/images/comparison-45.png"
          style={{ width: '100%', border: '1px #ccc solid' }}
          alt=""
        />
      </div>
    </div>
  )
}

MockComparison.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
}

export default MockComparison

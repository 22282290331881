import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import Root from './root'
import { unregister } from './registerServiceWorker'

ReactGA.initialize([
  {
    trackingId: 'UA-42214866-3',
    gaOptions: { name: 'fit3d-web' },
    debug: process.env.NODE_ENV === 'development',
  },
])

ReactDOM.render(<Root />, document.getElementById('root'))
unregister()

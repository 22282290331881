import React from 'react'
import { PaginationItem, PaginationLink } from 'reactstrap'

export default function PageSelect({
  maxPages,
  maxButtons,
  currentPage,
  handlePageSelect,
}) {
  const totalButtons = maxPages < maxButtons ? maxPages : maxButtons

  const delta = 4
  const upperbound =
    currentPage + delta > totalButtons ? currentPage + delta - 1 : totalButtons
  const range = []
  const pages = []
  // rangeWithDots = [],
  // l;
  // Generate the page numbers to be displayed

  for (let i = currentPage - delta; i <= upperbound; i++) {
    if (i <= maxPages && i >= 1) range.push(i)
  }

  // Ellipsis code, maybe use later?
  // for (let i = currentPage - delta; i <= currentPage + delta; i++) {
  //   if (i < maxPages && i > 1) range.push(i);
  // }
  //
  // console.log(range);
  //
  // range.push(maxPages);

  // for (let i of range) {
  //   console.log(i);
  //   if (l) {
  //     if (i - l === 2) {
  //       rangeWithDots.push(l + 1);
  //     } else if (i - l !== 1) {
  //       rangeWithDots.push('...');
  //     }
  //   }
  //   rangeWithDots.push(i);
  //   l = i;
  // }
  //
  // console.log(rangeWithDots);

  // Genrate page item components
  for (let i = 0; i < range.length; i++) {
    const active = currentPage + 1 === range[i]
    pages.push(
      <PaginationItem
        key={range[i]}
        active={active}
        value={range[i]}
        onClick={handlePageSelect}
      >
        <PaginationLink
          value={range[i]}
          style={{
            border: active ? '1px #1997c6 solid' : '1px #ced4da solid',
          }}
        >
          {range[i]}
        </PaginationLink>
      </PaginationItem>
    )
  }
  return pages
}

export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const FETCH_CLIENT_DATA = 'FETCH_CLIENT_DATA'
export const FETCH_CLIENT_DATA_SUCCESS = 'FETCH_CLIENT_DATA_SUCCESS'
export const FETCH_CLIENT_DATA_FAILURE = 'FETCH_CLIENT_DATA_FAILURE'

export const UPDATE_CLIENT_MEASUREMENTS = 'UPDATE_CLIENT_MEASUREMENTS'
export const UPDATE_CLIENT_MEASUREMENTS_SUCCESS =
  'UPDATE_CLIENT_MEASUREMENTS_SUCCESS'
export const UPDATE_CLIENT_MEASUREMENTS_FAILURE =
  'UPDATE_CLIENT_MEASUREMENTS_FAILURE'

export const UPDATE_CLIENT_ADDITIONAL = 'UPDATE_CLIENT_ADDITIONAL'
export const UPDATE_CLIENT_ADDITIONAL_SUCCESS =
  'UPDATE_CLIENT_ADDITIONAL_SUCCESS'
export const UPDATE_CLIENT_ADDITIONAL_FAILURE =
  'UPDATE_CLIENT_ADDITIONAL_FAILURE'

export const MANAGE_CLIENT = 'MANAGE_CLIENT'
export const MANAGE_CLIENT_SUCCESS = 'MANAGE_CLIENT_SUCCESS'
export const MANAGE_CLIENT_FAILURE = 'MANAGE_CLIENT_FAILURE'
export const REMOVE_CLIENT = 'REMOVE_CLIENT'

export const FETCH_CLIENT_RECORDS = 'FETCH_CLIENT_RECORDS'
export const FETCH_CLIENT_RECORDS_SUCCESS = 'FETCH_CLIENT_RECORDS_SUCCESS'
export const FETCH_CLIENT_RECORDS_FAILURE = 'FETCH_CLIENT_RECORDS_FAILURE'

export const FETCH_CLIENT_REPORT = 'FETCH_CLIENT_REPORT'
export const FETCH_CLIENT_REPORT_SUCCESS = 'FETCH_CLIENT_REPORT_SUCCESS'
export const FETCH_CLIENT_REPORT_FAILURE = 'FETCH_CLIENT_REPORT_FAILURE'

// export const UPDATE_CLIENT_CURRENT = 'UPDATE_CLIENT_CURRENT';
// export const UPDATE_CLIENT_BASELINE = 'UPDATE_CLIENT_BASELINE';

export const FETCH_PARQ_DATA = 'FETCH_PARQ_DATA'
export const FETCH_PARQ_DATA_SUCCESS = 'FETCH_PARQ_DATA_SUCCESS'
export const FETCH_PARQ_DATA_FAILURE = 'FETCH_PARQ_DATA_FAILURE'

export const UPDATE_PARQ_DATA = 'UPDATE_PARQ_DATA'
export const UPDATE_PARQ_DATA_SUCCESS = 'UPDATE_PARQ_DATA_SUCCESS'
export const UPDATE_PARQ_DATA_FAILURE = 'UPDATE_PARQ_DATA_FAILURE'

export const FETCH_PARQ_INTEREST = 'FETCH_PARQ_INTEREST'
export const FETCH_PARQ_INTEREST_SUCCESS = 'FETCH_PARQ_INTEREST_SUCCESS'
export const FETCH_PARQ_INTEREST_FAILURE = 'FETCH_PARQ_INTEREST_FAILURE'

export const UPDATE_PARQ_INTEREST = 'UPDATE_PARQ_INTEREST'
export const UPDATE_PARQ_INTEREST_SUCCESS = 'UPDATE_PARQ_INTEREST_SUCCESS'
export const UPDATE_PARQ_INTEREST_FAILURE = 'UPDATE_PARQ_INTEREST_FAILURE'

export const FETCH_CLIENT_SUMMARY = 'FETCH_CLIENT_SUMMARY'
export const FETCH_CLIENT_SUMMARY_SUCCESS = 'FETCH_CLIENT_SUMMARY_SUCCESS'
export const FETCH_CLIENT_SUMMARY_FAILURE = 'FETCH_CLIENT_SUMMARY_FAILURE'

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const RESET_CLIENT_DATA = 'RESET_CLIENT_DATA'
export const RESET_CLIENT_REPORT = 'RESET_CLIENT_REPORT'

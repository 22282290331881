import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import Immutable from 'immutable'

export const DEFAULT_MAX_BUTTONS = 8

// TODO: need to change fix this
class CustomPagination extends Component {
  constructor(props) {
    super(props)

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.handlePageSelect = this.handlePageSelect.bind(this)
    this.reloadClientRecords = this.reloadClientRecords.bind(this)
  }

  handlePageSizeChange(event) {
    this.props.setPageSize(event.target.value)
  }

  handlePageSelect(event) {
    this.props.setPage(parseInt(event.target.getAttribute('value'), 10) - 1)
  }

  reloadClientRecords() {
    this.props.fetchAllRecords(0, 10, true)
  }

  renderPagination() {
    const { pageProperties, maxButtons, currentPage } = this.props
    const maxPages = pageProperties.get('maxPages')
    const totalButtons = maxPages < maxButtons ? maxPages : maxButtons

    const delta = 4
    const upperbound =
      currentPage + delta > totalButtons
        ? currentPage + delta - 1
        : totalButtons
    const range = []
    const pages = []
    // rangeWithDots = [],
    // l;
    // Generate the page numbers to be displayed

    for (let i = currentPage - delta; i <= upperbound; i++) {
      if (i <= maxPages && i >= 1) range.push(i)
    }

    // Ellipsis code, maybe use later?
    // for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    //   if (i < maxPages && i > 1) range.push(i);
    // }
    //
    // console.log(range);
    //
    // range.push(maxPages);

    // for (let i of range) {
    //   console.log(i);
    //   if (l) {
    //     if (i - l === 2) {
    //       rangeWithDots.push(l + 1);
    //     } else if (i - l !== 1) {
    //       rangeWithDots.push('...');
    //     }
    //   }
    //   rangeWithDots.push(i);
    //   l = i;
    // }
    //
    // console.log(rangeWithDots);

    // Genrate page item components
    for (let i = 0; i < range.length; i++) {
      pages.push(
        <PaginationItem
          key={range[i]}
          active={currentPage === range[i]}
          value={range[i]}
          onClick={this.handlePageSelect}
        >
          <PaginationLink
            value={range[i]}
            style={{
              border:
                currentPage === range[i]
                  ? '1px #1997c6 solid'
                  : '1px #ced4da solid',
            }}
          >
            {range[i]}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return pages
  }

  render() {
    const { pageProperties, currentPage, pageSize } = this.props
    const maxPages = pageProperties.get('maxPages')

    const handleReload = this.reloadClientRecords

    return (
      <div>
        <div className="paginate">
          <div className="d-flex page-size-select">
            <strong style={{ whiteSpace: 'nowrap', lineHeight: '35px' }}>
              Select Page Size: &nbsp;&nbsp;
            </strong>
            <select
              defaultValue={pageSize || 5}
              onChange={this.handlePageSizeChange}
              className="custom-select"
              style={{ height: '35px' }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="pagination-container" style={{ marginLeft: '20px' }}>
            <Pagination>
              {/* See setPage function in /admin/grid_wrapper.js to for explanation for weird arguments lol */}
              <PaginationItem
                disabled={currentPage === 1}
                onClick={currentPage > 1 ? () => this.props.setPage(0) : null}
              >
                <PaginationLink style={{ border: '1px #ced4da solid' }}>
                  First
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                disabled={currentPage === 1}
                onClick={
                  currentPage > 1
                    ? () => this.props.setPage(currentPage - 2)
                    : null
                }
              >
                <PaginationLink
                  previous
                  style={{ border: '1px #ced4da solid' }}
                />
              </PaginationItem>
              {this.renderPagination()}
              <PaginationItem
                disabled={currentPage === maxPages}
                onClick={
                  currentPage < maxPages
                    ? () => this.props.setPage(currentPage)
                    : null
                }
              >
                <PaginationLink next style={{ border: '1px #ced4da solid' }} />
              </PaginationItem>
              <PaginationItem
                disabled={currentPage === maxPages}
                onClick={
                  currentPage < maxPages
                    ? () => this.props.setPage(maxPages - 1)
                    : null
                }
              >
                <PaginationLink style={{ border: '1px #ced4da solid' }}>
                  Last
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
          {this.props.newSearch && (
            <div className="reload-button">
              <button
                className="btn btn-outline-primary"
                onClick={handleReload}
              >
                <span className="fas fa-sync mr-1" /> Reload Client Records
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

CustomPagination.defaultProps = {
  maxButtons: DEFAULT_MAX_BUTTONS,
}

CustomPagination.propTypes = {
  maxButtons: PropTypes.number,
  pageProperties: PropTypes.object,
  setPageSize: PropTypes.func,
  setPage: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  newSearch: PropTypes.bool,
  fetchAllRecords: PropTypes.func,
}

const mapStateToProps = (state) => {
  if (Immutable.fromJS(state).get('pageProperties')) {
    return { pageProperties: Immutable.fromJS(state).get('pageProperties') }
  }
  return {}
}

export default connect(mapStateToProps)(CustomPagination)

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import { fetchAdminRecord, fetchAdminSummary } from '../../../actions/admin'

import Loading from '../../utilities/loading'
import Search from '../Search'
import Pagination from '../Pagination'
import AdminRecordsHeader from './Header'
import RecordsTable from './RecordsTable'
import MetricsTable from './MetricsTable'
import ReferenceMedia from './media'

const searchOptions = [
  { value: 'email', label: 'E-mail' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'scannerScannerCode', label: 'Scanner' },
  { value: 'scannerFacilityName', label: 'Facility' },
  { value: 'scanPackageId', label: 'PackageId' },
  { value: 'status', label: 'Status' },
  { value: 'hwVersion', label: 'Hardware Version' },
]

const AdminRecords = ({
  loading,
  admin,
  data,
  fetchAdminRecord,
  fetchAdminSummary,
}) => {
  const record = admin.get('record')
  const [selectedScan, setSelectedScan] = React.useState(
    record && record.size > 0
      ? {
          scanPackageId: record.get('scanPackageId'),
          recordId: record.get('id'),
        }
      : null
  )

  React.useEffect(() => {
    fetchAdminSummary({
      page: 0,
      size: 10,
    })
  }, [])

  const updateSelectedScan = (scan) => {
    fetchAdminRecord(scan.scanPackageId)
    setSelectedScan(scan)
  }

  const handleDataFetch = (params) => {
    fetchAdminSummary(params)
  }

  // NOTE: make styling more fluid, or add stylesheet
  return (
    <div className="mx-4">
      <AdminRecordsHeader />
      <Search options={searchOptions} fetchData={handleDataFetch} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <RecordsTable
            loading={loading}
            data={data}
            selectedScan={selectedScan}
            updateSelectedScan={updateSelectedScan}
          />
          <div className="row mx-0 d-flex justify-content-end">
            <Pagination fetchData={handleDataFetch} />
          </div>
        </>
      )}

      <div className="pt-3 mb-5 pb-5 row mx-0">
        {selectedScan && selectedScan.scanPackageId && (
          <ReferenceMedia scanPackageId={selectedScan.scanPackageId} />
        )}
        <div className="col-md-5 pr-0" style={{ marginBottom: '70px' }}>
          {record && record.get('scanMeasurement') && (
            <MetricsTable
              wellnessMetrics={record.get('wellnessMetrics')}
              measurements={record.get('scanMeasurement')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

AdminRecords.propTypes = {
  loading: PropTypes.bool.isRequired,
  admin: ImmutablePropTypes.map,
  fetchAdminRecord: PropTypes.func.isRequired,
  data: ImmutablePropTypes.list,
}

const mapStateToProps = (state) => ({
  admin: state.admin,
})
const mapDispatchToProps = (dispatch) => ({
  fetchAdminSummary: (params) => dispatch(fetchAdminSummary(params)),
  fetchAdminRecord: (packageId) => dispatch(fetchAdminRecord(packageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRecords)

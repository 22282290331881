import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { mergeUserRecords } from '../../../../actions/admin/users'

import MergeUsersList from './UsersList'
import InstructionsCard from './InstructionsCard'
import ConfirmationModal from './ConfirmationModal'
import MergeButton from './MergeButton'

const MergeUsers = ({ mergeUserRecords }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [data, setData] = React.useState({
    to: null,
    from: null,
  })
  const [emails, setEmails] = React.useState({
    to: null,
    from: null,
  })

  const handleOpen = () => setModalOpen(true)
  const handleClose = () => {
    setModalOpen(false)
    setErrorMessage('')
  }

  const updateUserIdState = (key, email, userId) => {
    setData((oldData) => ({
      ...oldData,
      [key]: userId,
    }))

    setEmails((oldData) => ({
      ...oldData,
      [key]: email,
    }))
  }

  const handleMerge = () => {
    mergeUserRecords(data)
    handleClose()
  }

  return (
    <div>
      <div>
        <h3 className="dashhead-title">Merge Users</h3>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <MergeUsersList label="from" updateUserIdState={updateUserIdState} />
        </div>
        <div className="col-sm-3">
          <MergeUsersList label="to" updateUserIdState={updateUserIdState} />
        </div>
        <div className="col-sm-3">
          <MergeButton data={data} onClick={handleOpen} />
        </div>
        <div className="col-sm-3 mt-3">
          <InstructionsCard />
        </div>
      </div>
      <ConfirmationModal
        modalOpen={modalOpen}
        emails={emails}
        errorMessage={errorMessage}
        onClose={handleClose}
        onMerge={handleMerge}
      />
    </div>
  )
}

MergeUsers.propTypes = {
  mergeUserRecords: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  mergeUserRecords: (params) => dispatch(mergeUserRecords(params)),
})

export default connect(null, mapDispatchToProps)(MergeUsers)

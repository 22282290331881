import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchAdminUsersSearch,
  clearUsersSearched,
} from '../../../../actions/admin/users'

class MergeUsersList extends Component {
  constructor(props) {
    super(props)

    // handle clicking outside of the merge users list area
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.updateInput = this.updateInput.bind(this)
    this.updateSelectedUserId = this.updateSelectedUserId.bind(this)

    this.state = {
      value: '',
      matchedUsers: [],
      selectedUserId: null,
      focused: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.usersSearched && nextProps.usersSearched.length > 0) {
      this.setState({
        matchedUsers: nextProps.usersSearched,
      })
    }
  }

  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
  }

  // handle click events
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ focused: false })
    }
  }

  handleFocus() {
    this.setState({ focused: true })
  }

  // update state input
  updateInput(event) {
    const params = {
      page: 0,
      size: 10,
    }

    if (event.target.value) {
      params.search = {
        searchParam: 'email',
        searchString: event.target.value,
      }
    }

    this.props.fetchAdminUsersSearch(params)
    this.setState({ value: event.target.value })
  }

  updateSelectedUserId(email, userId) {
    this.setState({
      value: email,
      selectedUserId: userId,
    })

    this.props.clearUsersSearched()
    this.props.updateUserIdState(this.props.label, email, userId)
  }

  renderList() {
    const { value, focused, matchedUsers, selectedUserId } = this.state
    return (
      <ul className="list-group mt-3">
        {value &&
          focused &&
          matchedUsers &&
          matchedUsers.map(({ email, userId }, idx) => {
            let className = 'list-group-item'
            if (selectedUserId === userId) {
              className += ' active'
            }
            return (
              <li
                key={email + idx}
                className={className}
                style={{ cursor: 'pointer' }}
                onClick={() => this.updateSelectedUserId(email, userId)}
              >
                {email}
              </li>
            )
          })}
      </ul>
    )
  }

  renderEmailSelected() {
    const { selectedUserId, value } = this.state
    return (
      value &&
      selectedUserId && (
        <div className="text-center" style={{ color: '#1686b0' }}>
          Selected Email: {value}
        </div>
      )
    )
  }

  render() {
    const { value } = this.state
    const handleChange = this.updateInput
    return (
      <div ref={this.setWrapperRef}>
        <label style={{ width: '100%' }}>
          {this.capitalize(this.props.label)}
          <input
            className="form-control"
            value={value}
            onChange={handleChange}
            onFocus={this.handleFocus.bind(this)}
          />
        </label>
        {this.renderList()}
        {this.renderEmailSelected()}
      </div>
    )
  }
}

MergeUsersList.propTypes = {
  usersSearched: PropTypes.array,
  fetchAdminUsersSearch: PropTypes.func,
  clearUsersSearched: PropTypes.func,
  updateUserIdState: PropTypes.func,
  label: PropTypes.string,
}

const mapStateToProps = (state) => ({
  usersSearched: state.adminUsers.get('usersSearched'),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchAdminUsersSearch, clearUsersSearched }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MergeUsersList)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Immutable from 'immutable'

import {
  updateScanValid,
  fetchAdminSummary,
  // fetchAdminUsers,
} from '../../actions/admin/index'
import { fetchClientRecords } from '../../actions/coach/records'

import { fetchPostureRecords } from '../../actions/admin/posture'
import { fetchFacilities } from '../../actions/admin/facilities'
import { fetchFacilityUsers } from '../../actions/facility'
import { fetchQcRecords } from '../../actions/qc/index'
import { updateSearchState, resetSearchState } from '../../actions/search'

import Pagination from '../griddle/pagination'
// import Loading from '../utilities/loading';

import { capitalize, formatDateTime } from '../../functions/text_format'

// gridOptions = Object of { key, recordName, newSearch, customPageSize }
export const adminGridWrapper = (WrappedComponent, gridOptions) => {
  const { key, recordName, newSearch, customPageSize } = gridOptions
  class GriddleWrapper extends Component {
    constructor(props) {
      super(props)

      this.fetchData = this.fetchData.bind(this)
      this.submitFilteredSearch = this.submitFilteredSearch.bind(this)
      this.resetSearchState = this.resetSearchState.bind(this)
      this.handleValidation = this.handleValidation.bind(this)

      this.setPageSize = this.setPageSize.bind(this)
      this.setPage = this.setPage.bind(this)

      // NOTE: This is currently made for each reducer, need to decouple this information
      this.fetchDataFunctions = {
        coach: (params) => props.fetchClientRecords(params),
        admin: (params) => props.fetchAdminSummary(params),
        // adminUsers: (params) => props.fetchAdminUsers(params),
        adminPosture: (params) => props.fetchPostureRecords(params),
        // adminFacilities: (params) => props.fetchFacilities(params),
        facility: (params) => props.fetchFacilityUsers(params),
        qc: (page, size) => props.fetchQcRecords(page, size),
      }

      const data = props[key].get(recordName)

      this.state = {
        currentPage: 0,
        pageSize: customPageSize || 5,
        maxPages: 1,
        data: data || new Immutable.List(),
      }
    }

    componentDidMount() {
      const { currentPage, pageSize } = this.state
      // NOTE: hack, make it so that it is universal later
      this.fetchData(currentPage, pageSize, key !== 'coach')
    }

    // TODO: change this to componentWillUpdate
    componentWillReceiveProps(nextProps) {
      // if state and reducer slice exists
      if (nextProps[key] && nextProps[key].get(recordName)) {
        const data = nextProps[key].get(recordName)
        const pageInfo = nextProps[key].get('pageInfo')

        // fallback in case certain endpoints don't handle page/size queries
        const start = this.state.currentPage * this.state.pageSize
        const end = start + this.state.pageSize

        this.setState({
          data: pageInfo ? data : data.slice(start, end),
          maxPages: this.getMaxPages(data, pageInfo) || this.state.maxPages, // Link header will not return a rel=last object on last page, so we must account for that case
        })
      }
    }

    // shouldResetSearch only occurs in pagination -> button that clears search and displays all data
    // NOTE refactor this so that resetSearch is its own function
    fetchData(page, size, shouldResetSearch) {
      console.log('key', key, this.props.newSearch, this.props.pagination)
      let results
      const resultsWithoutSearch = { page, size }
      const searchObject = this.props.search.get('search')

      if (size !== this.state.pageSize) {
        this.setState({ pageSize: size })
      }
      if (shouldResetSearch) {
        this.resetSearchState()
      }

      if (key === 'admin') {
        results = {
          search: this.props.newSearch,
          ...resultsWithoutSearch,
        }
      } else {
        if (
          searchObject &&
          searchObject.searchParam &&
          searchObject.searchString &&
          !shouldResetSearch
        ) {
          results = Object.assign(
            { search: searchObject },
            resultsWithoutSearch
          )
        } else {
          results = resultsWithoutSearch
        }
      }

      this.fetchDataFunctions[key](results)
    }

    handleValidation(data) {
      this.props.updateScanValid(data)
    }

    getExternalData(page) {
      const newPage = page - 1 || 0
      this.fetchData(newPage, this.state.pageSize)
    }

    getMaxPages(data, pageInfo) {
      let maxPages

      if (!pageInfo) {
        maxPages = Math.ceil(data.size / this.state.pageSize)
      } else if (pageInfo && pageInfo.get('last')) {
        maxPages = parseInt(pageInfo.get('last').get('page'), 10)
      }

      return maxPages
    }

    // NOTE: find a way to move page logic elsewhere
    // NOTE: also need to change this, because this is confusing af (i.e. previous means currentPage - 2, next === this.props.setPage(currentPage)) ?? wtf
    setPage(index) {
      // clicking the next button, if index < 1, set page as 1. if index > maxPages, set it at maxPages, otherwise increase page by 1
      const newIndex =
        index > this.state.maxPages
          ? this.state.maxPages
          : index < 1
          ? 1
          : index + 1
      this.getExternalData(newIndex)
      this.setState({ currentPage: newIndex - 1 })
    }

    setPageSize(size) {
      this.fetchData(0, size)

      this.setState({
        pageSize: parseInt(size, 10),
        currentPage: 0,
      })
    }

    // NOTE: find a way to move search logic eslewhere
    submitFilteredSearch(searchParam, searchString) {
      const search = { searchParam, searchString }
      // update search params
      this.props.updateSearchState(search)
      this.setState({ currentPage: 0 })

      // fetch data to update what is displayed
      this.fetchDataFunctions[key]({
        search,
        page: 0,
        size: this.state.pageSize,
      })
    }

    resetSearchState() {
      this.props.resetSearchState()
    }

    render() {
      const { data, currentPage, pageSize, maxPages } = this.state

      // if (this.props[key].get('loading')) {
      //   return <Loading />;
      // }

      const pageProperties = { pageSize, maxPages }
      const paginationProps = {
        setPageSize: this.setPageSize,
        setPage: this.setPage,
        currentPage: currentPage + 1,
        pageSize,
        newSearch,
        fetchAllRecords: this.fetchData,
      }

      // NOTE: this newSearch and fetchData flags are temporary edge cases for coach
      const components = {
        Pagination: () => (
          <Pagination
            setPageSize={this.setPageSize}
            setPage={this.setPage}
            currentPage={currentPage + 1}
            pageSize={pageSize}
            newSearch={newSearch}
            fetchAllRecords={this.fetchData}
          />
        ),
        SettingsToggle: () => null,
      }

      const styleConfig = {
        classNames: {
          Filter: 'griddle-filter form-control mb-3',
          Table: 'table table-bordered',
        },
      }

      const handleValidation = this.handleValidation

      return (
        <WrappedComponent
          {...this.props}
          loading={this.props[key].get('loading')}
          paginationProps={paginationProps}
          fetchData={this.fetchData}
          submitFilteredSearch={this.submitFilteredSearch}
          search={this.props.search.get('search')}
          resetSearchState={this.resetSearchState}
          data={data}
          pageProperties={pageProperties}
          components={components}
          styleConfig={styleConfig}
          handleValidation={handleValidation}
          capitalize={capitalize}
          formatDateTime={formatDateTime}
        />
      )
    }
  }

  GriddleWrapper.propTypes = {
    coach: PropTypes.object,
    adminPosture: PropTypes.object,
    qc: PropTypes.object,
    search: PropTypes.object,
    fetchClientRecords: PropTypes.func,
    fetchAdminSummary: PropTypes.func,
    // fetchAdminUsers: PropTypes.func,
    fetchFacilities: PropTypes.func,
    fetchPostureRecords: PropTypes.func,
    fetchQcRecords: PropTypes.func,
    fetchFacilityUsers: PropTypes.func,
    updateScanValid: PropTypes.func,
    updateSearchState: PropTypes.func,
    resetSearchState: PropTypes.func,
  }

  const mapStateToProps = (state) => ({
    coach: state.coach,
    admin: state.admin,
    // adminCoaches: state.adminCoaches,
    // adminUsers: state.adminUsers,
    adminPosture: state.adminPosture,
    facility: state.facility,
    qc: state.qc,
    search: state.search,
    // NOTE: temporary, this is to get admin records working with new components
    pagination: state.pagination,
    newSearch: state.newSearch,
  })

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        fetchClientRecords,
        fetchAdminSummary,
        // fetchAdminUsers,
        fetchFacilities,
        fetchPostureRecords,
        fetchFacilityUsers,
        fetchQcRecords,
        updateScanValid,
        updateSearchState,
        resetSearchState,
      },
      dispatch
    )

  return connect(mapStateToProps, mapDispatchToProps)(GriddleWrapper)
}

import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
} from '../actions/auth'

const isAuthenticated =
  !!window.localStorage.getItem('token') &&
  new Date().getTime() < parseInt(window.localStorage.getItem('expires_at'))

export default (state = isAuthenticated, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return true
    case AUTH_LOGIN_FAILURE:
    case AUTH_LOGOUT:
      return false
    default:
      return state
  }
}

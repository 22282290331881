import { createSelector } from 'reselect'
import postureRecordsSelector from './posture_records'

export default createSelector(
  (state) => state.current.get('currentScan'),
  (state) => postureRecordsSelector(state),
  (currentScan, records) => {
    if (!currentScan || !records) {
      return undefined
    }

    if (
      records.filter((record) => record.get('id') === currentScan.get('id'))
        .size !== 0
    ) {
      return records.find(
        (record) => record.get('id') === currentScan.get('id')
      )
    } else {
      return records.first()
    }
  }
)

import React from 'react'

export const renderNameField = (field) => {
  const handleFocus = field.focusHandler
  const handleBlur = field.blurHandler
  return (
    <div className="form-group" style={{ width: '100%' }}>
      <label>{field.label}</label>
      <input
        className="form-control"
        style={{ width: '100%', marginBottom: '15px' }}
        {...field.input}
        type={field.type}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {field.meta.touched && field.meta.error && (
        <span style={{ color: 'red' }}>{field.meta.error}</span>
      )}
    </div>
  )
}

// export const renderField = (field) => (
//   <div className='form-group' style={ { width: '100%' } }>
//     <label>{ field.label }</label>
//     <div>
//       <input className='form-control' style={ { borderRadius: 0 } } { ...field.input } type={ field.type } />
//       { field.meta.touched && field.meta.error &&
//         <span style={ { color: 'red' } }>{ field.meta.error }</span> }
//     </div>
//   </div>
// );

export const genderSelect = (field) => (
  <div className="form-group" style={{ width: '100%' }}>
    <div>
      <label>{field.label}</label>
    </div>
    <div>
      <select
        className="form-control"
        style={{ width: '100%', marginBottom: '15px' }}
        {...field.input}
        type={field.type}
      >
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>
      {field.meta.touched && field.meta.error && (
        <span style={{ color: 'red' }}>{field.meta.error}</span>
      )}
    </div>
  </div>
)

export const unitSelect = (field) => (
  <div className="form-group" style={{ width: '100%' }}>
    <label>{field.label}</label>
    <div>
      <select
        className="form-control"
        style={{ width: '100%', marginBottom: '15px' }}
        {...field.input}
        type={field.type}
      >
        <option value="US">US</option>
        <option value="Metric">Metric</option>
      </select>
      {field.meta.touched && field.meta.error && (
        <span style={{ color: 'red' }}>{field.meta.error}</span>
      )}
    </div>
  </div>
)

export const ethnicitySelect = (field) => (
  <div className="form-group" style={{ width: '100%' }}>
    <label>{field.label}</label>
    <div>
      <select
        className="form-control"
        style={{ width: '100%', marginBottom: '15px' }}
        {...field.input}
        type={field.type}
      >
        <option value={0}>Unspecified</option>
        <option value={1}>Hispanic/Latino</option>
        <option value={2}>American Indian or Alaska Native</option>
        <option value={3}>Asian</option>
        <option value={4}>Black or African American</option>
        <option value={5}>Native Hawaiian or Other Pacific Islander</option>
        <option value={6}>Caucasian</option>
      </select>
      {field.meta.touched && field.meta.error && (
        <span style={{ color: 'red' }}>{field.meta.error}</span>
      )}
    </div>
  </div>
)

export const timeZoneSelect = (field) => (
  <div className="form-group" style={{ width: '100%' }}>
    <label htmlFor="timeZoneSelect">{field.label}</label>
    <div>
      <select
        id="timeZoneSelect"
        className="form-control"
        style={{ width: '100%', marginBottom: '15px' }}
        {...field.input}
        type={field.type}
      >
        <option value="BIT (UTC-12:00)">BIT (UTC-12:00)</option>
        <option value="SST (UTC-11:00)">SST (UTC-11:00)</option>
        <option value="TAHT (UTC-10:00)">TAHT (UTC-10:00)</option>
        <option value="AKST (UTC-9:00)">AKST (UTC-9:00)</option>
        <option value="PST (UTC-8:00)">PST (UTC-8:00)</option>
        <option value="MST (UTC-7:00)">MST (UTC-7:00)</option>
        <option value="CST (UTC-6:00)">CST (UTC-6:00)</option>
        <option value="EST (UTC-5:00)">EST (UTC-5:00)</option>
        <option value="AST (UTC-4:00)">AST (UTC-4:00)</option>
        <option value="(UTC-3:00)">(UTC-3:00)</option>
        <option value="(UTC-2:00)">(UTC-2:00)</option>
        <option value="(UTC-1:00)">(UTC-1:00)</option>
        <option value="GMT (UTC 0:00)">GMT (UTC 0:00)</option>
        <option value="CET (UTC+1:00)">CET (UTC+1:00)</option>
        <option value="EET (UTC+2:00)">EET (UTC+2:00)</option>
        <option value="(UTC+300)">(UTC+3:00)</option>
        <option value="(UTC+4:00)">(UTC+4:00)</option>
        <option value="(UTC+5:00">(UTC+5:00)</option>
        <option value="(UTC+6:00">(UTC+6:00)</option>
        <option value="(UTC+7:00">(UTC+7:00)</option>
        <option value="(UTC+8:00">(UTC+8:00)</option>
        <option value="(UTC+9:00">(UTC+9:00)</option>
        <option value="(UTC+10:00">(UTC+10:00)</option>
        <option value="(UTC+11:00">(UTC+11:00)</option>
        <option value="(UTC+12:00">(UTC+12:00)</option>
        <option value="(UTC+13:00">(UTC+13:00)</option>
        <option value="(UTC+14:00">(UTC+14:00)</option>
      </select>
      {field.meta.touched && field.meta.error && (
        <span style={{ color: 'red' }}>{field.meta.error}</span>
      )}
    </div>
  </div>
)

import Immutable from 'immutable'
import parse from 'parse-link-header'

import { FETCH_RESOURCES } from '../../actions/facility/types'
import * as types from '../../actions/facility/types'

const reducers = {
  [FETCH_RESOURCES]: (state, action) => {
    return state.set('resources', Immutable.fromJS(action.payload.data))
  },

  // Fetch list of users from a facility (current fetches all, not paginated)
  [types.FETCH_FACILITY_USERS]: (state) => state.set('loading', true),
  [types.FETCH_FACILITY_USERS_SUCCESS]: (state, action) => {
    const pageInfo = parse(action.payload.headers.link)

    return state
      .set('users', Immutable.fromJS(action.payload.data))
      .set('pageInfo', Immutable.fromJS(pageInfo))
      .set('loading', false)
  },
  [types.FETCH_FACILITY_USERS_FAILURE]: (state) => state.set('loading', false),

  // Update whether or not a user is a coach
  [types.UPDATE_FACILITY_USER_SUCCESS]: (state, action) => {
    const index = state
      .get('users')
      .findIndex((item) => item.get('userId') === action.meta.userId)
    const updatedList = state.get('users').update(index, (item) => {
      return item.set('coach', Immutable.fromJS(action.payload.data.coach))
    })

    return state.set('users', updatedList)
  },

  [types.FETCH_FACILITY_STATS_SUCCESS]: (state, action) => {
    return state.set('stats', Immutable.fromJS(action.payload.data))
  },
  [types.FETCH_FACILITY_ACTIVITY_SUCCESS]: (state, action) => {
    return state.set('activity', Immutable.fromJS(action.payload.data))
  },
  [types.FETCH_COACHES_ACTIVITY_SUCCESS]: (state, action) => {
    return state.set('coachesActivity', Immutable.fromJS(action.payload.data))
  },
}

const defaultState = new Immutable.Map({
  activity: new Immutable.Map({}),
  coachesActivity: new Immutable.Map({}),
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

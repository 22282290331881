import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { acceptCookiePolicy } from '../../actions/user'

const CookiePolicy = (props) => (
  <div
    className="card card-default mx-4 mt-4"
    style={{
      border: 'none',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.18)',
      background: '#eee',
      zIndex: 100,
    }}
  >
    <div className="card-body">
      <div className="mb-3">
        This website stores cookies on your computer. These cookies are used to
        collect information about how you interact with our website and allow us
        to remember you. We use this information in order to improve and
        customize your browsing experience and for analytics and metrics about
        our visitors both on this website and other media. To find out more
        about the cookies we use, see our&nbsp;
        <a
          href="https://www.fit3d.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </div>
      <div className="d-flex">
        <button
          className="btn btn-primary ml-auto"
          onClick={props.handleCookiePolicy}
        >
          Got it, thanks.
        </button>
      </div>
    </div>
  </div>
)

CookiePolicy.propTypes = {
  handleCookiePolicy: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  handleCookiePolicy: () => {
    dispatch({ type: 'HIDE_COOKIE_NOTICE' })
    dispatch(acceptCookiePolicy())
  },
})

export default connect(null, mapDispatchToProps)(CookiePolicy)

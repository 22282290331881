import React, { Component } from 'react'
import _ from 'lodash'

export default class Metadata extends Component {
  renderRow(metadataItem) {
    const { metadata } = this.props.rowData
    // if (metadataItem.key === 'scanPackageId') {
    //   return (
    //     <tr key={ _.uniqueId() } className='row mt-3'>
    //       <td colSpan='2' className='text-nowrap'>{ metadata[metadataItem.key] }</td>
    //     </tr>
    //   );
    // } else {
    return (
      <ul className="list-unstyled">
        <li key={_.uniqueId()}>
          <div>
            <b>{metadataItem.name}</b>
          </div>
          <div>{metadata[metadataItem.key]}</div>
        </li>
      </ul>
    )
    // }
  }

  render() {
    const tableContents = this.props.cellProperties.metadataKeyNameList.map(
      this.renderRow.bind(this)
    )

    return (
      <div>
        <table className="table p-0">
          <tbody>{tableContents}</tbody>
        </table>
      </div>
    )
  }
}

import React from 'react'

export default function TwoPanelView({ left, right }) {
  return (
    <div className="row mx-0">
      <div className="col-sm-6 px-3">{left}</div>
      <div className="col-sm-6 px-3">{right}</div>
    </div>
  )
}

const motivationValues = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
].map((value) => ({
  label: value,
  value,
}))

const options = {
  yesNo: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
  jointPain: [
    { label: 'Neck', value: 'neck' },
    { label: 'Shoulder', value: 'shoulder' },
    { label: 'Mid Back', value: 'midBack' },
    { label: 'Low Back', value: 'lowBack' },
    { label: 'Elbow', value: 'elbow' },
    { label: 'Wrist', value: 'wrist' },
    { label: 'Hip', value: 'hip' },
    { label: 'Knee', value: 'knee' },
    { label: 'Foot/Ankle', value: 'footAnkle' },
  ],
  symptoms: [
    { label: 'Headaches', value: 'headaches' },
    { label: 'Allergies', value: 'allergies' },
    { label: 'Asthma', value: 'asthma' },
    { label: 'Depression', value: 'depression' },
    { label: 'Anxiety', value: 'anxiety' },
    { label: 'Gain Weight Easily', value: 'gainWeightEasily' },
    { label: 'Dizziness', value: 'dizziness' },
    { label: 'Fatigue', value: 'fatigue' },
    { label: 'Indigestion', value: 'indigestion' },
    { label: 'Difficulty Losing Weight', value: 'difficultyLosingWeight' },
    { label: 'Constipation', value: 'constipation' },
    { label: 'Diarrhea', value: 'diarrhea' },
    { label: 'Insomnia', value: 'insomnia' },
    { label: 'Restless Sleep', value: 'restlessSleep' },
    { label: 'Heart Trouble', value: 'heartTrouble' },
  ],
  timesEatOut: [
    { label: 'Never', value: 'never' },
    { label: 'Not Often', value: 'notOften' },
    { label: 'Regularly', value: 'regularly' },
    { label: 'Always', value: 'always' },
  ],
  fitnessGoalPrevention: [
    { label: 'Time', value: 'time' },
    { label: 'Money', value: 'money' },
    { label: 'Spouse', value: 'spouse' },
    { label: 'Procrastination', value: 'procratination' },
    { label: 'Lack of Knowledge', value: 'lackOfKnowledge' },
  ],
  activityLevel: [
    { label: 'Sedentary', value: 'sedentary' },
    { label: 'Moderate', value: 'moderate' },
    { label: 'Active', value: 'active' },
    { label: 'Very Active', value: 'veryActive' },
  ],
  motivationValues,
}

export default options

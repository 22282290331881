import React from 'react'
import PropTypes from 'prop-types'

const Unavailable = (props) => (
  <div className="d-flex align-items-center justify-content-center unavailable">
    <div className="text-center">
      <img className="mb-3 logo" src="/logo-fit3d.png" alt="brand" />
      <h5 className="text-uppercase">{props.title}</h5>
      {props.children}
    </div>
  </div>
)

Unavailable.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  destination: PropTypes.string,
  history: PropTypes.object,
  children: PropTypes.node,
}

export default Unavailable

import { createSelector } from 'reselect'
import orderedScanSelector from './ordered_scans_selector'
import visibleRecordsSelector from './visible_records_selector'
import _ from 'lodash'

const completedScanSelector = (state) => orderedScanSelector(state)
const visibleScanRecords = (state) => visibleRecordsSelector(state)

const getVisibleScans = (scans, records) => {
  var visibleRecordIds = []
  records.forEach((record) => visibleRecordIds.push(record.get('scan')))

  return scans.filter((scan) =>
    _.includes(visibleRecordIds, scan.get('scanId'))
  )
}

export default createSelector(
  completedScanSelector,
  visibleScanRecords,
  getVisibleScans
)

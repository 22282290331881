import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { parseValue } from './utils/parse_value'

export default class PostureCell extends Component {
  constructor(props) {
    super(props)
    this.updateSelected = this.updateSelected.bind(this)
  }

  updateSelected() {
    const { metricType, orientation, value, updateSelected } = this.props
    updateSelected(metricType, orientation, value)
  }

  render() {
    const { metricType, orientation, value, selected, direction } = this.props

    const key = metricType + orientation + value
    const isSelected =
      selected &&
      metricType === selected.metricType &&
      orientation === selected.orientation &&
      value === selected.value
    const style = { padding: '0.25rem 0.5rem' }
    const selectedStyle = Object.assign(
      {},
      { background: '#1686b0', color: 'white' },
      style
    )
    const handleClick = this.updateSelected
    return (
      <td
        key={key}
        onClick={handleClick}
        style={isSelected ? selectedStyle : style}
      >
        {parseValue(value, direction)}
      </td>
    )
  }
}

PostureCell.propTypes = {
  metricType: PropTypes.string,
  orientation: PropTypes.string,
  direction: PropTypes.string,
  value: PropTypes.number,
  selected: PropTypes.object,
  updateSelected: PropTypes.func,
}

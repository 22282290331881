import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { updateUser } from '../../../actions/user'
import {
  renderNameField,
  unitSelect,
  timeZoneSelect,
  ethnicitySelect,
  genderSelect,
} from './field_components'
import DateField from './date_field'
import { validate } from './validations'

class EditForm extends Component {
  constructor(props) {
    super(props)

    this.handleCancel = this.handleCancel.bind(this)

    this.initialState = {
      errors: null,
      statusMessage: null,
      statusColor: null,
    }

    this.state = this.initialState
  }

  componentWillReceiveProps(nextProps) {
    const editMessage = this.props.user.get('editMessage')
    const nextEditMessage = nextProps.user.get('editMessage')
    const loadingUserUpdate = this.props.user.get('loadingUserUpdate')

    if (editMessage !== 'failure' && nextEditMessage === 'failure') {
      this.setState({
        statusMessage:
          'Failed to update user profile. Please try again or contact support.',
        statusColor: 'text-danger',
      })
    } else if (editMessage !== 'success' && nextEditMessage === 'success') {
      this.setState({
        statusMessage: 'Update successful!',
        statusColor: 'text-success',
      })
    } else if (loadingUserUpdate) {
      this.setState({ statusMessage: 'Updating...' })
    }
  }

  handleCancel() {
    this.props.cancelEdit()
    this.setState(this.initialState)
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props
    return (
      <div>
        <div className="mb-3">
          <h3>Edit Profile</h3>
        </div>
        <form
          key="form"
          onSubmit={handleSubmit((data) => {
            window.ga('send', 'event', 'Button', 'click', 'edited user profile')
            this.props.updateUser(data)
          }).bind(this)}
          className="form"
        >
          <div className="row">
            <div className="col-md-6">
              <Field
                name="firstName"
                type="text"
                component={renderNameField}
                label="First Name"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="lastName"
                type="text"
                component={renderNameField}
                label="Last Name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="ethnicity"
                type="text"
                component={ethnicitySelect}
                label="Ethnicity"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="gender"
                type="text"
                component={genderSelect}
                label="Gender"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="timezone"
                type="text"
                component={timeZoneSelect}
                label="TimeZone"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="units"
                type="text"
                component={unitSelect}
                label="Units"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <Field
                name="birthDate"
                type="text"
                component={DateField}
                label="Birthdate"
              />
            </div>
            <div className="col-md-6">
              <Field
                name="zipCode"
                type="text"
                component={renderNameField}
                label="Zip Code"
              />
            </div>
          </div>
          {this.state.statusMessage && (
            <div className={`text-center ${this.state.statusColor} mb-4`}>
              {this.state.statusMessage}
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={this.handleCancel}
              className="btn"
              disabled={submitting}
              style={{ width: '200px' }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pristine || submitting}
              style={{ width: '200px' }}
            >
              Submit Changes
            </button>
          </div>
        </form>
      </div>
    )
  }
}

EditForm.propTypes = {
  handleSubmit: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object,
  fields: PropTypes.array,
  style: PropTypes.string,
  cancelEdit: PropTypes.func,
  updateUserData: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
}

let Form = reduxForm({
  form: 'profileForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(EditForm)

Form = connect(
  (state) => ({
    initialValues: {
      firstName: state.user.get('user').get('firstName'),
      lastName: state.user.get('user').get('lastName'),
      email: state.user.get('user').get('email'),
      birthDate: state.user.get('user').get('birthDate'),
      ethnicity: state.user.get('user').get('ethnicity'),
      timezone: state.user.get('user').get('timezone'),
      units: state.user.get('user').get('units'),
      gender: state.user.get('user').get('gender'),
      zipCode: state.user.get('user').get('zipCode'),
    },
    user: state.user,
  }),
  { updateUser }
)(Form)

export default Form

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _ from 'lodash'

import { fetchFacilityStats } from '../../../actions/facility/index'

import NoTranslate from '../../hocs/NoTranslate'
import Loading from '../../utilities/loading'
import FacilityStatChart from './chart'

import './facility_dashboard.css'

const trendNames = {
  activeUsersByMonth: {
    yTitle: 'Users',
    name: 'Active Users (Monthly)',
  },
  newScansByMonth: {
    yTitle: 'Scans',
    name: 'New Scans (Monthly)',
  },
  newUsersByMonth: {
    yTitle: 'Users',
    name: 'New Users (Monthly)',
  },
  coachActivityByMonth: {
    yTitle: 'Coach Activity',
    name: 'Coach Activity (Monthly)',
  },
}

const stats = [
  { key: 'users', name: 'Users', color: '#1CA8DD' },
  { key: 'coaches', name: 'Coaches', color: '#0da4d7' },
  { key: 'scans', name: 'Scans', color: '#9F86FF' },
  { key: 'parq', name: 'PARQs', color: 'rgba(176, 60, 206, 0.9)' },
  { key: 'win', name: 'Wins', color: '#1BC98E' },
  { key: 'winRate', name: 'Win Rate', color: '#1badc9' },
]

const parseStat = (stat, value) => {
  if (stat === 'winRate' && typeof value === 'number') {
    return `${value * 100}%` // winrate only if number is a decimal
  }
  if (typeof value === 'number') {
    return value
  }
  return 'N/A'
}

const StatCards = ({ facilityStats }) => {
  const statCards = stats.map((stat, idx) => {
    const style = {
      color: '#fff',
      borderRadius: '3px',
      backgroundColor: stat.color,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)',
    }

    return (
      <div
        key={_.uniqueId()}
        className="col-sm-6 mb-3"
        style={
          idx % 2 === 0 ? { paddingRight: '10px' } : { paddingLeft: '10px' }
        }
      >
        <div className="statcard" style={style}>
          <div className="p-3">
            <span className="statcard-desc" style={{ color: 'white' }}>
              {stat.name}
            </span>
            <h2 className="statcard-number">
              {parseStat(stat.key, facilityStats[stat.key])}
            </h2>
          </div>
        </div>
      </div>
    )
  })

  return <div className="row mx-0">{statCards}</div>
}

StatCards.propTypes = {
  facilityStats: PropTypes.object,
}

const LeaderBoardList = (coaches) =>
  coaches.map(({ name, parq, wins }) => (
    <li key={_.uniqueId()} className="list-group-item">
      <NoTranslate>{name}</NoTranslate>
      <div className="float-right">
        <span>
          ParQ <strong>{parq}</strong>
        </span>
        <span className="ml-3">
          Wins <strong>{wins}</strong>
        </span>
      </div>
    </li>
  ))

class FacilityDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedTrend: 'coachActivityByMonth' }
  }

  componentDidMount() {
    this.props.fetchFacilityStats()

    if (this.props.user.get('coach') || this.props.user.get('facilityAdmin')) {
      if (window._cio && window._cio.page) {
        window._cio.page('/facility/dashboard')
      }
    }
  }

  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
  }

  parseData(trend) {
    return trend.map((trendPoint) => {
      const date = moment(trendPoint.date, 'YYYY-MM')
      const utcDate = Date.UTC(date.get('year'), date.get('month'))
      return [utcDate, trendPoint.value]
    })
  }

  changeTrend(trend, event) {
    event.preventDefault()
    if (this.state.selectedTrend !== trend) {
      this.setState({ selectedTrend: trend })
    }
  }

  // Charts properties
  createSeries(facilityTrend) {
    const facilityTrends = this.props.facility.get('stats').get('trends').toJS()
    const { name } = trendNames[facilityTrend]

    if (facilityTrend === 'coachActivityByMonth') {
      const { coachActivityByMonth } = facilityTrends
      const parqStatusWithColors = [
        { key: 'parqs', color: 'rgba(176, 60, 206, 0.9)' },
        { key: 'win', color: '#31ce99' },
        { key: 'maybe', color: '#f4c20d' },
        { key: 'no', color: '#E64759' },
      ]

      return parqStatusWithColors.map(({ key, color }) => {
        const data = []
        coachActivityByMonth.forEach((activity) => {
          data.push({
            date: activity.date,
            value: activity[key],
          })
        })

        return {
          name: this.capitalize(key),
          data: this.parseData(data),
          color,
        }
      })
    }

    return [
      {
        name: name,
        data: this.parseData(facilityTrends[facilityTrend]),
        color: '#66CCCC',
      },
    ]
  }

  renderCharts() {
    const trends = []
    const facilityTrends = this.props.facility.get('stats').get('trends').toJS()

    for (const facilityTrend in facilityTrends) {
      const { yTitle } = trendNames[facilityTrend]
      trends.push({
        code: facilityTrend,
        yTitle,
        series: this.createSeries(facilityTrend),
      })
    }

    // NOTE: pass series through instead of data
    return trends.map(({ code, yTitle, series }) => (
      <div
        key={_.uniqueId()}
        className={this.state.selectedTrend === code ? '' : 'hidden'}
      >
        <FacilityStatChart yTitle={yTitle} series={series} />
      </div>
    ))
  }

  render() {
    const { selectedTrend } = this.state
    if (!this.props.facility.get('stats')) {
      return <Loading />
    }

    const facilityStats = this.props.facility.get('stats').get('summary').toJS()
    const facilityLeaders = this.props.facility.get('stats').get('leaders')

    return (
      <div className="container">
        <div className="dashhead mb-3">
          <div className="dashhead-titles mt-4">
            <h6 className="dashhead-subtitle">Success Hub</h6>
            <h2 className="dashhead-title">Usage Dashboard</h2>
          </div>
        </div>

        {/* <div className='row'>
          <div className='hr-divider mb-4 text-center'>
            <h3
              className='hr-divider-content hr-divider-heading text-center'
              style={ { fontFamily: 'Lato, sans-serif', letterSpacing: '2px' } }>
              Summary
            </h3>
          </div>
        </div> */}

        <div className="p-0 mb-4">
          <div
            className="card-body p-0"
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)' }}
          >
            {/* <div className='row mb-4'> */}
            <div className="text-center text-uppercase mb-4 mx-auto facility-dashboard-header">
              Summary
            </div>
            <div className="row px-3 pt-2">
              <div className="statcards col-md-6 pr-0">
                <StatCards facilityStats={facilityStats} />
              </div>
              <div className="tab-content mb-3 col-md-6">
                <div className="pb-3">
                  {/* <div className={ `text-center text-uppercase mx-auto ${styles.header}` }>
                  Trends
                </div> */}
                  <div className="p-0 text-center mb-3">
                    <div className="btn-group" style={{ width: '90%' }}>
                      <button
                        className={`btn btn-outline-primary ${
                          selectedTrend === 'coachActivityByMonth'
                            ? 'active'
                            : ''
                        }`}
                        style={{
                          width: '25%',
                          fontWeight: 300,
                          borderBottomLeftRadius: '2px',
                          borderTopLeftRadius: '2px',
                        }}
                        onClick={this.changeTrend.bind(
                          this,
                          'coachActivityByMonth'
                        )}
                      >
                        Coach Activity
                      </button>
                      <button
                        className={`btn btn-outline-primary ${
                          selectedTrend === 'newUsersByMonth' ? 'active' : ''
                        }`}
                        style={{ width: '25%', fontWeight: 300 }}
                        onClick={this.changeTrend.bind(this, 'newUsersByMonth')}
                      >
                        New Users
                      </button>
                      <button
                        className={`btn btn-outline-primary ${
                          selectedTrend === 'activeUsersByMonth' ? 'active' : ''
                        }`}
                        style={{ width: '25%', fontWeight: 300 }}
                        onClick={this.changeTrend.bind(
                          this,
                          'activeUsersByMonth'
                        )}
                      >
                        Active Users
                      </button>
                      <button
                        className={`btn btn-outline-primary ${
                          selectedTrend === 'newScansByMonth' ? 'active' : ''
                        }`}
                        style={{
                          width: '25%',
                          fontWeight: 300,
                          borderBottomRightRadius: '2px',
                          borderTopRightRadius: '2px',
                        }}
                        onClick={this.changeTrend.bind(this, 'newScansByMonth')}
                      >
                        New Scans
                      </button>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane px-3 mb-3">
                    {this.renderCharts()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='row'>
          <div className='hr-divider text-center'>
            <h3
              className='hr-divider-content hr-divider-heading text-center'
              style={ { fontFamily: 'Lato, sans-serif', letterSpacing: '2px' } }>
              Top Users
            </h3>
          </div>
        </div> */}
        <div className="p-0 mb-4">
          <div
            className="card-body p-0"
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)' }}
          >
            <div className="text-center text-uppercase mb-3 facility-dashboard-header">
              Coach Activity Leaders
            </div>
            <div className="row p-3">
              <div className="col-md-4">
                <ul className="list-group facility-list-group">
                  <li className="list-group-header">
                    Coach Leaders (Current Month)
                  </li>
                  {/* <li className='list-group-item'>
                    <span className='mr-3'>John User</span>
                    <span className='icon icon-trophy' />
                    <span className='icon icon-trophy' />
                    <span className='icon icon-trophy' />
                    <div className='float-right'>
                      <span>ParQ <strong>15</strong></span>
                      <span className='ml-3'>Wins <strong>15</strong></span>
                    </div>
                    <span className='list-group-progress' style={ { width: '70%' } } />
                  </li> */}
                  {LeaderBoardList(facilityLeaders.get('last30').toJS())}
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-group facility-list-group">
                  <li className="list-group-header">
                    Coach Leaders (All Time)
                  </li>
                  {LeaderBoardList(facilityLeaders.get('total').toJS())}
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-group facility-list-group">
                  <li className="list-group-header">What is this?</li>
                  <li className="list-group-item">
                    Coaches are ranked based on their ParQs completed and Wins
                    (potential clients they have marked as interested).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FacilityDashboard.propTypes = {
  fetchFacilityStats: PropTypes.func,
  facility: PropTypes.object,
  user: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user.get('user'),
  facility: state.facility,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFacilityStats }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FacilityDashboard)

import action from '../middleware'
import * as AdminApi from '../../lib/api/admin'

import { setPageInfo } from '../pagination'

export const FETCH_ADMIN_SUMMARY = 'FETCH_ADMIN_SUMMARY'
export const FETCH_ADMIN_SUMMARY_SUCCESS = 'FETCH_ADMIN_SUMMARY_SUCCESS'
export const FETCH_ADMIN_SUMMARY_FAILURE = 'FETCH_ADMIN_SUMMARY_FAILURE'

export const ADMIN_FETCH_REFERRALS = 'ADMIN_FETCH_REFERRALS'
export const ADMIN_FETCH_REFERRALS_SUCCESS = 'ADMIN_FETCH_REFERRALS_SUCCESS'
export const ADMIN_FETCH_REFERRALS_FAILURE = 'ADMIN_FETCH_REFERRALS_FAILURE'

export const ADMIN_FETCH_RECORDS = 'ADMIN_FETCH_RECORDS'
export const ADMIN_FETCH_RECORDS_SUCCESS = 'ADMIN_FETCH_RECORDS_SUCCESS'
export const ADMIN_FETCH_RECORDS_FAILURE = 'ADMIN_FETCH_RECORDS_FAILURE'

export const FETCH_ADMIN_RECORD = 'FETCH_ADMIN_RECORD'
export const FETCH_ADMIN_RECORD_SUCCESS = 'FETCH_ADMIN_RECORD_SUCCESS'
export const FETCH_ADMIN_RECORD_FAILURE = 'FETCH_ADMIN_RECORD_FAILURE'

export const ADMIN_SCAN_VALID = 'ADMIN_SCAN_VALID'
export const ADMIN_SCAN_VALID_SUCCESS = 'ADMIN_SCAN_VALID_SUCCESS'
export const ADMIN_SCAN_VALID_FAILURE = 'ADMIN_SCAN_VALID_FAILURE'

export const REPROCESS = 'REPROCESS'
export const REPROCESS_SUCCESS = 'REPROCESS_SUCCESS'
export const REPROCESS_FAILURE = 'REPROCESS_FAILURE'

// export const REPROCESS_IMAGE = 'REPROCESS_IMAGE'
// export const REPROCESS_MEASUREMENTS = 'REPROCESS_MEASUREMENTS'
// export const REPROCESS_FROM_PLY = 'REPROCESS_FROM_PLY'
// export const REPROCESS_ALL = 'REPROCESS_ALL'

export const fetchAdminReferrals = (page, size) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/referrals?page=${page}&size=${size}`,
      method: 'GET',
      actionTypes: {
        requestType: ADMIN_FETCH_REFERRALS,
        successType: ADMIN_FETCH_REFERRALS_SUCCESS,
        failureType: ADMIN_FETCH_REFERRALS_FAILURE,
      },
    },
    dispatch
  )

export const fetchAdminRecords = (page, size) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records?page=${page}&size=${size}`,
      method: 'GET',
      actionTypes: {
        requestType: ADMIN_FETCH_RECORDS,
        successType: ADMIN_FETCH_RECORDS_SUCCESS,
        failureType: ADMIN_FETCH_RECORDS_FAILURE,
      },
    },
    dispatch
  )

// export function fetchAdminRecordsRequest() {
//   return {
//     type: ADMIN_FETCH_RECORDS,
//     payload: 'fetching'
//   };
// }
//
// export function fetchAdminRecords(page, size) {
//   return dispatch => {
//     dispatch(fetchAdminRecordsRequest());
//     return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/admin/records?page=${page}&size=${size}`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//       .then(response => {
//         dispatch({
//           type: ADMIN_FETCH_RECORDS_SUCCESS,
//           payload: response
//         });
//       }).catch(error => {
//         dispatch({
//           type: ADMIN_FETCH_RECORDS_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

export const updateScanValid = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/${params.id}/validate`,
      method: 'POST',
      data: params,
      actionTypes: {
        requestType: ADMIN_SCAN_VALID,
        successType: ADMIN_SCAN_VALID_SUCCESS,
        failureType: ADMIN_SCAN_VALID_FAILURE,
      },
    },
    dispatch
  )

// export function updateScanValid(params) {
//   const response = axios({
//     method: 'post',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/admin/records/${params.id}/validate`,
//     data: params
//   });
//
//   return {
//     type: ADMIN_SCAN_VALID,
//     payload: response
//   };
// }

/* Admin Records */
// All records
export const fetchAdminSummary = (params) => (dispatch) => {
  dispatch({ type: FETCH_ADMIN_SUMMARY })

  AdminApi.fetchAdminRecordsSummary(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: FETCH_ADMIN_SUMMARY_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: FETCH_ADMIN_SUMMARY_FAILURE, error }))
}

// Single record
export const fetchAdminRecord = (packageId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/${packageId}`,
      method: 'GET',
      actionTypes: {
        requestType: FETCH_ADMIN_RECORD,
        successType: FETCH_ADMIN_RECORD_SUCCESS,
        failureType: FETCH_ADMIN_RECORD_FAILURE,
      },
    },
    dispatch
  )

/* Reprocess Data */
// export const reprocessImage = packageId => dispatch => action({
//   endpoint: `/v1/admin/super/scans/${packageId}/reprocess/img`,
//   method: 'GET',
//   actionTypes: {
//     successType: REPROCESS_IMAGE,
//     failureType: REPROCESS_IMAGE_FAILURE
//   }
// }, dispatch);

export const handleReprocess = (packageId, data) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/${packageId}/reprocess`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: REPROCESS,
        successType: REPROCESS_SUCCESS,
        failureType: REPROCESS_FAILURE,
      },
    },
    dispatch
  )

// export const reprocessImage = packageId => dispatch => {
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/admin/super/scans/${packageId}/reprocess/img`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: REPROCESS_IMAGE,
//       payload: response
//     }));
// };
//
// export const reprocessMeasurements = packageId => dispatch => {
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/admin/super/scans/${packageId}/reprocess/measurement`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: REPROCESS_MEASUREMENTS,
//       payload: response
//     }));
// };
//
// export const reprocessFromPly = packageId => dispatch => {
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/admin/super/scans/${packageId}/reprocess/clean`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: REPROCESS_FROM_PLY,
//       payload: response
//     }));
// };
//
// export const reprocessAll = packageId => dispatch => {
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/admin/super/scans/${packageId}/reprocess`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: REPROCESS_ALL,
//       payload: response
//     }));
// };

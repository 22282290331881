import React from 'react'
import { connect } from 'react-redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { updateRole, removeRole } from '../../../actions/admin/users'

import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import EmailLabel from './emailEdit/EmailLabel'
import FacilityLabel from './facilityEdit/Label'
import AdminUserActions from './AdminUserActions'

import { formatDateTime } from '../../../lib/formatText'

const UsersTable = ({
  users,
  pageSize,
  onEmailEditOpen,
  setEmailEditDefaults,
  onFacilityEditOpen,
  setFacilityEditDefaults,
  updateRole,
  removeRole,
}) => {
  // Use our custom components instead of griddle
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered table-hover',
    },
  }

  return (
    <Griddle
      data={users.map((user) => parseData(user))}
      plugins={[plugins.LocalPlugin]}
      pageProperties={{ pageSize }}
      components={components}
      styleConfig={styleConfig}
    >
      <RowDefinition>
        <ColumnDefinition id="lastName" title="Last Name" />
        <ColumnDefinition id="firstName" title="First Name" />
        <ColumnDefinition
          id="email"
          title="E-mail"
          onOpen={onEmailEditOpen}
          setEmailEditDefaults={setEmailEditDefaults}
          customComponent={enhancedWithRowData(EmailLabel)}
        />
        <ColumnDefinition id="signupDate" title="Signup Date" />
        <ColumnDefinition
          id="facility"
          title="Facility"
          onOpen={onFacilityEditOpen}
          setFacilityEditDefaults={setFacilityEditDefaults}
          customComponent={enhancedWithRowData(FacilityLabel)}
        />
        <ColumnDefinition
          id="actions"
          title="Admin Role"
          updateRole={updateRole}
          removeRole={removeRole}
          customComponent={enhancedWithRowData(AdminUserActions)}
        />
      </RowDefinition>
    </Griddle>
  )
}

const parseData = (user) => {
  return {
    lastName: user.lastName,
    firstName: user.firstName,
    email: user.email,
    userId: user.userId,
    signupDate: formatDateTime(user.signupDate),
    facility: user.facility ? user.facility.name : '',
    actions: user.facilityAdmin,
  }
}

const mapStateToProps = (state) => ({
  users: state.newAdmin.users.list,
  facilities: state.newAdmin.facilities.list,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) => ({
  updateRole: (params) => dispatch(updateRole(params)),
  removeRole: (params) => dispatch(removeRole(params)),
  onEmailEditOpen: () => dispatch({ type: 'OPEN_EMAIL_EDIT' }),
  setEmailEditDefaults: (params) =>
    dispatch({ type: 'EDIT_ADMIN_USER_EMAIL', payload: params }),
  onFacilityEditOpen: () => dispatch({ type: 'OPEN_FACILITY_EDIT' }),
  setFacilityEditDefaults: (params) =>
    dispatch({ type: 'EDIT_ADMIN_USER_FACILITY', payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)

import React from 'react'
import PropTypes from 'prop-types'

const ValidateButton = ({
  scanValidateValues,
  handleValidation,
  title,
  slice,
  validationValue,
}) => {
  const currentValue = scanValidateValues.get(slice)
  const active =
    (!currentValue && !validationValue) || currentValue === validationValue
      ? ' active'
      : ''

  return (
    <button
      className={`btn btn-sm btn-outline-primary${active}`}
      style={{ padding: '3px 10px' }}
      onClick={() => handleValidation(slice, validationValue)}
    >
      {title}
    </button>
  )
}

ValidateButton.propTypes = {
  scanValidateValues: PropTypes.object,
  handleValidation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  slice: PropTypes.string,
  validationValue: PropTypes.number.isRequired,
}

const ValidationTable = (props) => {
  const rows = props.sliceKeyNameList.map(({ key, name }) => (
    <tr key={key}>
      <td>{name}</td>
      <td className="text-center">
        <div className="btn-group">
          <ValidateButton
            {...props}
            title="N/A"
            slice={key}
            validationValue={0}
          />
          <ValidateButton
            {...props}
            title="Yes"
            slice={key}
            validationValue={1}
          />
          <ValidateButton
            {...props}
            title="No"
            slice={key}
            validationValue={2}
          />
        </div>
      </td>
    </tr>
  ))

  return (
    <table className="mb-3 table">
      <tbody>{rows}</tbody>
    </table>
  )
}

ValidationTable.propTypes = {
  sliceKeyNameList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

export default ValidationTable

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PostureRow from '../../../newWellness/posture/posture_row'
import Categories from '../../../newWellness/posture/categories'

class PostureGrid extends Component {
  constructor(props) {
    super(props)

    this.updateSelected = this.updateSelected.bind(this)
    this.state = {
      selectedCategory: 'front',
    }

    const posture = props.clientReport.get('posture')

    this.displayProps = {
      front: {
        degree: 0,
        direction: 'front',
        data: posture.get('front'),
      },
      side: {
        degree: 90,
        direction: 'side',
        data: posture.get('side'),
      },
      back: {
        degree: 180,
        direction: 'back',
        data: posture.get('front'),
      },
    }
  }

  updateSelected(category) {
    this.setState({ selectedCategory: category })
  }

  render() {
    const {
      clientReport,
      clientId,
      recordId,
      accessLevel,
      gender,
      units,
    } = this.props
    const { selectedCategory } = this.state

    const postureStatus = clientReport.get('postureStatus')
    let postureGrid

    if (
      postureStatus &&
      postureStatus.toLowerCase().indexOf('complete') !== -1
    ) {
      const src =
        accessLevel === 'full'
          ? `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${this.displayProps[selectedCategory].degree}/type/posture`
          : `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${this.displayProps[selectedCategory].degree}/type/posture-silhouette`

      postureGrid = (
        <div className="card card-default" style={{ borderRadius: 0 }}>
          <div className="card-body p-0">
            <Categories
              selectedCategory={selectedCategory}
              updateSelected={this.updateSelected}
            />
            <PostureRow
              {...this.displayProps[selectedCategory]}
              src={src}
              packageId={clientReport.get('scanPackageId')}
              recordId={recordId}
              accessLevel={accessLevel}
              gender={gender}
              units={units}
            />
          </div>
        </div>
      )
    } else {
      const titleStyle = {
        color: '#1686b0',
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: '16px',
        letterSpacing: '2px',
      }
      postureGrid = (
        <div className="text-center" style={titleStyle}>
          Posture processing is in progress
        </div>
      )
    }

    return (
      <div
        className={`mb-5 ${
          this.props.accessLevel !== 'full' ? 'print-break-after' : null
        }`}
      >
        <div>
          <h2>Posture</h2>
        </div>
        {postureGrid}
      </div>
    )
  }
}

PostureGrid.propTypes = {
  clientId: PropTypes.string,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  clientReport: PropTypes.object,
  accessLevel: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}

export default PostureGrid

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'

import { makeMarker } from '../../functions/chart'
import { convertUTC } from '../../functions/text_format'

export class WellnessChart extends Component {
  constructor(props) {
    super(props)

    this.wellness = {
      bodyShape: [
        { bodyShapeRating: 'Body Shape Rating' },
        { trunkLegVolRatio: 'Trunk to Leg Volume Ratio' },
      ],
      bodyComp: [
        { bfp: 'Body Fat Percent' },
        { weight: 'Weight' },
        { fatMass: 'Fat Mass' },
        { leanMass: 'Lean Mass' },
      ],
    }

    if (props.bfpAccess !== 1) {
      this.wellness.bodyComp = [{ weight: 'Weight' }]
    }

    this.config = {
      chart: {
        type: 'spline',
        height: 230,
        style: {
          fontFamily: 'Helvetica',
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        lineWidth: 2,
        startOnTick: false,
        minPadding: 0,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          const { x, y, series } = this.point
          return (
            `<b>${series.name}:</b> ${y}<br />` +
            moment(x)
              .utcOffset(moment().utcOffset())
              .format('YYYY-MM-DD (hh:mm A)')
          )
        },
      },
      legend: {
        verticalAlign: 'top',
        align: 'right',
        float: true,
        itemStyle: {
          color: '#888',
          fontWeight: '300',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      series: this.getData(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.config.series = this.getData(nextProps)
  }

  getWellnessMetrics(records) {
    return records.filter((record) => !!record.get('wellnessMetrics'))
  }

  getData(props) {
    const selectedMetricType = this.wellness[props.selectedMetric] // an array
    const recordsWithMetrics = this.getWellnessMetrics(props.records)
      .toJS()
      .reverse()
    const series = []

    for (let i = 0; i < selectedMetricType.length; i++) {
      const metricData = []
      const metricsSegment = Object.keys(selectedMetricType[i])[0]

      for (let j = 0; j < recordsWithMetrics.length; j++) {
        const date = convertUTC(recordsWithMetrics[j].recordDate)
        const measurement =
          recordsWithMetrics[j].wellnessMetrics[metricsSegment]

        if (convertUTC(props.currentScan.get('recordDate')) === date) {
          metricData.push(makeMarker(date, measurement, '#cc6666'))
        } else if (convertUTC(props.baselineScan.get('recordDate')) === date) {
          metricData.push(makeMarker(date, measurement, '#FF00FF'))
        } else {
          metricData.push([date, measurement])
        }
      }

      const name = selectedMetricType[i][metricsSegment]
      series.push({
        name,
        data: metricData,
        visible: metricsSegment !== 'trunkLegVolRatio',
      })
    }

    return series
  }

  render() {
    const histogramStyle = {
      width: '100%!important',
      zIndex: 0,
      height: '60%',
    }

    return (
      <div className="p-0" style={histogramStyle}>
        <ReactHighcharts config={this.config} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bfpAccess: state.user.get('user').get('facility').get('bfpAccess'),
})

WellnessChart.propTypes = {
  records: PropTypes.object.isRequired,
  selectedMetric: PropTypes.string.isRequired,
  currentScan: PropTypes.object.isRequired,
  baselineScan: PropTypes.object.isRequired,
  bfpAccess: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(WellnessChart)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Immutable from 'immutable'

import * as ClientActions from '../../../actions/coach/client'
import { fetchFacilityAdditional } from '../../../actions/wellness'
import { clearMessage } from '../../../actions/coach/index'
import { withClients } from '../wrappers/with_clients'

import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { griddleWrapper } from '../../griddle/grid_wrapper'
import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import Pagination from '../../griddle/pagination'
import NoTranslate from '../../hocs/NoTranslate'
import Image from './image'
import Measurements from './measurements'
import Metrics from './metrics'
import Additional from './additional'
import ClientInfo from './client_info'
import Loading from '../../utilities/loading'

export class ClientHistory extends Component {
  constructor(props) {
    super(props)

    this.redirectPage = this.redirectPage.bind(this)
    this.parseData = this.parseData.bind(this)
    this.updateHeightWeight = this.updateHeightWeight.bind(this)

    const client = props.data ? props.data : null
    this.state = {
      client,
      message: null,
    }
  }

  componentDidMount() {
    const { userId } = this.props.match.params
    this.props.fetchClientData(userId)
    this.props.fetchFacilityAdditional()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ client: nextProps.data })
    }
    this.setState({ message: nextProps.coach.get('message') })
  }

  redirectPage(route) {
    return () => {
      if (route === '/staff/clients/records') {
        this.props.resetClientData()
      }
      this.props.history.push(route)
    }
  }

  updateHeightWeight(data) {
    this.props.updateMeasurements(data)
  }

  parseData(record) {
    const wellnessMetrics = record.get('wellnessMetrics')
    let additionalMetrics = null
    if (
      wellnessMetrics &&
      wellnessMetrics.get('additional') &&
      wellnessMetrics.get('additional').size !== 0
    ) {
      additionalMetrics = wellnessMetrics.get('additional')
    }
    const { userId } = this.props.match.params

    const { message } = this.state
    return {
      scan: { userValid: record.get('userValid') },
      standardMetrics: { measurements: record.get('scanMeasurement') },
      wellnessMetrics: {
        wellnessMetrics,
        measurementLoading: this.props.coach.get('measurementLoading'),
        message,
      },
      additionalMetrics: {
        additionalMetrics: additionalMetrics
          ? additionalMetrics.toJS()
          : additionalMetrics,
        metricsLoading: this.props.coach.get('metricsLoading'),
        message,
      },
      stats: {
        recordId: record.get('id'),
        recordDate: record.get('recordDate'),
        status: record.get('status'),
        scanPackageId: record.get('packageId'),
        userId,
        gender: this.props.clientData.get('gender'),
      },
    }
  }

  render() {
    const {
      coach,
      data,
      clientData,
      capitalize,
      pageProperties,
      components,
      styleConfig,
    } = this.props
    const { userId } = this.props.match.params
    const user = this.props.user.get('user')
    const { client } = this.state

    if (coach.get('loading') || !client || !user || !data || !clientData) {
      return <Loading />
    }

    const results = client.map(this.parseData).toJS()

    const firstName = clientData.get('firstName')
    const lastName = clientData.get('lastName')
    const accessLevel = clientData.get('accessLevel')

    const refactoredAccessLevel =
      accessLevel.toLowerCase() === 'full' ? 'Full' : 'Limited'

    const units = user.units === 'US' ? 'Inches' : 'cm'

    styleConfig.classNames.Table = 'table table-bordered'
    components.Pagination = () => null

    return (
      <div className="container">
        <div className="dashhead mt-4">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">Success Hub</h6>
            <h3 className="dashhead-title">
              Assessment Snapshots
              <NoTranslate>{` - ${capitalize(firstName)} ${capitalize(
                lastName
              )} `}</NoTranslate>
              {`(${refactoredAccessLevel})`}
            </h3>
          </div>
          <div className="dashhead-toolbar">
            <button
              className="btn btn-primary btn-sm"
              onClick={this.redirectPage('/staff/clients/records')}
            >
              <span className="icon icon-back" />
              &nbsp; Return to Client Records
            </button>
          </div>
        </div>
        <hr className="mt-0 mb-4" />
        <Griddle
          data={results}
          pageProperties={pageProperties}
          plugins={[plugins.LocalPlugin]}
          components={components}
          styleConfig={styleConfig}
        >
          <RowDefinition>
            <ColumnDefinition
              id="scan"
              title="Scan"
              accessLevel={accessLevel}
              userId={userId}
              name={{ firstName, lastName }}
              customComponent={enhancedWithRowData(Image)}
            />
            <ColumnDefinition
              id="standardMetrics"
              title={`Standard Metrics (${units})`}
              customComponent={enhancedWithRowData(Measurements)}
            />
            <ColumnDefinition
              id="wellnessMetrics"
              title="Wellness Metrics"
              userId={userId}
              user={user}
              updateHeightWeight={this.updateHeightWeight}
              clearMessage={this.props.clearMessage}
              customComponent={enhancedWithRowData(Metrics)}
            />
            <ColumnDefinition
              id="additionalMetrics"
              title="Additional Metrics"
              type="additional"
              metrics={this.props.metrics}
              updateClientAdditional={this.props.updateClientAdditional}
              fetchFacilityAdditional={this.props.fetchFacilityAdditional}
              clearMessage={this.props.clearMessage}
              customComponent={enhancedWithRowData(Additional)}
            />
            <ColumnDefinition
              id="stats"
              title="Additional Info"
              accessLevel={accessLevel}
              name={{ firstName, lastName }}
              clientId={userId}
              customComponent={enhancedWithRowData(ClientInfo)}
            />
          </RowDefinition>
        </Griddle>
        <div className="row mx-0 d-flex justify-content-end">
          <Pagination
            {...this.props.paginationProps}
            pageProperties={Immutable.fromJS(pageProperties)}
          />
        </div>
      </div>
    )
  }
}

const actions = {
  ...ClientActions,
  fetchFacilityAdditional,
  clearMessage,
}

const mapStateToProps = (state) => ({
  coach: state.coach,
  user: state.user,
  metrics: state.metrics,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default griddleWrapper(
  withClients(connect(mapStateToProps, mapDispatchToProps)(ClientHistory)),
  'coach',
  (DataSource) => DataSource.get('client')
)

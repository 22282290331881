import React from 'react'
import PropTypes from 'prop-types'

import NoTranslate from '../hocs/NoTranslate'
import ScanImage from '../scans/scan_image'
import VideoModal from '../scans/hocs/video_modal'
import Visibility from './visibility'
import Delete from './delete'
import FileDownload from '../utilities/file_download'

import { formatDateTime } from '../../functions/text_format'
import './history.css'

// record is the same as currentScan
const InfoPanel = ({ record }) => (
  <div className="card py-2">
    <VideoModal
      shouldOpen
      header={
        <NoTranslate>{formatDateTime(record.get('recordDate'))}</NoTranslate>
      }
      videoId="report-video"
      scanPackageId={record.get('scanPackageId')}
      src={`/v1/records/${record.get('scanPackageId')}/scan`}
    >
      <div className="mx-auto">
        <ScanImage
          imageId="info-panel"
          style={{ width: '100%' }}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${record.get(
            'scanPackageId'
          )}/scan/img/0/type/blank`}
        />
        <span
          className="fas fa-video"
          style={{ position: 'absolute', top: '20px', right: '20px' }}
        />
      </div>
    </VideoModal>
    <table className="table">
      <tbody>
        <tr>
          <td>
            <span style={{ fontWeight: 400 }}>Date Taken:</span>
          </td>
          <td>
            <NoTranslate>
              {formatDateTime(record.get('recordDate'))}
            </NoTranslate>
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 400 }}>Status:</span>
          </td>
          <td>{record.get('status')}</td>
        </tr>
        <tr>
          <td>
            <span style={{ fontWeight: 400 }}>Posture Status:</span>
          </td>
          <td>{record.get('postureStatus')}</td>
        </tr>
      </tbody>
    </table>
    <div className="px-3">
      <div className="d-flex">
        <Visibility record={record} />
      </div>
      <div className="d-flex">
        <FileDownload
          linkId={'userHistory' + record.get('scanPackageId') + 'obj'}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${record.get(
            'scanPackageId'
          )}/scan/download?type=obj`}
          style={{ width: '100%' }}
        >
          <button className="btn btn-primary btn-block btn-square text-uppercase include-button mx-auto mb-2">
            Download Scan Obj
          </button>
        </FileDownload>
      </div>
      <div className="d-flex">
        <FileDownload
          linkId={'userHistory' + record.get('scanPackageId') + 'gif'}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${record.get(
            'scanPackageId'
          )}/scan/download?type=gif`}
          style={{ width: '100%' }}
        >
          <button className="btn btn-primary btn-block btn-square text-uppercase include-button mx-auto mb-2">
            Download Scan Gif
          </button>
        </FileDownload>
      </div>
      <div className="d-flex">
        <FileDownload
          linkId={'userHistory' + record.get('scanPackageId') + 'measurements'}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/records/measurements/download`}
          style={{ width: '100%' }}
        >
          <button className="btn btn-primary btn-block btn-square text-uppercase include-button mx-auto mb-2">
            Download Measurements
          </button>
        </FileDownload>
      </div>
      <div className="d-flex">
        <Delete record={record} />
      </div>
    </div>
  </div>
)

InfoPanel.propTypes = {
  record: PropTypes.object.isRequired,
}

export default InfoPanel

import * as types from './types'
import action from '../middleware'

export const fetchClientReport = ({ clientId, recordId }) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/${recordId}/report`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_CLIENT_REPORT,
        successType: types.FETCH_CLIENT_REPORT_SUCCESS,
        failureType: types.FETCH_CLIENT_REPORT_FAILURE,
      },
    },
    dispatch
  )

export const fetchClientSummary = (clientId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/summary`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_CLIENT_SUMMARY,
        successType: types.FETCH_CLIENT_SUMMARY_SUCCESS,
        failureType: types.FETCH_CLIENT_SUMMARY_FAILURE,
      },
    },
    dispatch
  )

export const resetClientReport = () => ({
  type: types.RESET_CLIENT_REPORT,
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Categories from './categories'
import PostureRow from './posture_row'

class PostureGrid extends Component {
  constructor(props) {
    super(props)

    this.updateSelected = this.updateSelected.bind(this)
    this.state = {
      selectedCategory: 'front',
    }

    const posture = props.report.get('posture')

    this.displayProps = {
      front: {
        degree: 0,
        direction: 'front',
        data: posture.get('front'),
      },
      side: {
        degree: 90,
        direction: 'side',
        data: posture.get('side'),
      },
      back: {
        degree: 180,
        direction: 'back',
        data: posture.get('front'),
      },
    }
  }

  updateSelected(category) {
    this.setState({ selectedCategory: category })
  }

  render() {
    const { recordId, report, gender, units } = this.props
    const { selectedCategory } = this.state
    // const posture = report.get('posture');
    const postureStatus = report.get('postureStatus')
    let postureGrid

    const src = `${process.env.REACT_APP_API_BASEURL}/v1/records/${report.get(
      'scanPackageId'
    )}/scan/img/${this.displayProps[selectedCategory].degree}/type/posture`

    if (
      postureStatus &&
      postureStatus.toLowerCase().indexOf('complete') !== -1
    ) {
      postureGrid = (
        <div className="card card-default" style={{ borderRadius: 0 }}>
          <div className="card-body p-0">
            <Categories
              selectedCategory={selectedCategory}
              updateSelected={this.updateSelected}
            />
            <PostureRow
              {...this.displayProps[selectedCategory]}
              src={src}
              packageId={report.get('scanPackageId')}
              recordId={recordId}
              // degree={ 0 }
              // direction='front'
              // data={ posture.get('front') }
              gender={gender}
              units={units}
            />
            {/* <PostureRow
                    packageId={ report.get('scanPackageId') }
                    recordId={ recordId }
                    degree={ 90 }
                    direction='side'
                    data={ posture.get('side') }
                    gender={ gender }
                    units={ units } />
                  <PostureRow
                    packageId={ report.get('scanPackageId') }
                    recordId={ recordId }
                    degree={ 180 }
                    direction='back'
                    data={ posture.get('front') }
                    gender={ gender }
                    units={ units } /> */}
          </div>
        </div>
      )
    } else {
      const titleStyle = {
        color: '#1686b0',
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: '16px',
        letterSpacing: '2px',
      }
      postureGrid = (
        <div className="text-center" style={titleStyle}>
          Posture processing is in progress
        </div>
      )
    }

    return (
      <div className="mb-5 print-break-after">
        <div>
          <h2>Posture</h2>
        </div>
        {postureGrid}
      </div>
    )
  }
}

PostureGrid.propTypes = {
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  report: PropTypes.object,
  gender: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}

export default PostureGrid

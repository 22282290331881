import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MeasurementTable from './measurement_table'
import ChartContainer from './chart_container'

export default class MeasurementComparisonInfo extends Component {
  constructor(props) {
    super(props)

    this.changeBodySegement = this.changeBodySegement.bind(this)
    this.state = { selectedSegment: 'torso' }
  }

  changeBodySegement(event) {
    window.ga(
      'send',
      'event',
      'Form Event',
      'Select Changed',
      'selected measurement comparison data type'
    )
    this.setState({ selectedSegment: event.target.value })
  }

  render() {
    const handleChnage = this.changeBodySegement
    return (
      <div style={{ width: '100%' }}>
        <div
          className="mb-2"
          style={{
            color: '#137499',
            fontFamily: 'Lato, sans-serif',
            fontSize: '18px',
            fontWeight: 400,
          }}
        >
          Select a body group:
        </div>
        <select
          onChange={handleChnage}
          className="custom-select"
          style={{ width: '100%', borderRadius: 0, marginBottom: '10px' }}
        >
          <option value="torso">Torso</option>
          <option value="arms">Arms</option>
          <option value="legs">Legs</option>
          <option value="torsoAdditional">Additional Torso</option>
        </select>
        <MeasurementTable
          bodySegments={this.props.bodySegments}
          {...this.props}
          selectedSegment={this.state.selectedSegment}
          currentScan={this.props.currentScan}
          baselineScan={this.props.baselineScan}
          user={this.props.user}
        />
        <div
          style={{
            color: '#137499',
            fontFamily: 'Lato, sans-serif',
            fontSize: '18px',
            fontWeight: 400,
          }}
        >
          Your measurement trends
        </div>
        <div style={{ color: 'rgb(81, 86, 91)' }}>
          (Hover over graph to view more details)
        </div>
        <ChartContainer
          records={this.props.records}
          bodySegments={this.props.bodySegments}
          selectedSegment={this.state.selectedSegment}
          currentScan={this.props.currentScan}
          baselineScan={this.props.baselineScan}
          units={this.props.units}
          width={this.props.width}
        />
      </div>
    )
  }
}

MeasurementComparisonInfo.propTypes = {
  bodySegments: PropTypes.object,
  currentScan: PropTypes.object,
  baselineScan: PropTypes.object,
  width: PropTypes.number,
  updateCurrentTime: PropTypes.func,
  user: PropTypes.object,
  units: PropTypes.string,
  records: PropTypes.object,
}

// data is each grid item
export const sortName = (data, column, sortAscending = true) => {
  const sorted = data.sort((a, b) => {
    return a
      .get(column)
      .toLowerCase()
      .localeCompare(b.get(column).toLowerCase())
  })
  return sortAscending ? sorted : sorted.reverse()
}

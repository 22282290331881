import Immutable from 'immutable'

import {
  // FETCH_RECORDS,
  // SCAN_RECORDS_REQUEST,
  SCAN_RECORDS_SUCCESS,
  SCAN_RECORDS_FAILURE,
  UPDATE_SCAN_RECORD,
  DELETE_SCAN_RECORD,
} from '../actions/types'
import {
  ADD_ADDITIONAL_SUCCESS,
  UPDATE_METRIC_SUCCESS,
} from '../actions/wellness/types'

const reducers = {
  // [FETCH_RECORDS]: (state, action) => {
  //   return state.set('data', Immutable.fromJS(action.payload.data) );
  // },
  [SCAN_RECORDS_SUCCESS]: (state, action) =>
    state
      .set('records', Immutable.fromJS(action.payload.data))
      .set('loading', false),
  [SCAN_RECORDS_FAILURE]: (state) => state.set('loading', false),
  [UPDATE_SCAN_RECORD]: (state, action) => {
    const index = state.get('records').findIndex((record) => {
      return record.get('id') === action.meta.recordId
    })
    const updatedList = state
      .get('records')
      .set(index, Immutable.fromJS(action.payload.data))
    // const updatedList = state.get('records').update(index, record => {
    //   console.log(index, record);
    //   return record.set('visibility', action.payload.data.visibility);
    // });
    return state.set('records', updatedList)
  },
  [DELETE_SCAN_RECORD]: (state, action) => {
    return state.set(
      'records',
      state
        .get('records')
        .filter((record) => record.get('id') !== action.meta.recordId)
    )
  },
  [ADD_ADDITIONAL_SUCCESS]: (state, action) => {
    const index = state
      .get('records')
      .findIndex((record) => record.get('id') === action.meta.recordId)
    const updatedList = state.get('records').update(index, (record) => {
      return record.setIn(
        ['wellnessMetrics', 'additional'],
        Immutable.fromJS(action.payload.data)
      )
    })
    return state.set('records', updatedList)
  },

  [UPDATE_METRIC_SUCCESS]: (state, action) => {
    const { recordId } = action.payload.data
    const index = state
      .get('records')
      .findIndex((item) => item.get('id') === recordId)

    const updatedList = state.get('records').update(index, (record) => {
      return record.set(
        'wellnessMetrics',
        Immutable.fromJS(action.payload.data)
      )
    })

    return state.set('records', updatedList)
  },
}

const defaultState = new Immutable.Map({
  loading: true,
  records: new Immutable.List(),
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    // Call the given reducer from action.type with the current state
    return reducers[action.type](state, action)
  }
  // If there was no reducer function to modify state return our existing state
  return state
}

export const FETCH_HISTOGRAM_DATA = 'FETCH_HISTOGRAM_DATA'
export const FETCH_HISTOGRAM_DATA_SUCCESS = 'FETCH_HISTOGRAM_DATA_SUCCESS'
export const FETCH_HISTOGRAM_DATA_FAILURE = 'FETCH_HISTOGRAM_DATA_FAILURE'

export const FETCH_HEALTH_RANGES = 'FETCH_HEALTH_RANGES'
export const FETCH_HEALTH_RANGES_SUCCESS = 'FETCH_HEALTH_RANGES_SUCCESS'
export const FETCH_HEALTH_RANGES_FAILURE = 'FETCH_HEALTH_RANGES_FAILURE'

export const FETCH_WELLNESS_RANGES = 'FETCH_WELLNESS_RANGES'
export const FETCH_WELLNESS_RANGES_SUCCESS = 'FETCH_WELLNESS_RANGES_SUCCESS'
export const FETCH_WELLNESS_RANGES_FAILURE = 'FETCH_WELLNESS_RANGES_FAILURE'

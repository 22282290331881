export const questions = {
  pastSurgery: 'Have you had surgery in the past 6 months?',
  medication: 'Are you on any medication?',
  jointPain: 'Do you experience any chronic joint pain?',
  symptoms: 'Do you experience any of the following?',
  healthClub: 'Have you ever been a member of a health club before?',
  whyCancelHealthClub: 'Why might have you cancelled?',
  exerciseLast6Months: 'Have you been exercising for the last 6 months?',
  motivation:
    'Have you ever had a hard time attaining results and motivating yourself?',
  motivationWhy: 'If so, why?',
  timesWorkout: 'How many times a week do you plan on working out?',
  timesEatInDay: 'How many times a day do you eat?',
  timesEatOut: 'How often do you eat out for lunch and/or dinner?',
  fitnessGoals: 'What are your primary fitness goals?',
  bodyGoals: 'What areas of your body do you want to focus on?',
  fitnessGoalPrevention:
    'What prevented you from reaching your fitness goals in the past?',
  occupation: 'What is your current occupation?',
  activityLevel: 'Current physical activity level?',
  motivationValue: 'How motivated are you to reach your fitness goals?',
}

export const clientIllnesses = {
  neck: 'Neck',
  shoulder: 'Shoulder',
  midBack: 'Mid Back',
  lowBack: 'Low Back',
  elbow: 'Elbow',
  wrist: 'Wrist',
  hip: 'Hip',
  knee: 'Knee',
  footAnkle: 'Foot/Ankle',
  headaches: 'Headaches',
  allergies: 'Allergies',
  asthma: 'Asthma',
  depression: 'Depression',
  anxiety: 'Anxiety',
  gainWeightEasily: 'Gain Weight Easily',
  dizziness: 'Dizziness',
  fatigue: 'Fatigue',
  indigestion: 'Indigestion',
  difficultyLosingWeight: 'Difficulty Losing Weight',
  constipation: 'Constipation',
  diarrhea: 'Diarrhea',
  insomnia: 'Insomnia',
  restlessSleep: 'Restless Sleep',
  heartTrouble: 'Heart Trouble',
}

export const formattedAnswers = {
  yes: 'Yes',
  no: 'No',
  never: 'Never',
  notOfen: 'Not Often',
  regularly: 'Regularly',
  always: 'Always',
  time: 'Time',
  money: 'Money',
  spouse: 'Spouse',
  procrastination: 'Procrastination',
  lackOfKnowledge: 'Lack of Knowledge',
  sedentary: 'Sedentary',
  moderate: 'Moderate',
  active: 'Active',
  veryActive: 'Very Active',
}

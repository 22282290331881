import * as AdminApi from '../../lib/api/admin'
import { setPageInfo } from '../pagination'

export const ADMIN_FETCH_COACHES = 'ADMIN_FETCH_COACHES'
export const ADMIN_FETCH_COACHES_SUCCESS = 'ADMIN_FETCH_COACHES_SUCCESS'
export const ADMIN_FETCH_COACHES_FAILURE = 'ADMIN_FETCH_COACHES_FAILURE'

export const fetchCoaches = (params) => (dispatch) => {
  dispatch({ type: ADMIN_FETCH_COACHES })

  AdminApi.fetchCoaches(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      console.log('SUCCESS', result)

      dispatch({
        type: ADMIN_FETCH_COACHES_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: ADMIN_FETCH_COACHES_FAILURE, error }))
}

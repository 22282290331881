import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { ModalHOC } from '../../hocs/with_modal'
import NoTranslate from '../../hocs/NoTranslate'
import ScanVideo from '../scan_video'
import ScanControl from '../scan_control'

import './video_modal.css'

const VideoModal = (props) => {
  console.log(props.isModalOpen)
  return (
    <div
      className="video-modal-container"
      onClick={props.shouldOpen ? props.openModal : null}
    >
      {props.children}
      {props.shouldOpen && (
        <Modal isOpen={props.isModalOpen} toggle={props.closeModal}>
          <ModalHeader
            toggle={props.closeModal}
            style={{ borderBottom: 'none' }}
          >
            <NoTranslate>{props.header}</NoTranslate>
          </ModalHeader>
          <ModalBody>
            <ScanVideo {...props} />
            <ScanControl />
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

VideoModal.propTypes = {
  children: PropTypes.node,
  shouldOpen: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isModalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default ModalHOC(VideoModal)

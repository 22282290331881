import React from 'react'
import PropTypes from 'prop-types'

import '../parq.css'

const CheckboxGroup = ({ input, meta, options }) => {
  const { name, onChange } = input
  const { touched, error } = meta
  const inputValue = input.value

  const checkboxes = options.map(({ label, value }, index) => {
    const handleChange = (event) => {
      const arr = [...inputValue]
      if (event.target.checked) {
        arr.push(value)
      } else {
        arr.splice(arr.indexOf(value), 1)
      }
      return onChange(arr)
    }

    const checked = inputValue.includes(value)
    const activeRadioStyling = checked ? 'activeRadioHolder' : ''
    const id = `checkbox-${index}-${name}`

    return (
      <label key={id} className={`mr-3 radioHolder ${activeRadioStyling}`}>
        <span className="mr-3 tick" />
        <input
          type="checkbox"
          {...input}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </label>
    )
  })

  return (
    <div className="checkboxGroupContainer">
      <div>{checkboxes}</div>
      {touched && error && <p className="error">{error}</p>}
    </div>
  )
}

CheckboxGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default CheckboxGroup

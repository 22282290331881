import { createSelector } from 'reselect'
import postureRecordsSelector from './posture_records'

export default createSelector(
  (state) => state.current.get('baselineScan'),
  (state) => postureRecordsSelector(state),
  (baselineScan, records) => {
    if (!baselineScan || !records) {
      return undefined
    }

    if (
      records.filter((record) => record.get('id') === baselineScan.get('id'))
        .size !== 0
    ) {
      return baselineScan
    } else {
      return records.last()
    }
  }
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash';

const genderMap = {
  Female: 'female',
  Male: 'male',
}

// const bodyParts = [
//   { neck: 'neckCollarGirth' },
//   { bust: 'bustGirth' },
//   { chest: 'chestAtBladesGirth' },
//   { waist: 'waistSmallBackGirth' },
//   { hips: 'seatGirth' },
//   { leftBiceps: 'bicepsLeftGirth' },
//   { rightBiceps: 'bicepsRightGirth' },
//   { leftForearm: 'forearmLeftGirth' },
//   { rightForearm: 'forearmRightGirth' },
//   { leftThigh: 'thighLeftMaxGirth' },
//   { rightThigh: 'thighRightMaxGirth' },
//   { leftCalf: 'calfLeftGirth' },
//   { rightCalf: 'calfRightGirth' }
// ];

export default class Measurements extends Component {
  constructor(props) {
    super(props)

    this.measurementKeyNameList = {
      neckCollarGirth: 'Neck',
      chestAtBladesGirth: 'Chest',
      bustGirth: 'Bust',
      waistSmallBackGirth: 'Waist',
      seatGirth: 'Hips',
      bicepsLeftGirth: 'Left Biceps',
      bicepsRightGirth: 'Right Biceps',
      forearmLeftGirth: 'Left Forearm',
      forearmRightGirth: 'Right Forearm',
      thighLeftMaxGirth: 'Left Thigh',
      thighRightMaxGirth: 'Right Thigh',
      calfLeftGirth: 'Left Calf',
      calfRightGirth: 'Right Calf',
    }

    const gender = props.gender
    const keyToDelete = gender === 'Female' ? 'chestAtBladesGirth' : 'bustGirth'

    delete this.measurementKeyNameList[keyToDelete]

    this.state = {
      selectedMeasurement: null,
    }
  }

  updateSelectedMeasurement(measurement) {
    this.setState({ selectedMeasurement: measurement })
  }

  renderImage() {
    // let bodyPartsCopy = bodyParts.slice();
    // const keyToDelete = this.props.gender === 'Female' ? 'chestAtBladesGirth' : 'bustGirth';

    // const genderParsedParts = _.filter(bodyPartsCopy, part => Object.values(part)[0] !== keyToDelete);

    return (
      <div className="my-3 mx-auto demoImageContainer">
        <img
          className="demoImage"
          src={`/images/measurement-guide-${genderMap[this.props.gender]}.png`}
          alt="measurement-guide"
        />
        {/* <div className='mapToolTips'>
          { genderParsedParts.map(bodyPart => {
            let bodyPartKey = Object.keys(bodyPart)[0];
            let bodyPartValue = bodyPart[bodyPartKey];

            return (
              <div
                key={ bodyPartKey }
                className={ bodyPartKey }
                onMouseEnter={ this.updateSelectedMeasurement.bind(this, bodyPartValue) }
                onMouseLeave={ this.updateSelectedMeasurement.bind(this, null) }>
                <div className={ `${bodyPartKey}ToolTip` }>{ this.measurementKeyNameList[bodyPartValue] }</div>
              </div>
          );}) }
        </div> */}
      </div>
    )
  }

  renderMeasurementsData() {
    const { scanMeasurements } = this.props

    return Object.keys(this.measurementKeyNameList).map((measurement) => {
      const measurementName = this.measurementKeyNameList[measurement]
      const value = scanMeasurements.get(measurement)

      const style = { fontSize: '16px' }

      if (this.state.selectedMeasurement === measurement) {
        style.background = '#1686b0'
        style.color = 'white'
      }

      return (
        <tr key={measurement}>
          <td style={style}>{measurementName}</td>
          <td style={style}>{value}</td>
        </tr>
      )
    })
  }

  render() {
    const units = this.props.units === 'US' ? 'Inches' : 'cm'
    return (
      <div className="mb-5">
        <h2 className="mb-3">Measurements</h2>
        <div className="row mx-0">
          <div className="d-flex justify-content-center col-md-6 ">
            <div className="text-center demo-image-cell">
              <div style={{ padding: '8px 0', fontSize: '1.25rem' }}>
                Demo Location Guide
              </div>
              {this.renderImage()}
              <div className="mb-3 mb-sm-0">
                * Note: The sample image is only used to display measurement
                locations and body parts. The table on the right displays your
                measurements.
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Measurement</th>
                  <th>{`Value ${units}`}</th>
                </tr>
              </thead>
              <tbody>{this.renderMeasurementsData()}</tbody>
            </table>
          </div>
          {/* <tbody>
            { this.renderMeasurementsData() }
          </tbody> */}
        </div>
      </div>
    )
  }
}

Measurements.propTypes = {
  scanMeasurements: PropTypes.object,
  packageId: PropTypes.string,
  gender: PropTypes.string,
  units: PropTypes.string.isRequired,
}

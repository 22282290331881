import * as types from './types'
import action from '../middleware'
import axios from 'axios'

// const url = process.env.REACT_APP_API_BASEURL ? process.env.REACT_APP_API_BASEURL : '/';

// export function fetchClients() {
//   return {
//     type: types.FETCH_CLIENTS,
//     payload: 'fetching'
//   };
// }
//
// export function loadClients() {
//   return (dispatch, getState) => {
//     const clients = getState().coach.get('clients');
//
//     if (clients) { return null; }
//     dispatch(fetchClients());
//     return axios.get(`${url}api/v1/facility/clients`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//       .then(response => {
//         dispatch({
//           type: types.FETCH_CLIENTS_SUCCESS,
//           payload: response
//         });
//       })
//       .catch(error => {
//         dispatch({
//           type: types.FETCH_CLIENTS_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

export const loadClients = () => (dispatch, getState) => {
  const clients = getState().coach.get('clients')
  if (clients) {
    return null
  }

  return action(
    {
      endpoint: '/v1/facility/clients',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_CLIENTS,
        successType: types.FETCH_CLIENTS_SUCCESS,
        failureType: types.FETCH_CLIENTS_FAILURE,
      },
    },
    dispatch
  )
}

// export function updateMeasurementsRequest(data) {
//   return {
//     type: types.UPDATE_CLIENT_MEASUREMENTS,
//     payload: data
//   };
// }
//
// export function updateMeasurements(data) {
//   return dispatch => {
//     dispatch(updateMeasurementsRequest(data));
//     return axios({
//       method: 'POST',
//       url: `${url}api/v1/facility/clients/${data.userId}/records/${data.recordId}/wellnessmetrics`,
//       withCredentials: `${ process.env.INClUDE_CREDS }`,
//       data: data.values
//     }).then(response => {
//       dispatch({
//         type: types.UPDATE_CLIENT_MEASUREMENTS_SUCCESS,
//         payload: response,
//         meta: {
//           recordId: data.recordId
//         }
//       });
//     }).catch(error => {
//       dispatch({
//         type: types.UPDATE_CLIENT_MEASUREMENTS_FAILURE,
//         payload: error
//       });
//     });
//   };
// }

// export const manageClient = data => dispatch => {
//   dispatch({
//     type: types.MANAGE_CLIENT,
//     payload: 'fetching'
//   });
//   return axios({
//     method: 'POST',
//     url: `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${data.clientId}/request`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data: data
//   }).then(response => {
//     dispatch({
//       type: types.MANAGE_CLIENT_SUCCESS,
//       payload: response
//     });
//   }).catch(error => {
//     dispatch({
//       type: types.MANAGE_CLIENT_FAILURE,
//       payload: error
//     });
//   });
// };

export function removeClient(data) {
  const response = axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${data.clientId}/remove`,
    withCredentials: `${process.env.INClUDE_CREDS}`,
    data: data,
  })

  return {
    type: types.REMOVE_CLIENT,
    payload: response,
  }
}

// export function requestClientData(data) {
//   return {
//     type: types.FETCH_CLIENT_DATA,
//     payload: data
//   };
// }
//
// export function fetchClientData(id) {
//   return dispatch => {
//     dispatch(requestClientData());
//     return axios.get(`${url}api/v1/facility/clients/${id}/records`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//       .then(response => {
//         dispatch({
//           type: types.FETCH_CLIENT_DATA_SUCCESS,
//           payload: response
//         });
//       })
//       .catch(error => {
//         dispatch({
//           type: types.FETCH_CLIENT_DATA_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

// export function updateAdditionalRequest(data) {
//   return {
//     type: types.UPDATE_CLIENT_ADDITIONAL,
//     payload: data
//   };
// }
//
// export function updateAdditionalSuccess(response, data) {
//   return {
//     type: types.UPDATE_CLIENT_ADDITIONAL_SUCCESS,
//     payload: response,
//     meta: {
//       recordId: data.recordId
//     }
//   };
// }
//
// export function updateClientAdditional(data) {
//   return dispatch => {
//     dispatch(updateAdditionalRequest(data));
//     return axios({
//       method: 'POST',
//       url: `${url}api/v1/facility/clients/${data.userId}/records/${data.recordId}/wellnessmetrics/additionals`,
//       withCredentials: `${ process.env.INClUDE_CREDS }`,
//       data: data.values
//     }).then(response => {
//         dispatch(updateAdditionalSuccess(response, data));
//     }).catch(error => {
//         dispatch({
//           type: types.UPDATE_CLIENT_ADDITIONAL_FAILURE,
//           payload: error
//         });
//     });
//   };
// }

export const clearMessage = () => ({
  type: types.CLEAR_MESSAGE,
  payload: '',
})

// export const resetClientData = () => ({
//   type: types.RESET_CLIENT_DATA,
//   payload: ''
// });

// Client Report
// const fetchClientReportRequest = () => ({
//   type: types.FETCH_CLIENT_REPORT,
//   payload: 'fetching'
// });
//
// export const fetchClientReport = ({ clientId, recordId }) => dispatch => {
//   dispatch(fetchClientReportRequest());
//
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/${recordId}/report`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: types.FETCH_CLIENT_REPORT_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.FETCH_CLIENT_REPORT_FAILURE,
//       payload: error
//     }));
// };
//
// const fetchClientSummaryRequest = () => ({
//   type: types.FETCH_CLIENT_SUMMARY,
//   payload: 'fetching'
// });
//
// export const fetchClientSummary = clientId => dispatch => {
//   dispatch(fetchClientSummaryRequest());
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/summary`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: types.FETCH_CLIENT_SUMMARY_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.FETCH_CLIENT_SUMMARY_FAILURE,
//       payload: error
//     }));
// };
//
// export const resetClientReport = () => ({
//   type: types.RESET_CLIENT_REPORT,
//   payload: ''
// });

// // PARQ
// const fetchPARQDataRequest = () => ({
//   type: types.FETCH_PARQ_DATA,
//   payload: 'fetching'
// });
//
// export const fetchPARQData = ({ clientId, recordId }) => dispatch => {
//   dispatch(fetchPARQDataRequest());
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/${recordId}/parq`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: types.FETCH_PARQ_DATA_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.FETCH_PARQ_DATA_FAILURE,
//       payload: error
//     }));
// };
//
// // PARQ Edit
// export const updatePARQRequest = () => ({
//   type: types.UPDATE_PARQ_DATA,
//   payload: 'fetching'
// });
//
// export const updatePARQData = ({ clientId, recordId, content }) => dispatch => {
//   const data = { recordId, content };
//   dispatch(updatePARQRequest());
//
//   return axios({
//     method: 'POST',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/${recordId}/parq`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data
//   }).then(response => dispatch({
//       type: types.UPDATE_PARQ_DATA_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.UPDATE_PARQ_DATA_FAILURE,
//       payload: error
//     }));
// };
//
// // PARQ Interest
// const getPARQInterestRequest = () => ({
//   type: types.FETCH_PARQ_INTEREST,
//   payload: 'fetching'
// });
//
// export const getPARQInterest = (clientId, recordId) => dispatch => {
//   dispatch(getPARQInterestRequest());
//   return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/${recordId}/parq/status`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//     .then(response => dispatch({
//       type: types.FETCH_PARQ_INTEREST_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.FETCH_PARQ_INTEREST_FAILURE,
//       payload: error
//     }));
// };
//
// const updatePARQInterestRequest = () => ({
//   type: types.UPDATE_PARQ_INTEREST,
//   payload: 'fetching'
// });
//
// export const updatePARQInterest = ({ clientId, recordId, data }) => dispatch => {
//   dispatch(updatePARQInterestRequest());
//   return axios({
//     method: 'POST',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/facility/clients/${clientId}/records/${recordId}/parq/status`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data
//   }).then(response => dispatch({
//       type: types.UPDATE_PARQ_INTEREST_SUCCESS,
//       payload: response
//     }))
//     .catch(error => dispatch({
//       type: types.UPDATE_PARQ_INTEREST_FAILURE,
//       payload: error
//     }));
// };

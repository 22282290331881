import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { inputField } from './input'
import {
  required,
  oneDecimalPoint,
  weightUS,
  weightMetric,
  heightUS,
  heightMetric,
} from './validation'

let EditForm = (props) => {
  return (
    <form
      onSubmit={props.handleSubmit((data) => props.handleMetricSubmit(data))}
    >
      <div className="mb-3">
        <Field
          name="height"
          className="form-control"
          component={inputField}
          label={`Height ${props.units === 'US' ? '(Inches)' : '(cm)'}`}
          validate={[
            required,
            oneDecimalPoint,
            props.units === 'US' ? heightUS : heightMetric,
          ]}
          style={{ width: '150px' }}
        />
      </div>
      <div className="mb-4">
        <Field
          name="weight"
          className="form-control"
          component={inputField}
          label={`Weight ${props.units === 'US' ? '(lbs)' : '(kg)'}`}
          validate={[
            required,
            oneDecimalPoint,
            props.units === 'US' ? weightUS : weightMetric,
          ]}
          style={{ width: '150px' }}
        />
      </div>
      <div className="row mx-0">
        <button className="btn btn-primary" type="submit">
          Update Metrics
        </button>
      </div>
    </form>
  )
}

EditForm.propTypes = {
  units: PropTypes.string.isRequired,
  handleMetricSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

EditForm = reduxForm({
  form: 'biometricsForm',
})(EditForm)

export default EditForm

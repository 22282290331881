import { createSelector } from 'reselect'
import { convertUTC } from '../functions/text_format'
// import Immutable from 'immutable';
import visibleRecords from './visible_records'

// const metricsSelector = state => state.metrics.get('metrics');
const baselineScanSelector = (state) => state.current.get('baselineScan')
const visibleRecordsSelector = (state) => visibleRecords(state)

const getBaselineMetric = (records, scan) => {
  if (records && scan) {
    return records.find(
      (metric) => metric.get('scanPackageId') === scan.get('scanPackageId')
    )
  } else if (records) {
    return records
      .sortBy((metric) => convertUTC(metric.get('recordDate')))
      .reverse()
      .last()
  }

  return null
}

export default createSelector(
  visibleRecordsSelector,
  baselineScanSelector,
  getBaselineMetric
)

import React, { Component } from 'react'
import _ from 'lodash'

import ScanDate from '../scans/scan_dates'

import { capitalize } from '../../functions/text_format'
import './report.css'

export default class ComparisonInfo extends Component {
  constructor(props) {
    super(props)

    this.measurements = [
      props.user.get('gender').toLowerCase() === 'female'
        ? { bustGirth: 'Bust' }
        : { chestAtBladesGirth: 'Chest' },
      { waistSmallBackGirth: 'Waist' },
      { seatGirth: 'Hips' },
      { bicepsLeftGirth: 'Left Biceps' },
      { bicepsRightGirth: 'Right Biceps' },
      { forearmLeftGirth: 'Left Forearm' },
      { forearmRightGirth: 'Right Forearm' },
      { thighLeftMaxGirth: 'Left Thigh' },
      { thighRightMaxGirth: 'Right Thigh' },
      { calfLeftGirth: 'Left Calf' },
      { calfRightGirth: 'Right Calf' },
    ]
  }

  getCurrentMeasurementData(key) {
    const recordId = this.props.currentScan.get('id')
    return this.getSingleMeasurement(recordId, key)
  }

  getBaselineMeasurementData(key) {
    const baselineScan = this.props.baselineScan
    const baselineScanId = baselineScan.get('id')
    return !baselineScan
      ? this.getCurrentMeasurementData(key)
      : this.getSingleMeasurement(baselineScanId, key)
  }

  getSingleMeasurement(recordId, key) {
    const measurements = this.props.records
      .find((record) => {
        return record.get('id') === recordId
      })
      .get('scanMeasurement')

    return measurements.get(key)
  }

  renderMeasurementData() {
    return this.measurements.map((segment) => {
      var key = Object.keys(segment)[0]
      var baselineMeasurement = this.getBaselineMeasurementData(key)
      var currentMeasurement = this.getCurrentMeasurementData(key)
      var difference = Math.max(
        ((currentMeasurement - baselineMeasurement) * 10) / 10
      ).toFixed(1)
      return (
        <tr key={key}>
          <td>{segment[key]}</td>
          <td>{baselineMeasurement}</td>
          <td>{this.getCurrentMeasurementData(key)}</td>
          <td>{difference}</td>
        </tr>
      )
    })
  }

  renderScanDates() {
    const {
      baselineScan,
      currentScan,
      updateBaselineScan,
      updateCurrentScan,
    } = this.props
    return ['baseline', 'current'].map((type) => {
      return (
        <div key={_.uniqueId()} style={{ margin: '0 20px' }}>
          <div className="comparison-label">{capitalize(type)}</div>
          <ScanDate
            report
            type={type}
            records={this.props.records}
            scan={type === 'baseline' ? baselineScan : currentScan}
            updateScan={
              type === 'baseline' ? updateBaselineScan : updateCurrentScan
            }
          />
        </div>
      )
    })
  }

  render() {
    const units = this.props.user.get('units') === 'US' ? 'Inches' : 'cm'
    return (
      <div className="comparison-container">
        <div className="comparison-header">
          <h1 className="hr-divider-content">Measurement Comparisons</h1>
        </div>
        <div className="mb-3 comparison-date-selector">
          <div className="row mx-0 mb-4">
            <h3>Select two scans to view compare:</h3>
          </div>
          <div className="row mx-0">{this.renderScanDates()}</div>
        </div>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>Measurement Name</th>
              <th>
                <span isolate="true">Baseline</span>
                <span isolate="true">{` (${units})`}</span>
              </th>
              <th>
                <span isolate="true">Current</span>
                <span isolate="true">{` (${units})`}</span>
              </th>
              <th>
                <span isolate="true">Difference</span>
                <span isolate="true">{` (${units})`}</span>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderMeasurementData()}</tbody>
        </table>
      </div>
    )
  }
}

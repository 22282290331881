import React, { Component } from 'react'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'
import { adminGridWrapper } from '../grid_wrapper'
import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import { formatDateTime } from '../../../functions/text_format'

import PostureImage from '../image'
import Metadata from '../metadata'
import Stats from '../MeasurementStats'
import Actions from '../ValidateActions'

import '../admin.css'

const metadataKeyNameList = [
  { key: 'postureLastUpdated', name: 'Last Updated' },
  { key: 'validatedDate', name: 'Validated Date' },
  { key: 'validatedBy', name: 'Validated By' },
  { key: 'postureComplete', name: 'Posture Status' },
  { key: 'status', name: 'Status' },
  { key: 'scanValid', name: 'Valid' },
  { key: 'recordId', name: 'Record Id' },
]

class AdminPostureQC extends Component {
  parseData(metric) {
    return {
      0: 0,
      90: 90,
      180: 180,
      metadata: {
        postureLastUpdated: formatDateTime(metric.get('postureLastUpdated')),
        validatedDate: formatDateTime(metric.get('validatedDate')),
        validatedBy: metric.get('validatedBy')
          ? metric.get('validatedBy').substring(0, 10)
          : null,
        postureComplete: metric.get('postureComplete'),
        status: metric.get('status'),
        scanValid: metric.get('scanValid'),
        recordId: metric.get('id'),
        scanPackageId: metric.get('scanPackageId'),
      },
      stats: { measurements: metric.get('scanMeasurement') },
      scanValid: metric.get('scanValid'),
    }
  }

  render() {
    const {
      data,
      pageProperties,
      components,
      styleConfig,
      handleValidation,
    } = this.props
    const results = data.map(this.parseData.bind(this)).toJS()

    return (
      <div className="admin-container">
        <div style={{ marginBotton: '25px', borderBottom: '1px #eee solid' }}>
          <h6 className="dashhead-subtitle">Fit3D Admin</h6>
          <h3 className="dashhead-title">Posture QC</h3>
        </div>
        <div className="griddle-responsive-container small-padding">
          <Griddle
            data={results}
            pageProperties={pageProperties}
            plugins={[plugins.LocalPlugin]}
            components={components}
            styleConfig={styleConfig}
          >
            <RowDefinition>
              <ColumnDefinition
                id="0"
                title="0"
                degree={0}
                type="posture"
                customComponent={enhancedWithRowData(PostureImage)}
              />
              <ColumnDefinition
                id="120"
                title="120"
                degree={90}
                type="posture"
                customComponent={enhancedWithRowData(PostureImage)}
              />
              <ColumnDefinition
                id="90"
                title="90"
                degree={180}
                type="posture"
                customComponent={enhancedWithRowData(PostureImage)}
              />
              <ColumnDefinition
                id="metadata"
                title="Metadata"
                metadataKeyNameList={metadataKeyNameList}
                customComponent={enhancedWithRowData(Metadata)}
              />
              <ColumnDefinition
                id="stats"
                title="Stats"
                customComponent={enhancedWithRowData(Stats)}
              />
              <ColumnDefinition
                id="scanValid"
                title="Scan Valid"
                handleValidation={handleValidation}
                customComponent={enhancedWithRowData(Actions)}
              />
            </RowDefinition>
          </Griddle>
        </div>
      </div>
    )
  }
}

const gridOptions = {
  key: 'adminPosture',
  recordName: 'scanRecords',
  customPageSize: 10,
}

export default adminGridWrapper(AdminPostureQC, gridOptions)

import React from 'react'
import { connect } from 'react-redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { formatDateTime } from '../../../lib/formatText'

const CoachesTable = ({ coaches, pageSize }) => {
  // Use our custom components instead of griddle
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered table-hover',
    },
  }

  return (
    <Griddle
      data={coaches.map((coach) => parseData(coach))}
      plugins={[plugins.LocalPlugin]}
      pageProperties={{ pageSize }}
      components={components}
      styleConfig={styleConfig}
    >
      <RowDefinition>
        <ColumnDefinition id="email" title="Email" />
        <ColumnDefinition id="firstName" title="First" />
        <ColumnDefinition id="lastName" title="Last" />
        <ColumnDefinition id="signupDate" title="Signup Date" />
        <ColumnDefinition id="roles" title="Roles" />
        <ColumnDefinition id="facility" title="Facility" />
      </RowDefinition>
    </Griddle>
  )
}

const parseData = (coach) => {
  return {
    email: coach.email,
    firstName: coach.firstName,
    lastName: coach.lastName,
    roles: coach.superAdmin
      ? 'Fit3D Admin'
      : coach.facilityAdmin
      ? 'Facility Admin'
      : coach.coach
      ? 'Coach'
      : 'User',
    signupDate: formatDateTime(coach.signupDate),
    facility: coach.facility && coach.facility.name,
  }
}

const mapStateToProps = (state) => ({
  coaches: state.newAdmin.coaches,
  pageSize: state.pagination.pageSize,
})

export default connect(mapStateToProps)(CoachesTable)

import React from 'react'
import { connect } from 'react-redux'

import { setPage, setPageSize } from '../../actions/pagination'

import Pagination from '../pagination'

const AdminPagination = ({ search, pagination, fetchData, setPage }) => {
  const handlePageSelect = (page) => {
    const { searchParam, searchString } = search
    const params = {
      search: {
        searchParam,
        searchString,
      },
      page,
      size: pagination.pageSize,
    }

    fetchData(params)
  }

  const handleSizeSelect = (size) => {
    const { searchParam, searchString } = search
    const params = {
      search: {
        searchParam,
        searchString,
      },
      page: 0,
      size,
    }

    fetchData(params)
  }

  const handleReload = () => {
    fetchData({
      page: 0,
      size: pagination.pageSize,
    })

    setPage(0)
  }

  return (
    <Pagination
      onPageSelect={handlePageSelect}
      onSizeSelect={handleSizeSelect}
      onReload={handleReload}
    />
  )
}

const mapStateToProps = (state) => ({
  search: state.newSearch,
  pagination: state.pagination,
})

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setPage(page)),
  setPageSize: (pageSize) => dispatch(setPageSize(pageSize)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPagination)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loadClients } from '../../../actions/coach/index'

export const withClients = (WrappedComponent) => {
  class ClientFilter extends Component {
    constructor(props) {
      super(props)
      this.state = { clientData: null }
    }

    componentDidMount() {
      this.props.loadClients()
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.coach.get('clients')) {
        const clientData = nextProps.coach.get('clients').find((client) => {
          return client.get('userId') === nextProps.match.params.userId
        })
        this.setState({ clientData })
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} clientData={this.state.clientData} />
      )
    }
  }

  ClientFilter.propTypes = {
    loadClients: PropTypes.func,
    coach: PropTypes.object,
    match: PropTypes.object,
  }

  const mapStateToProps = (state) => ({ coach: state.coach })
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ loadClients }, dispatch)

  return connect(mapStateToProps, mapDispatchToProps)(ClientFilter)
}

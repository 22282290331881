import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import currentScanSelector from '../../../selectors/current_scan'

let AdditionalForm = (props) => {
  const fields = props.facilityMetrics.map((metric) => {
    return (
      <div key={metric.get('typeId')} className="col-md-6 mb-2">
        <label>{`${metric.get('name')} (${metric.get('units')})`}</label>
        <Field
          className="form-control input-sm col-sm-8"
          name={metric.get('label')}
          component="input"
          type="text"
        />
      </div>
    )
  })

  const handleClose = props.closeModal

  return (
    <form
      onSubmit={props.handleSubmit((data) =>
        props.handleAdditionalSubmit(data)
      )}
    >
      <div className="row mb-4 mx-0">{fields}</div>
      {props.message && <div className="text-center mb-3">{props.message}</div>}
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-default ml-auto mr-3"
          onClick={handleClose}
        >
          Close
        </button>
        <button type="submit" className="btn btn-primary">
          Update Metrics
        </button>
      </div>
    </form>
  )
}

AdditionalForm = reduxForm({
  form: 'additionalForm',
})(AdditionalForm)

AdditionalForm.propTypes = {
  facilityMetrics: PropTypes.object,
  message: PropTypes.string,
  handleAdditionalSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
}

function generateInitialValues(state) {
  const currentScan = currentScanSelector(state)

  if (currentScan && currentScan.get('wellnessMetrics')) {
    const initialValues = {}
    currentScan
      .get('wellnessMetrics')
      .get('additional')
      .forEach((metric) => {
        initialValues[metric.get('label')] = metric.get('value')
      })

    return initialValues
  }
  return null
}

const mapStateToProps = (state) => ({
  facilityMetrics: state.metrics.get('facility'),
  message: state.metrics.get('statusMessage'),
  initialValues: generateInitialValues(state),
})

export default connect(mapStateToProps)(AdditionalForm)

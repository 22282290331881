export const FIT3D_ADMIN = 'FIT3D_ADMIN'
export const FACILITY = 'FACILITY'
export const FITNESS = 'FITNESS'
export const HISTORY = 'HISTORY'
export const MARKETPLACE = 'MARKETPLACE'
export const ACCOUNT = 'ACCOUNT'
// export const ENTERPRISE = 'ENTERPRISE';

export const fetchRouteGroup = (url) => {
  const groups = {
    [FIT3D_ADMIN]: ['/admin'],
    [FACILITY]: ['/facility', '/staff'],
    [FITNESS]: ['/wellness', '/comparison', '/glossary'],
    [HISTORY]: ['/history'],
    [MARKETPLACE]: ['/marketplace'],
    [ACCOUNT]: [
      // '/history',
      '/user/coaches',
      '/profile',
    ],
    // [ENTERPRISE]: ['/enterprise']
  }

  for (const group in groups) {
    const urls = groups[group]
    for (const i in urls) {
      if (urls[i] === url || url.indexOf(urls[i]) !== -1) return group
    }
  }

  return null
}

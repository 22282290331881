import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ScanImage extends Component {
  constructor(props) {
    super(props)
    this._mounted = false
    this.ID = props.imageId ? props.src + props.imageId : props.src
  }

  componentDidMount() {
    this._mounted = true
    this.fetchScanImg(this.props.src)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.fetchScanImg(nextProps.src)
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  fetchScanImg(uri) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    }

    return new Promise((resolve, reject) => {
      window.window
        .fetch(uri, options)
        .then((response) => response.blob())
        .then((blob) => {
          if (this._mounted) {
            const url = URL.createObjectURL(blob)

            const imgSrc = document.getElementById(this.ID)
            imgSrc.setAttribute('src', url)
          }
          resolve()
        })
        .catch((err) => reject(err))
    })
  }

  render() {
    return <img id={this.ID} style={this.props.style} alt="" />
  }
}

ScanImage.propTypes = {
  imageId: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default ScanImage

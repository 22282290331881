import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { capitalize } from '../../../functions/text_format'

export const FacilityActivityList = (props) => {
  const total = props.list.reduce(
    (accumulator, el) => accumulator + el.get(props.type),
    0
  )
  return props.list.map((item, idx) => (
    <li key={_.uniqueId()} className="d-flex list-group-item">
      {`${capitalize(props.title)} ${idx + 1}`}
      <div className="ml-auto">
        <span className="ml-3">
          <span
            className="badge badge-primary badge-pill"
            style={{
              borderRadius: '2px',
              padding: '6px 12px',
              fontSize: '80%',
            }}
          >
            {item.get(props.type)}
          </span>
        </span>
      </div>
      <span
        className="list-group-progress bg-primary"
        style={{ width: (item.get(props.type) / total) * 100 + '%' }}
      />
    </li>
  ))
}

FacilityActivityList.propTypes = {
  list: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

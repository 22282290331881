import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadScanRecords } from '../actions/records'
import { loadMetrics } from '../actions/wellness'

import Report from '../components/report/index.js'
import ComparisonReport from '../components/report/comparison'

class ReportPage extends Component {
  componentDidMount() {
    this.props.loadScanRecords()
    this.props.loadMetrics()
  }

  render() {
    return (
      <Switch>
        <Route
          path="/report/comparison"
          component={(props) => <ComparisonReport {...props} />}
        />
        <Route path="/report" component={(props) => <Report {...props} />} />
      </Switch>
    )
  }
}

ReportPage.propTypes = {
  loadScanRecords: PropTypes.func,
  loadMetrics: PropTypes.func,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loadScanRecords, loadMetrics }, dispatch)

export default connect(null, mapDispatchToProps)(ReportPage)

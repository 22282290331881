import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { updateCurrentScan, updateBaselineScan } from '../../actions/records'
// import { updateCurrentPosture, updateBaselinePosture } from '../../actions/posture';
import currentScanSelector from '../../selectors/current_scan'
import { convertUTC, formatDateTime } from '../../functions/text_format'

import NoTranslate from '../hocs/NoTranslate'

import './scans.css'

// TODO: Change currentScan so that it can take in any scan
class ScanDate extends Component {
  constructor(props) {
    super(props)

    this.changeScan = this.changeScan.bind(this)
    this.renderOption = this.renderOption.bind(this)
  }

  changeScan(event) {
    event.preventDefault()
    window.ga(
      'send',
      'event',
      'Form Event',
      'Select Changed',
      'Selected scan from scan date'
    )

    this.props.updateScan(this.findRecord(event.target.value))
    this.setState({ selectedScanId: parseInt(event.target.value, 10) })
  }

  findRecord(recordId) {
    return this.props.records.find((record) => {
      return record.get('id') === parseInt(recordId, 10)
    })
  }

  renderOption(record) {
    const date = formatDateTime(moment.utc(record.get('recordDate')))
    const recordId = record.get('id')
    const currentScanDate = this.props.currentScan
      ? this.props.currentScan.get('recordDate')
      : this.props.records.first().get('recordDate')
    const currentScanUTC = convertUTC(currentScanDate)
    const utcDate = convertUTC(record.get('recordDate'))

    if (this.props.type === 'baseline' && utcDate >= currentScanUTC) {
      return (
        <option key={recordId} value={recordId} disabled>
          {date}
        </option>
      )
    }
    return (
      <option key={recordId} value={recordId}>
        {date}
      </option>
    )
  }

  render() {
    if (!this.props.scan) {
      return <div />
    }
    const optionList = this.props.records.map(this.renderOption)
    const handleSelect = this.changeScan
    return (
      <div>
        <NoTranslate>
          <select
            value={this.props.scan.get('id')}
            onChange={handleSelect}
            className={`custom-select ${
              this.props.report ? 'report-date-container' : 'scanDateContainer'
            }`}
          >
            {optionList.toJS()}
          </select>
        </NoTranslate>
      </div>
    )
  }
}

ScanDate.propTypes = {
  records: PropTypes.object.isRequired,
  // postureData: PropTypes.object,
  updateScan: PropTypes.func.isRequired,
  updateCurrentScan: PropTypes.func,
  updateBaselineScan: PropTypes.func,
  // updateCurrentPosture: PropTypes.func,
  // updateBaselinePosture: PropTypes.func,
  type: PropTypes.string,
  scan: PropTypes.object,
  currentScan: PropTypes.object,
  report: PropTypes.bool,
}

export const mapStateToProps = (state) => ({
  currentScan: currentScanSelector(state),
})

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateCurrentScan, updateBaselineScan }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ScanDate)

import * as RestAPI from '../restApi'

// Scan records
export const fetchAdminRecordsSummary = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/records/summary', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateScanValidStatus = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/records/${params.id}/validate`, params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

// QC records
export const fetchQcRecords = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/records/qc', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

// Facilities
export const fetchFacilities = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/facilities', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const createFacility = (data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/facilities`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateFacility = (facilityId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/facilities/${facilityId}`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

// Scanners
export const fetchScanners = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/scanners', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateScanner = (scannerId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/scanners/${scannerId}`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

// Coaches
export const fetchCoaches = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/users/coaches', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

// Users
export const fetchUsers = (params) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/v1/admin/users', params)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateUserEmail = (userId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/users/${userId}/email`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateUserFacility = (userId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/users/${userId}/facility`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const updateRole = (userId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/users/${userId}/roles`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const removeRole = (userId, data) => {
  return new Promise((resolve, reject) => {
    RestAPI.DELETE(`/v1/admin/users/${userId}/roles`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const mergeUserRecords = (data) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/v1/admin/users/merge`, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

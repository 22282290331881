import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { renderNameField } from './field_components'

class PasswordForm extends Component {
  constructor(props) {
    super(props)

    this.hidePasswordForm = this.hidePasswordForm.bind(this)
    this.handleTouched = this.handleTouched.bind(this)

    this.initialState = {
      error: null,
      success: null,
      anyTouched: false,
    }

    this.state = this.initialState
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.selected === 'passwordForm' &&
      nextProps.selected !== 'passwordForm'
    ) {
      this.props.reset()
    }
  }

  hidePasswordForm() {
    this.setState({ success: null })
    this.props.cancelPasswordEdit()
  }

  validatePassword(values) {
    this.setState({ error: null })

    if (!values.currentPassword) {
      throw new SubmissionError({
        currentPassword: 'Current password required.',
        _error: 'Failed to change password.',
      })
    }
    if (!values.password) {
      throw new SubmissionError({
        password: 'Password required.',
        _error: 'Failed to change password.',
      })
    }
    if (values.password.length < 8) {
      throw new SubmissionError({
        password: 'Password must be 8 characters in length.',
        _error: 'Failed to change password.',
      })
    }
    if (!values.confirmPassword) {
      throw new SubmissionError({
        confirmPassword: 'Password confirmation required.',
        _error: 'Failed to change password.',
      })
    }
    if (values.password !== values.confirmPassword) {
      throw new SubmissionError({
        confirmPassword: 'Passwords do not match.',
        _error: 'Failed to change password.',
      })
    }
    if (values.currentPassword === values.password) {
      throw new SubmissionError({
        confirmPassword:
          'New password cannot be the same as your current password.',
        _error: 'Failed to change password.',
      })
    } else {
      this.props.updatePassword(values).then((response) => {
        if (response && response.payload && response.payload.status === 200) {
          this.setState({ success: 'Successfully changed password!' })
          this.props.reset()
        } else {
          this.setState({ error: 'Failed to change password.' })
        }
      })
    }
  }

  handleTouched(bool) {
    this.setState({ anyTouched: bool })
    if (bool === true) {
      this.setState({ success: null })
    }
  }

  handleCancel() {
    this.props.cancelPasswordEdit()
    this.setState(this.initialState)
  }

  renderFields() {
    const fieldsInfo = [
      { name: 'currentPassword', label: 'Current Password' },
      { name: 'password', label: 'New Password' },
      { name: 'confirmPassword', label: 'Confirm New Password' },
    ]

    return fieldsInfo.map(({ name, label }) => (
      <div key={name} className="form-group">
        <Field
          name={name}
          type="password"
          component={renderNameField}
          label={label}
          focusHandler={() => this.handleTouched(true)}
          blurHandler={() => this.handleTouched(false)}
        />
      </div>
    ))
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props
    return (
      <div>
        <div className="mb-3">
          <h3>Edit Password</h3>
        </div>
        <div>
          <form
            onSubmit={handleSubmit((data) => {
              window.ga(
                'send',
                'event',
                'Button',
                'click',
                'user edit password'
              )
              this.validatePassword(data)
            }).bind(this)}
          >
            <div>{this.renderFields()}</div>
            {this.state.error && (
              <div className="text-danger">{this.state.error}</div>
            )}
            {this.state.success && (
              <div className="text-success">{this.state.success}</div>
            )}
            <div className="d-flex justify-content-end mt-5">
              <button
                type="button"
                onClick={this.handleCancel}
                className="btn"
                disabled={submitting}
                style={{ width: '200px' }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary ml"
                disabled={pristine || submitting}
                style={{ width: '200px' }}
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const Form = reduxForm({
  form: 'passwordForm',
})(PasswordForm)

PasswordForm.propTypes = {
  selected: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  cancelPasswordEdit: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  anyTouched: PropTypes.bool,
}

export default Form

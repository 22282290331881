import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { updateUserFacility } from '../../../../actions/admin/users'

import { capitalize } from '../../../../lib/formatText'

const FacilityEditModal = ({
  facilities,
  users: { facilityEditForm, facilityEditOpen, errorMessage },
  updateUserFacility,
  onUpdate,
  onClose,
}) => {
  const {
    userId,
    firstName,
    lastName,
    currentFacility,
    facility,
    selectedId,
  } = facilityEditForm
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [matchedFacilityNames, setMatchedFacilityNames] = React.useState([])

  React.useEffect(() => {
    if (errorMessage || !facilityEditOpen) {
      setIsSubmitting(false)
    }
  }, [errorMessage, facilityEditOpen])

  const handleUpdate = (event) => {
    const input = event.target.value
    let filteredData = facilities.filter(({ name }) => {
      return (
        input && name && name.toLowerCase().indexOf(input.toLowerCase()) !== -1
      )
    })

    // minimize the amount of data being shown (for large lists of facilities)
    if (filteredData.length > 10) {
      filteredData = filteredData.slice(0, 10)
    }

    setMatchedFacilityNames(filteredData)

    onUpdate({ facility: event.target.value })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    updateUserFacility({
      userId,
      data: {
        facility: {
          id: selectedId,
        },
      },
    })
  }

  const handleFacilitySelect = (id, name) => {
    onUpdate({ selectedId: id, facility: name })
  }

  return (
    <Modal isOpen={facilityEditOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {`Change Facility for ${capitalize(firstName)} ${capitalize(lastName)}`}
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          This user is currently a part of <strong>{currentFacility}</strong>.
          Start typing a facility name to search for a facility. The max amount
          of results displayed is 10.
        </div>
        <div className="input-group">
          <input
            className="form-control"
            placeholder="Facility Name"
            value={facility}
            onChange={handleUpdate}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <span className="icon icon-magnifying-glass" />
            </span>
          </div>
        </div>
        <ul className="list-group mt-3">
          {matchedFacilityNames.map(({ name, id }, idx) => {
            let className = 'list-group-item'
            if (selectedId === id) {
              className += ' active'
            }
            return (
              <li
                key={name + idx}
                className={className}
                style={{ cursor: 'pointer' }}
                onClick={() => handleFacilitySelect(id, name)}
              >
                {name}
              </li>
            )
          })}
        </ul>
        {errorMessage && (
          <div className="text-center text-danger">{errorMessage}</div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={onClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          disabled={
            !(selectedId && matchedFacilityNames.length > 0) || isSubmitting
          }
          onClick={handleSubmit}
        >
          Change Facility
        </button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  facilities: state.newAdmin.facilities.list,
  users: state.newAdmin.users,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserFacility: (params) => dispatch(updateUserFacility(params)),
  onUpdate: (facility) =>
    dispatch({ type: 'EDIT_ADMIN_USER_FACILITY', payload: facility }),
  onClose: () => dispatch({ type: 'CLOSE_FACILITY_EDIT' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityEditModal)

import React from 'react'
import PropTypes from 'prop-types'

import Table from './Table'

import {
  wellnessMetricsKeyNameList,
  measurementKeyNameList,
} from '../../measurements/measurement_names'

const selectedWellnessMetrics = [
  'bfp31',
  'bfp4',
  'bmi',
  'bmr',
  'bodyShapeRating',
  'fatMass',
  'height',
  'leanMass',
  'trunkLegVolRatio',
  'wc',
  'weight',
  'weightManual',
  'whr',
]

const selectedMeasurements = [
  'neckCollarGirth',
  'chestAtBladesGirth',
  'bustGirth',
  'underbustGirth',
  'waistSmallBackGirth',
  'seatGirth',
  'bicepsLeftGirth',
  'bicepsRightGirth',
  'forearmLeftGirth',
  'forearmRightGirth',
  'thighLeftMaxGirth',
  'thighRightMaxGirth',
  'calfLeftGirth',
  'calfRightGirth',
  'ankleLeftGirth',
  'ankleRightGirth',
  'hipTopGirth',
  'elbowLeftGirth',
  'elbowRightGirth',
  'wristLeftGirth',
  'wristRightGirth',
  'kneeLeftGirth',
  'kneeRightGirth',
]

const WellnessTable = ({ wellnessMetrics }) => {
  const table = []
  const wellnessMetricsCopy = selectedWellnessMetrics.slice() // don't modify selectedWellnessMetrics
  while (wellnessMetricsCopy.length > 0) {
    const metrics = wellnessMetricsCopy.splice(0, 2)
    table.push(
      <tr key={metrics[0] + metrics[1]}>
        <td style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}>
          {wellnessMetricsKeyNameList[metrics[0]]}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}>
          {wellnessMetrics.get(metrics[0])}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}>
          {wellnessMetricsKeyNameList[metrics[1]]}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}>
          {wellnessMetrics.get(metrics[1])}
        </td>
      </tr>
    )
  }
  return <Table table={table} />
}

const MeasurementTable = ({ measurements }) => {
  const table = []
  const measurementsCopy = selectedMeasurements.slice()
  while (measurementsCopy.length > 0) {
    const metrics = measurementsCopy.splice(0, 2)
    table.push(
      <tr key={metrics[0] + metrics[1]}>
        <td style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}>
          {measurementKeyNameList[metrics[0]]}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}>
          {measurements.get(metrics[0])}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}>
          {measurementKeyNameList[metrics[1]]}
        </td>
        <td style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}>
          {measurements.get(metrics[1])}
        </td>
      </tr>
    )
  }
  return <Table table={table} />
}

MeasurementTable.propTypes = {
  measurements: PropTypes.object,
}

const MetricsTable = ({ measurements, wellnessMetrics }) => (
  <>
    {wellnessMetrics && (
      <div className="mb-3">
        <WellnessTable wellnessMetrics={wellnessMetrics} />
      </div>
    )}
    {measurements && <MeasurementTable measurements={measurements} />}
  </>
)

export default MetricsTable

import React from 'react'
import PropTypes from 'prop-types'

export const shortAnswerField = ({
  label,
  input,
  type,
  meta: { touched, error },
}) => (
  <div className="input-row mb-5">
    <h4>{label}</h4>
    <div>
      <input className="form-control input-lg" {...input} type={type} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
)

shortAnswerField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
}

import { createSelector } from 'reselect'

import completeClientRecordsSelector from './complete_client_records'

export default createSelector(
  (state) => state.current.get('currentScan'),
  (state) => completeClientRecordsSelector(state),
  (currentScan, clientRecords) => {
    if (!clientRecords || clientRecords.size === 0) {
      return undefined
    }

    if (
      currentScan &&
      clientRecords.find((record) => record.get('id') === currentScan.get('id'))
    ) {
      return currentScan
    } else {
      return clientRecords.first()
    }
  }
)

import * as RestAPI from '../../restApi'

export const getScanner = (scannerCode) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET(`/admin/scanners/${scannerCode}`, null, 'v2Admin')
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

export const deprecateScanner = (scannerCode) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/admin/scanners/${scannerCode}/deprecate`, null, 'v2Admin')
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}

import React from 'react'
import PropTypes from 'prop-types'

import { metricData } from '../wellness/metric_data'
import './wellness.css'

const MetricSelector = (props) => {
  const metricSelector = props.metrics.map((metric) => {
    const { name, units } = metricData(props.units)[metric]
    let className = 'statcard metric-selector p-3'

    if (metric === props.selectedMetric) {
      className += ' selected'
    }

    return (
      <div
        key={name}
        className="mb-3"
        style={{ cursor: 'pointer' }}
        onClick={() => props.updateSelected('selectedMetric', metric)}
      >
        <div className={className}>
          <div>
            <span
              className="statcard-desc"
              style={{
                color: metric === props.selectedMetric ? 'white' : '#1686b0',
              }}
            >
              {name}
            </span>
            <h2 className="statcard-number">
              <div>
                <span style={{ fontSize: '48px' }}>
                  {props.wellnessMetrics.get(metric)}
                </span>
                <span style={{ fontSize: '28px' }}>{units}</span>
              </div>
            </h2>
          </div>
        </div>
      </div>
    )
  })

  return <div>{metricSelector}</div>
}

MetricSelector.propTypes = {
  selectedMetric: PropTypes.string.isRequired,
  wellnessMetrics: PropTypes.object,
  metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  units: PropTypes.string,
  updateSelected: PropTypes.func.isRequired,
}

export default MetricSelector

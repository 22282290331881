import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { handleReprocess } from '../../../actions/admin'

import AdminRecordActions from './actions'
import Loading from '../../utilities/loading'

import { formatDateTime } from '../../../lib/formatText'

const RecordsTable = ({
  admin: { fetchingRecords, records },
  pageSize,
  selectedScan,
  handleReprocess,
  updateSelectedScan,
}) => {
  // Use our custom components instead of griddle
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered table-hover',
    },
  }

  return (
    <div
      className="griddle-responsive-container small-padding"
      style={{ position: 'relative' }}
    >
      <Griddle
        data={records.map((record) => parseData(record, selectedScan))}
        plugins={[plugins.LocalPlugin]}
        pageProperties={{ pageSize }}
        components={components}
        styleConfig={styleConfig}
      >
        <RowDefinition>
          <ColumnDefinition id="packageId" title="packageId" />
          <ColumnDefinition id="status" title="Status" />
          <ColumnDefinition id="SR" title="SR" />
          <ColumnDefinition id="SW" title="SW" />
          <ColumnDefinition id="SRV" title="SRV" />
          <ColumnDefinition id="SWV" title="SWV" />
          <ColumnDefinition id="N" title="N" />
          <ColumnDefinition id="R" title="R" />
          <ColumnDefinition id="M" title="M" />
          <ColumnDefinition id="P" title="P" />
          <ColumnDefinition id="V" title="V" />
          <ColumnDefinition id="HW" title="HW" />
          <ColumnDefinition id="recordDate" title="Date" />
          <ColumnDefinition id="email" title="E-mail" />
          <ColumnDefinition id="facility" title="Facility" />
          <ColumnDefinition id="scanner" title="Scanner" />
          <ColumnDefinition
            id="actions"
            title="Actions"
            handleReprocess={handleReprocess}
            updateSelectedScan={updateSelectedScan}
            customComponent={AdminRecordActions}
          />
        </RowDefinition>
      </Griddle>
      {fetchingRecords && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            top: 0,
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading />
        </div>
      )}
    </div>
  )
}

function parseData(record, selectedScan) {
  return {
    packageId: record.scanPackageId,
    status: record.status,
    SR: record.statusScanRaw,
    SW: record.statusScanWatertight,
    SRV: record.statusScanRawVis,
    SWV: record.statusScanWatertightVis,
    N: record.notifiedStatus,
    R: record.reprocessCount,
    M: record.measurementsValid,
    V: record.scanValid,
    P: record.postureStatusId,
    HW: record.hwVersion,
    scanId: record.id,
    recordDate: formatDateTime(record.recordDate),
    email: record.email,
    facility: record.facilityName,
    scanner: record.scannerCode,
    actions: {
      scanPackageId: record.scanPackageId,
      recordId: record.id,
      selectedScan,
    },
  }
}

const mapStateToProps = (state) => ({
  admin: state.newAdmin,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleReprocess }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RecordsTable)

import React from 'react'
import PropTypes from 'prop-types'

const AccessInstruction = ({ title, body }) => (
  <div className="card" style={{ borderRadius: 0 }}>
    <h6
      className="card-header text-xs-center m-a-0"
      style={{ borderRadius: 0, background: '#1997c6', color: 'white' }}
    >
      {title}
    </h6>
    <div className="card-body text-xs-center">{body}</div>
  </div>
)

AccessInstruction.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default AccessInstruction

import React from 'react'
import PropTypes from 'prop-types'

const SurveyResults = ({ results }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        <th style={{ width: '66.67%' }}>Question</th>
        <th style={{ width: '66.67%' }}>Answer</th>
      </tr>
    </thead>
    <tbody>{results}</tbody>
  </table>
)

SurveyResults.propTypes = {
  results: PropTypes.array,
}

export default SurveyResults

import React from 'react'

import TwoPanelView from './TwoPanelView'
import ScanImage from '../../../scans/scan_image'

export default function SilhouetteImages({ scanPackageId }) {
  return (
    <TwoPanelView
      left={
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/img/0/type/slice`}
        />
      }
      right={
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/img/90/type/slice`}
        />
      }
    />
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchClientData, resetClientData } from '../../../actions/coach/client'
import { updateCurrentScan, updateBaselineScan } from '../../../actions/records'
// import { loadScanRecords } from '../../../actions/records';
// import { loadPostureData } from '../../../actions/posture';

// import currentScanSelector from '../../../selectors/posture/current_posture_scan';
// import baselineScanSelector from '../../../selectors/posture/baseline_posture_scan';
import completeClientRecordsSelector from '../../../selectors/complete_client_records'
import clientPostureRecordsSelector from '../../../selectors/posture/client_posture_records'
import clientBaselineSelector from '../../../selectors/client_baseline_scan'
import clientCurrentSelector from '../../../selectors/client_current_scan'

// import BaselinePosturePanel from './baseline_posture_panel';
import PostureComparisonInfo from '../../posture/comparison/posture_comparison_info'
import PosturePanel from '../../posture/posture_panel'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

class ClientPostureComparison extends Component {
  constructor(props) {
    super(props)

    this.updateView = this.updateView.bind(this)
    this.state = { view: 'front' }
  }

  componentDidMount() {
    this.props.fetchClientData(this.props.match.params.userId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentScan && !nextProps.currentScan.get('posture')) {
      nextProps.updateCurrentScan(nextProps.postureRecords.first())
    }
    if (nextProps.baselineScan && !nextProps.baselineScan.get('posture')) {
      nextProps.updateBaselineScan(nextProps.postureRecords.last())
    }
  }

  updateView(view) {
    window.ga('send', 'event', 'Button', 'click', `User viewed ${view} posture`)
    this.setState({ view })
  }

  renderBaselinePosturePanel(className) {
    const clientId = this.props.match.params.userId
    const recordId = this.props.baselineScan.get('id')

    const { view } = this.state
    let degree
    switch (view) {
      case 'front':
        degree = '0'
        break
      case 'side':
        degree = '90'
        break
      case 'back':
        degree = '180'
        break
      default:
        degree = '0'
    }

    let src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${degree}/type/posture`
    if (this.props.baselineScan.get('accessLevel') !== 'full') {
      src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${degree}/type/posture-silhouette`
    }

    return (
      <div className={className}>
        <PosturePanel
          title="Baseline"
          type="baseline"
          view={this.state.view}
          scan={this.props.baselineScan}
          currentPosture={this.props.baselineScan}
          records={this.props.postureRecords}
          updateScan={this.props.updateBaselineScan}
          // postureData={ this.props.postureData }
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  renderPosturePanel(className) {
    const clientId = this.props.match.params.userId
    const recordId = this.props.currentScan.get('id')

    const { view } = this.state
    let degree
    switch (view) {
      case 'front':
        degree = '0'
        break
      case 'side':
        degree = '90'
        break
      case 'back':
        degree = '180'
        break
      default:
        degree = '0'
    }

    let src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${degree}/type/posture`
    if (this.props.currentScan.get('accessLevel') !== 'full') {
      src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${degree}/type/posture-silhouette`
    }

    return (
      <div className={className}>
        <PosturePanel
          title="Current"
          type="current"
          view={this.state.view}
          scan={this.props.currentScan}
          currentPosture={this.props.currentScan}
          records={this.props.postureRecords}
          updateScan={this.props.updateCurrentScan}
          // postureData={ this.props.postureData }
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  // TODO: clean up loading logic
  render() {
    const {
      coach,
      clientRecords,
      postureRecords,
      currentScan,
      baselineScan,
    } = this.props

    if (
      this.props.user.get('user').get('facility').get('postureAccess') !== 1
    ) {
      return (
        <Unavailable title="Unavailable for this facility">
          <div>
            Click <u onClick={() => this.props.history.push('/')}>here</u> to
            return home.
          </div>
        </Unavailable>
      )
    }
    if (coach.get('loading') || !clientRecords) {
      return <Loading />
    }
    if (postureRecords && postureRecords.size < 2) {
      return (
        <Unavailable title="Not enough visible scans">
          <div>
            There needs to be at least 2 different visible scans.&nbsp; Click{' '}
            <u
              onClick={() => this.props.history.push('/staff/clients/records')}
            >
              here
            </u>{' '}
            to return to all client records.
          </div>
        </Unavailable>
      )
    } else if (
      !currentScan ||
      !baselineScan ||
      !currentScan.get('posture') ||
      !baselineScan.get('posture')
    ) {
      return <Loading />
    }

    return (
      <div className="pt-3 pt-sm-4 px-3 px-sm-5">
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Posture Comparison
          </div>
        </div>
        <div className="row equal">
          <div className="col-lg-8 pl-0">
            <div className="row mx-0" style={{ marginBottom: '1.25rem' }}>
              {this.renderBaselinePosturePanel('col-sm-6')}
              {this.renderPosturePanel('col-sm-6')}
            </div>
          </div>
          <div className="col-lg-4 pr-md-0">
            <PostureComparisonInfo
              user={this.props.user}
              updateView={this.updateView}
              view={this.state.view}
              currentPosture={this.props.currentScan}
              baselinePosture={this.props.baselineScan}
              width={this.props.windowDimensions.width}
            />
          </div>
        </div>
      </div>
    )
  }
}

ClientPostureComparison.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
  }),
  coach: PropTypes.object,
  clientRecords: PropTypes.object,
  postureRecords: PropTypes.object,
  baselineScan: PropTypes.object,
  currentScan: PropTypes.object,
  user: PropTypes.object,
  fetchClientData: PropTypes.func,
  resetClientData: PropTypes.func,
  updateCurrentScan: PropTypes.func,
  updateBaselineScan: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    windowDimensions: state.windowDimensions,
    clientRecords: completeClientRecordsSelector(state),
    postureRecords: clientPostureRecordsSelector(state),
    baselineScan: clientBaselineSelector(state),
    currentScan: clientCurrentSelector(state),
    coach: state.coach,
    user: state.user,
  }
}

const actions = {
  fetchClientData,
  resetClientData,
  updateCurrentScan,
  updateBaselineScan,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPostureComparison)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BSRChart from '../../wellness/wellness_panels/bsr_chart'

// import femaleFrontHeadShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-head-shift-female.csv';
// import maleFrontHeadShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-head-shift-male.csv';
// import femaleFrontShoulderShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-shoulder-shift-female.csv';
// import maleFrontShoulderShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-shoulder-shift-male.csv';
// import femaleFrontUnderbustShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-underbust-shift-female.csv';
// import maleFrontUnderbustShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-underbust-shift-male.csv';
// import femaleFrontKneeShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-knee-shift-female.csv';
// import maleFrontKneeShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-knee-shift-male.csv';
// import femaleFrontHipShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-hip-shift-female.csv';
// import maleFrontHipShift from 'dsv-loader!../../../../../data/posture-front-shift/posture-front-hip-shift-male.csv';
//
// import femaleFrontHeadTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-head-tilt-female.csv';
// import maleFrontHeadTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-head-tilt-male.csv';
// import femaleFrontShoulderTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-shoulder-tilt-female.csv';
// import maleFrontShoulderTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-shoulder-tilt-male.csv';
// import femaleFrontUnderbustTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-underbust-tilt-female.csv';
// import maleFrontUnderbustTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-underbust-tilt-male.csv';
// import femaleFrontKneeTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-knee-tilt-female.csv';
// import maleFrontKneeTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-knee-tilt-male.csv';
// import femaleFrontHipTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-hip-tilt-female.csv';
// import maleFrontHipTilt from 'dsv-loader!../../../../../data/posture-front-tilt/posture-front-hip-tilt-male.csv';
//
// import femaleSideHeadShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-head-shift-female.csv';
// import maleSideHeadShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-head-shift-male.csv';
// import femaleSideShoulderShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-shoulder-shift-female.csv';
// import maleSideShoulderShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-shoulder-shift-male.csv';
// import femaleSideKneeShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-knee-shift-female.csv';
// import maleSideKneeShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-knee-shift-male.csv';
// import femaleSideHipShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-hip-shift-female.csv';
// import maleSideHipShift from 'dsv-loader!../../../../../data/posture-side-shift/posture-side-hip-shift-male.csv';

// const formatValue = (value, units) => {
//   // example value: 0.4 inches left
//   if (!units) {
//     return value;
//   }
//   if (value && value.split(' ').length === 2) {
//     return `${value.split(' ')[0]} ${units === 'US' ? 'inches' : 'cm'} ${value.split(' ')[1]}`;
//   }
//   return `${value} ${units === 'US' ? 'inches' : 'cm'}`; // case for 0
// };

// format the CSV file: convert [{ bmr: Number, count(*): Number }] to [[ Number, Number ]] data structure
// const formatData = (data, metric) => {
//   let formattedData = [];
//   for (var i = 0; i < data.length; i++) {
//     formattedData.push([parseFloat(data[i][metric]), parseFloat(data[i]['count(*)']) ]);
//   }
//   return formattedData;
// };
//
// const dataMapping = {
//   front: {
//     shift: {
//       head: {
//         female: formatData(femaleFrontHeadShift, 'front_head_shift'),
//         male: formatData(maleFrontHeadShift, 'front_head_shift')
//       },
//       shoulder: {
//         female: formatData(femaleFrontShoulderShift, 'front_shoulder_shift'),
//         male: formatData(maleFrontShoulderShift, 'front_shoulder_shift')
//       },
//       underbust: {
//         female: formatData(femaleFrontUnderbustShift, 'front_underbust_shift'),
//         male: formatData(maleFrontUnderbustShift, 'front_underbust_shift')
//       },
//       hip: {
//         female: formatData(femaleFrontHipShift, 'front_hip_shift'),
//         male: formatData(maleFrontHipShift, 'front_hip_shift')
//       },
//       knee: {
//         female: formatData(femaleFrontKneeShift, 'front_knee_shift'),
//         male: formatData(maleFrontKneeShift, 'front_knee_shift')
//       }
//     },
//     tilt: {
//       head: {
//         female: formatData(femaleFrontHeadTilt, 'front_head_tilt'),
//         male: formatData(maleFrontHeadTilt, 'front_head_tilt')
//       },
//       shoulder: {
//         female: formatData(femaleFrontShoulderTilt, 'front_shoulder_tilt'),
//         male: formatData(maleFrontShoulderTilt, 'front_shoulder_tilt')
//       },
//       underbust: {
//         female: formatData(femaleFrontUnderbustTilt, 'front_underbust_tilt'),
//         male: formatData(maleFrontUnderbustTilt, 'front_underbust_tilt')
//       },
//       hip: {
//         female: formatData(femaleFrontHipTilt, 'front_hip_tilt'),
//         male: formatData(maleFrontHipTilt, 'front_hip_tilt')
//       },
//       knee: {
//         female: formatData(femaleFrontKneeTilt, 'front_knee_tilt'),
//         male: formatData(maleFrontKneeTilt, 'front_knee_tilt')
//       }
//     }
//   },
//   side: {
//     shift: {
//       head: {
//         female: formatData(femaleSideHeadShift, 'side_head_shift'),
//         male: formatData(maleSideHeadShift, 'side_head_shift')
//       },
//       shoulder: {
//         female: formatData(femaleSideShoulderShift, 'side_shoulder_shift'),
//         male: formatData(maleSideShoulderShift, 'side_shoulder_shift')
//       },
//       hip: {
//         female: formatData(femaleSideHipShift, 'side_hip_shift'),
//         male: formatData(maleSideHipShift, 'side_hip_shift')
//       },
//       knee: {
//         female: formatData(femaleSideKneeShift, 'side_knee_shift'),
//         male: formatData(maleSideKneeShift, 'side_knee_shift')
//       }
//     }
//   }
// };

const ComparisonComponent = ({ datasets, selected, name, gender }) => {
  if (
    selected &&
    selected.direction &&
    selected.orientation &&
    selected.metricType
  ) {
    const { direction, orientation, metricType } = selected
    const refactoredDirection = direction === 'side' ? 'side' : 'front' // back and front share same data and shift/tilt orientations
    const data = {
      female: datasets.get('histograms').get('female').posture[
        refactoredDirection
      ][orientation][metricType],
      male: datasets.get('histograms').get('male').posture[refactoredDirection][
        orientation
      ][metricType],
    }

    return (
      <div>
        <h5>
          How does your <b style={{ color: '#1686b0' }}>{name}</b> data compare
          against {`${gender.toLowerCase()}s`} @ Fit3D? *
        </h5>
        {/* <div className='mb-5'>
          <h1>{ formatValue(value, units) }</h1>
        </div> */}
        <div>
          <BSRChart
            gender={gender}
            value={selected.value}
            data={data}
            title={name}
          />
        </div>
        <div>
          <div>
            * This data is based on all scans taken using Fit3D body scanners.
          </div>
        </div>
      </div>
    )
  } else if (selected) {
    return (
      <div>
        <h5>
          How does your <b style={{ color: '#1686b0' }}>{name}</b> data compare
          against {`${gender.toLowerCase()}s`} @ Fit3D?
        </h5>
        {/* <div className='mb-5'>
        <h1>{ formatValue(value, units) }</h1>
        </div> */}
        <div>
          <div>
            * This data is based on all scans taken using Fit3D body scanners.
          </div>
          <div>** Comparison graphic for your demographic coming soon!</div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: '400px' }}>
      <h4>Select data to view.</h4>
    </div>
  )
}

ComparisonComponent.propTypes = {
  datasets: PropTypes.object,
  selected: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  gender: PropTypes.string.isRequired,
  units: PropTypes.string,
}

const mapStateToProps = (state) => ({ datasets: state.datasets })

export default connect(mapStateToProps)(ComparisonComponent)

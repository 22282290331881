import { combineReducers } from 'redux'
import facilities from './admin/facilities'
import scanners from './admin/scanners'
import users from './admin/users'
import {
  FETCH_ADMIN_SUMMARY,
  FETCH_ADMIN_SUMMARY_SUCCESS,
  FETCH_ADMIN_SUMMARY_FAILURE,
} from '../actions/admin'
import { FETCH_QC_RECORDS_SUCCESS } from '../actions/admin/qc'
import { FETCH_FACILITIES_SUCCESS } from '../actions/admin/facilities'
import { ADMIN_FETCH_COACHES_SUCCESS } from '../actions/admin/coaches'

const fetchingRecords = (state = false, action) => {
  switch (action.type) {
    case FETCH_ADMIN_SUMMARY:
      return true
    case FETCH_ADMIN_SUMMARY_SUCCESS:
    case FETCH_ADMIN_SUMMARY_FAILURE:
      return false
    default:
      return state
  }
}

const records = (state = [], action) => {
  switch (action.type) {
    case FETCH_ADMIN_SUMMARY_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const qcRecords = (state = [], action) => {
  switch (action.type) {
    case FETCH_QC_RECORDS_SUCCESS:
      return action.payload
    case FETCH_FACILITIES_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const coaches = (state = [], action) => {
  switch (action.type) {
    case ADMIN_FETCH_COACHES_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  fetchingRecords,
  records,
  qcRecords,
  scanners,
  coaches,
  users,
  facilities,
})

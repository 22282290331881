import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default function ConfirmationModal({
  modalOpen,
  emails,
  errorMessage,
  onClose,
  onMerge,
}) {
  return (
    <Modal isOpen={modalOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <h5>Merge Records</h5>
      </ModalHeader>
      <ModalBody>
        <div>
          You are about to take records from{' '}
          <span style={{ color: '#1686b0' }}>{emails.from}</span> to{' '}
          <span style={{ color: '#1686b0' }}>{emails.to}</span>. Click the
          'Confirm Merge Records' button to proceed.
        </div>
        {errorMessage && (
          <div className="text-center text-danger mt-3">{errorMessage}</div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onMerge}>
          Confirm Merge Records
        </button>
      </ModalFooter>
    </Modal>
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  loadScanRecords,
  updateCurrentScan,
  updateBaselineScan,
} from '../../actions/records'
import { clearVideo } from '../../actions/scan/video'

import visibleRecordsSelector from '../../selectors/visible_records'
import currentScanSelector from '../../selectors/current_scan'
import baselineScanSelector from '../../selectors/baseline_scan'

import ScanPanel from '../wellness/scan_panel'
import MeasurementComparisonInfo from '../comparison/measurement_info'
import ComboControl from '../comparison/combo_control'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

import '../comparison/comparison.css'

class Comparison extends Component {
  constructor(props) {
    super(props)

    const gender = props.user.get('user').get('gender')

    const torso = [
      gender === 'Female'
        ? { bustGirth: 'Bust' }
        : { chestAtBladesGirth: 'Chest' },
      { waistSmallBackGirth: 'Waist' },
      { seatGirth: 'Hips' },
    ]

    this.state = {
      bodySegments: {
        torso,
        arms: [
          { bicepsLeftGirth: 'Left Biceps' },
          { bicepsRightGirth: 'Right Biceps' },
          { forearmLeftGirth: 'Left Forearm' },
          { forearmRightGirth: 'Right Forearm' },
        ],
        legs: [
          { thighLeftMaxGirth: 'Left Thigh' },
          { thighRightMaxGirth: 'Right Thigh' },
          { calfLeftGirth: 'Left Calf' },
          { calfRightGirth: 'Right Calf' },
        ],
        torsoAdditional: [
          { waistSmallBackGirth: 'Waist' },
          { hipsAtGirthMaxGirth: 'Max Waist' },
          { waistNaturalGirth: 'Natural Waist' },
          { seatGirth: 'Hips' },
          { thighLeftMaxGirth: 'Left Thigh' },
          { thighRightMaxGirth: 'Right Thigh' },
        ],
      },
    }
  }

  componentDidMount() {
    this.props.loadScanRecords()
  }
  //
  // componentWillReceiveProps(nextProps) {
  //   if (!this.props.user.get('user') && nextProps.user.get('user')) {
  //     const gender = nextProps.user.get('user').get('gender');
  //     const bodySegment = gender === 'Female' ? { 'bustGirth': 'Bust' } : { 'chestAtBladesGirth': 'Chest' };
  //
  //     const bodySegmentsCopy = Object.assign({}, this.state.bodySegments);
  //     bodySegmentsCopy.torso.push(bodySegment);
  //
  //     this.setState({ bodySegments: bodySegmentsCopy });
  //   }
  // }

  componentWillUnmount() {
    this.props.clearVideo()
  }

  renderBaselineScanPanel(className) {
    return (
      <div key="baseline" className={className}>
        <ScanPanel
          hideControl
          type="baseline"
          header="Baseline"
          videoId="v0"
          currentScan={this.props.baselineScan}
          records={this.props.records}
          user={this.props.user}
          customStyle="measurement-scan-panel"
          updateScan={this.props.updateBaselineScan}
          history={this.props.history}
          src={`/v1/records/${this.props.baselineScan.get(
            'scanPackageId'
          )}/scan`}
        />
      </div>
    )
  }

  renderScanPanel(className) {
    return (
      <div key="current" className={className}>
        <ScanPanel
          hideControl
          header="Current"
          videoId="v1"
          currentScan={this.props.currentScan}
          records={this.props.records}
          customStyle="measurement-scan-panel"
          updateScan={this.props.updateCurrentScan}
          user={this.props.user}
          history={this.props.history}
          src={`/v1/records/${this.props.currentScan.get(
            'scanPackageId'
          )}/scan`}
        />
      </div>
    )
  }

  render() {
    const {
      windowDimensions: { width },
      currentScan,
      baselineScan,
    } = this.props
    if (!this.props.finishSettingScans) {
      return <Loading />
    } else if (this.props.records.size < 2) {
      return (
        <Unavailable title="Not enough viewable scans (minimum 2)">
          <div>
            There needs to be at least 2 different viewable scans. Click&nbsp;
            <u onClick={() => this.props.history.push('/history')}>here</u>
            &nbsp;to view your scan history.
          </div>
        </Unavailable>
      )
    }

    return (
      <div>
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Measurement Comparison
          </div>
          {/* <div style={ { color: '#137499', fontSize: '18px' } }>
            Compare your measurements (placeholder text to look better)
          </div> */}
        </div>
        <div className="row equal">
          <div className="col-lg-8 pl-0">
            <div className="row mx-0" style={{ marginBottom: '1.25rem' }}>
              {this.renderBaselineScanPanel('col-sm-6')}
              {this.renderScanPanel('col-sm-6')}
            </div>
            {(!width || width > 768) && (
              <div className="row mb-3 mx-0">
                <div
                  style={{
                    margin: '0 0.6rem',
                    padding: '15px 10px 15px 20px',
                    width: '100%',
                    border: '1px #ddd solid',
                  }}
                >
                  <ComboControl
                    currentScanId={currentScan.get('scanPackageId')}
                    baselineScanId={baselineScan.get('scanPackageId')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4 pr-md-0">
            <div className="measurement-panel">
              <MeasurementComparisonInfo
                bodySegments={this.state.bodySegments}
                records={this.props.records}
                currentScan={this.props.currentScan}
                baselineScan={this.props.baselineScan}
                units={this.props.user.get('user').get('units')}
                width={width}
                user={this.props.user}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Comparison.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
  }).isRequired,
  finishSettingScans: PropTypes.bool.isRequired,
  records: PropTypes.object,
  current: PropTypes.object,
  baselineScan: PropTypes.object,
  currentScan: PropTypes.object,
  loadScanRecords: PropTypes.func,
  updateCurrentScan: PropTypes.func.isRequired,
  updateBaselineScan: PropTypes.func.isRequired,
  clearVideo: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    windowDimensions: state.windowDimensions,
    finishSettingScans: state.current.get('finishSettingScans'),
    records: visibleRecordsSelector(state),
    currentScan: currentScanSelector(state),
    baselineScan: baselineScanSelector(state),
    user: state.user,
  }
}

const actions = {
  loadScanRecords,
  updateCurrentScan,
  updateBaselineScan,
  clearVideo,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Comparison)

import { createSelector } from 'reselect'
import completeClientRecordsSelector from '../complete_client_records'

export default createSelector(
  (state) => completeClientRecordsSelector(state),
  (clientRecords) => {
    if (!clientRecords) {
      return undefined
    }
    return clientRecords.filter((record) => record.get('posture'))
  }
)

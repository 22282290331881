import React from 'react'

import FacilityCreate from './create'

export default function FacilityHeader(props) {
  return (
    <>
      <div
        className="mt-3"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">Fit3D Admin</h6>
          <h3 className="dashhead-title">Facilities</h3>
        </div>
        <FacilityCreate />
      </div>
      <hr className="mt-0 mb-4" />
    </>
  )
}

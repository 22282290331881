import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './report.css'

const MetricPanel = ({ user, currentMetric, title }) => {
  const units = user.get('user').get('units')
  const facility = user.get('user').get('facility')

  let weight = 'lbs'
  let length = 'inches'

  if (units !== 'US') {
    weight = 'kg'
    length = 'cm'
  }

  const metricGroup = {
    'Body Shape': ['bodyShapeRating', 'trunkLegVolRatio'],
    'Body Composition': ['weight', 'bfp', 'fatMass', 'leanMass'],
    Additional: ['wc', 'whr'],
  }

  if (facility && facility.get('bfpAccess') !== 1) {
    metricGroup['Body Composition'] = ['weight', 'height']
  }

  const metricNames = {
    bodyShapeRating: { value: 'Body Shape Rating', units: '' },
    // absi: { value: 'ABSI', units: '' },
    // sbsi: { value: 'SBSI', units: '' },
    trunkLegVolRatio: { value: 'Trunk to Leg Volume Ratio', units: '' },
    bfp: { value: 'Body Fat Percentage', units: '%' },
    fatMass: { value: 'Fat Mass', units: weight },
    leanMass: { value: 'Lean Mass', units: weight },
    weight: { value: 'Weight', units: weight },
    height: { value: 'Height', units: length },
    wc: { value: 'Waist Circumference', units: length },
    whr: { value: 'Waist to Hip Ratio', units: '' },
  }

  return (
    <div
      className="card card-default mb-3"
      style={{ width: '100%', borderRadius: 0 }}
    >
      <div className="text-center">
        <div className="p-3" style={{ fontSize: '20px' }}>
          {title}
        </div>
      </div>
      <div className="card-body py-0">
        <table className="table" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {metricGroup[title].map((metric) => (
              <tr key={_.uniqueId()}>
                <td>{metricNames[metric].value}</td>
                <td style={{ width: '30%' }}>
                  <span isolate>{`${currentMetric.get(metric)}`}</span>
                  <span isolate>{` ${metricNames[metric].units}`}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

MetricPanel.propTypes = {
  currentMetric: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string,
}

export default MetricPanel

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import InterestLevel from '../interest_level'

export default class InterestLevelModal extends Component {
  constructor(props) {
    super(props)

    this.updateInterestLevelSelected = this.updateInterestLevelSelected.bind(
      this
    )
    this.state = { interestLevelSelected: null }
  }

  componentDidMount() {
    const { clientId, recordId, getPARQInterest } = this.props

    getPARQInterest(clientId, recordId).then((response) => {
      if (
        response &&
        response.payload &&
        response.payload.status === 200 &&
        this.state.interestLevelSelected !== response.payload.data.status
      ) {
        this.setState({ interestLevelSelected: response.payload.data.status })
      }
    })
  }

  updateInterestLevelSelected(interestLevel) {
    const { clientId, recordId, updatePARQInterest } = this.props
    return () => {
      updatePARQInterest({
        clientId,
        recordId,
        data: { status: interestLevel },
      })
      this.setState({ interestLevelSelected: interestLevel })
    }
  }

  render() {
    const { isInterestLevelModalOpen, closeInterestLevelModal } = this.props
    return (
      <Modal isOpen={isInterestLevelModalOpen}>
        <ModalHeader closeButton>Interest Level</ModalHeader>
        <ModalBody>
          <div className="text-center mt-3 mb-3">
            <InterestLevel
              interestLevelSelected={this.state.interestLevelSelected}
              updateInterestLevelSelected={this.updateInterestLevelSelected}
            />
          </div>
          <div>
            <h5>Select your client's interest level!</h5>
            <ul>
              <li>
                <span className="text-danger">Red</span> - Not Interested
              </li>
              <li>
                <span className="text-warning">Yellow</span> - Interested Later
              </li>
              <li>
                <span className="text-success"> Green</span> - Interested
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={closeInterestLevelModal}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    )
  }
}

InterestLevelModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  getPARQInterest: PropTypes.func,
  updatePARQInterest: PropTypes.func,
  isInterestLevelModalOpen: PropTypes.bool.isRequired,
  closeInterestLevelModal: PropTypes.func.isRequired,
}

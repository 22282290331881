import { createSelector } from 'reselect'
import visibleRecordsSelector from '../visible_records'
import _ from 'lodash'

const posturesSelector = (state) => state.posture.get('postureData')
const visibleRecords = (state) => visibleRecordsSelector(state)

const getPostureRecords = (postures, records) => {
  if (!postures || records.size === 0) {
    return undefined
  }

  const visibleRecordIds = records.map((record) => record.get('id'))

  return postures.filter((posture) =>
    _.includes(visibleRecordIds.toJS(), posture.get('recordId'))
  )
}

export default createSelector(
  posturesSelector,
  visibleRecords,
  getPostureRecords
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import * as WellnessActions from '../../../actions/wellness'

import currentScanSelector from '../../../selectors/current_scan'

import { ModalHOC } from '../../hocs/with_modal'
import AdditionalForm from './form'

import { parseComma } from '../../../functions/text_format'

class Additional extends Component {
  constructor(props) {
    super(props)
    this.handleAdditionalSubmit = this.handleAdditionalSubmit.bind(this)
  }

  componentDidMount() {
    this.props.fetchFacilityAdditional()
    this.props.fetchAdditionalMetrics(this.props.currentScan.get('id'))
  }

  componentWillUnmount() {
    this.props.clearStatusMessage()
  }

  // TODO: can refactor this
  handleAdditionalSubmit(data) {
    const results = []

    for (const name in data) {
      const selectedMetric = this.props.metrics
        .get('facility')
        .find((metric) => metric.get('label') === name)

      const currentMetric = this.props.currentScan
        .get('wellnessMetrics')
        .get('additional')
        .find((metric) => metric.get('label') === name)

      if (selectedMetric) {
        const result = {
          typeId: selectedMetric.get('typeId'),
          value: parseFloat(parseComma(data[name])),
        }
        // we need to keep the same id as the metrics that already have values
        if (currentMetric) {
          result.id = currentMetric.get('id')
        }
        results.push(result)
      }
    }

    this.props.addAdditionalMetrics(results, this.props.currentScan.get('id'))
  }

  render() {
    const {
      metrics,
      currentScan,
      clearStatusMessage,
      isModalOpen,
      closeModal,
      openModal,
    } = this.props
    // const additionalMetrics = metrics.get('additional');
    const additionalMetrics = currentScan
      .get('wellnessMetrics')
      .get('additional')

    function handleCloseModal() {
      clearStatusMessage()
      closeModal()
    }

    if (!metrics.get('facility') || !additionalMetrics) {
      return <div />
    }

    const handleSubmit = this.handleAdditionalSubmit

    return (
      <div>
        <button
          className="btn btn-outline-primary btn-block"
          onClick={openModal}
        >
          Additional Metrics
        </button>
        <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
          <ModalHeader toggle={handleCloseModal}>
            Additional Metrics
          </ModalHeader>
          <ModalBody>
            <AdditionalForm
              handleAdditionalSubmit={handleSubmit}
              closeModal={handleCloseModal}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

Additional.propTypes = {
  metrics: PropTypes.object,
  currentScan: PropTypes.object.isRequired,
  fetchFacilityAdditional: PropTypes.func.isRequired,
  fetchAdditionalMetrics: PropTypes.func.isRequired,
  addAdditionalMetrics: PropTypes.func.isRequired,
  clearStatusMessage: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  metrics: state.metrics,
  currentScan: currentScanSelector(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(WellnessActions, dispatch)
}

const withHOCs = compose(ModalHOC, connect(mapStateToProps, mapDispatchToProps))

export default withHOCs(Additional)

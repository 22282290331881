// Import all reducers and export a single combined reducer
import * as types from '../actions/types'
import { UPDATE_DIMENSIONS } from '../actions/index'
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './auth'
import navReducer from './nav'
import recordsReducer from './records'
import wellnessReducer from './wellness'
import statusReducer from './status'
import marketplaceReducer from './marketplace'
import notifications from './notifications'
import printReducer from './print'
import metricsReducer from './metrics'
import userReducer from './user'
import postureReducer from './posture'
import adminReducer from './admin'
import adminUserReducer from './admin_users'
import adminPostureReducer from './adminPosture'
import qcReducer from './qc'
import userCoachReducer from './userCoach'
import coachReducer from './coach'
import facilityReducer from './facility'
import searchReducer from './search'
import newSearch from './newSearch'
import datasetsReducer from './datasets'
import videoReducer from './scan/video'
import pagination from './pagination'
import newAdmin from './newAdmin'
// import loadingTypeformReducer from './typeform';
import Immutable from 'immutable'

const defaultState = new Immutable.Map({
  scanRecords: new Immutable.Map(),
  scans: new Immutable.Map(),
  scanMeasurements: new Immutable.Map(),
})
// Updates an entity cache in response to any action with response.entities.
// export function entities(state = { scanRecords: {}, scans: {} }, action) {
export function entities(state = defaultState, action) {
  if (action && action.response && action.response.entities) {
    return state.mergeDeep(action.response.entities)
  }

  return state
}

const defaultResultState = new Immutable.Map({
  scanRecords: new Immutable.List(),
  scans: new Immutable.List(),
  scanMeasurements: new Immutable.List(),
})
// Updates an entity result in response to any action with response.entities.
// Keeps result order from server intact to use with normalized result
export function result(state = defaultResultState, action) {
  if (
    action &&
    action.response &&
    action.response.result &&
    action.response.entities
  ) {
    state = state.mergeDeep({ scanRecords: action.response.result })

    // Hack until get optimized version for ordered children in normalized result
    var orderedScans = state
      .get('scanRecords')
      .map((id) => action.response.entities.scanRecords[id].scan)
      .filter((n) => !!n)
    state = state.mergeDeep({ scans: orderedScans })

    var orderedScanMeasurement = orderedScans
      .map((id) => action.response.entities.scans[id].scanMeasurement)
      .filter((n) => !!n)
    state = state.mergeDeep({ scanMeasurements: orderedScanMeasurement })
  }
  return state
}

const defaultCurrentScanState = new Immutable.Map({
  finishSettingScans: false,
})

export const current = (state = defaultCurrentScanState, action) => {
  switch (action.type) {
    // Crucial for indicating whether or not we attempted to find baseline/current scans
    // Allows us to clean up loading logic for components such as comparison
    // Always occurs at the end of the "loadScanRecords chain"
    case types.FINISH_SCAN_UPDATES:
      return state.set('finishSettingScans', true)
    // This is used so that we can set current posture/current baseline separately
    // Also for loading logic in posture comparison
    // Hacky, should decouple posture from records
    case types.RESET_SCAN_UPDATES:
      return state.set('finishSettingScans', false)
    case types.UPDATE_CURRENT_SCAN:
      return state.set('currentScan', Immutable.fromJS(action.payload))
    case types.UPDATE_BASELINE_SCAN:
      return state.set('baselineScan', Immutable.fromJS(action.payload))
    case types.UPDATE_CURRENT_POSTURE:
      return state.set('currentPosture', Immutable.fromJS(action.payload))
    case types.UPDATE_BASELINE_POSTURE:
      return state.set('baselinePosture', Immutable.fromJS(action.payload))
    case types.UPDATE_SCAN_RECORD:
      return state.set('selectedScanHistory', action.payload.data)
    default:
      return state
  }
}

// Updates error message to notify about the failed fetches.
// function errorMessage(state = null, action) {
//   const { type, error } = action;
//
//   if (type === ActionTypes.RESET_ERROR_MESSAGE) {
//     return null;
//   } else if (error) {
//     return action.error;
//   }
//
//   return state;
// }

export const windowDimensions = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DIMENSIONS:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  isAuthenticated: authReducer,
  entities,
  result,
  current,
  records: recordsReducer,
  wellness: wellnessReducer,
  status: statusReducer,
  marketplace: marketplaceReducer,
  print: printReducer,
  metrics: metricsReducer,
  user: userReducer,
  posture: postureReducer,
  form: formReducer,
  admin: adminReducer,
  adminPosture: adminPostureReducer,
  adminUsers: adminUserReducer,
  qc: qcReducer,
  userCoaches: userCoachReducer,
  coach: coachReducer,
  facility: facilityReducer,
  search: searchReducer,
  newSearch,
  pagination,
  datasets: datasetsReducer,
  routing: routerReducer,
  windowDimensions,
  nav: navReducer,
  notifications,
  video: videoReducer,
  newAdmin,
  // loadingTypeform: loadingTypeformReducer
})

// const rootReducer = (state, action) => {
//   if (action.type === types.RESET_STATE) {
//     state = undefined;
//   }
//
//   return appReducer(state, action);
// };

// export default rootReducer;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchAdminReferrals,
  fetchAdminRecords,
  updateScanValid,
} from '../../../actions/admin/index'
import { validateRecord } from '../../../actions/admin/validation'

import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'
import Pagination from '../../griddle/pagination'
import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'

import Image from '../image'
import ManualValidation from './manual_validation'
import AutoValidation from './auto_validation'
// import Metadata from './metadata';
import Stats from '../MeasurementStats'
// import Actions from '../actions';
import Loading from '../../utilities/loading'

import { formatDateTime } from '../../../functions/text_format'

import '../admin.css'

class AdminValidation extends Component {
  constructor(props) {
    super(props)

    this.setPageSize = this.setPageSize.bind(this)
    this.setPage = this.setPage.bind(this)
    this.changeRecordType = this.changeRecordType.bind(this)

    this.state = {
      currentPage: 0,
      pageSize: 5,
      maxPages: 1,
      results: [],
      currentRecordType: 'referrals',
    }
  }

  componentDidMount() {
    const { currentPage, pageSize } = this.state
    this.props.fetchAdminReferrals(currentPage, pageSize)
  }

  componentWillReceiveProps(nextProps) {
    const scanRecords = nextProps.admin.get('scanRecords')
    if (scanRecords) {
      const results = scanRecords.map(this.parseData.bind(this)).toJS()
      const pageInfo = nextProps.admin.get('pageInfo')

      let maxPages
      if (!pageInfo) {
        maxPages = 1
      } else if (pageInfo && pageInfo.get('last')) {
        maxPages = parseInt(pageInfo.get('last').get('page'), 10)
      }

      this.setState({
        results,
        maxPages: maxPages || this.state.maxPages, // Link header will not return a rel=last object on last page, so we must account for that case
      })
    }
  }

  // handleValidation(data) {
  //   this.props.updateScanValid(data);
  // }

  fetchData(page, size) {
    if (this.state.currentRecordType === 'referrals') {
      this.props.fetchAdminReferrals(page, size)
    } else {
      this.props.fetchAdminRecords(page, size)
    }
  }

  getExternalData(page) {
    const newPage = page - 1 || 0
    this.fetchData(newPage, this.state.pageSize)
  }

  setPage(index) {
    const newIndex =
      index > this.state.maxPages
        ? this.state.maxPages
        : index < 1
        ? 1
        : index + 1
    this.getExternalData(newIndex)
    this.setState({ currentPage: newIndex - 1 })
  }

  setPageSize(size) {
    this.fetchData(0, size)
    this.setState({
      pageSize: parseInt(size, 10),
      currentPage: 0,
    })
  }

  changeRecordType(event) {
    if (
      event.target.value === 'referrals' &&
      this.state.currentRecordType !== 'referrals'
    ) {
      this.setState({
        currentRecordType: 'referrals',
        currentPage: 0,
        pageSize: 10,
      })
      this.props.fetchAdminReferrals(0, 10)
    } else if (
      event.target.value === 'records' &&
      this.state.currentRecordType !== 'records'
    ) {
      this.setState({
        currentRecordType: 'records',
        currentPage: 0,
        pageSize: 10,
      })
      this.props.fetchAdminRecords(0, 10)
    }
  }

  renderRecordTypeSelector() {
    const handleChange = this.changeRecordType
    return (
      <select
        form="recordType"
        className="custom-select"
        value={this.state.currentRecordType}
        onChange={handleChange}
      >
        <option key="referrals" value="referrals">
          Referrals
        </option>
        <option key="records" value="records">
          Records
        </option>
      </select>
    )
  }

  parseData(record) {
    return {
      0: 0,
      160: 160,
      manualValidation: record.get('scanValidate'),
      autoValidation: record.get('scanValidate'),
      metadata: {
        recordDate: formatDateTime(record.get('recordDate')),
        status: record.get('status'),
        scanValid: record.get('scanValid'),
        recordId: record.get('id'),
        scanPackageId: record.get('scanPackageId'),
      },
      stats: {
        wellness: record.get('wellnessMetrics'),
        measurements: record.get('scanMeasurement'),
      },
      // scanValid: record.gt('scanValid')
    }
  }

  render() {
    const { currentPage, pageSize, maxPages, results } = this.state
    if (
      !this.props.admin.get('scanRecords') ||
      this.props.admin.get('loading')
    ) {
      return <Loading />
    }

    const pageProperties = { pageSize, maxPages }

    const components = {
      Pagination: () => (
        <Pagination
          setPageSize={this.setPageSize}
          setPage={this.setPage}
          currentPage={currentPage + 1}
          pageSize={pageSize}
        />
      ),
      SettingsToggle: () => <span />,
    }

    const styleConfig = {
      classNames: {
        Filter: 'griddle-filter form-control mb-3',
        Table: 'table table-bordered',
      },
    }

    const metadataKeyNameList = [
      { key: 'recordDate', name: 'Date' },
      { key: 'status', name: 'Status' },
      { key: 'scanValid', name: 'Valid' },
      { key: 'recordId', name: 'Record Id' },
      { key: 'scanPackageId', name: 'Scan PackageId' },
    ]

    return (
      <div className="admin-container">
        <div style={{ marginBotton: '25px', borderBottom: '1px #eee solid' }}>
          <h6 className="dashhead-subtitle">Fit3D Admin</h6>
          <div className="d-flex" style={{ width: '100%' }}>
            <h3 className="dashhead-title">Scan Validation</h3>
            <div
              className="d-flex"
              style={{ marginLeft: 'auto', marginBottom: '10px' }}
            >
              <div style={{ marginRight: '10px' }}>
                Select Record Type:&nbsp; {this.renderRecordTypeSelector()}
              </div>
            </div>
          </div>
        </div>

        <Griddle
          data={results}
          pageProperties={pageProperties}
          plugins={[plugins.LocalPlugin]}
          components={components}
          styleConfig={styleConfig}
        >
          <RowDefinition>
            <ColumnDefinition
              id="0"
              title="0"
              degree={0}
              type="blank"
              customComponent={enhancedWithRowData(Image)}
            />
            <ColumnDefinition
              id="160"
              title="160"
              degree={160}
              type="blank"
              customComponent={enhancedWithRowData(Image)}
            />
            <ColumnDefinition
              id="manualValidation"
              title="Manual Validation"
              validateRecord={this.props.validateRecord}
              customComponent={enhancedWithRowData(ManualValidation)}
            />
            <ColumnDefinition
              id="autoValidation"
              title="Auto Validation"
              customComponent={enhancedWithRowData(AutoValidation)}
            />
            {/* <ColumnDefinition id='metadata' title='Metadata' metadataKeyNameList={ metadataKeyNameList } customComponent={ enhancedWithRowData(Metadata) } /> */}
            <ColumnDefinition
              id="stats"
              title="Stats"
              metadataKeyNameList={metadataKeyNameList}
              customComponent={enhancedWithRowData(Stats)}
              showMetadata
            />
            {/* <ColumnDefinition id='scanValid' title='Scan Valid' handleValidation={ ::this.handleValidation } customComponent={ enhancedWithRowData(Actions) } /> */}
          </RowDefinition>
        </Griddle>
      </div>
    )
  }
}

AdminValidation.propTypes = {
  fetchAdminReferrals: PropTypes.func,
  fetchAdminRecords: PropTypes.func,
  validateRecord: PropTypes.func.isRequired,
  updateScanValid: PropTypes.func,
  admin: PropTypes.object,
}

const mapStateToProps = (state) => ({ admin: state.admin })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchAdminReferrals, fetchAdminRecords, validateRecord, updateScanValid },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AdminValidation)

import axios from 'axios'
import * as types from './types'
import { getQueryString } from '../admin/utils/functions'

import action from '../middleware'

export const fetchQcRecords = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/qc?${getQueryString(params)}`,
      method: 'GET',
      actionTypes: {
        requestType: types.QC_FETCH_RECORDS,
        successType: types.QC_FETCH_RECORDS_SUCCESS,
        failureType: types.QC_FETCH_RECORDS_FAILURE,
      },
    },
    dispatch
  )
//
// export function fetchQcRecordsRequest() {
//   return {
//     type: QC_FETCH_RECORDS,
//     payload: 'fetching'
//   };
// }
//
// export function fetchQcRecords(params) {
//   const { page, size } = params;
//   return dispatch => {
//     dispatch(fetchQcRecordsRequest());
//     return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/admin/records/qc?page=${page}&size=${size}`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//       .then(response => {
//         dispatch({
//           type: QC_FETCH_RECORDS_SUCCESS,
//           payload: response
//         });
//       }).catch(error => {
//         dispatch({
//           type: QC_FETCH_RECORDS_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

export function updateScanValid(params) {
  var response = axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${params.id}/validate`,
    data: params,
  })

  return {
    type: types.QC_SCAN_VALID,
    payload: response,
  }
}

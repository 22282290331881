import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateCurrentScan, updateBaselineScan } from '../../actions/records'

import NoTranslate from '../hocs/NoTranslate'
import ScanDate from '../scans/scan_dates'
import ScanVideo from '../scans/scan_video'
import ScanControl from '../scans/scan_control'

import './shop_modal.css'

// TODO: ADD SRC to SNAP IMAGE
const ShopModal = (props) => {
  const handleClose = props.closeModal
  return (
    <div className="d-flex justify-content-center align-items-center marketplace-modal py-3 px-5">
      <div className="row">
        <div className="col-sm-6 text-center mt-4">
          <div style={{ width: '250px' }}>
            <div>
              <ScanVideo
                videoId="v1"
                scanPackageId={props.currentScan.get('scanPackageId')}
                src={`/v1/records/${props.currentScan.get(
                  'scanPackageId'
                )}/scan`}
              />
              <ScanControl />
            </div>
          </div>
        </div>
        <div className="col-sm-6 text-center marketplace-modal-info">
          <div className="text-center">
            <h4 className="text-uppercase mb-3 marketplace-modal-title">
              Shop at <NoTranslate>{props.name}</NoTranslate>
            </h4>
            <div className="mb-3 text-left">
              <p className="mb-3">
                We'll pass your measurements and/or scan to this vendor for a
                potential better fit. For more information about what is being
                passed on, view our{' '}
                <a href="https://support.fit3d.com/hc/en-us/articles/360009592034">
                  Marketplace FAQs.
                </a>
              </p>
              <p className="mb-3">
                The partner site may not auto fill your information, but the
                data IS being passed along to the partner.
              </p>
              <div className="mb-3 text-center">
                <h5 style={{ marginBottom: '7px', fontWeight: '400' }}>
                  Pick a scan:
                </h5>
                <ScanDate
                  report
                  records={props.records}
                  scan={props.currentScan}
                  updateScan={props.updateCurrentScan}
                />
              </div>
              {props.currentOpened === 'stantt' && props.values && (
                <p className="text-center">
                  <b>
                    Chest: {props.values.chest} | Waist: {props.values.waist} |
                    Sleeve: {props.values.sleeve}
                  </b>
                </p>
              )}
              <p>
                Click the "continue" button to shop at {props.name}'s site. This
                will open a new tab.
              </p>
            </div>
            <div>
              <form
                className="ml-3-0"
                action={props.urls.get(String(props.currentScan.get('id')))}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.getQueryHTMLValues(props.values)}
                <div>
                  <button
                    type="submit"
                    className="btn btn-square text-uppercase btn-block mx-auto marketplace-button"
                    onClick={() => {
                      window.ga(
                        'send',
                        'event',
                        'Button',
                        'click',
                        `User clicked on submit button for ${props.name}`
                      )
                      props.fetchReferralURL()
                    }}
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="btn btn-square text-uppercase btn-block button-close"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShopModal.propTypes = {
  scanRecords: PropTypes.object,
  records: PropTypes.object,
  gender: PropTypes.string.isRequired,
  currentScan: PropTypes.object,
  updateCurrentScan: PropTypes.func.isRequired,
  urls: PropTypes.object,
  values: PropTypes.object,
  currentOpened: PropTypes.string,
  name: PropTypes.string,
  chest: PropTypes.string,
  waist: PropTypes.string,
  sleeve: PropTypes.string,
  fetchReferralURL: PropTypes.func,
  getQueryHTMLValues: PropTypes.func,
  closeModal: PropTypes.func,
}

const actions = {
  updateCurrentScan,
  updateBaselineScan,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(null, mapDispatchToProps)(ShopModal)

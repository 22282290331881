import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as PARQActions from '../../../actions/coach/parq'
import { clearMessage } from '../../../actions/coach/index'

import Survey from './survey/survey'
import SurveyCompleteModal from './survey_complete/survey_complete_modal'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

import { capitalize } from '../../../functions/text_format'

class PARQSurvey extends Component {
  constructor(props) {
    super(props)

    this.redirectPage = this.redirectPage.bind(this)
    this.handlePARQSubmit = this.handlePARQSubmit.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.state = { isModalOpen: false }
  }

  componentDidMount() {
    const { clientId, recordId } = this.props.match.params
    this.props.fetchPARQData({ clientId, recordId })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coach.get('message') === 'success') {
      this.openModal()
    }
  }

  componentWillUnmount() {
    this.props.clearMessage()
  }

  redirectPage(route) {
    return () => {
      this.props.history.push(route)
    }
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
    this.props.clearMessage()
  }

  handlePARQSubmit(data) {
    const { clientId, recordId } = this.props.match.params

    this.props.updatePARQData({
      recordId,
      clientId,
      content: data,
    })
  }

  render() {
    const {
      match: {
        params: { clientId, recordId },
      },
    } = this.props
    const parqData = this.props.coach.get('parqData')

    if (this.props.coach.get('loading')) {
      return <Loading />
    }

    if (this.props.coach.get('pageError') || parqData.size === 0) {
      return (
        <Unavailable title="Client PARQ Survey is unavailable">
          <div>
            Take some scans to view your scan history. Click{' '}
            <u onClick={this.redirectPage('/staff/clients/records')}>here</u> to
            return to all clients' records page.
          </div>
        </Unavailable>
      )
    }

    const handleSubmit = this.handlePARQSubmit

    return (
      <div id="client-survey" className="container mb-5 pb-5">
        <div className="mt-5">
          <Survey
            initialValues={this.props.initialValues}
            redirectPage={this.redirectPage('/staff/clients/records')}
            handlePARQSubmit={handleSubmit}
            firstName={capitalize(parqData.get('firstName'))}
            lastName={capitalize(parqData.get('lastName'))}
          />
        </div>
        {this.props.coach.get('message') === 'success' ? (
          <SurveyCompleteModal
            parqData={this.props.coach.get('parqData')}
            updatePARQInterest={this.props.updatePARQInterest}
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            redirectPage={this.redirectPage}
            clientId={clientId}
            recordId={recordId}
          />
        ) : null}
      </div>
    )
  }
}

PARQSurvey.propTypes = {
  coach: PropTypes.object,
  initialValues: PropTypes.object,
  fetchPARQData: PropTypes.func,
  updatePARQData: PropTypes.func,
  updatePARQInterest: PropTypes.func,
  clearMessage: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      recordId: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

const actions = {
  ...PARQActions,
  clearMessage,
}

const mapStateToProps = (state) => ({
  coach: state.coach,
  initialValues:
    state.coach.get('parqData') && state.coach.get('parqData').get('content')
      ? state.coach.get('parqData').get('content').toJS()
      : null,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PARQSurvey)

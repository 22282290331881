import { createSelector } from 'reselect'
import _ from 'lodash'
import Immutable from 'immutable'

const recordSelector = (state) => state.entities.get('scanRecords')
const scanSelector = (state) => state.result.get('scans')

const getCompletedScanRecords = (records, scans) => {
  const selectedScans = scans.toJS().map((value) => {
    return _.find(records.toJS(), (record) => record.scan === value)
  })
  return Immutable.fromJS(selectedScans)
}

export default createSelector(
  recordSelector,
  scanSelector,
  getCompletedScanRecords
)

import { combineReducers } from 'redux'
import {
  ADMIN_FETCH_USERS_SUCCESS,
  REMOVE_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAILURE,
  UPDATE_USER_FACILITY_SUCCESS,
} from '../../actions/admin/users'

const list = (state = [], action) => {
  switch (action.type) {
    case ADMIN_FETCH_USERS_SUCCESS:
      return action.payload
    case UPDATE_ROLE_SUCCESS:
    case REMOVE_ROLE_SUCCESS:
    case UPDATE_USER_EMAIL_SUCCESS:
    case UPDATE_USER_FACILITY_SUCCESS:
      return state.map((user) => {
        if (user.userId === action.payload.userId) {
          return action.payload
        }
        return user
      })
    default:
      return state
  }
}

const errorMessage = (state = '', action) => {
  switch (action.type) {
    case UPDATE_USER_EMAIL:
      return ''
    case UPDATE_USER_EMAIL_FAILURE:
      return action.error
    default:
      return state
  }
}

const emailEditOpen = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_EMAIL_EDIT':
      return true
    case 'CLOSE_EMAIL_EDIT':
    case UPDATE_USER_EMAIL_SUCCESS:
      return false
    default:
      return state
  }
}

const initialEmailEditState = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
}

const emailEditForm = (state = initialEmailEditState, action) => {
  switch (action.type) {
    case 'EDIT_ADMIN_USER_EMAIL':
      return {
        ...state,
        ...action.payload,
      }
    case 'CLOSE_EMAIL_EDIT':
      return initialEmailEditState
    default:
      return state
  }
}

const facilityEditOpen = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_FACILITY_EDIT':
      return true
    case 'CLOSE_FACILITY_EDIT':
    case UPDATE_USER_FACILITY_SUCCESS:
      return false
    default:
      return state
  }
}

const initialFacilityEditState = {
  userId: '',
  firstName: '',
  lastName: '',
  currentFacility: '',
  selectedId: '',
}

const facilityEditForm = (state = initialFacilityEditState, action) => {
  switch (action.type) {
    case 'EDIT_ADMIN_USER_FACILITY':
      return {
        ...state,
        ...action.payload,
      }
    case 'CLOSE_FACILITY_EDIT':
      return initialFacilityEditState
    default:
      return state
  }
}

export default combineReducers({
  list,
  errorMessage,
  emailEditOpen,
  emailEditForm,
  facilityEditOpen,
  facilityEditForm,
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateSelectedMetric } from '../../actions/wellness'

import SubNavContainer from '../../components/navbar/sub_nav_container'
import NavItem from '../../components/navbar/nav_item'
import SubNav from '../../components/navbar/sub_nav'

// images: 'report-images-grid',
// wellnessGrid: 'report-wellness-grid',
// postureGrid: 'report-posture-grid',
// balanceGrid: 'report-balance-grid',
// measurementGrid: 'report-measurements-grid'

// NOTE: store elemtn id in reducer here, so we can handle scrolling on wellness report page instead of here
class FitnessNav extends Component {
  constructor(props) {
    super(props)
    this.clickHandler = this.clickHandler.bind(this)
  }

  // displayGrid is a bool to show if the grid is even available. don't want to re-render wellness component if the component doesn't even exist
  clickHandler(id, displayGrid) {
    if (this.props.pathname !== '/wellness') {
      this.props.updateSelectedMetric(id)
      this.props.redirectPage('/wellness')()
    } else if (this.props.pathname === '/wellness' && displayGrid) {
      this.props.updateSelectedMetric(id)
    }
  }

  render() {
    const selectedMetric = this.props.wellness.get('selectedMetric')
    const report = this.props.wellness.get('report')

    const resultsSubNav = [
      {
        text: 'Scan',
        clickHandler: () => this.clickHandler('report-images-grid', !!report),
        isSelected: selectedMetric === 'report-images-grid' && !!report,
      },
      {
        text: 'Wellness',
        clickHandler: () =>
          this.clickHandler(
            'report-wellness-grid',
            !!(report && report.get('wellnessMetrics'))
          ),
        isSelected:
          selectedMetric === 'report-wellness-grid' &&
          !!(report && report.get('wellnessMetrics')),
      },
      {
        text: 'Posture',
        clickHandler: () =>
          this.clickHandler(
            'report-posture-grid',
            !!(report && report.get('posture'))
          ),
        isSelected:
          selectedMetric === 'report-posture-grid' &&
          !!(report && report.get('posture')),
      },
      {
        text: 'Balance',
        clickHandler: () =>
          this.clickHandler(
            'report-balance-grid',
            !!(report && report.get('balance'))
          ),
        isSelected:
          selectedMetric === 'report-balance-grid' &&
          !!(report && report.get('balance')),
      },
      {
        text: 'Measurements',
        clickHandler: () =>
          this.clickHandler(
            'report-measurements-grid',
            !!(report && report.get('measurements'))
          ),
        isSelected:
          selectedMetric === 'report-measurements-grid' &&
          !!(report && report.get('measurements')),
      },
      {
        text: 'Printable',
        clickHandler: this.props.redirectPage('/report'),
        isSelected: this.props.pathname === '/report',
      },
    ]

    const comparisonSubNav = [
      {
        text: 'Wellness',
        clickHandler: this.props.redirectPage('/comparison/wellness'),
        isSelected: this.props.pathname === '/comparison/wellness',
      },
      {
        text: 'Measurements',
        clickHandler: this.props.redirectPage('/comparison/measurements'),
        isSelected: this.props.pathname === '/comparison/measurements',
      },
      {
        text: 'Posture',
        clickHandler: this.props.redirectPage('/comparison/posture'),
        isSelected: this.props.pathname === '/comparison/posture',
      },
      {
        text: 'Printable',
        clickHandler: this.props.redirectPage('/report/comparison'),
        isSelected: this.props.pathname === '/report/comparison',
      },
    ]

    const isResultPageSelected =
      this.props.selectedSubPage === 'fitnessResults' ||
      this.props.pathname === '/wellness' ||
      this.props.pathname === '/report'
    const isComparisonSelected =
      this.props.selectedSubPage === 'fitnessComparison' ||
      this.props.pathname.indexOf('/comparison') !== -1
    const isGlossarySelected = this.props.pathname === '/glossary'

    return (
      <SubNavContainer header="Fitness">
        <ul
          className="nav-pills flex-md-column list-unstyled"
          style={{ padding: '15px 0px' }}
        >
          <NavItem
            isSelected={isResultPageSelected}
            text="Results"
            clickHandler={this.props.redirectPage('/wellness')}
          >
            <SubNav subNavProps={resultsSubNav} />
          </NavItem>
          <NavItem
            isSelected={isComparisonSelected}
            text="Comparison"
            clickHandler={this.props.redirectPage('/comparison/wellness')}
          >
            <SubNav subNavProps={comparisonSubNav} />
          </NavItem>
          <NavItem
            isSelected={isGlossarySelected}
            text="Glossary"
            clickHandler={this.props.redirectPage('/glossary')}
          />
        </ul>
      </SubNavContainer>
    )
  }
}

FitnessNav.propTypes = {
  wellness: ImmutablePropTypes.contains({
    selectedMetric: PropTypes.string,
    report: ImmutablePropTypes.map,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  selectedSubPage: PropTypes.string,
  updateSelectedMetric: PropTypes.func.isRequired,
  redirectPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  wellness: state.wellness,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateSelectedMetric }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FitnessNav)

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { updateUserEmail } from '../../../../actions/admin/users'

import { capitalize } from '../../../../lib/formatText'

const EmailEditModal = ({
  users: { emailEditForm, emailEditOpen, errorMessage },
  updateUserEmail,
  onUpdate,
  onClose,
}) => {
  const { userId, firstName, lastName, email } = emailEditForm
  const [isUpdating, setIsUpdating] = React.useState(false)

  React.useEffect(() => {
    if (errorMessage || !emailEditOpen) {
      setIsUpdating(false)
    }
  }, [errorMessage, emailEditOpen])

  const handleUpdate = (e) => {
    onUpdate({ email: e.target.value })
  }

  const handleSubmit = () => {
    setIsUpdating(true)
    updateUserEmail({
      userId,
      data: { email },
    })
  }

  return (
    <Modal isOpen={emailEditOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {`Change Email for ${capitalize(firstName)} ${capitalize(lastName)}`}
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          Current email: <strong>{email}</strong>
        </div>
        <div className="input-group">
          <input
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={handleUpdate}
          />
          <span className="input-group-btn" onClick={handleSubmit}>
            <button className="btn btn-primary" disabled={isUpdating}>
              {isUpdating ? 'Updating' : 'Update'}
            </button>
          </span>
        </div>
        {errorMessage && (
          <div className="text-center text-danger m-y">{errorMessage}</div>
        )}
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  users: state.newAdmin.users,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserEmail: (params) => dispatch(updateUserEmail(params)),
  onUpdate: (email) =>
    dispatch({ type: 'EDIT_ADMIN_USER_EMAIL', payload: email }),
  onClose: () => dispatch({ type: 'CLOSE_EMAIL_EDIT' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailEditModal)

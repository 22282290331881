import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as VideoActions from '../../actions/scan/video'
// import { mapActions } from 'utils';
// import { status } from '../../../../actions';

import '../scans/scan_control.css'

class ComboControl extends Component {
  constructor(props) {
    super(props)

    this.handleAnimation = this.handleAnimation.bind(this)
    this.changeRangeValue = this.changeRangeValue.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.currentScanId
    const nextCurrentId = nextProps.currentScanId
    const baselineId = this.props.baselineScanId
    const nextBaselineId = nextProps.baselineScanId

    if (currentId !== nextCurrentId || baselineId !== nextBaselineId) {
      this.props.toggleAnimation('off')
      this.props.video
        .get('videoRefs')
        .valueSeq()
        .forEach((video) => {
          video.pause()
          this.props.updateCurrentTime(video.currentTime)
        })
    }
  }

  changeRangeValue(event) {
    const firstKey = this.props.video.get('videoRefs').keySeq().first()
    const time =
      this.props.video.get('videoRefs').get(firstKey).duration *
      (event.target.value / 100)

    this.props.video
      .get('videoRefs')
      .keySeq()
      .forEach((key) => {
        if (this.props.video.get('videoRefs').get(key)) {
          this.props.video.get('videoRefs').get(key).currentTime = time
        }
      })
    this.props.updateCurrentTime(time)

    const value = Math.ceil(
      (100 / this.props.video.get('videoRefs').get(firstKey).duration) *
        this.props.video.get('videoRefs').get(firstKey).currentTime
    ).toString()
    this.props.updateRangeValue(value)
  }

  handleAnimation() {
    const currentAnimationStatus = this.props.video.get('animation')

    this.props.video
      .get('videoRefs')
      .valueSeq()
      .forEach((video) => {
        if (currentAnimationStatus === 'off') {
          video.play()
        } else {
          const time = video.currentTime
          video.pause()
          this.props.updateCurrentTime(time)
        }
      })

    const animationStatus = currentAnimationStatus === 'off' ? 'on' : 'off'
    this.props.toggleAnimation(animationStatus)
  }

  render() {
    const { video } = this.props
    if (video.get('videoRefs').size === 0) {
      return <div />
    }
    const handleChange = this.changeRangeValue
    const handleVideoPlay = this.handleAnimation
    return (
      <div className="d-flex justify-content-center" style={{ width: '100%' }}>
        <div className="control-button" onClick={handleVideoPlay}>
          {video.get('animation') === 'off' ? (
            <span
              className="icon icon-controller-play"
              style={{ fontSize: '17px' }}
            />
          ) : (
            <span
              className="icon icon-controller-paus"
              style={{ fontSize: '17px' }}
            />
          )}
        </div>
        <div className="slider">
          <input
            className="sliderRange"
            type="range"
            value={this.props.video.get('rangeValue')}
            onChange={handleChange}
            min="0"
            max="100"
          />
        </div>
      </div>
    )
  }
}

ComboControl.propTypes = {
  video: ImmutablePropTypes.mapContains({
    animation: PropTypes.string.isRequired,
    rangeValue: PropTypes.string.isRequired,
    videoRefs: ImmutablePropTypes.map,
  }).isRequired,
  toggleAnimation: PropTypes.func.isRequired,
  updateCurrentTime: PropTypes.func.isRequired,
  updateRangeValue: PropTypes.func.isRequired,
  currentScanId: PropTypes.string,
  baselineScanId: PropTypes.string,
  style: PropTypes.object,
}

const mapStateToProps = (state) => ({
  video: state.video,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(VideoActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ComboControl)

import Immutable from 'immutable'
import parse from 'parse-link-header'

import * as types from '../../actions/coach/types'

const reducers = {
  // Fetch all clients
  [types.FETCH_CLIENTS]: (state) => state.set('loading', true),
  [types.FETCH_CLIENTS_SUCCESS]: (state, action) => {
    return state
      .set('clients', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [types.FETCH_CLIENTS_FAILURE]: (state) => state.set('loading', false),

  [types.FETCH_CLIENT_DATA]: (state) => state.set('loading', true),
  [types.FETCH_CLIENT_DATA_SUCCESS]: (state, action) => {
    return state
      .set('client', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [types.FETCH_CLIENT_DATA_FAILURE]: (state) => state.set('loading', false),

  // Updating client's measurements
  [types.UPDATE_CLIENT_MEASUREMENTS]: (state) =>
    state.set('measurementLoading', true),
  [types.UPDATE_CLIENT_MEASUREMENTS_SUCCESS]: (state, action) => {
    const index = state
      .get('client')
      .findIndex((item) => item.get('id') === action.meta.recordId)
    const updatedList = state.get('client').update(index, (item) => {
      return item.set('wellnessMetrics', Immutable.fromJS(action.payload.data))
    })

    return state
      .set('client', updatedList)
      .set('measurementLoading', false)
      .set('message', 'success')
  },
  [types.UPDATE_CLIENT_MEASUREMENTS_FAILURE]: (state) =>
    state.set('measurementLoading', false).set('message', 'failure'),

  [types.UPDATE_CLIENT_ADDITIONAL]: (state) =>
    state.set('metricsLoading', true),
  [types.UPDATE_CLIENT_ADDITIONAL_SUCCESS]: (state, action) => {
    const index = state
      .get('client')
      .findIndex((item) => item.get('id') === action.meta.recordId)
    const updatedList = state.get('client').update(index, (item) => {
      return item.setIn(
        ['wellnessMetrics', 'additional'],
        Immutable.fromJS(action.payload.data)
      )
    })

    return state
      .set('client', updatedList)
      .set('metricsLoading', false)
      .set('message', 'success')
  },
  [types.UPDATE_CLIENT_ADDITIONAL_FAILURE]: (state) =>
    state.set('metricsLoading', false).set('message', 'failure'),

  // Manage client permissions
  [types.MANAGE_CLIENT]: (state) => state.set('message', 'fetching'),
  [types.MANAGE_CLIENT_SUCCESS]: (state, action) => {
    const { userId, accessRequestDate } = action.payload.data
    const newClientRecords = []

    state.get('clientRecords').forEach((clientRecord) => {
      let newClientRecord = clientRecord

      if (userId === clientRecord.get('userId')) {
        newClientRecord = clientRecord.set(
          'accessRequestDate',
          accessRequestDate
        )
      }

      newClientRecords.push(newClientRecord)
    })

    return state
      .set('clientRecords', Immutable.fromJS(newClientRecords))
      .set('message', null)
  },
  [types.MANAGE_CLIENT_FAILURE]: (state) => state.set('message', 'failure'),

  // Client Records
  [types.FETCH_CLIENT_RECORDS]: (state) => state.set('loading', true),
  [types.FETCH_CLIENT_RECORDS_SUCCESS]: (state, action) => {
    const pageInfo = parse(action.payload.headers.link)
    return state
      .set('clientRecords', Immutable.fromJS(action.payload.data))
      .set('pageInfo', Immutable.fromJS(pageInfo))
      .set('loading', false)
  },

  [types.FETCH_CLIENT_RECORDS_FAILURE]: (state) => state.set('loading', false),

  // Client Reports
  [types.FETCH_CLIENT_REPORT]: (state) =>
    state.set('loading', true).set('pageError', false),
  [types.FETCH_CLIENT_REPORT_SUCCESS]: (state, action) => {
    return state
      .set('clientReport', Immutable.fromJS(action.payload.data))
      .set('loading', false)
      .set('pageError', false)
  },
  [types.FETCH_CLIENT_REPORT_FAILURE]: (state) =>
    state.set('loading', false).set('pageError', true),

  // Updating client current/baseline scans
  // [types.UPDATE_CLIENT_CURRENT]: (state, action) => state.set('currentScan', action.payload),
  // [types.UPDATE_CLIENT_BASELINE]: (state, action) => state.set('baselineScan', action.payload),

  // PARQ
  [types.FETCH_PARQ_DATA]: (state) =>
    state.set('loading', true).set('pageError', false),
  [types.FETCH_PARQ_DATA_SUCCESS]: (state, action) => {
    return state
      .set('parqData', Immutable.fromJS(action.payload.data))
      .set('loading', false)
      .set('pageError', false)
  },
  [types.FETCH_PARQ_DATA_FAILURE]: (state) =>
    state.set('loading', false).set('pageError', true),

  // Editing PARQ
  [types.UPDATE_PARQ_DATA]: (state) => state.set('loadingPARQUpdate', true),
  [types.UPDATE_PARQ_DATA_SUCCESS]: (state, action) => {
    return state
      .set('parqData', Immutable.fromJS(action.payload.data))
      .set('loadingPARQUpdate', false)
      .set('message', 'success')
  },
  [types.UPDATE_PARQ_DATA_FAILURE]: (state) =>
    state.set('loadingPARQUpdate', false).set('message', 'failure'),

  // PARQ Interest
  [types.FETCH_PARQ_INTEREST]: (state) => state.set('loadingPARQUpdate', true),
  [types.FETCH_PARQ_INTEREST_SUCCESS]: (state, action) => {
    return state
      .set('clientPARQInterest', Immutable.fromJS(action.payload.data))
      .set('loadingPARQUpdate', false)
  },
  [types.FETCH_PARQ_INTEREST_FAILURE]: (state) =>
    state.set('loadingPARQUpdate', false),

  // Client Summary
  [types.FETCH_CLIENT_SUMMARY]: (state) => state.set('loading', true),
  [types.FETCH_CLIENT_SUMMARY_SUCCESS]: (state, action) => {
    return state
      .set('clientSummary', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [types.FETCH_CLIENT_SUMMARY_FAILURE]: (state) => state.set('loading', false),

  [types.CLEAR_MESSAGE]: (state) => state.set('message', null),

  [types.RESET_CLIENT_DATA]: (state) => state.set('client', null),

  [types.RESET_CLIENT_REPORT]: (state) =>
    state.set('clientReport', null).set('clientSummary', null),
}

const defaultState = new Immutable.Map({
  loading: false,
  parqData: new Immutable.List(),
  loadingPARQUpdate: false,
  metricsLoading: false,
  measurementLoading: false,
  message: null,
  pageError: false,
  // currentScan: null,
  // baselineScan: null
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

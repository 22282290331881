import Immutable from 'immutable'

import {
  UPDATE_RANGE,
  HANDLE_ANIMATION,
  FETCH_IMAGE,
  SCAN_RECORDS_REQUEST,
  SCAN_RECORDS_SUCCESS,
  SCAN_RECORDS_FAILURE,
} from '../actions/types'

const reducers = {
  [UPDATE_RANGE]: (state, action) => {
    return state.set('rangeValue', action.payload)
  },

  [HANDLE_ANIMATION]: (state, action) => {
    return state.set('animation', action.payload)
  },

  [FETCH_IMAGE]: (state, action) => {
    return state.set('selected', action.payload)
  },

  [SCAN_RECORDS_REQUEST]: (state) => {
    return state.set('isFetchingRecords', true)
  },

  [SCAN_RECORDS_SUCCESS]: (state) => {
    return state.set('isFetchingRecords', false)
  },

  [SCAN_RECORDS_FAILURE]: (state) => {
    return state.set('isFetchingRecords', false)
  },
}

const defaultState = Immutable.Map({
  rangeValue: '0',
  animation: 'off',
  selected: '0',
  isFetchingRecords: false,
})

export default function records(state = defaultState, action) {
  if (typeof reducers[action.type] === 'function') {
    // Call the given reducer from action.type with the current state
    return reducers[action.type](state, action)
  }
  // If there was no reducer function to modify state return our existing state
  return state
}

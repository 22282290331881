import React, { Component } from 'react'
import PropTypes from 'prop-types'

// NOTE: Similar to ScanImage, should we refactor? Or keep the names different to easily identify?
class SnapImage extends Component {
  constructor(props) {
    super(props)
    this._mounted = false

    this.state = {
      error: false,
    }
  }

  componentDidMount() {
    this._mounted = true
    this.fetchSnapImg(this.props.src)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.fetchSnapImg(nextProps.src)
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  fetchSnapImg(uri) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    }

    return new Promise((resolve, reject) => {
      window.window
        .fetch(uri, options)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ error: false })
            return response.blob()
          }
          this.setState({
            loading: false,
            error: true,
          })
          // reject();
          return null
        })
        .then((blob) => {
          if (this._mounted && !this.state.error) {
            const url = URL.createObjectURL(blob)

            const img = document.getElementById(this.props.src)
            img.setAttribute('src', url)
          }
          resolve()
        })
        .catch((err) => reject(err))
    })
  }

  render() {
    if (this.state.error) {
      return (
        <img src={this.props.placeholderSrc} style={this.props.style} alt="" />
      )
    }
    return <img id={this.props.src} style={this.props.style} alt="" />
  }
}

SnapImage.propTypes = {
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string,
  style: PropTypes.object,
}

export default SnapImage

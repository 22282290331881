import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Route } from 'react-router-dom'

import { loadScanRecords } from '../actions/records'
import {
  fetchHistogramData,
  fetchWellnessHealthRanges,
} from '../actions/datasets'
import { fetchWellnessReport } from '../actions/wellness'

import currentScanSelector from '../selectors/current_scan'

import Wellness from '../components/newWellness'

class WellnessPage extends Component {
  componentDidMount() {
    this.props.loadScanRecords()
    this.props.fetchWellnessHealthRanges()

    // for posture stuff
    this.props.fetchHistogramData('male')
    this.props.fetchHistogramData('female')

    if (this.props.currentScan && this.props.currentScan.get('id')) {
      this.props.fetchWellnessReport(this.props.currentScan.get('id'))
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentScan = this.props.currentScan
    const nextScan = nextProps.currentScan

    if (
      (!currentScan && nextScan) ||
      (currentScan && currentScan.get('id') !== nextScan.get('id'))
    ) {
      this.props.fetchWellnessReport(nextScan.get('id'))
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/wellness" component={Wellness} />
      </Switch>
    )
  }
}

WellnessPage.propTypes = {
  currentScan: PropTypes.object,
  loadScanRecords: PropTypes.func.isRequired,
  fetchHistogramData: PropTypes.func.isRequired,
  fetchWellnessHealthRanges: PropTypes.func.isRequired,
  fetchWellnessReport: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  currentScan: currentScanSelector(state),
})

const actions = {
  loadScanRecords,
  fetchHistogramData,
  fetchWellnessHealthRanges,
  fetchWellnessReport,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WellnessPage)

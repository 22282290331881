export const FETCH_RESOURCES = 'FETCH_RESOURCES'
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE'

export const DOWNLOAD_RESOURCE = 'DOWNLOAD_RESOURCE'
export const DOWNLOAD_RESOURCE_FAILURE = 'DOWNLOAD_RESOURCE_FAILURE'

export const FETCH_FACILITY_USERS = 'FETCH_FACILITY_USERS'
export const FETCH_FACILITY_USERS_SUCCESS = 'FETCH_FACILITY_USERS_SUCCESS'
export const FETCH_FACILITY_USERS_FAILURE = 'FETCH_FACILITY_USERS_FAILURE'

export const UPDATE_FACILITY_USER = 'UPDATE_FACILITY_USER'
export const UPDATE_FACILITY_USER_SUCCESS = 'UPDATE_FACILITY_USER_SUCCESS'
export const UPDATE_FACILITY_USER_FAILURE = 'UPDATE_FACILITY_USER_FAILURE'

export const FETCH_FACILITY_STATS = 'FETCH_FACILITY_STATS'
export const FETCH_FACILITY_STATS_SUCCESS = 'FETCH_FACILITY_STATS_SUCCESS'
export const FETCH_FACILITY_STATS_FAILURE = 'FETCH_FACILITY_STATS_FAILURE'

// Activity Challenges
export const FETCH_FACILITY_ACTIVITY = 'FETCH_FACILITY_ACTIVITY'
export const FETCH_FACILITY_ACTIVITY_SUCCESS = 'FETCH_FACILITY_ACTIVITY_SUCCESS'
export const FETCH_FACILITY_ACTIVITY_FAILURE = 'FETCH_FACILITY_ACTIVITY_FAILURE'

export const FETCH_COACHES_ACTIVITY = 'FETCH_COACHES_ACTIVITY'
export const FETCH_COACHES_ACTIVITY_SUCCESS = 'FETCH_COACHES_ACTIVITY_SUCCESS'
export const FETCH_COACHES_ACTIVITY_FAILURE = 'FETCH_COACHES_ACTIVITY_FAILURE'

export const CREATE_FACILITY = 'CREATE_FACILITY'
export const CREATE_FACILITY_SUCCESS = 'CREATE_FACILITY_SUCCESS'
export const CREATE_FACILITY_FAILURE = 'CREATE_FACILITY_FAILURE'

import React from 'react'

export default function Input({ value, onChange }) {
  return (
    <div>
      <label>Scanner Code</label>
      <input
        className="form-control"
        value={value}
        onChange={onChange}
        style={{ width: 240 }}
      />
    </div>
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as VideoActions from '../../actions/scan/video'
// import { mapActions } from 'utils';
// import { status } from '../../../actions';

// import Slider from '../utilities/slider';

import './scan_control.css'

class ScanControl extends Component {
  constructor(props) {
    super(props)

    this.handleAnimation = this.handleAnimation.bind(this)
    this.changeRangeValue = this.changeRangeValue.bind(this)
    this.openFullScreen = this.openFullScreen.bind(this)
  }

  changeRangeValue(event) {
    var videos = this.props.video.get('videoRefs').toJS()
    for (const videoId in videos) {
      const video = videos[videoId]
      var time = video.duration * (event.target.value / 100)
      video.currentTime = time
    }
  }

  handleAnimation() {
    var currentAnimationStatus = this.props.video.get('animation')
    var videos = this.props.video.get('videoRefs').toJS()

    for (const videoId in videos) {
      const video = videos[videoId]
      if (currentAnimationStatus === 'off') {
        video.play()
      } else {
        const time = video.currentTime
        video.pause()
        this.props.updateCurrentTime(time)
      }
    }

    var animationStatus = currentAnimationStatus === 'off' ? 'on' : 'off'
    this.props.toggleAnimation(animationStatus)
  }

  openFullScreen() {
    var videos = this.props.video.get('videoRefs').toJS()
    for (const videoId in videos) {
      const video = videos[videoId]
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      }
    }
  }

  render() {
    if (this.props.video.get('videoRefs').size === 0) {
      return <div />
    }
    const handleChange = this.changeRangeValue
    const handleFullScreen = this.openFullScreen
    return (
      <div className="d-flex justify-content-center" style={this.props.style}>
        <div className="control-button" onClick={this.handleAnimation}>
          {this.props.video.get('animation') === 'off' ? (
            <span
              className="icon icon-controller-play"
              style={{ fontSize: '18px' }}
            />
          ) : (
            <span
              className="icon icon-controller-paus"
              style={{ fontSize: '18px' }}
            />
          )}
        </div>
        {/* <Slider
          min='0' max='100'
          changeRangeValue={ this.changeRangeValue }
          rangeValue={ this.props.video.get('rangeValue') }/> */}
        <div className="slider">
          <input
            className="sliderRange"
            type="range"
            value={this.props.video.get('rangeValue')}
            onChange={handleChange}
            min="0"
            max="100"
          />
        </div>
        <div onClick={handleFullScreen}>
          <span
            className="icon icon-resize-full-screen"
            style={{ fontSize: '17px' }}
          />
        </div>
      </div>
    )
  }
}

ScanControl.propTypes = {
  video: ImmutablePropTypes.mapContains({
    animation: PropTypes.string.isRequired,
    rangeValue: PropTypes.string.isRequired,
    videoRefs: ImmutablePropTypes.map,
  }).isRequired,
  toggleAnimation: PropTypes.func.isRequired,
  updateCurrentTime: PropTypes.func.isRequired,
  style: PropTypes.object,
}

const mapStateToProps = (state) => ({
  video: state.video,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(VideoActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ScanControl)

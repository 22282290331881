import * as types from './types'
import action from '../middleware'

export const fetchResources = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/facility/docs',
      method: 'GET',
      actionTypes: {
        successType: types.FETCH_RESOURCES,
        failureType: types.FETCH_RESOURCES_FAILURE,
      },
    },
    dispatch
  )

//
// export function fetchResources() {
//   var response = axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/docs`, { withCredentials: `${ process.env.INClUDE_CREDS }` });
//   return {
//     type: FETCH_RESOURCES,
//     payload: response
//   };
// }

export const downloadResource = (docKey) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/doc/download?docKey=${docKey}`,
      method: 'GET',
      actionTypes: {
        successType: types.DOWNLOAD_RESOURCE,
        failureType: types.DOWNLOAD_RESOURCE_FAILURE,
      },
    },
    dispatch
  )

// export function downloadResource(docKey) {
//   var response = axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/doc/download?docKey=${docKey}`, { withCredentials: `${ process.env.INClUDE_CREDS }` });
//
//   return {
//     type: DOWNLOAD_RESOURCE,
//     payload: response
//   };
// }

// Facility's Users
export const fetchFacilityUsers = (params) => (dispatch) => {
  const queryStringParams = []
  for (const key in params) {
    if (key === 'search') {
      const { searchParam, searchString } = params[key]
      queryStringParams.push(`${key}=${searchParam}:${searchString}`)
    } else {
      queryStringParams.push(`${key}=${params[key]}`)
    }
  }
  const queryString = queryStringParams.join('&')

  return action(
    {
      endpoint: `/v1/facility/admin/users/search?${queryString}`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_FACILITY_USERS,
        successType: types.FETCH_FACILITY_USERS_SUCCESS,
        failureType: types.FETCH_FACILITY_USERS_FAILURE,
      },
    },
    dispatch
  )
}

// export function fetchFacilityUsers() {
//   var response = axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/admin/users`, { withCredentials: `${ process.env.INClUDE_CREDS }` });
//
//   return {
//     type: FETCH_FACILITY_USERS,
//     payload: response
//   };
// }

export const updateFacilityUser = (data) => (dispatch) => {
  const parsedData = {
    coach: data.isCoach,
    userId: data.userId,
  }

  return action(
    {
      endpoint: `/v1/facility/admin/users/${parsedData.userId}/role`,
      method: 'POST',
      data: parsedData,
      actionTypes: {
        requestType: types.UPDATE_FACILITY_USER,
        successType: types.UPDATE_FACILITY_USER_SUCCESS,
        failureType: types.UPDATE_FACILITY_USER_FAILURE,
      },
      meta: {
        userId: data.userId,
      },
    },
    dispatch
  )
}

// export function updateFacilityUser(data) {
//   const parsedData = {
//     coach: data.isCoach,
//     userId: data.userId
//   };
//
//   var response = axios({
//     method: 'POST',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/facility/admin/users/${parsedData.userId}/role`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data: parsedData
//   });
//
//   return {
//     type: UPDATE_FACILITY_USER,
//     payload: response
//   };
// }

export const fetchFacilityStats = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/facility/stats',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_FACILITY_STATS,
        successType: types.FETCH_FACILITY_STATS_SUCCESS,
        failureType: types.FETCH_FACILITY_STATS_FAILURE,
      },
    },
    dispatch
  )

export const createFacility = (data) => (dispatch) =>
  action(
    {
      endpoint: '/v1/admin/facilities',
      method: 'POST',
      data,
      actionTypes: {
        requestType: types.CREATE_FACILITY,
        successType: types.CREATE_FACILITY_SUCCESS,
        failureType: types.CREATE_FACILITY_FAILURE,
      },
    },
    dispatch
  )
// export const fetchFacilityStats = () => {
//   var response = axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/facility/stats`, { withCredentials: `${ process.env.INClUDE_CREDS }` });
//
//   return {
//     type: FETCH_FACILITY_STATS,
//     payload: response
//   };
// };

export const fetchFacilityActivity = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/facility/challenge/activity',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_FACILITY_ACTIVITY,
        successType: types.FETCH_FACILITY_ACTIVITY_SUCCESS,
        failureType: types.FETCH_FACILITY_ACTIVITY_FAILURE,
      },
    },
    dispatch
  )

export const fetchCoachesActivity = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/facility/challenge/coach/activity',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_COACHES_ACTIVITY,
        successType: types.FETCH_COACHES_ACTIVITY_SUCCESS,
        failureType: types.FETCH_COACHES_ACTIVITY_FAILURE,
      },
    },
    dispatch
  )

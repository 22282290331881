import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchState } from '../actions/search'

import Coach from '../components/coach'
import ClientHistory from '../components/coach/client'
import WellnessComparison from '../components/coach/client/comparison'
import MeasurementComparison from '../components/coach/client/measurement_comparison'
import PostureComparison from '../components/coach/client/posture_comparison'
import OverlayComparison from '../components/coach/client/overlay_comparison'
import ClientReport from '../components/coach/client_report/index.js'
import MockComparison from '../components/coach/mockReports/comparison_index'
import PARQ from '../components/coach/PARQ/index'

class StaffPage extends Component {
  componentWillMount() {
    const superAdmin = this.props.user.get('user').get('superAdmin')
    const facilityAdmin = this.props.user.get('user').get('facilityAdmin')
    const coach = this.props.user.get('user').get('coach')

    if (!(superAdmin || facilityAdmin || coach)) {
      this.props.history.push('/')
    }
  }

  componentWillUnmount() {
    this.props.resetSearchState()
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/staff/clients/records"
            component={(props) => <Coach {...props} />}
          />
          {/* <Route exact path='/staff/clients/manage' component={ ManageClient } /> */}
          <Route
            exact
            path="/staff/clients/:userId/records"
            component={ClientHistory}
          />
          <Route
            exact
            path="/staff/clients/:userId/comparison/wellness"
            component={WellnessComparison}
          />
          <Route
            exact
            path="/staff/clients/:userId/comparison/measurements"
            component={MeasurementComparison}
          />
          <Route
            exact
            path="/staff/clients/:userId/comparison/posture"
            component={PostureComparison}
          />
          <Route
            exact
            path="/staff/clients/:userId/comparison/overlay"
            component={OverlayComparison}
          />
          <Route
            exact
            path="/staff/clients/:clientId/records/:recordId/report"
            component={ClientReport}
          />
          <Route
            exact
            path="/staff/clients/:clientId/records/:recordId/report/comparison"
            component={(props) => (
              <MockComparison {...props} user={this.props.user.get('user')} />
            )}
          />
          <Route
            exact
            path="/staff/clients/:clientId/records/:recordId/parq"
            component={PARQ}
          />
          {/* <Route exact path='/staff/clients/:userId/reports/:recordId/comparison' component={ ClientComparisonReport } /> */}
          {/* <Route path='/staff/facility' component={ Facility } /> */}
        </Switch>
      </div>
    )
  }
}

StaffPage.propTypes = {
  resetSearchState: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetSearchState }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StaffPage)

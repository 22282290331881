import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { metricData } from './metric_data'
import Categories from './categories'
import MetricSelector from './metric_selector'
import Chart from './chart'
import './wellness.css'

class NewWellness extends Component {
  constructor(props) {
    super(props)

    this.selectors = {
      bodyShape: ['bodyShapeRating', 'wc', 'whr', 'trunkLegVolRatio'],
      bodyComp:
        props.bfpAccess === 1
          ? ['bfp', 'weight', 'fatMass', 'leanMass']
          : ['weight'],
      fitnessLevel: ['bmr'],
    }

    this.updateSelected = this.updateSelected.bind(this)
    this.state = {
      selectedMetric: 'bodyShapeRating',
      selectedCategory: 'bodyShape',
    }
  }

  updateSelected(key, value) {
    if (this.state[key] !== value) {
      if (key === 'selectedCategory') {
        this.setState({ selectedMetric: this.selectors[value][0] })
      }
      this.setState({ [key]: value })
    }
  }

  renderHistogram(metric) {
    const { wellnessMetrics, healthRanges, units } = this.props
    const { name } = metricData(units)[metric]

    let data

    if (healthRanges.get(metric)) {
      data = healthRanges.get(metric).get('populationHistogram')
    }

    if (data) {
      return (
        <Chart
          metric={metric}
          ranges={healthRanges.get(metric).get('ranges')}
          value={wellnessMetrics.get(metric)}
          data={data}
          title={name}
        />
      )
    }
    return null
  }

  render() {
    const { selectedMetric, selectedCategory } = this.state
    return (
      <div className="mb-5 print-break-after">
        <div>
          <h2>Wellness Metrics</h2>
        </div>
        <div className="card card-default p-0" style={{ borderRadius: 0 }}>
          <div className="card-body p-0">
            <Categories
              selectedCategory={selectedCategory}
              updateSelected={this.updateSelected}
            />
            <div className="row mx-0">
              <div className="col-sm-4 p-3 card-container">
                <MetricSelector
                  selectedMetric={this.state.selectedMetric}
                  wellnessMetrics={this.props.wellnessMetrics}
                  metrics={this.selectors[selectedCategory]}
                  units={this.props.units}
                  updateSelected={this.updateSelected}
                />
              </div>
              <div className="col-sm-8 px-3 pb-3">
                <div style={{ paddingTop: '30px', marginBottom: '40px' }}>
                  <h3>What is it?</h3>
                  {metricData(this.props.units)[selectedMetric].description}
                  {selectedMetric !== 'weight' && (
                    <div>
                      <h3>How do I compare?</h3>
                      <p>
                        The chart below shows how you compare against
                        recommended healthy ranges as well as how you compare to
                        other Fit3D users with your same gender and similar age.
                      </p>
                    </div>
                  )}
                </div>
                <div>{this.renderHistogram(selectedMetric)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NewWellness.propTypes = {
  facility: PropTypes.object,
  histograms: PropTypes.object,
  healthRanges: PropTypes.object,
  wellnessMetrics: PropTypes.object,
  gender: PropTypes.string,
  units: PropTypes.string.isRequired,
  bfpAccess: PropTypes.number.isRequired,
}

export default NewWellness

import { combineReducers } from 'redux'

import {
  FETCH_FACILITIES,
  FETCH_FACILITIES_SUCCESS,
  FETCH_FACILITIES_FAILURE,
  CREATE_FACILITY,
  CREATE_FACILITY_SUCCESS,
  CREATE_FACILITY_FAILURE,
  UPDATE_FACILITY,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAILURE,
} from '../../actions/admin/facilities'

const fetchingFacilities = (state = false, action) => {
  switch (action.type) {
    case FETCH_FACILITIES:
      return true
    case FETCH_FACILITIES_SUCCESS:
    case FETCH_FACILITIES_FAILURE:
      return false
    default:
      return state
  }
}

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case CREATE_FACILITY:
    case UPDATE_FACILITY:
      return true
    case CREATE_FACILITY_SUCCESS:
    case UPDATE_FACILITY_SUCCESS:
    case CREATE_FACILITY_FAILURE:
    case UPDATE_FACILITY_FAILURE:
      return false
    default:
      return state
  }
}

const list = (state = [], action) => {
  switch (action.type) {
    case FETCH_FACILITIES_SUCCESS:
      return action.payload
    case CREATE_FACILITY_SUCCESS:
      return [action.payload, ...state]
    case UPDATE_FACILITY_SUCCESS:
      return state.map((facility) => {
        if (facility.id === action.payload.id) {
          return action.payload
        }
        return facility
      })
    default:
      return state
  }
}

const initialFormState = {
  id: '',
  name: '',
  company: '',
  bfpAccess: 0,
  postureAccess: 0,
  addressCity: '',
  addressState: '',
  addressCountry: '',
  addressZip: '',
  activationCode: '',
  defaultCoachFilter: '',
  pipelinePriority: '',
  enterprise: '',
}

const editForm = (state = initialFormState, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_FACILITY_FORM':
      return {
        ...state,
        ...action.payload,
      }
    case 'CLOSE_FACILITY_EDIT_MODAL':
      return initialFormState
    default:
      return state
  }
}

const editModalOpen = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_FACILITY_EDIT_MODAL':
      return !state
    case 'CLOSE_FACILITY_EDIT_MODAL':
      return false
    default:
      return state
  }
}

const errorMessage = (state = '', action) => {
  switch (action.type) {
    case CREATE_FACILITY:
    case UPDATE_FACILITY:
      return ''
    case CREATE_FACILITY_FAILURE:
    case UPDATE_FACILITY_FAILURE:
      return action.error
    default:
      return state
  }
}

export default combineReducers({
  fetchingFacilities,
  isSubmitting,
  list,
  editForm,
  editModalOpen,
  errorMessage,
})

import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import { shortAnswerField } from '../fields/short_answer_field'
import CheckboxGroup from '../fields/checkbox_group'
import RadioGroup from '../fields/radio_group'
import RatingsGroup from '../fields/ratings'
import NoTranslate from '../../../hocs/NoTranslate'

import options from '../fields/options'
import '../parq.css'

let Survey = ({
  redirectPage,
  firstName,
  lastName,
  handlePARQSubmit,
  handleSubmit,
  submitting,
}) => (
  <div>
    <div>
      <div className="my-4 pt-4 pb-3">
        <h1 className="text-center">
          <NoTranslate>{`${firstName} ${lastName}`}</NoTranslate>'s PARQ Survey
        </h1>
      </div>
      <form
        onSubmit={handleSubmit((data) => handlePARQSubmit(data))}
        className="col-xs-10 col-xs-offset-1"
      >
        <div className="text-uppercase">
          <h4 className="header">Health History</h4>
        </div>
        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              1. Have you had surgery in the past 6 months?
            </h4>
            <Field
              component={RadioGroup}
              name="pastSurgery"
              options={options.yesNo}
            />
          </div>
        </div>

        <div className="form-group">
          <Field
            name="medication"
            type="text"
            component={shortAnswerField}
            label="2. Are you on any medication?"
          />
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <div className="mb-3">
              <h4>
                <span>3. Do you experience any chronic joint pain?&nbsp;</span>
                <span style={{ color: '#38b3b3' }}>
                  <i>Select all that apply.</i>
                </span>
              </h4>
            </div>
            <Field
              name="jointPain"
              component={CheckboxGroup}
              options={options.jointPain}
            />
          </div>
        </div>

        <div className="form-group mb-3">
          <div className="input-row mb-5">
            <div className="mb-3">
              <h4>
                <span>4. Do you experience any of the following?&nbsp;</span>
                <span style={{ color: '#38b3b3' }}>
                  <i>Select all that apply.</i>
                </span>
              </h4>
            </div>
            <Field
              name="symptoms"
              component={CheckboxGroup}
              options={options.symptoms}
            />
          </div>
        </div>

        <div className="text-uppercase mt-5">
          <h4 className="header">Fitness Information</h4>
        </div>
        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              5. Have you ever been a member of a health club before?
            </h4>
            <Field
              component={RadioGroup}
              name="healthClub"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
        </div>

        <div className="form-group">
          <Field
            name="whyCancelHealthClub"
            type="text"
            component={shortAnswerField}
            label="6. Why might have you cancelled?"
          />
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              7. Have you been exercising for the last 6 months?
            </h4>
            <Field
              component={RadioGroup}
              name="exerciseLast6Months"
              options={options.yesNo}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              8. Have you ever had a hard time attaining results and motivating
              yourself?
            </h4>
            <Field
              component={RadioGroup}
              name="motivation"
              options={options.yesNo}
            />
          </div>
        </div>

        <div className="form-group">
          <Field
            name="motivationWhy"
            type="text"
            component={shortAnswerField}
            label="9. If so, why?"
          />
        </div>
        <div className="form-group">
          <Field
            name="timesWorkout"
            type="text"
            component={shortAnswerField}
            label="10. How many times a week do you plan on working out?"
          />
        </div>
        <div className="form-group">
          <Field
            name="timesEatInDay"
            type="text"
            component={shortAnswerField}
            label="11. How many times a day do you eat?"
          />
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              12. How often do you eat out for lunch and/or dinner?
            </h4>
            <Field
              component={RadioGroup}
              name="timesEatOut"
              options={options.timesEatOut}
            />
          </div>
        </div>

        <div className="form-group">
          <Field
            name="fitnessGoals"
            type="text"
            component={shortAnswerField}
            label="13. What are your primary fitness goals?"
          />
        </div>
        <div className="form-group">
          <Field
            name="bodyGoals"
            type="text"
            component={shortAnswerField}
            label="14. What areas of your body do you want to focus on?"
          />
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">
              15. What prevented you from reaching your fitness goals in the
              past?
            </h4>
            <Field
              component={RadioGroup}
              name="fitnessGoalPrevention"
              options={options.fitnessGoalPrevention}
            />
          </div>
        </div>

        <div className="form-group">
          <Field
            name="occupation"
            type="text"
            component={shortAnswerField}
            label="16. What is your current occupation?"
          />
        </div>

        <div className="form-group">
          <div className="input-row mb-5">
            <h4 className="mb-3">17. Current physical activity level?</h4>
            <Field
              component={RadioGroup}
              name="activityLevel"
              options={options.activityLevel}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-group">
            <div className="input-row mb-5">
              <h4 className="mb-4">
                18. How motivated are you to reach your fitness goals?
              </h4>
              <div className="ratingsContainer">
                <span className="mr-3 text-danger ratingsText">
                  Not Motivated
                </span>
                <Field
                  name="motivationValue"
                  component={RatingsGroup}
                  options={options.motivationValues}
                />
                <span className="ml-3 text-success ratingsText">
                  Very Motivated
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-primary btn-lg mr-2"
            disabled={submitting}
            onClick={redirectPage}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg ml-2"
            disabled={submitting}
          >
            Submit Survey
          </button>
        </div>
      </form>

      {/* <div>
        <div className={ styles['survey-progress'] }>
          <div className={ styles['survey-progress-bar'] } />
        </div>
      </div> */}
    </div>
  </div>
)

Survey = reduxForm({
  form: 'parqSurvey',
})(Survey)

Survey.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  redirectPage: PropTypes.func,
  handlePARQSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
}

export default Survey

import React from 'react'
import { connect } from 'react-redux'

import {
  resetSearchState,
  updateSearchParam,
  updateSearchString,
} from '../../actions/search'
import { setPage } from '../../actions/pagination'

import Filter from '../griddle/search'

const Search = ({
  search,
  options,
  pageSize,
  fetchData,
  updateSearchParam,
  updateSearchString,
  resetSearchState,
  setPage,
}) => {
  React.useEffect(() => {
    return () => {
      resetSearchState()
    }
  }, [])

  const handleSubmit = (searchParam, searchString) => {
    updateSearchParam(searchParam)
    updateSearchString(searchString)
    setPage(0)

    fetchData({
      search: {
        searchParam,
        searchString,
      },
      page: 0,
      size: pageSize,
    })
  }

  return (
    <Filter
      submitFilteredSearch={handleSubmit}
      search={search}
      searchOptions={options}
      resetSearchState={resetSearchState}
      placholder="Search records"
    />
  )
}

const mapStateToProps = (state) => ({
  search: state.newSearch,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) => ({
  updateSearchParam: (string) => dispatch(updateSearchParam(string)),
  updateSearchString: (string) => dispatch(updateSearchString(string)),
  setPage: (page) => dispatch(setPage(page)),
  resetSearchState: () => dispatch(resetSearchState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)

import action from '../middleware'
import { getQueryString } from './utils/functions'
//
// export function fetchPostureRecordsRequest() {
//   return {
//     type: ADMIN_POSTURE_FETCH_RECORDS,
//     payload: 'fetching'
//   };
// }
//
// export function fetchPostureRecords(params) {
//   const { page, size } = params;
//   return dispatch => {
//     dispatch(fetchPostureRecordsRequest());
//     return axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/admin/records/posture?page=${page}&size=${size}`, { withCredentials: `${ process.env.INClUDE_CREDS }` })
//       .then(response => {
//         dispatch({
//           type: ADMIN_POSTURE_FETCH_RECORDS_SUCCESS,
//           payload: response
//         });
//       }).catch(error => {
//         dispatch({
//           type: ADMIN_POSTURE_FETCH_RECORDS_FAILURE,
//           payload: error
//         });
//       });
//   };
// }

export const ADMIN_POSTURE_FETCH_RECORDS = 'ADMIN_POSTURE_FETCH_RECORDS'
export const ADMIN_POSTURE_FETCH_RECORDS_SUCCESS =
  'ADMIN_POSTURE_FETCH_RECORDS_SUCCESS'
export const ADMIN_POSTURE_FETCH_RECORDS_FAILURE =
  'ADMIN_POSTURE_FETCH_RECORDS_FAILURE'

export const fetchPostureRecords = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/posture?${getQueryString(params)}`,
      method: 'GET',
      actionTypes: {
        requestType: ADMIN_POSTURE_FETCH_RECORDS,
        successType: ADMIN_POSTURE_FETCH_RECORDS_SUCCESS,
        failureType: ADMIN_POSTURE_FETCH_RECORDS_FAILURE,
      },
    },
    dispatch
  )

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import { fetchCoachesActivity } from '../../../actions/facility'

import { FacilityActivityList } from './list'

class CoachActivity extends Component {
  componentDidMount() {
    this.props.fetchCoachesActivity()
  }

  render() {
    const { coachesActivity } = this.props
    return (
      <div className="container mb-5">
        <div className="dashhead mb-3">
          <div className="dashhead-titles mt-4">
            <h6 className="dashhead-subtitle">Facility Dashboard</h6>
            <h2 className="dashhead-title">Coach Activity Challenge</h2>
          </div>
        </div>
        <div className="p-0 mb-4">
          <div
            className="card-body p-0"
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)' }}
          >
            <div className="text-center text-uppercase mb-3 facility-dashboard-header">
              Coach Activity Leaderboard - August 2018
            </div>
            <div className="row px-3 pb-3">
              <div className="col-sm-6">
                <ul className="list-group facility-list-group">
                  <li className="list-group-header">
                    August Leaders - <strong>Completed Client Scans</strong>
                  </li>
                  {coachesActivity.get('leaders') &&
                    coachesActivity.get('leaders').get('scans') && (
                      <FacilityActivityList
                        list={coachesActivity.get('leaders').get('scans')}
                        title="coach"
                        type="scans"
                      />
                    )}
                </ul>
              </div>
              <div className="col-sm-6">
                <div
                  key={_.uniqueId()}
                  className="d-flex list-group-item"
                  style={{ borderRadius: '.25em' }}
                >
                  Your Clients' Completed Scans
                  <div className="ml-auto">
                    <span className="ml-3">
                      <span
                        className="badge badge-primary badge-pill"
                        style={{
                          borderRadius: '2px',
                          padding: '6px 12px',
                          fontSize: '80%',
                        }}
                      >
                        {coachesActivity.get('summary')
                          ? coachesActivity.get('summary').get('scans')
                          : null}
                      </span>
                    </span>
                  </div>
                  {/* <span className='list-group-progress bg-primary' style={ { width: item.get(props.type) + '%' } } /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 mb-4">
          <div
            className="card-body p-0"
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)' }}
          >
            <div className="text-center text-uppercase mb-3 facility-dashboard-header">
              Rules and Conditions
            </div>
            <div className="row p-3 mx-0">
              <h5 className="mb-3">
                Fit3D's August 2018 Coach Success Competition Rules and
                Conditions
              </h5>
              <p>
                <b>WHO CAN ENTER:</b> All coaches and facility admins who are
                listed as either on the Fit3D platform are automatically
                entered. Void where prohibited or restricted by law or
                regulation. Not eligible are employees and their immediate
                families including household members of Fit3D, Inc. or their
                respective agents, attorneys, subsidiaries, divisions, and
                advertising and promotion agencies.
              </p>
              <p>
                <b>HOW TO ENTER:</b> All coaches and admins who are listed as
                either on the Fit3D platform by end of business (5pm PT),
                Wednesday July 31, 2018 are automatically entered. To be
                eligible for a prize, the winning coach much accept the prize
                via email confirmation.
              </p>
              <div>
                <b>HOW TO PARTICIPATE AND COMPETE:</b>
                <ul>
                  <li>
                    Competition Period: August 1, 2018 at 12:00 AM (PST) and
                    ending August 31, 2018 at 11:59 PM (PT)
                  </li>
                  <li>
                    Clients who scan must be attributed to the coach to be
                    considered part of the competition.
                  </li>
                  <li>
                    Coach must be listed as either a Coach or Admin on their
                    facility's Fit3D Dashboard by end of business (5pm PT),
                    Wednesday July 31, 2018 to be entered into the competition.
                  </li>
                  <li>
                    Scans completed by coaches and admins will not be factored
                    into total valid scan counts.
                  </li>
                </ul>
              </div>
              <p>
                <b>WINNERS:</b> At the end of the Competition Period, Fit3D will
                announce the top ten coaches/admins of the competition in an
                email September 10, 2018. The winner will be notified directly
                via electronic mail on September 11, 2018 and will have three
                (3) days to confirm a desire to receive their prizes. If prizes
                are not confirmed, the prize may be forfeited and offered to
                another winner.
              </p>
              <div>
                <b>PARTICIPANT PRIZES:</b> There will be one (1) winner. All
                prizes are non-transferrable.
                <ul>
                  <li>
                    The coach or facility admin who helps clients/members
                    complete the most valid Fit3D body scans during the month of
                    August 2018.
                  </li>
                </ul>
              </div>
              <p>
                <b>TERMS:</b> Entrants: (i) acknowledge compliance with these
                Official Rules, the Terms of Service of Fit3D, and all
                eligibility requirements; (ii) agree that Sponsor has sole
                discretion to resolve any dispute as it sees fit; and (iii)
                agree that neither you nor your heirs, successors or assigns
                will assert any claim relating to damages, losses or injuries
                against Sponsor and Fit3D Participating Locations or their
                respective agents, affiliates, subsidiaries, divisions,
                attorneys, printers and advertising and promotion agencies
                (collectively “Released Parties”). All entry information becomes
                the property of Sponsor. This contest is subject to U.S.
                federal, state and local laws and regulations. Released Parties
                (i) make no warranty, guaranty or representation of any kind
                concerning any prize; (ii) disclaim any implied warranty; and
                (iii) are not in any way responsible or liable for damage, loss
                or injury resulting from altered, late, lost, damaged,
                misdirected, mutilated, illegible or incomplete entries, or
                human, telephone, computer, online, or technical malfunctions,
                failures or difficulties (including busy lines and
                disconnections) of any kind. Winner assumes liability for
                injuries caused or claimed to be caused by participating in the
                contest, or by the acceptance, possession or use of any prize.
              </p>
              <p>
                <b>CONDITIONS:</b> No correspondence will be entered into by
                Sponsor. Any tax due on any prize is the sole responsibility of
                the winner. A completed W-9 will be required by Fit3D in order
                to issue prize winnings in cash. Winners hereby consent to the
                use of his/her name and photograph for promotional purposes
                without additional compensation, unless prohibited by law.
                Persons found tampering with or abusing any aspect of this
                contest as solely determined by the Sponsor will be disqualified
                from winning any prize. In the event the contest is compromised
                by a virus, bugs, non-authorized human intervention or other
                causes beyond the control of Sponsor which corrupts or impairs
                the administration, security, fairness or proper play of the
                contest, Sponsor reserves the right in its sole discretion to
                suspend or terminate the contest and award prizes in a later
                contest. Should the contest be terminated prior to the stated
                expiration date, notice will be posted at www.fit3d.com.
              </p>
              <p>
                <b>PRIVACY:</b> By entering into this contest you also agree to
                Sponsor's use of your personal information as described in
                Sponsor Privacy Policy at https://www.fit3d.com/privacy/.
              </p>
              <p>
                <b>COMMUNICATION:</b> By entering into this contest you also
                expressly acknowledge and agree that you will receive
                communications from Sponsor related to the contest. You may
                elect to unsubscribe from such communications.
              </p>
              <p>
                <b>WINNERS:</b> The names of all winners will be posted online
                at www.fit3d.com after September 10, 2018.
              </p>
              <p>
                <b>SPONSOR:</b> Fit3D, Inc., 2955 Campus Dr, San Mateo, CA
                94403.
              </p>
              Questions? Contact our support team at support.fit3d.com.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CoachActivity.propTypes = {
  fetchCoachesActivity: PropTypes.func,
  coachesActivity: PropTypes.object,
}

const mapStateToProps = (state) => ({
  coachesActivity: state.facility.get('coachesActivity'),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCoachesActivity }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CoachActivity)

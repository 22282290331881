import { combineReducers } from 'redux'
import { RESET_SEARCH_STATE } from '../actions/search/types'
import { UPDATE_SEARCH_PARAM, UPDATE_SEARCH_STRING } from '../actions/search'

const searchParam = (state = 'email', action) => {
  switch (action.type) {
    case UPDATE_SEARCH_PARAM:
      return action.payload
    case RESET_SEARCH_STATE:
      return 'email'
    default:
      return state
  }
}

const searchString = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SEARCH_STRING:
      return action.payload
    case RESET_SEARCH_STATE:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  searchParam,
  searchString,
})

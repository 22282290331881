import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Table, Row, NullTable } from './table'

const measurementKeyNameList = [
  { key: 'neckCollarGirth', name: 'Neck' },
  { key: 'bustGirth', name: 'Bust' },
  { key: 'waistSmallBackGirth', name: 'Waist' },
  { key: 'bicepsLeftGirth', name: 'BicepsL' },
  { key: 'forearmLeftGirth', name: 'ForearmL' },
  { key: 'thighLeftMaxGirth', name: 'ThighMaxL' },
  { key: 'ankleLeftGirth', name: 'AnkleL' },

  { key: 'chestAtBladesGirth', name: 'Chest' },
  { key: 'underbustGirth', name: 'Underbust' },
  { key: 'seatGirth', name: 'Hips' },
  { key: 'bicepsRightGirth', name: 'BicepsR' },
  { key: 'forearmRightGirth', name: 'ForearmR' },
  { key: 'thighRightMaxGirth', name: 'ThighMaxR' },
  { key: 'ankleRightGirth', name: 'AnkleR' },
]

const MeasurementsTable = ({ measurements }) => {
  if (measurements) {
    const table1 = measurementKeyNameList
      .slice(0, measurementKeyNameList.length / 2)
      .map((measurement) => {
        return (
          <Row key={_.uniqueId()} datum={measurements} data={measurement} />
        )
      })

    const table2 = measurementKeyNameList
      .slice(measurementKeyNameList.length / 2)
      .map((measurement) => {
        return (
          <Row key={_.uniqueId()} datum={measurements} data={measurement} />
        )
      })

    return (
      <div>
        <h5 className="mb-3 text-center">
          <strong>Measurements</strong>
        </h5>
        <div key={_.uniqueId()} className="d-flex">
          <Table table={table1} />
          <Table table={table2} />
        </div>
      </div>
    )
  } else {
    return <NullTable title="Measurements" />
  }
}

MeasurementsTable.propTypes = {
  measurements: PropTypes.object,
}

export default MeasurementsTable

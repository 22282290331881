import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import jwt from 'jwt-simple'

import Unavailable from '../utilities/unavailable'

const BASE_URL = 'https://embed.chartio.com/d/287245'
const ORGANIZATION_SECRET = process.env.REACT_APP_CHARTIO_SECRET

class Chartio extends Component {
  constructor(props) {
    super(props)

    this.state = { iframeHeight: null }
  }

  componentDidMount() {
    // usually we don't want to set state in componentDidMount outside of a cb, but this scenario is ok because we need to measure dashboard height
    this.setState({
      iframeHeight: document.querySelector('.dashhead').offsetHeight,
    })
  }

  render() {
    const { iframeHeight } = this.state

    const now = parseInt(new Date().getTime() / 1000)

    const payload = {
      iat: now,
      nbf: now,
      exp: now + 86400, // one day from now.
      dashboard: 287245,
      organization: 41083,
      env: { ENTERPRISE: 'crunch' }, // mapping of variable names to values which will be passed to the hidden variables on the dashboard
    }

    const token = jwt.encode(payload, ORGANIZATION_SECRET, 'HS256')

    return (
      <div
        className="pt-3 pt-sm-4 px-3 px-sm-5"
        style={{ overflow: 'hidden', height: '100vh' }}
      >
        <div className="dashhead" style={{ borderBottom: 'none' }}>
          <div className="dashhead-titles mt-3">
            <h6 className="dashhead-subtitle">Enterprise</h6>
            <h3 className="dashhead-title">Enterprise Reporting</h3>
          </div>
        </div>
        {this.props.user.get('user').get('enterpriseRole') ? (
          <iframe
            src={`${BASE_URL}/${token}`}
            style={{
              width: '100%',
              height: iframeHeight
                ? `calc(100vh - ${iframeHeight + 48}px)`
                : null,
              border: 'none',
            }}
          />
        ) : (
          <Unavailable title="Enterprise Unavailable">
            This page is unavailable, please use the nav bar to redirect to a
            different page.
          </Unavailable>
        )}
      </div>
    )
  }
}

Chartio.propTypes = {
  user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Chartio)

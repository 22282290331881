import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Search extends Component {
  constructor(props) {
    super(props)

    this.submitSearch = this.submitSearch.bind(this)
    this.changeParam = this._changeParam.bind(this)
    this.searchChange = this._searchChange.bind(this)
    this.resetSearchState = this.resetSearchState.bind(this)

    // set search options
    let searchOptions

    if (props.searchOptions) {
      searchOptions = props.searchOptions.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))
    } else {
      searchOptions = [
        <option key="email" value="email">
          E-mail
        </option>,
        <option key="firstName" value="firstName">
          First Name
        </option>,
        <option key="lastName" value="lastName">
          Last Name
        </option>,
      ]
    }

    this.state = {
      searchParam: props.searchOptions[0].value || props.search.searchParam,
      searchString: props.search.searchString || '',
      searchOptions,
    }
  }

  _changeParam(event) {
    this.setState({ searchParam: event.target.value })
  }

  _searchChange(event) {
    this.setState({ searchString: event.target.value.trim() })
  }

  moveFocusToEnd(event) {
    const tempValue = event.target.value
    event.target.value = ''
    event.target.value = tempValue
  }

  resetSearchState() {
    this.props.resetSearchState()
    this.setState({ searchString: '' })
    document.getElementById('search-bar').value = ''
  }

  submitSearch(e) {
    e.preventDefault() // necessary to prevent reloading whole page on form submit
    const { searchParam, searchString } = this.state

    // NOTE: hacky way of adding iscoach, need to refactor
    this.props.submitFilteredSearch(
      searchParam,
      searchParam === 'iscoach' ? true : searchString
    )
  }

  render() {
    const handleSubmit = this.submitSearch
    const handleParamChange = this.changeParam
    const handleSearchChange = this.searchChange
    const handleFocus = this.moveFocusToEnd
    const handleReset = this.resetSearchState

    return (
      <form
        className="text-center search-bar-container"
        onSubmit={handleSubmit}
      >
        <div className="param-select">
          <select
            value={this.state.searchParam}
            onChange={handleParamChange}
            className="form-control form-control-lg custom-select"
          >
            {this.state.searchOptions}
          </select>
        </div>
        <div className="search-bar">
          <input
            id="search-bar"
            defaultValue={this.state.searchString}
            onChange={handleSearchChange}
            className="form-control form-control-lg"
            placeholder={
              this.props.placeholder || 'Search Clients (Name or E-mail)'
            }
            autoFocus
            disabled={this.state.searchParam === 'iscoach'}
            onFocus={handleFocus}
          />
          {this.state.searchString ? (
            <div
              className="fas fa-times search-button text-danger"
              onClick={handleReset}
            />
          ) : (
            <div className="fas fa-search search-button" />
          )}
        </div>
        <button
          type="submit"
          className="btn btn-lg btn-primary"
          style={{ fontWeight: 300 }}
        >
          Search
        </button>
      </form>
    )
  }
}

Search.propTypes = {
  submitFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.shape({
    searchParam: PropTypes.string.isRequired,
    searchString: PropTypes.string,
  }),
  searchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  resetSearchState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

import Immutable from 'immutable'
import * as types from '../../actions/scan/types'

const defaultState = Immutable.Map({
  animation: 'off',
  rangeValue: '0',
  currentTime: null, // Preserve time across videos in comparison
  videoRefs: new Immutable.Map({}),
})

const reducers = {
  [types.UPDATE_VIDEO_REF]: (state, action) => {
    const { videoId, video } = action.payload
    return state.setIn(['videoRefs', videoId], video)
  },
  [types.UPDATE_RANGE_VALUE]: (state, action) =>
    state.set('rangeValue', action.payload),
  [types.UPDATE_CURRENT_TIME]: (state, action) =>
    state.set('currentTime', action.payload),
  [types.TOGGLE_ANIMATION]: (state, action) =>
    state.set('animation', action.payload),
  [types.CLEAR_VIDEO]: () => defaultState,
}

export default (state = defaultState, action) => {
  if (typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

import { combineReducers } from 'redux'
import { FETCH_USER_SUCCESS } from '../actions/user/types'

// Notification that shows up on the bottom right corner, should be tied to the browserId
// NOTE: in the future, we should handle cases where users log in and out
const displayMarketplaceInfo = (
  state = !window.localStorage.getItem('viewedMarketplace'),
  action
) => {
  switch (action.type) {
    case 'HIDE_MARKETPLACE_NOTICE':
      return false
    default:
      return state
  }
}

const displayCookieNotice = (state = true, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return !action.payload.data.cookiePolicyAccepted
    case 'HIDE_COOKIE_NOTICE':
      return false
    default:
      return state
  }
}

const displayScanErrorNotice = (state = true, action) => {
  switch (action.type) {
    case 'HIDE_SCAN_ERROR_NOTICE':
      return false
    default:
      return state
  }
}

const surveyClosed = (state = false, action) => {
  switch (action.type) {
    case 'SURVEY_CLOSE':
      return true
    default:
      return state
  }
}

export default combineReducers({
  displayMarketplaceInfo,
  displayCookieNotice,
  displayScanErrorNotice,
  surveyClosed,
})

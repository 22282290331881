import { combineReducers } from 'redux'
import {
  SET_PAGINATION_PAGE,
  SET_PAGE_SIZE,
  SET_PAGE_INFO,
} from '../actions/pagination'

const page = (state = 0, action) => {
  switch (action.type) {
    case SET_PAGINATION_PAGE:
      return action.payload
    case SET_PAGE_SIZE:
    case 'RESET_PAGINATION':
      return 0
    default:
      return state
  }
}

const pageSize = (state = 10, action) => {
  switch (action.type) {
    case SET_PAGE_SIZE:
      return action.payload
    case 'RESET_PAGINATION':
      return 10
    default:
      return state
  }
}

const pageInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_PAGE_INFO:
      return action.payload
    case 'RESET_PAGINATION':
      return {}
    default:
      return state
  }
}

const maxPages = (state = 1, action) => {
  switch (action.type) {
    case SET_PAGE_INFO: {
      const pageInfo = action.payload
      if (pageInfo.last) {
        return parseInt(pageInfo.last.page)
      } else if (pageInfo.prev) {
        // we are on the last page so link headers are different
        return parseInt(pageInfo.prev.page) + 2
      }
      return 1
    }
    default:
      return state
  }
}

export default combineReducers({
  page,
  pageSize,
  pageInfo,
  maxPages,
})

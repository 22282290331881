import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import _ from 'lodash'

import ScanDate from '../scans/scan_dates'

import { capitalize } from '../../functions/text_format'
import './report.css'

export default class WellnessComparisonInfo extends Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    const unitSystem = user.get('units')
    const units = unitSystem === 'US' ? 'lbs' : 'kg'

    this.wellnessMetrics = [
      { bodyShapeRating: 'Body Shape Rating', units: '' },
      { trunkLegVolRatio: 'Trunk to Leg Volume Ratio', units: '' },
      { weight: 'Weight', units: `(${units})` },
    ]

    const facility = user.get('facility')
    if (facility && facility.get('bfpAccess') === 1) {
      this.wellnessMetrics.push({ bfp: 'Body Fat Percentage', units: '%' })
      this.wellnessMetrics.push({ fatMass: 'Fat Mass', units: `(${units})` })
      this.wellnessMetrics.push({ leanMass: 'Lean Mass', units: `(${units})` })
    }
  }

  renderMetricData() {
    return this.wellnessMetrics.map((metric) => {
      const key = Object.keys(metric)[0]
      const baselineMetric = this.props.baselineScan
        .get('wellnessMetrics')
        .get(key)
      const currentMetric = this.props.currentScan
        .get('wellnessMetrics')
        .get(key)
      const difference = Math.max(
        ((currentMetric - baselineMetric) * 10) / 10
      ).toFixed(1)
      return (
        <tr key={_.uniqueId()}>
          <td>
            <span isolate>{metric[key]}</span>
            <span isolate>{` ${metric.units}`}</span>
          </td>
          <td>{baselineMetric}</td>
          <td>{currentMetric}</td>
          <td>{difference}</td>
        </tr>
      )
    })
  }

  renderScanDates() {
    const {
      records,
      baselineScan,
      currentScan,
      updateBaselineScan,
      updateCurrentScan,
    } = this.props
    return ['baseline', 'current'].map((type) => (
      <div key={_.uniqueId()} style={{ margin: '0 20px' }}>
        <div className="comparison-label">{capitalize(type)}</div>
        <ScanDate
          report
          type={type}
          records={records}
          scan={type === 'baseline' ? baselineScan : currentScan}
          updateScan={
            type === 'baseline' ? updateBaselineScan : updateCurrentScan
          }
        />
      </div>
    ))
  }

  render() {
    return (
      <div className="comparison-container">
        <div className="comparison-header">
          <h1 className="hr-divider-content">Wellness Metric Comparisons</h1>
        </div>
        <div className="mb-3 comparison-date-selector">
          <div className="row mx-0 mb-4">
            <h3>Select two scans to view compare:</h3>
          </div>
          <div className="row mx-0">{this.renderScanDates()}</div>
        </div>
        <Table responsive="true">
          <thead>
            <tr>
              <th>Metric Name</th>
              <th>Baseline</th>
              <th>Current</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>{this.renderMetricData()}</tbody>
        </Table>
      </div>
    )
  }
}

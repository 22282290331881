import React from 'react'

export default function FacilityEditButton({ cellProperties, rowData }) {
  const handleClick = () => {
    const { actions, ...data } = rowData

    cellProperties.setDefaultFormData({
      ...actions,
      ...data,
    })

    cellProperties.onOpen()
  }
  return (
    <button
      className="btn btn-outline-primary btn-xs py-0"
      onClick={handleClick}
    >
      Update Facility
    </button>
  )
}

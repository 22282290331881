import {
  ADMIN_POSTURE_FETCH_RECORDS,
  ADMIN_POSTURE_FETCH_RECORDS_SUCCESS,
  ADMIN_POSTURE_FETCH_RECORDS_FAILURE,
} from '../../actions/admin/posture'
import Immutable from 'immutable'
import parse from 'parse-link-header'

const reducers = {
  [ADMIN_POSTURE_FETCH_RECORDS]: (state) => {
    return state.set('loading', true)
  },

  [ADMIN_POSTURE_FETCH_RECORDS_SUCCESS]: (state, action) => {
    const pageInfo = parse(action.payload.headers.link)
    return state
      .set('scanRecords', Immutable.fromJS(action.payload.data))
      .set('pageInfo', Immutable.fromJS(pageInfo))
      .set('loading', false)
  },

  [ADMIN_POSTURE_FETCH_RECORDS_FAILURE]: (state) => {
    return state.set('loading', false)
  },
}

const defaultState = new Immutable.Map({ loading: false })

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

import React from 'react'
import PropTypes from 'prop-types'

import SubNavContainer from '../../components/navbar/sub_nav_container'
import NavItem from '../../components/navbar/nav_item'

import { version } from '../../../package.json'

const Fit3dAdminNav = ({ isQcAdmin, pathname, redirectPage }) => (
  <SubNavContainer header="Admin" subheader={`Version ${version}`}>
    <ul
      className="nav-pills flex-md-column list-unstyled"
      style={{ padding: '15px 0px' }}
    >
      {isQcAdmin ? (
        <>
          <NavItem
            isSelected={pathname === '/admin/records'}
            text="Records"
            clickHandler={redirectPage('/admin/records')}
          />
          <NavItem
            isSelected={pathname === '/admin/records/qc'}
            text="QC"
            clickHandler={redirectPage('/admin/records/qc')}
          />
        </>
      ) : (
        <>
          <NavItem
            isSelected={pathname === '/admin/records'}
            text="Records"
            clickHandler={redirectPage('/admin/records')}
          />
          <NavItem
            isSelected={pathname === '/admin/records/qc'}
            text="QC"
            clickHandler={redirectPage('/admin/records/qc')}
          />
          <NavItem
            isSelected={pathname === '/admin/facilities'}
            text="Facilities"
            clickHandler={redirectPage('/admin/facilities')}
          />
          <NavItem
            isSelected={pathname === '/admin/scanners'}
            text="Scanners"
            clickHandler={redirectPage('/admin/scanners')}
          />
          <NavItem
            isSelected={pathname === '/admin/coaches'}
            text="Coaches"
            clickHandler={redirectPage('/admin/coaches')}
          />
          <NavItem
            isSelected={pathname === '/admin/users'}
            text="Users"
            clickHandler={redirectPage('/admin/users')}
          />
        </>
      )}
    </ul>
  </SubNavContainer>
)

Fit3dAdminNav.propTypes = {
  pathname: PropTypes.string.isRequired,
  redirectPage: PropTypes.func.isRequired,
}

export default Fit3dAdminNav

import * as types from './types'
import action from '../middleware'

// Client Data
export const fetchClientData = (id) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${id}/records`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_CLIENT_DATA,
        successType: types.FETCH_CLIENT_DATA_SUCCESS,
        failureType: types.FETCH_CLIENT_DATA_FAILURE,
      },
    },
    dispatch
  )

// Updating Client's Additional Metrics
export const updateClientAdditional = (data) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${data.userId}/records/${data.recordId}/wellnessmetrics/additionals`,
      method: 'POST',
      data: data.values,
      actionTypes: {
        requestType: types.UPDATE_CLIENT_ADDITIONAL,
        successType: types.UPDATE_CLIENT_ADDITIONAL_SUCCESS,
        failureType: types.UPDATE_CLIENT_ADDITIONAL_FAILURE,
      },
      meta: {
        recordId: data.recordId,
      },
    },
    dispatch
  )

export const updateMeasurements = (data) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${data.userId}/records/${data.recordId}/wellnessmetrics`,
      method: 'POST',
      data: data.values,
      actionTypes: {
        requestType: types.UPDATE_CLIENT_MEASUREMENTS,
        successType: types.UPDATE_CLIENT_MEASUREMENTS_SUCCESS,
        failureType: types.UPDATE_CLIENT_MEASUREMENTS_FAILURE,
      },
      meta: {
        recordId: data.recordId,
      },
    },
    dispatch
  )

export const manageClient = (data) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${data.clientId}/request`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: types.MANAGE_CLIENT,
        successType: types.MANAGE_CLIENT_SUCCESS,
        failureType: types.MANAGE_CLIENT_FAILURE,
      },
    },
    dispatch
  )

// Resetting Client Data
export const resetClientData = () => ({
  type: types.RESET_CLIENT_DATA,
})

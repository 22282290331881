import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidationTable from './table'
import ValidateAllButtons from './validate_all_buttons'

const sliceKeyNameList = [
  { key: 'manualTopBack', name: 'Top Back' },
  { key: 'manualMiddleFront', name: 'Middle Front' },
  { key: 'manualMiddleBack', name: 'Middle Back' },
  { key: 'manualBottomFront', name: 'Bottom Front' },
  { key: 'manualBottomBack', name: 'Bottom Back' },
  { key: 'manualArmAngle', name: 'Arm Angle' },
]

class ManualValidation extends Component {
  // static sliceKeyNameList = [
  //   { key: 'manualNeck', name: 'Neck' },
  //   { key: 'manualBust', name: 'Bust' },
  //   { key: 'manualUnderbust', name: 'Underbust' },
  //   { key: 'manualWaistNatural', name: 'Waist Natural' },
  //   { key: 'manualWaist', name: 'Waist' },
  //   { key: 'manualHips', name: 'Hips' },
  //   { key: 'manualThighMidLeft', name: 'Thigh Mid (L)' },
  //   { key: 'manualThighMidRight', name: 'Thigh Mid (R)' },
  //   { key: 'manualCalfLeft', name: 'Calf (L)' },
  //   { key: 'manualCalfRight', name: 'Calf (R)' },
  //   { key: 'manualAnkleLeft', name: 'Ankle (L)' },
  //   { key: 'manualAnkleRight', name: 'Ankle (R)' }
  // ];

  constructor(props) {
    super(props)

    this.handleValidation = this.handleValidation.bind(this)
    this.validateAll = this.validateAll.bind(this)
  }

  handleValidation(key, value) {
    this.props.cellProperties.validateRecord({
      recordId: this.props.rowData.metadata.recordId,
      data: {
        [key]: value,
      },
    })
  }

  validateAll(value) {
    const data = {}
    sliceKeyNameList.forEach(({ key }) => {
      data[key] = value // key in this case is the manual slice name
    })

    this.props.cellProperties.validateRecord({
      recordId: this.props.rowData.metadata.recordId,
      data,
    })
  }

  render() {
    if (this.props.value) {
      const handleValidation = this.handleValidation
      return (
        <div style={{ width: '350px' }}>
          <ValidationTable
            sliceKeyNameList={sliceKeyNameList}
            scanValidateValues={this.props.value}
            handleValidation={handleValidation}
          />
          <div className="text-center mb-3">
            <h5>
              <strong>Validate All</strong>
            </h5>
          </div>
          <div className="text-center">
            <ValidateAllButtons validateAll={this.validateAll} />
          </div>
        </div>
      )
    }
    return <div />
  }
}

ManualValidation.propTypes = {
  value: PropTypes.object,
  cellProperties: PropTypes.shape({
    validateRecord: PropTypes.func.isRequired,
  }),
  rowData: PropTypes.shape({
    metadata: PropTypes.shape({
      recordId: PropTypes.number,
    }),
  }),
}

export default ManualValidation

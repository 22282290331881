import * as types from './types'
import action from '../middleware'

// PARQ
export const fetchPARQData = ({ clientId, recordId }) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/${recordId}/parq`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_PARQ_DATA,
        successType: types.FETCH_PARQ_DATA_SUCCESS,
        failureType: types.FETCH_PARQ_DATA_FAILURE,
      },
    },
    dispatch
  )

// PARQ Edit
export const updatePARQData = ({ clientId, recordId, content }) => (
  dispatch
) => {
  const data = { recordId, content }
  return action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/${recordId}/parq`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: types.UPDATE_PARQ_DATA,
        successType: types.UPDATE_PARQ_DATA_SUCCESS,
        failureType: types.UPDATE_PARQ_DATA_FAILURE,
      },
    },
    dispatch
  )
}

// PARQ Interest
export const getPARQInterest = (clientId, recordId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/${recordId}/parq/status`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_PARQ_INTEREST,
        successType: types.FETCH_PARQ_INTEREST_SUCCESS,
        failureType: types.FETCH_PARQ_INTEREST_FAILURE,
      },
    },
    dispatch
  )

export const updatePARQInterest = ({ clientId, recordId, data }) => (
  dispatch
) =>
  action(
    {
      endpoint: `/v1/facility/clients/${clientId}/records/${recordId}/parq/status`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: types.UPDATE_PARQ_INTEREST,
        successType: types.UPDATE_PARQ_INTEREST_SUCCESS,
        failureType: types.UPDATE_PARQ_INTEREST_FAILURE,
      },
    },
    dispatch
  )

import React from 'react'
import PropTypes from 'prop-types'

import MeasurementsTable from './MeasurementsTable'
import MetricsTable from './metrics'
import Metadata from './metadata'

export default function Stats(props) {
  const {
    cellProperties: { showWellness, showMetadata },
  } = props
  const { measurements, wellnessMetrics } = props.rowData.stats
  return (
    <div>
      <MeasurementsTable measurements={measurements} />
      {showWellness && <MetricsTable wellnessMetrics={wellnessMetrics} />}
      {showMetadata && (
        <div>
          <h5 className="mb-3 text-center">
            <strong>Metadata</strong>
          </h5>
          <Metadata {...props} />
        </div>
      )}
    </div>
  )
}

Stats.propTypes = {
  rowData: PropTypes.object,
  cellProperties: PropTypes.shape({
    showWellness: PropTypes.bool,
    showMetadata: PropTypes.bool,
  }),
}

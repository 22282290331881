import React from 'react'

export default function QcRecordsHeader(props) {
  return (
    <div style={{ marginBotton: '25px', borderBottom: '1px #eee solid' }}>
      <h6 className="dashhead-subtitle">Fit3D Admin</h6>
      <h3 className="dashhead-title">Manufacturing QC</h3>
    </div>
  )
}

import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default class ManageAccess extends Component {
  constructor(props) {
    super(props)

    this.closeModal = this.closeModal.bind(this)

    this.state = {
      accessLevel: props.value.get('accessLevel'),
      limitedModalIsOpen: false,
      fullModalIsOpen: false,
      griddleKeyUpdated: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { griddleKeyUpdated } = this.state
    const {
      griddleKey,
      cellProperties: { isUserCoachAdd, updateNotSelected },
    } = nextProps
    const manageCoachStatus = nextProps.value.get('manageCoachStatus')
    const currentAccessLevel = this.props.value.get('accessLevel')
    const nextAccessLevel = nextProps.value.get('accessLevel')

    if (griddleKey === griddleKeyUpdated && manageCoachStatus === 'success') {
      if (currentAccessLevel !== nextAccessLevel) {
        this.props.cellProperties.clearStatus()
        this.setState({
          limitedModalIsOpen: false,
          fullModalIsOpen: false,
          griddleKeyUpdated: false,
          accessLevel: nextAccessLevel,
        })
      } else if (isUserCoachAdd) {
        updateNotSelected(this.props.value.get('userId')) // this ONLY occurs in the manage section for my coaches
        this.closeModal()
      }
    }
  }

  openLimitedModal() {
    if (this.state.accessLevel !== 'limited') {
      this.setState({ limitedModalIsOpen: true })
    }
  }

  openFullModal() {
    if (this.state.accessLevel !== 'full') {
      this.setState({ fullModalIsOpen: true })
    }
  }

  closeModal() {
    this.props.cellProperties.clearStatus()
    this.setState({
      limitedModalIsOpen: false,
      fullModalIsOpen: false,
    })
  }

  handleSubmit(value) {
    const userId = this.props.value.get('userId')
    const data = {
      userId,
      accessLevel: value,
    }

    this.props.cellProperties.manageCoach(data)
    this.setState({ griddleKeyUpdated: this.props.griddleKey })
  }

  renderStatus() {
    switch (this.props.value.get('manageCoachStatus')) {
      case 'failure':
        return (
          <span className="text-danger">
            Failure to update coach access level. Please try again or contact
            support.
          </span>
        )
      case 'fetching':
        return (
          <div className="text-center">
            <strong style={{ color: '#FA9300' }}>Updating...</strong>
          </div>
        )
      default:
        return null
    }
  }

  renderModal() {
    const { fullModalIsOpen, limitedModalIsOpen } = this.state
    const { capitalize } = this.props.cellProperties

    let type = ''
    if (fullModalIsOpen) {
      type = 'full'
    }
    if (limitedModalIsOpen) {
      type = 'limited'
    }
    const imageStatus = type === 'full' ? 'including' : 'NOT'

    const handleClose = this.closeModal

    return (
      <Modal
        isOpen={fullModalIsOpen || limitedModalIsOpen}
        onHide={handleClose}
      >
        <ModalHeader closeButton>
          <h5>Confirm Access</h5>
        </ModalHeader>
        <ModalBody>
          <p>Confirm {capitalize(type)} Access</p>
          <p>
            Give your coach access to your Fit3D data <b>{imageStatus}</b> scan
            images? You can view your coach or change the coach's access at your
            My Coaches page.
          </p>
          <div>{this.renderStatus()}</div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={this.handleSubmit.bind(this, type)}
          >
            Give {capitalize(type)} Access
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {
    const { accessLevel } = this.state
    const active = 'btn btn-primary active btn-sm'
    const inactive = 'btn btn-default btn-sm'
    const buttonWidth = { width: '100px' }

    return (
      <div className="text-center px-3-0 mx-0">
        <div className="btn-group">
          <button
            type="button"
            value="limited"
            className={accessLevel === 'limited' ? active : inactive}
            onClick={this.openLimitedModal.bind(this, 'limited')}
            style={buttonWidth}
          >
            Limited
          </button>
          <button
            type="button"
            value="full"
            className={accessLevel === 'full' ? active : inactive}
            onClick={this.openFullModal.bind(this, 'full')}
            style={buttonWidth}
          >
            Full
          </button>
        </div>
        {this.renderModal()}
      </div>
    )
  }
}

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export default function CreateFacilityModal({ modalOpen, body, onClose }) {
  return (
    <Modal isOpen={modalOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <div>Create New Facility</div>
        <small>
          Email will <b>NOT</b> be sent to Purchaser POC using billing contact
          info below.
        </small>
      </ModalHeader>
      <ModalBody className="px-0">{body}</ModalBody>
    </Modal>
  )
}

import * as SessionApi from '../lib/api/session'
import { getWebAuth } from '../lib/auth'

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const AUTH_PROFILE_SUCCESS = 'AUTH_PROFILE_SUCCESS'
export const AUTH_PROFILE_FAIL = 'AUTH_PROFILE_FAIL'

export const oauth2Login = (authResult) => {
  // always capture the token into local storage on a login
  window.localStorage.setItem('token', authResult.accessToken)
  window.localStorage.setItem('id_token', authResult.idToken)
  window.localStorage.setItem(
    'expires_at',
    `${authResult.expiresIn * 1000 + new Date().getTime()}`
  )

  // THUNK
  return (dispatch) => {
    // grab user profile information from auth0 immediately after login
    const webAuth = getWebAuth(window.location.href)
    webAuth.client.userInfo(authResult.accessToken, (error, profile) => {
      if (profile) {
        dispatch({
          type: AUTH_PROFILE_SUCCESS,
          profile,
        })
      } else {
        dispatch({
          type: AUTH_PROFILE_FAIL,
          error,
        })
      }
    })

    // validate the token we received with the backend
    SessionApi.current()
      .then((session) => {
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          token: authResult.accessToken,
          session,
        })
      })
      .catch((error) => {
        // given that our validation failed, lets purge the token we just set
        window.localStorage.removeItem('token')

        dispatch({
          type: AUTH_LOGIN_FAILURE,
          error,
        })
      })
  }
}

export const oauth2Logout = () => (dispatch) => {
  // grab our current token, if it exists
  const token = window.localStorage.getItem('token')

  window.localStorage.clear()

  getWebAuth(window.location.origin).logout({
    returnTo: window.location.origin,
  })

  dispatch({
    type: AUTH_LOGOUT,
    token,
  })
}

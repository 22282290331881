import * as RestAPI from '../restApi'

// fetch (and thus validate) the current user session
export const current = () => {
  return new Promise((resolve, reject) => {
    if (!window.localStorage.getItem('token')) {
      // we don't even have a token, so this is already a rejection
      reject(new Error('No session token'))
    } else {
      // session token exists, so lets validate it with the backend
      RestAPI.GET('/v1/session')
        .then((result) => {
          // apply custom work to unmarshall response (if needed)
          resolve(result)
        })
        .catch((error) => {
          // apply application specific error handling logic
          reject(error)
        })
    }
  })
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import uuid from 'uuid/v4'

import { updateWindowDimensions } from '../actions'
import { oauth2Login, oauth2Logout } from '../actions/auth'
import { fetchUser, acceptCookiePolicy } from '../actions/user'
import { resetSearchState } from '../actions/search'
import { getWebAuth } from '../lib/auth'

import NavBar from './navbar/index'
import Routes from './Routes'
import CookiePolicy from '../components/notifications/cookie'
// import ScanError from '../components/notifications/scan_error';
// import MarketplaceNotification from '../components/notifications/marketplace';
import Loading from '../components/utilities/loading'

import './app.css'

// NOTE: isAuthenticated === (window.localStorage.getItem('token') && new Date().getTime() < parseInt(window.localStorage.getItem('expires_at')))
// TODO: is there a cleaner, logical way to do this?
class App extends Component {
  constructor(props) {
    super(props)
    this.updateDimensions = this._updateDimensions.bind(this)
  }

  componentDidMount() {
    this.updateDimensions() // find initial window size

    this.setBrowserId() // set browser id, which is currently only used for the marketplace notifcation. we don't want to store this in the backend, and we want to send reminders periodically

    if (!this.props.isAuthenticated) {
      this.authenticate(this.props)
    } else {
      this.props.fetchUser()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      // this signifies a logout, so send the user to the oauth server for full logout
      this.props.oauth2Logout()
    } else if (
      !prevProps.isAuthenticated &&
      this.props.isAuthenticated &&
      window.localStorage.getItem('token')
    ) {
      this.props.fetchUser()
      if (window.localStorage.getItem('redirectPath')) {
        this.props.history.push(window.localStorage.getItem('redirectPath'))
      }
    }

    if (
      prevProps.user.get('fetchingUser') &&
      !this.props.user.get('fetchingUser')
    ) {
      const user = this.props.user.get('user')
      if (!user) {
        this.props.oauth2Logout()
      } else {
        this.setNpsScript()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  authenticate(props) {
    if (window.location.hash.indexOf('access_token') !== -1) {
      // user coming in after oauth2 login, token is on the url
      const webAuth = getWebAuth(window.location.href)

      webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = ''
          props.oauth2Login(authResult)
        } else if (err) {
          console.log('Error with token parsing', err)
        }
      })
    } else if (
      window.location.hash.indexOf('error') !== -1 &&
      window.location.hash.indexOf('unauthorized' !== -1)
    ) {
      this.props.oauth2Logout()
    } else {
      // store redirect path in local storage so we can redirect with our react app, this allows for cleaner allowed callback urls
      window.localStorage.setItem('redirectPath', window.location.pathname)

      const webAuth = getWebAuth(window.location.origin)
      webAuth.authorize()
    }
  }

  setBrowserId() {
    let browserId = window.localStorage.getItem('browserId')

    if (!browserId) {
      browserId = uuid()
      window.localStorage.setItem('browserId', browserId)
    }
  }

  setNpsScript() {
    const user = this.props.user.get('user')

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true

    const writeKey =
      user.get('coach') || user.get('facilityAdmin')
        ? process.env.REACT_APP_COACH_NPS_KEY
        : process.env.REACT_APP_USER_NPS_KEY

    script.innerHTML = `(function() { window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/satismeter.js";parent.appendChild(script);})();

      satismeter({
        writeKey: "${writeKey}",
        userId: "${user.get('userId')}", // current user unique ID (required)
        traits: {
          name: "${user.get('firstName')} ${user.get(
      'lastName'
    )}", // current user name (optional)
          email: "${user.get('email')}", // current user email (optional)
          createdAt: "${user.get(
            'dateCreated'
          )}" // when user was registered (optional)
        }
      });`

    document.head.appendChild(script)
  }

  _updateDimensions() {
    const documentElement = document.documentElement
    const body = document.getElementsByTagName('body')[0]
    const width =
      window.innerWidth || documentElement.clientWidth || body.clientWidth
    const height =
      window.innerHeight || documentElement.clientHeight || body.clientHeight

    this.props.updateWindowDimensions({
      width,
      height,
    })
  }

  render() {
    const { isAuthenticated, user, notifications } = this.props

    if (!isAuthenticated) {
      return null
    } else if (user.get('fetchingUser')) {
      return <Loading />
    }

    return (
      <div className="app">
        <NavBar {...this.props} />
        <div>
          {notifications.displayCookieNotice &&
            !user.get('user').get('cookiePolicyAccepted') && <CookiePolicy />}
          {/* { notifications.displayScanErrorNotice && <ScanError /> } */}
          <Routes user={user} />
          {/* { (notifications.displayMarketplaceInfo && pathname !== '/marketplace') && <MarketplaceNotification /> } */}
        </div>
      </div>
    )
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.shape({
    displayMarketplaceInfo: PropTypes.bool.isRequired,
    displayCookieNotice: PropTypes.bool.isRequired,
  }).isRequired,
  updateWindowDimensions: PropTypes.func.isRequired,
  oauth2Login: PropTypes.func.isRequired,
  oauth2Logout: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  acceptCookiePolicy: PropTypes.func.isRequired,
  resetSearchState: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
  user: state.user,
  notifications: state.notifications,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateWindowDimensions,
      oauth2Login,
      oauth2Logout,
      fetchUser,
      acceptCookiePolicy,
      resetSearchState,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)

import * as types from './types'
import action from '../middleware'

// Signup
export const signup = (params) => (dispatch) =>
  action(
    {
      endpoint: '/v1/auth/users/web',
      method: 'POST',
      data: params,
      actionTypes: {
        requestType: types.SIGNUP,
        successType: types.SIGNUP_SUCCESS,
        failureType: types.SIGNUP_FAILURE,
      },
    },
    dispatch
  )

// Session
export const login = () => ({
  type: types.LOGIN,
  payload: true,
})

export const logout = () => ({
  type: types.LOGOUT,
  payload: true,
})

// Cookie Policy
export const acceptCookiePolicy = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/user/policy',
      method: 'POST',
      data: { cookiePolicyAccepted: true },
      actionTypes: {
        requestType: 'ACCEPT_COOKIE_POLICY',
        successType: 'ACCEPT_COOKIE_POLICY_SUCCESS',
        errorType: 'ACCEPT_COOKIE_POLICY_FAILURE',
      },
    },
    dispatch
  )

// User Profile
export const fetchUser = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/user',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_USER,
        successType: types.FETCH_USER_SUCCESS,
        failureType: types.FETCH_USER_FAILURE,
      },
    },
    dispatch
  )

export const updateUser = (params) => (dispatch) => {
  return action(
    {
      endpoint: '/v1/user',
      method: 'POST',
      data: params,
      actionTypes: {
        requestType: types.UPDATE_USER,
        successType: types.UPDATE_USER_SUCCESS,
        failureType: types.UPDATE_USER_FAILURE,
      },
    },
    dispatch
  )
}

export const updatePassword = (passwordData) => (dispatch) => {
  return action(
    {
      endpoint: '/v1/user/password',
      method: 'POST',
      data: passwordData,
      actionTypes: {
        requestType: types.UPDATE_PASSWORD,
        successType: types.UPDATE_PASSWORD_SUCCESS,
        failureType: types.UPDATE_PASSWORD_FAILURE,
      },
    },
    dispatch
  )
}

export const clearEditMessage = () => ({
  type: types.CLEAR_MESSAGE,
})

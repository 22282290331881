import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'

import { convertPercentage, findMax } from '../../../functions/chart'

// import data from '../../../helpers/assessment.js';

export default class BSRChart extends Component {
  constructor(props) {
    super(props)
    let height = props.width > 989 && props.width < 1200 ? 120 : 180
    height = props.width < 414 ? 150 : height
    height = props.width < 321 ? 110 : height

    const data = props.gender === 'Female' ? props.data.female : props.data.male
    const parsedData = convertPercentage(data)

    this.config = {
      chart: {
        type: 'areaspline',
        height: height,
        width: props.maxWidth,
        style: {
          fontFamily: 'Helvetica',
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '%',
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        title: {
          text: props.title,
        },
        plotLines: [
          {
            color: '#ff8a8a',
            value: props.value,
            width: 2,
            zIndex: 4,
            dashStyle: 'ShortDash',
          },
        ],
      },
      yAxis: {
        gridLineColor: 'transparent',
        title: {
          text: '% of Scans',
        },
        max: findMax(parsedData) + 1,
        tickInterval: (findMax(parsedData) + 1) / 2,
      },
      series: [
        {
          name: 'Distribution',
          data: parsedData,
          color: '#6ccbdf',
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps) {
    this.config.xAxis.plotLines = [
      {
        color: '#ff8a8a',
        value: nextProps.value,
        width: 2,
        zIndex: 4,
        dashStyle: 'ShortDash',
      },
    ]
    this.config.xAxis.title.text = nextProps.title

    const data =
      nextProps.gender === 'Female'
        ? nextProps.data.female
        : nextProps.data.male
    const parsedData = convertPercentage(data)
    this.config.yAxis.max = findMax(parsedData) + 1
    // console.log(parsedData);
    this.config.yAxis.tickInterval = (findMax(parsedData) + 1) / 2
    this.config.series = [
      {
        name: 'Distribution',
        data: parsedData,
        color: '#6ccbdf',
      },
    ]
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data || this.props.value !== nextProps.value
    )
  }

  render() {
    const histogramStyle = {
      width: '100%!important',
      zIndex: 0,
      height: '60%',
    }

    return (
      <div className="p-0" style={histogramStyle}>
        <ReactHighcharts config={this.config} />
      </div>
    )
  }
}

BSRChart.propTypes = {
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  value: PropTypes.number,
  gender: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
}

import React from 'react'
import { connect } from 'react-redux'

import { fetchUsers } from '../../../actions/admin/users'
import { fetchFacilities } from '../../../actions/admin/facilities'

import Loading from '../../utilities/loading'
import Search from '../Search'
import Pagination from '../Pagination'
import Header from './Header'
import UsersTable from './UsersTable'
import EmailEditModal from './emailEdit/Modal'
import FacilityEditModal from './facilityEdit/Modal'
import MergeUsers from './merge'

const searchOptions = [
  { value: 'email', label: 'E-mail' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'facilityName', label: 'Facility' },
]

const AdminUsers = ({ fetchingFacilities, fetchUsers, fetchFacilities }) => {
  React.useEffect(() => {
    fetchUsers({
      page: 0,
      size: 10,
    })

    fetchFacilities({
      page: 0,
      size: 10000,
    })
  }, [])

  const handleFetech = (params) => {
    fetchUsers(params)
  }

  return (
    <div className="mx-4">
      <Header />
      <div className="mb-5 small-padding">
        <Search options={searchOptions} fetchData={handleFetech} />
        {fetchingFacilities ? (
          <Loading />
        ) : (
          <>
            <UsersTable />
            <EmailEditModal />
            <FacilityEditModal />
            <div className="row mx-0 d-flex justify-content-end">
              <Pagination fetchData={handleFetech} />
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: '100px' }}>
        <MergeUsers />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  fetchingFacilities: state.newAdmin.facilities.fetchingFacilities,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (params) => dispatch(fetchUsers(params)),
  fetchFacilities: (params) => dispatch(fetchFacilities(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)

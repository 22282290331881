import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { createFacility } from '../../../../actions/admin/facilities'

import CreateFacilityModal from './FacilityCreateModal'
import FacilityEditForm from '../Form'

const FacilityCreate = ({
  isSubmitting,
  facilities,
  errorMessage,
  createFacility,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [data, setData] = React.useState({
    name: '',
    company: '',
    bfpAccess: 0,
    postureAccess: 0,
    addressCity: '',
    addressState: '',
    addressZip: '',
    activationCode: '',
  })

  React.useEffect(() => {
    if (facilities[0] && facilities[0].name === data.name) {
      handleClose()
    }
  }, [facilities])

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleUpdate = (event) => {
    const { name, value } = event.target

    setData((oldData) => ({
      ...oldData,
      [name]: value,
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const { scannerCode, ...dataToSubmit } = data

    createFacility(dataToSubmit)
  }

  return (
    <>
      <button className="btn btn-outline-primary mb-3" onClick={handleOpen}>
        Create New Facility
      </button>
      <CreateFacilityModal
        body={
          <FacilityEditForm
            isSubmitting={isSubmitting}
            data={data}
            submitText="Create Facility"
            onClose={handleClose}
            onUpdate={handleUpdate}
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
          />
        }
        modalOpen={modalOpen}
        onClose={handleClose}
      />
    </>
  )
}
FacilityCreate.propTypes = {
  createFacility: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isSubmitting: state.newAdmin.facilities.isSubmitting,
  facilities: state.newAdmin.facilities.list,
  errorMessage: state.newAdmin.facilities.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
  createFacility: (params) => dispatch(createFacility(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityCreate)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
// import { connect } from 'react-redux';
import moment from 'moment'

import AccessRequest from './action_menu/access_request'
import InterestLevelModal from './action_menu/interest_level_modal'
import ScanImage from '../scans/scan_image'
// import SnapImage from '../scans/snap_image'
import FileDownload from '../utilities/file_download'

import './coach.css'

class Actions extends Component {
  constructor(props) {
    super(props)

    this.openAccessRequestModal = this.openAccessRequestModal.bind(this)
    this.closeAccessRequestModal = this.closeAccessRequestModal.bind(this)
    this.openInterestLevelModal = this.openInterestLevelModal.bind(this)
    this.closeInterestLevelModal = this.closeInterestLevelModal.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleAccessRequest = this.handleAccessRequest.bind(this)

    this.state = {
      actionsShown: false,
      scanShown: false,
      isAccessRequestModalOpen: false,
      isInterestLevelModalOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value.get('message') === 'success') {
      this.closeAccessRequestModal()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only triggers render if action menu is being opened or closed, OR if current menu is open
    const actionsMenuStateChanged =
      this.state.actionsShown !== nextState.actionsShown
    const scanMenuStateChanged = this.state.scanShown !== nextState.scanShown
    const accessRequestStateChanged =
      this.state.isAccessRequestModalOpen !== nextState.isAccessRequestModalOpen
    return (
      nextState.actionsShown ||
      nextState.scanShown ||
      actionsMenuStateChanged ||
      scanMenuStateChanged ||
      accessRequestStateChanged
    )
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  redirectPage(route) {
    this.props.cellProperties.redirectPage(route)
  }

  closePanel(type, typeShown, isOtherTypeOpen) {
    const { cellProperties } = this.props
    this.setState({ [typeShown]: false }) // close panel depending on the menu type

    if (!cellProperties.actionMenuProps[isOtherTypeOpen]) {
      // close other menu if opening a menu of a different type (i.e closes action menu if you're opening a scan menu)
      cellProperties.handleModalDisplay(type, false, null)
    }
  }

  // we only want one window open at a time (no multiple action menus, and no scan panel if action is open)
  handleAction({ type, otherType }) {
    const {
      cellProperties,
      rowData: { griddleKey },
    } = this.props
    const types = {
      actionMenu: {
        typeShown: 'actionsShown',
        otherTypeShown: 'scanShown',
        isSameTypeOpen: cellProperties.actionMenuProps.isActionOpen,
        isOtherTypeOpen: cellProperties.actionMenuProps.isScanOpen,
      },
      scanMenu: {
        typeShown: 'scanShown',
        otherTypeShown: 'actionsShown',
        isSameTypeOpen: cellProperties.actionMenuProps.isScanOpen,
        isOtherTypeOpen: cellProperties.actionMenuProps.isActionOpen,
      },
    }

    const {
      isSameTypeOpen,
      isOtherTypeOpen,
      typeShown,
      otherTypeShown,
    } = types[type]
    const rowOpen = cellProperties.actionMenuProps.rowOpen
    const isSameRow = typeof rowOpen !== 'number' || rowOpen === griddleKey // will return true if it's the first time a user clicks a button or if the user is clicking within the same row

    // if we're on the same, we need to close the other menu if it's open
    if (isSameRow && isOtherTypeOpen) {
      this.closePanel(otherType, otherTypeShown, isOtherTypeOpen) // close scan panel while opening action menu
    }

    // open menu if there currently isn't a same type menu open
    if (isSameRow && !this.state[typeShown] && !isSameTypeOpen) {
      this.setState({ [typeShown]: true })
      cellProperties.handleModalDisplay(type, true, griddleKey)
    } else if (isSameRow && this.state[typeShown]) {
      this.closePanel(type, typeShown, isOtherTypeOpen) // clicking on the current component's button closes current menu
    }
  }

  handleAccessRequest() {
    const clientId = this.props.value.get('clientId')
    const currentTime = new Date()
    const data = {
      clientId,
      accessRequestDate: moment
        .utc(currentTime)
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
    }

    this.props.cellProperties.manageClient(data)
  }

  openAccessRequestModal() {
    this.setState({ isAccessRequestModalOpen: true })
  }

  closeAccessRequestModal() {
    this.props.cellProperties.clearMessage()
    this.setState({ isAccessRequestModalOpen: false })
  }

  openInterestLevelModal() {
    this.setState({ isInterestLevelModalOpen: true })
  }

  closeInterestLevelModal() {
    this.setState({ isInterestLevelModalOpen: false })
  }

  // add reference to menu we are about to open
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  // only trigger the close if there is a reference to menu, if you click outside menu, and if modals aren't open
  handleClickOutside(event) {
    const noModalsOpen =
      !this.state.isAccessRequestModalOpen &&
      !this.state.isInterestLevelModalOpen
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      noModalsOpen
    ) {
      if (this.state.actionsShown) {
        this.closePanel('actionMenu', 'actionsShown', false)
      }
      if (this.state.scanShown) {
        this.closePanel('scanMenu', 'scanShown', false)
      }
    }
  }

  renderActionMenu(clientId) {
    if (this.state.actionsShown) {
      const {
        value,
        rowData: { name, status },
      } = this.props
      const handleAcessRequestOpen = this.openAccessRequestModal
      const handleAccessRequest = this.handleAccessRequest
      const handleInterestLevelOpen = this.openInterestLevelModal
      return (
        <div
          className="card card-primary actions-panel"
          ref={this.setWrapperRef}
          style={{ background: 'white' }}
        >
          <div className="card-header actions-panel-heading">
            <div className="arrow-right" />
            Actions
          </div>
          <ul className="list-group actions-panel-list-group">
            <li
              className="list-group-item"
              onClick={this.redirectPage.bind(
                this,
                `/staff/clients/${clientId}/records`
              )}
            >
              <span className="icon icon-folder" />
              &nbsp;&nbsp;Client Scan History
            </li>
            <li
              className="list-group-item"
              onClick={this.redirectPage.bind(
                this,
                `/staff/clients/${clientId}/comparison/wellness`
              )}
            >
              <span className="icon icon-users" />
              &nbsp;&nbsp;Client Comparison
            </li>
            <li className="list-group-item" onClick={handleAcessRequestOpen}>
              <span className="icon icon-man" />
              &nbsp;&nbsp;Request Client Access
              <AccessRequest
                clientId={clientId}
                name={name}
                isAccessRequestModalOpen={this.state.isAccessRequestModalOpen}
                closeAccessRequestModal={this.closeAccessRequestModal}
                accessRequestDate={value.get('accessRequestDate')}
                handleAccessRequest={handleAccessRequest}
                message={value.get('message')}
              />
            </li>
            {status.toLowerCase() === 'complete' && (
              <FileDownload
                linkId={'clientHistory' + clientId}
                src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/measurements/download`}
                // src={ `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${value.get('recordId')}/scan/download` }
                style={{ width: '100%' }}
              >
                <li className="px-0 list-group-item">
                  <button
                    type="submit"
                    style={{ border: 'none', background: 'transparent' }}
                  >
                    <span className="icon icon-download" />
                    &nbsp;&nbsp;Download Measurements
                  </button>
                </li>
              </FileDownload>
            )}
            <li className="list-group-item" onClick={handleInterestLevelOpen}>
              <span className="icon icon-emoji-happy" />
              &nbsp;&nbsp;Interest Level
              <InterestLevelModal
                clientId={value.get('clientId')}
                recordId={value.get('recordId')}
                getPARQInterest={this.props.cellProperties.getPARQInterest}
                updatePARQInterest={
                  this.props.cellProperties.updatePARQInterest
                }
                isInterestLevelModalOpen={this.state.isInterestLevelModalOpen}
                closeInterestLevelModal={this.closeInterestLevelModal}
              />
            </li>
          </ul>
        </div>
      )
    }
    return null
  }

  renderScan(clientId, recordId) {
    if (this.state.scanShown) {
      const { accessLevel, status } = this.props.rowData

      let image
      if (accessLevel === 'Full' && status === 'Complete') {
        image = (
          <ScanImage
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/blank`}
            style={{ width: '100%' }}
          />
        )
      } else if (accessLevel === 'Limited' && status === 'Complete') {
        image = (
          <ScanImage
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/slice`}
            style={{ width: '100%' }}
          />
        )
      } else {
        image = (
          <span>
            Scan {status.toLowerCase()}, preview only available when scan is
            completed.
          </span>
        )
      }

      return (
        <div
          className="card card-primary actions-panel"
          ref={this.setWrapperRef}
          style={{ background: 'white' }}
        >
          <div className="card-heading actions-panel-heading">
            <div className="arrow-right" />
            Scan
          </div>
          <div className="card-body">{image}</div>
        </div>
      )
    }
    return null
  }

  render() {
    const clientId = this.props.value.get('clientId')
    const recordId = this.props.value.get('recordId')
    const { actionsShown, scanShown } = this.state

    const clientPARQroute = `/staff/clients/${clientId}/records/${recordId}/parq`
    const clientReportURL = `/staff/clients/${clientId}/records/${recordId}/report`

    const buttonStyle = actionsShown ? 'btn-primary' : 'btn-outline-primary'
    // const buttonText = actionsShown ? 'Hide Actions' : 'Show Actions';
    const scanButtonStyle = scanShown ? 'btn-primary' : 'btn-outline-primary'
    const scanButtonIcon = scanShown
      ? 'icon icon-eye-with-line'
      : 'icon icon-eye'

    return (
      <div className="text-center">
        <div className="btn-group mx-auto actions-container" role="group">
          <button
            className={`btn ${scanButtonStyle} btn-sm action-button`}
            onClick={this.handleAction.bind(this, {
              type: 'scanMenu',
              otherType: 'actionMenu',
            })}
          >
            <span className={scanButtonIcon} />
            <div className="tooltip">
              <div className="arrow-down" />
              <span>Scan Preview</span>
            </div>
          </button>
          <button
            className="btn btn-outline-primary btn-sm action-button"
            onClick={this.redirectPage.bind(this, clientPARQroute)}
          >
            ParQ
            <div className="tooltip">
              <div className="arrow-down" />
              <span>PARQ Survey</span>
            </div>
          </button>
          <button
            className="btn btn-outline-primary btn-sm action-button"
            onClick={this.redirectPage.bind(this, clientReportURL)}
          >
            <span className="icon icon-clipboard" />
            <div className="tooltip">
              <div className="arrow-down" />
              <span>Client Report</span>
            </div>
          </button>
          <button
            className={`btn ${buttonStyle} btn-sm action-button`}
            onClick={this.handleAction.bind(this, {
              type: 'actionMenu',
              otherType: 'scanMenu',
            })}
          >
            <span className="icon icon-menu" />
            <div className="tooltip">
              <div className="arrow-down" />
              <span>More Actions</span>
            </div>
          </button>

          {this.renderActionMenu(clientId)}
          {this.renderScan(clientId, recordId)}
        </div>
      </div>
    )
  }
}

Actions.propTypes = {
  value: ImmutablePropTypes.mapContains({
    hasPARQ: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    recordId: PropTypes.number.isRequired,
    accessRequestDate: PropTypes.string,
    message: PropTypes.string,
  }),
  cellProperties: PropTypes.shape({
    redirectPage: PropTypes.func,
    handleModalDisplay: PropTypes.func,
    manageClient: PropTypes.func,
    getPARQInterest: PropTypes.func,
    updatePARQInterest: PropTypes.func,
    clearMessage: PropTypes.func,
  }),
  // pageProperties: ImmutablePropTypes.mapContains({
  //   rowOpen: PropTypes.number,
  //   isActionOpen: PropTypes.bool.isRequired,
  //   isScanOpen: PropTypes.bool.isRequired
  // }),
  rowData: PropTypes.shape({
    griddleKey: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    accessLevel: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
}

// const mapStateToProps = (state) => ({ pageProperties: state.get('pageProperties') });

export default Actions

import React from 'react'
import PropTypes from 'prop-types'

import './wellness.css'

const className = 'col-sm-4 text-center'

const Categories = (props) => (
  <div className="row mx-0 wellness-header">
    <div
      className={`${className} ${String(
        props.selectedCategory === 'bodyShape'
      )}`}
      onClick={() => props.updateSelected('selectedCategory', 'bodyShape')}
    >
      Body Shape
    </div>
    <div
      className={`${className} ${String(
        props.selectedCategory === 'bodyComp'
      )}`}
      onClick={() => props.updateSelected('selectedCategory', 'bodyComp')}
    >
      Body Composition
    </div>
    <div
      className={`${className} ${String(
        props.selectedCategory === 'fitnessLevel'
      )}`}
      onClick={() => props.updateSelected('selectedCategory', 'fitnessLevel')}
    >
      Fitness Level
    </div>
  </div>
)

Categories.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  updateSelected: PropTypes.func.isRequired,
}

export default Categories

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import NoTranslate from '../../hocs/NoTranslate'

const getMinuteDifference = (date1, date2) => (date2 - date1) / (1000 * 60)

const StatusText = (message) => {
  switch (message) {
    case 'fetching':
      return <div className="text-center mt-3">Requesting Access...</div>
    case 'failure':
      return (
        <div className="text-danger">
          Failed to request client access. Please contact support or try again.
        </div>
      )
    default:
      return null
  }
}

const CustomModalBody = (accessRequestDate, minuteDifference) => {
  const formattedDate = moment
    .utc(accessRequestDate)
    .utcOffset(moment().utcOffset())
    .format('YYYY-MM-DD (hh:mm A)')
  if (accessRequestDate && minuteDifference < 30) {
    return (
      <span>
        You have already requested access for this client. Your last request was
        sent on <NoTranslate>{formattedDate}</NoTranslate>. Please wait{' '}
        {30 - Math.floor(minuteDifference)} minutes before trying again.
      </span>
    )
  } else if (accessRequestDate) {
    return (
      <span>
        The last request was sent on <NoTranslate>{formattedDate}</NoTranslate>.
        By clicking "request", you will send a request to the selected client.
      </span>
    )
  } else {
    return 'By clicking "request", you will send a request to the selected client.'
  }
}

const AccessRequest = (props) => {
  const {
    name,
    isAccessRequestModalOpen,
    closeAccessRequestModal,
    handleAccessRequest,
    accessRequestDate,
    message,
  } = props
  const minuteDifference = getMinuteDifference(
    moment.utc(accessRequestDate).utcOffset(moment().utcOffset()),
    Date.now()
  )
  const disabled = accessRequestDate && minuteDifference < 30

  return (
    <Modal isOpen={isAccessRequestModalOpen}>
      <ModalHeader toggle={closeAccessRequestModal}>
        <div>
          <h3>
            Request Full Access for&nbsp;
            <NoTranslate>{name}</NoTranslate>
          </h3>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          {CustomModalBody(accessRequestDate, minuteDifference)}
        </div>
        <div>
          <small>
            <i>You may send one request to a client every 30 minutes.</i>
          </small>
        </div>
        <div>{StatusText(message)}</div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={closeAccessRequestModal}>
          Close
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={handleAccessRequest}
        >
          Request
        </button>
      </ModalFooter>
    </Modal>
  )
}

AccessRequest.propTypes = {
  name: PropTypes.string,
  isAccessRequestModalOpen: PropTypes.bool.isRequired,
  closeAccessRequestModal: PropTypes.func.isRequired,
  handleAccessRequest: PropTypes.func.isRequired,
  accessRequestDate: PropTypes.string,
  message: PropTypes.string,
  clearMessage: PropTypes.func,
}

export default AccessRequest

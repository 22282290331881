import React, { Component } from 'react'
import _ from 'lodash'

// import AdditionalForm from './additional_form';
import Form from './form'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import './client_history.css'

export default class Additional extends Component {
  constructor(props) {
    super(props)

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.getDefaultValue = this.getDefaultValue.bind(this)
    this.updateInputData = this.updateInputData.bind(this)
    this.handleAdditionalSubmit = this.handleAdditionalSubmit.bind(this)

    this.state = {
      additionalData: props.rowData.additionalMetrics.additionalMetrics,
      modalIsOpen: false,
      inputData: {},
      updatedCellKey: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { message, additionalMetrics } = nextProps.rowData.additionalMetrics
    const { updatedCellKey, additionalData } = this.state
    let nextAdditionalMetrics = additionalMetrics

    if (message && message === 'success') {
      this.closeModal()
    }

    if (
      additionalMetrics &&
      Number.isInteger(updatedCellKey) &&
      updatedCellKey === nextProps.griddleKey
    ) {
      if (additionalData) {
        nextAdditionalMetrics = this.updateAdditionalMetricsLocally(
          additionalData,
          additionalMetrics
        )
      }
    }
    this.setState({ additionalData: nextAdditionalMetrics })
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.props.cellProperties.clearMessage()
    this.setState({ modalIsOpen: false })
  }

  parseComma(string) {
    return string ? string.toString().replace(/,/g, '.') : string
  }

  updateAdditionalMetricsLocally(
    currentAdditionalMetrics,
    nextAdditionalMetrics
  ) {
    const results = []
    currentAdditionalMetrics.forEach((additionalMetric) => {
      const nextAdditionalMetric = _.find(
        nextAdditionalMetrics,
        (metric) => metric.typeId === additionalMetric.typeId
      )
      if (nextAdditionalMetric) {
        results.push(nextAdditionalMetric)
        _.remove(
          nextAdditionalMetrics,
          (metric) => metric.typeId === nextAdditionalMetric.typeId
        )
      } else {
        results.push(additionalMetric)
      }
    })
    return results.concat(nextAdditionalMetrics)
  }

  handleAdditionalSubmit() {
    const { userId, recordId } = this.props.rowData.stats
    const currentAdditionalMetrics = this.createAdditionalList(
      this.state.additionalData
    )
    const currentLabels = this.obtainCurrentLabels(currentAdditionalMetrics)
    const facilityAdditionalMetrics = this.props.cellProperties.metrics.facility

    const data = this.state.inputData
    const currentMetrics = currentAdditionalMetrics.map((metric) => {
      return {
        id: metric.id,
        typeId: metric.typeId,
        value: metric.value,
      }
    })

    for (const metricLabel in data) {
      if (_.includes(currentLabels, metricLabel)) {
        const additionalMetric = _.find(
          currentAdditionalMetrics,
          (metric) => metric.label === metricLabel
        )
        const index = currentMetrics.findIndex(
          (metric) => metric.id === additionalMetric.id
        )

        currentMetrics[index].value = parseFloat(
          this.parseComma(data[metricLabel])
        )
      } else {
        const additionalMetric = _.find(
          facilityAdditionalMetrics,
          (metric) => metric.label === metricLabel
        )
        currentMetrics.push({
          typeId: additionalMetric.typeId,
          value: parseFloat(this.parseComma(data[metricLabel])),
        })
      }
    }

    this.setState({ updatedCellKey: this.props.griddleKey })
    this.props.cellProperties.updateClientAdditional({
      values: currentMetrics,
      userId,
      recordId,
    })
  }

  createAdditionalList(additionalData) {
    const additionalMetricsList = []
    for (const additionalMetric in additionalData) {
      additionalMetricsList.push(additionalData[additionalMetric])
    }
    return additionalMetricsList
  }

  obtainCurrentLabels(additionalMetrics) {
    return additionalMetrics.map((metric) => metric.label)
  }

  renderTable(data) {
    return (
      <tr key={_.uniqueId()}>
        <td style={{ width: '50%' }}>{data.name}</td>
        <td style={{ width: '50%' }}>
          <span isolate>{data.value}</span>
          <span isolate>{` ${data.units}`}</span>
        </td>
      </tr>
    )
  }

  renderAddButton() {
    if (this.props.rowData.wellnessMetrics.wellnessMetrics) {
      const handleOpen = this.openModal
      return (
        <div
          id="add-additional-button"
          className="mt-3 text-center"
          onClick={handleOpen}
        >
          <button className="btn btn-outline-primary btn-sm btn-block">
            <span className="icon icon-circle-with-plus circle-plus" />
            Add/Edit Metric(s)
          </button>
        </div>
      )
    }
    return null
  }

  updateInputData(type, value) {
    const { inputData } = this.state
    inputData[type] = value
  }

  getDefaultValue(metric) {
    const existingMetric = _.find(
      this.state.additionalData,
      (data) => data.typeId === metric.typeId
    )
    return existingMetric ? existingMetric.value : null
  }

  renderModal() {
    const facilityMetrics = this.props.cellProperties.metrics.facility
    const sortedMetrics = _.sortBy(facilityMetrics, (metric) => metric.sort)
    const { inputData, modalIsOpen } = this.state
    const { message, metricsLoading } = this.props.rowData.additionalMetrics

    const handleSubmit = this.handleAdditionalSubmit

    return (
      <Modal isOpen={modalIsOpen} toggle={this.closeModal}>
        <ModalHeader className="additional-form-header" closeButton>
          <h4>Add/Edit Additional Metrics</h4>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            Leaving a field blank when submitting will not update the metric.
          </div>
          <Form
            inputData={inputData}
            getDefaultValue={this.getDefaultValue}
            metrics={sortedMetrics}
            message={message}
            isUpdating={metricsLoading}
            updateInputData={this.updateInputData}
            handleSubmit={handleSubmit}
            closeModal={this.closeModal}
            labelWidth="420px"
          />
        </ModalBody>
      </Modal>
    )
  }

  render() {
    if (!this.props.cellProperties.metrics.facility) {
      return <div />
    }
    const { additionalData } = this.state

    let table = (
      <span id="noAdditionalText">No additional metrics for this client</span>
    )
    if (additionalData) {
      const additionalMetricsList = this.createAdditionalList(additionalData)
      const sortedAdditionalData = _.sortBy(
        additionalMetricsList,
        (metric) => metric.sort
      )
      const tableElements = sortedAdditionalData.map(
        this.renderTable.bind(this)
      )
      table = (
        <table className="table">
          <tbody>{tableElements}</tbody>
        </table>
      )
    }

    return (
      <div key={this.props.rowData.stats.recordId}>
        {table}
        {this.renderAddButton()}
        {this.renderModal()}
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'

import { updateScanValid } from '../../../actions/admin/qc'
import { formatDateTime } from '../../../lib/formatText'

import { enhancedWithRowData } from '../../griddle/rowdata_wrapper'
import Image from '../image'
import Metadata from '../metadata'
import Stats from '../MeasurementStats'
import Actions from '../ValidateActions'

const metadataKeyNameList = [
  { key: 'recordDate', name: 'Date' },
  { key: 'sliderId', name: 'Slider Id' },
  { key: 'status', name: 'Status' },
  { key: 'scanValid', name: 'Valid' },
  { key: 'recordId', name: 'Record Id' },
  { key: 'scanPackageId', name: 'pkgId' },
]

const QcRecordsTable = ({ qcRecords, pageSize, handleValidation }) => {
  // Use our custom components instead of griddle
  const components = {
    Filter: () => null,
    Pagination: () => null,
    SettingsToggle: () => null,
  }

  const styleConfig = {
    classNames: {
      Filter: 'griddle-filter form-control mb-3',
      Table: 'table table-bordered',
    },
  }

  return (
    <div className="griddle-responsive-container small-padding">
      <Griddle
        data={qcRecords.map((record) => parseData(record))}
        pageProperties={{ pageSize }}
        plugins={[plugins.LocalPlugin]}
        components={components}
        styleConfig={styleConfig}
      >
        <RowDefinition>
          <ColumnDefinition
            id="0"
            title="0"
            degree={0}
            type="blank"
            customComponent={enhancedWithRowData(Image)}
          />
          <ColumnDefinition
            id="120"
            title="120"
            degree={120}
            type="blank"
            customComponent={enhancedWithRowData(Image)}
          />
          <ColumnDefinition
            id="90"
            title="90"
            degree={90}
            type="blank"
            customComponent={enhancedWithRowData(Image)}
          />
          <ColumnDefinition
            id="metadata"
            title="Metadata"
            metadataKeyNameList={metadataKeyNameList}
            customComponent={enhancedWithRowData(Metadata)}
          />
          <ColumnDefinition
            id="stats"
            title="Stats"
            customComponent={enhancedWithRowData(Stats)}
          />
          <ColumnDefinition
            id="scanValid"
            title="Scan Valid"
            handleValidation={handleValidation}
            customComponent={enhancedWithRowData(Actions)}
          />
        </RowDefinition>
      </Griddle>
    </div>
  )
}

const parseData = (metric) => {
  return {
    0: 0,
    120: 120,
    90: 90,
    metadata: {
      recordDate: formatDateTime(metric.recordDate),
      sliderId: metric.sliderId,
      status: metric.status,
      scanValid: metric.scanValid,
      recordId: metric.id,
      scanPackageId: metric.scanPackageId,
    },
    stats: { measurements: metric.scanMeasurement },
    scanValid: metric.scanValid,
  }
}

const mapStateToProps = (state) => ({
  qcRecords: state.newAdmin.qcRecords,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = (dispatch) => ({
  handleValidation: (params) => dispatch(updateScanValid(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QcRecordsTable)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'

import ComparisonChartModal from '../../comparison/hocs/chart_modal'

class PostureComparisonChart extends Component {
  constructor(props) {
    super(props)

    const frontBaseline = this.getData('front', 'shift', 'baseline', props)
    const frontCurrent = this.getData('front', 'shift', 'current', props)

    const range = this.getChartRange(frontBaseline, frontCurrent)
    let units = props.user.get('user').get('units') === 'US' ? 'in' : 'cm'
    units = props.type === 'tilt' ? '\xB0' : units

    const that = this

    this.config = {
      chart: {
        type: 'bar',
        height: 240,
        style: {
          fontFamily: 'Helvetica',
        },
        spacingLeft: 10,
      },
      title: {
        text: '',
      },
      plotOptions: {
        lineWidth: 2,
        startOnTick: false,
        minPadding: 0,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          let shift = ''
          if (this.y < 0) {
            if (that.props.view === 'side') {
              shift = 'Backward'
            } else {
              shift = 'Left'
            }
          } else if (this.y > 0) {
            if (that.props.view === 'side') {
              shift = 'Forward'
            } else {
              shift = 'Right'
            }
          }
          return `<b>${shift}</b> ${Math.abs(this.y)} ${units}`
        },
      },
      xAxis: {
        categories: ['Head', 'Shoulder', 'Underbust', 'Hip', 'Knee'],
      },
      yAxis: {
        min: range * -1,
        max: range,
        title: {
          text: `Posture ${this.capitalize(props.view)} Shift`,
          useHTML: true,
        },
        labels: {
          overflow: 'justify',
          formatter: function () {
            return Math.abs(this.value)
          },
        },
      },
      series: [
        {
          name: 'Baseline',
          data: frontBaseline,
        },
        {
          name: 'Current',
          data: frontCurrent,
        },
      ],
    }

    this.state = { modalIsOpen: false }
  }

  componentWillReceiveProps(nextProps) {
    let { view } = nextProps
    if (view === 'back') {
      view = 'front'
    }

    const frontBaseline = this.getData(
      view,
      nextProps.type,
      'baseline',
      nextProps
    )
    const frontCurrent = this.getData(
      view,
      nextProps.type,
      'current',
      nextProps
    )

    const range = this.getChartRange(frontBaseline, frontCurrent)
    this.config.series = [
      {
        name: 'Baseline',
        data: frontBaseline,
      },
      {
        name: 'Current',
        data: frontCurrent,
      },
    ]

    // flipped the graph so negativeShift is now considered forward (points same direction as picture)

    const negativeShift = view === 'side' ? 'Forward' : 'Left'
    const positiveShift = view === 'side' ? 'Backward' : 'Right'

    this.config.title.text = `Posture ${this.capitalize(
      nextProps.view
    )} ${this.capitalize(nextProps.type)}`
    this.config.yAxis.min = range * -1
    this.config.yAxis.max = range
    this.config.yAxis.title.text = `<span class='icon icon-arrow-left'></span> ${negativeShift}&nbsp;&nbsp;&nbsp;&nbsp;${positiveShift} <span class='icon icon-arrow-right'></span>`

    let units = nextProps.user.get('user').get('units') === 'US' ? 'in' : 'cm'
    units = nextProps.type === 'tilt' ? '\xB0' : units

    this.config.tooltip.valueSuffix = ` ${units}`

    if (view === 'front') {
      this.config.xAxis.categories = [
        'Head',
        'Shoulder',
        'Underbust',
        'Hip',
        'Knee',
      ]
    } else {
      this.config.xAxis.categories = ['Head', 'Shoulder', 'Hip', 'Knee']
    }
  }

  openModal() {
    window.ga('send', 'event', 'Button', 'click', 'Zoomed in on chart ')
    this.setState({ modalIsOpen: true })
    this.config.chart.height = 400
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
    this.config.chart.height = 240
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  getData(view, type, scanType, props) {
    let postureData = props.currentPosture
      .get('posture')
      .get(view)
      .get(type)
      .toJS()
    if (scanType === 'baseline') {
      postureData = props.baselinePosture
        .get('posture')
        .get(view)
        .get(type)
        .toJS()
    }
    const data = []
    for (var part in postureData) {
      let value = postureData[part]
      // we want the forward to go in the same direction the side view is facing, so multiply by -1
      if (view === 'side') {
        value = value * -1
      }
      data.push(value)
    }
    return data
  }

  getChartRange(baseline, current) {
    const absoluteValData = baseline.concat(current).map((val) => Math.abs(val))
    return Math.max.apply(this, absoluteValData)
  }

  render() {
    return (
      <ComparisonChartModal
        width={this.props.width}
        config={this.config}
        header={`Posture ${this.capitalize(this.props.view)} ${this.capitalize(
          this.props.type
        )}`}
      >
        <ReactHighcharts config={this.config} />
      </ComparisonChartModal>
    )
  }
}

PostureComparisonChart.propTypes = {
  data: PropTypes.array,
  currentPosture: PropTypes.object,
  baselinePosture: PropTypes.object,
  view: PropTypes.string,
  user: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.number,
}

export default PostureComparisonChart

import React from 'react'
import PropTypes from 'prop-types'

import './balance.css'

const getColor = (pct) => {
  const percentColors = [
    { pct: 0.0, color: { r: 255, g: 255, b: 255 } },
    { pct: 0.5, color: { r: 0, g: 0, b: 255 } },
    { pct: 1.0, color: { r: 0, g: 0, b: 255 } },
  ]

  // find the intervals we're working with
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break
    }
  }

  // obtain the exact percent color interval we're working between
  const lower = percentColors[i - 1]
  const upper = percentColors[i]
  // Get the % of the gradient in relation to the range group
  const colorRangeGroup = upper.pct - lower.pct
  const rangePct = (pct - lower.pct) / colorRangeGroup
  const pctLower = 1 - rangePct
  const pctUpper = rangePct

  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  }

  return `rgb(${[color.r, color.g, color.b].join(',')})`
}

const BalanceImage = ({ balanceData, getDecimalPercent }) => {
  const weightFrontLeftPercent = balanceData.get('weightFrontLeftPercent')
  const weightBackLeftPercent = balanceData.get('weightBackLeftPercent')
  const weightFrontRightPercent = balanceData.get('weightFrontRightPercent')
  const weightBackRightPercent = balanceData.get('weightBackRightPercent')

  const whiteFont = { color: 'white' }
  const blackFont = { color: 'black' }

  return (
    <div>
      <div className="row mb-3 mx-0 text-center">
        <div className="text-center mx-auto" style={{ width: '90%' }}>
          <div className="row mx-0">
            <div className="gradient" />
          </div>
          <div className="row mx-0 px-3 gradientLegend">
            <span>0%</span>
            <strong className="mx-auto">% of weight</strong>
            <span>100%</span>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div
          className="text-center squaresContainer"
          style={{ justifyContent: 'flex-end', float: 'left' }}
        >
          <img
            className="leftFoot"
            src="https://s3.amazonaws.com/fit3d-cdn/assets/foot-left.png"
            alt=""
          />
          <div
            className="mb-3 square"
            style={{
              background: getColor(getDecimalPercent(weightFrontLeftPercent)),
            }}
          >
            <div
              className="topLeft"
              style={
                getDecimalPercent(weightFrontLeftPercent) > 0.25
                  ? whiteFont
                  : blackFont
              }
            >
              {`${weightFrontLeftPercent}%`}
            </div>
          </div>
          <div
            className="square"
            style={{
              background: getColor(getDecimalPercent(weightBackLeftPercent)),
            }}
          >
            <div
              className="bottomLeft"
              style={
                getDecimalPercent(weightBackLeftPercent) > 0.25
                  ? whiteFont
                  : blackFont
              }
            >
              {`${weightBackLeftPercent}%`}
            </div>
          </div>
        </div>
        <div
          className="ml-3 text-center squaresContainer"
          style={{ float: 'left' }}
        >
          <img
            className="rightFoot"
            src="https://s3.amazonaws.com/fit3d-cdn/assets/foot-right.png"
            alt=""
          />
          <div
            className="mb-3 square"
            style={{
              background: getColor(getDecimalPercent(weightFrontRightPercent)),
            }}
          >
            <div
              className="topRight"
              style={
                getDecimalPercent(weightFrontRightPercent) > 0.25
                  ? whiteFont
                  : blackFont
              }
            >
              {`${weightFrontRightPercent}%`}
            </div>
          </div>
          <div
            className="square"
            style={{
              background: getColor(getDecimalPercent(weightBackRightPercent)),
            }}
          >
            <div
              className="bottomRight"
              style={
                getDecimalPercent(weightBackRightPercent) > 0.25
                  ? whiteFont
                  : blackFont
              }
            >
              {`${weightBackRightPercent}%`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BalanceImage.propTypes = {
  balanceData: PropTypes.object,
  getDecimalPercent: PropTypes.func,
}

export default BalanceImage

import React from 'react'

import Input from './Input'
import SubmitButton from './SubmitButton'
import DeprecateModal from './DeprecateModal'

export default function ScannerDeprecate(props) {
  const [scannerCode, setScannerCode] = React.useState('')
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleChange = (e) => {
    setScannerCode(e.target.value)
  }

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  return (
    <>
      <h3 className="dashhead-title">Deprecate Scanner</h3>
      <div className="row mx-0">
        <Input value={scannerCode} onChange={handleChange} />
        <SubmitButton disabled={!scannerCode} onClick={handleModalOpen} />
      </div>
      <DeprecateModal
        scannerCode={scannerCode}
        modalOpen={modalOpen}
        onClose={handleModalClose}
      />
    </>
  )
}

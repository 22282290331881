import { UPDATE_SEARCH_STATE, RESET_SEARCH_STATE } from './types'

export const UPDATE_SEARCH_PARAM = 'UPDATE_SEARCH_PARAM'
export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING'

// Search state handling (params should be searchParam and searchString)
export const updateSearchState = (params) => ({
  type: UPDATE_SEARCH_STATE,
  payload: params,
})

export const updateSearchParam = (param) => (dispatch) =>
  dispatch({
    type: UPDATE_SEARCH_PARAM,
    payload: param,
  })

export const updateSearchString = (string) => (dispatch) =>
  dispatch({
    type: UPDATE_SEARCH_STRING,
    payload: string,
  })

export const resetSearchState = () => ({
  type: RESET_SEARCH_STATE,
  payload: null,
})

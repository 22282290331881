import React from 'react'

export default function InstructionsCard(props) {
  return (
    <div className="card card-primary">
      <h6
        className="card-heading text-center m-0"
        style={{
          background: '#1997c6',
          color: 'white',
          padding: '0.75rem',
        }}
      >
        Merge Users Instructions
      </h6>
      <div className="card-body">
        <p>
          Look up two separate accounts by typing in two different e-mails in
          the input fields.
        </p>
        <p>
          Select an e-mail from the drop-down. Complete merging records by
          confirming with the merge button.
        </p>
      </div>
    </div>
  )
}

import {
  FETCH_USER_COACHES,
  FETCH_USER_COACHES_SUCCESS,
  FETCH_USER_COACHES_FAILURE,
  MANAGE_USER_COACH,
  MANAGE_USER_COACH_SUCCESS,
  MANAGE_USER_COACH_FAILURE,
  CLEAR_STATUS,
} from '../../actions/userCoach/types'
import Immutable from 'immutable'

const reducers = {
  [FETCH_USER_COACHES]: (state) => {
    return state.set('loading', true)
  },
  [FETCH_USER_COACHES_SUCCESS]: (state, action) => {
    return state
      .set('userCoaches', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [FETCH_USER_COACHES_FAILURE]: (state) => {
    return state.set('loading', false)
  },

  [MANAGE_USER_COACH]: (state) => {
    return state.set('manageCoachStatus', 'fetching')
  },

  [MANAGE_USER_COACH_SUCCESS]: (state, action) => {
    const {
      meta: { userId },
      payload: { data },
    } = action
    const index = state
      .get('userCoaches')
      .findIndex((coach) => coach.get('userId') === userId)

    // when removing coach access (i.e. none), action.payload.data is null
    const accessLevel = data ? data.accessLevel : 'none'
    const updatedCoachesList = state
      .get('userCoaches')
      .update(index, (coach) => {
        return coach.set('accessLevel', accessLevel)
      })

    return state
      .set('userCoaches', updatedCoachesList)
      .set('manageCoachStatus', 'success')
  },

  [MANAGE_USER_COACH_FAILURE]: (state) => {
    return state.set('manageCoachStatus', 'failure')
  },

  [CLEAR_STATUS]: (state) => {
    return state.set('manageCoachStatus', null)
  },
}

const defaultState = new Immutable.Map({
  loading: false,
  manageCoachStatus: null,
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }

  return state
}

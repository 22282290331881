import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PostureTable from './posture_comparison_table'
import ViewButtons from '../view_buttons'
import PostureComparisonChart from './chart_container'

export default class PostureComparisonInfo extends Component {
  constructor(props) {
    super(props)

    this.changeType = this.changeType.bind(this)
    this.state = { selectedType: 'shift' }
  }

  changeType(event) {
    window.ga(
      'send',
      'event',
      'Form Event',
      'Select Changed',
      'selected posture data type'
    )
    this.setState({ selectedType: event.target.value })
  }

  renderData() {
    if (this.state.selectedType === 'tilt' && this.props.view === 'side') {
      return <div>No tilt data for side view</div>
    }

    return (
      <div>
        <PostureTable
          view={this.props.view}
          currentPosture={this.props.currentPosture}
          baselinePosture={this.props.baselinePosture}
          type={this.state.selectedType}
          user={this.props.user}
        />
        <PostureComparisonChart
          view={this.props.view}
          currentPosture={this.props.currentPosture}
          baselinePosture={this.props.baselinePosture}
          type={this.state.selectedType}
          user={this.props.user}
          width={this.props.width}
        />
      </div>
    )
  }

  render() {
    const handleChange = this.changeType
    return (
      <div>
        <div className="mb-3">
          <ViewButtons
            updateView={this.props.updateView}
            view={this.props.view}
          />
        </div>
        <select
          onChange={handleChange}
          className="custom-select"
          style={{ width: '100%', borderRadius: 0, marginBottom: '10px' }}
        >
          <option value="shift">Shift</option>
          <option value="tilt">Tilt</option>
        </select>
        {this.renderData()}
      </div>
    )
  }
}

PostureComparisonInfo.propTypes = {
  updateView: PropTypes.func,
  view: PropTypes.string,
  postureData: PropTypes.object,
  currentPosture: PropTypes.object.isRequired,
  baselinePosture: PropTypes.object.isRequired,
  user: PropTypes.object,
  width: PropTypes.number,
}

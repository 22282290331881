import { createSelector } from 'reselect'
import { convertUTC } from '../functions/text_format'

const metricsSelector = (state) => state.metrics.get('metrics')
const currentScanSelector = (state) => state.current.get('currentScan')

const getCurrentMetric = (metrics, scan) => {
  if (metrics && scan) {
    return metrics.find(
      (metric) => metric.get('scanPackageId') === scan.get('scanPackageId')
    )
  } else if (metrics) {
    return metrics
      .sortBy((metric) => convertUTC(metric.get('recordDate')))
      .reverse()
      .first()
  }

  return null
}

export default createSelector(
  metricsSelector,
  currentScanSelector,
  getCurrentMetric
)

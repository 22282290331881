import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import PageSelect from './PageSelect'

export const DEFAULT_MAX_BUTTONS = 8

export default function PaginationButtons({
  currentPage,
  maxPages,
  handleFirstPage,
  handleLastPage,
  handlePrevious,
  handleNext,
  handlePageSelect,
}) {
  return (
    <div className="pagination-container" style={{ marginLeft: 20 }}>
      <Pagination>
        <PaginationItem disabled={currentPage === 0} onClick={handleFirstPage}>
          <PaginationLink style={{ border: '1px #ced4da solid' }}>
            First
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={currentPage === 0} onClick={handlePrevious}>
          <PaginationLink previous style={{ border: '1px #ced4da solid' }} />
        </PaginationItem>
        <PageSelect
          maxPages={maxPages}
          maxButtons={DEFAULT_MAX_BUTTONS}
          currentPage={currentPage}
          handlePageSelect={handlePageSelect}
        />
        <PaginationItem
          disabled={currentPage + 1 === maxPages}
          onClick={handleNext}
        >
          <PaginationLink next style={{ border: '1px #ced4da solid' }} />
        </PaginationItem>
        <PaginationItem
          disabled={currentPage + 1 === maxPages}
          onClick={handleLastPage}
        >
          <PaginationLink style={{ border: '1px #ced4da solid' }}>
            Last
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  )
}

import React from 'react'

export default function ReloadButton({ onClick }) {
  return (
    <div className="reload-button">
      <button className="btn btn-outline-primary" onClick={onClick}>
        <span className="fas fa-sync mr-1" /> Reload
      </button>
    </div>
  )
}

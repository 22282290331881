import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import ViewButtons from './view_buttons';
import ScanDate from '../scans/scan_dates'
import ScanImage from '../scans/scan_image'

export default class PosturePanel extends Component {
  renderPostureImage() {
    const { scan, view, src } = this.props
    const packageId = scan.get('scanPackageId')
    let degree

    switch (view) {
      case 'front':
        degree = '0'
        break
      case 'side':
        degree = '90'
        break
      case 'back':
        degree = '180'
        break
      default:
        degree = '0'
    }

    return (
      <div className="mb-3">
        <ScanImage
          src={
            src ||
            `${process.env.REACT_APP_API_BASEURL}/v1/records/${packageId}/scan/img/${degree}/type/posture`
          }
          style={{ width: '100%' }}
        />
      </div>
    )
  }

  render() {
    const { title, records, type, scan, updateScan } = this.props
    const scanDateProps = {
      scan,
      records: records,
      type: type,
      updateScan,
    }

    return (
      <div className="card text-center">
        <div className="card-body m-xs-0 px-3s-0">
          {title && <h3>{title}</h3>}
          <div className="mx-auto mt-2 mb-3" style={{ width: '80%' }}>
            <div className="d-flex justify-content-center align-items-center">
              <ScanDate {...scanDateProps} />
            </div>
          </div>
          {this.renderPostureImage()}
          {/* { !comparison ? <ViewButtons view={ view } updateView={ updateView } /> : <div /> } */}
        </div>
      </div>
    )
  }
}

PosturePanel.propTypes = {
  records: PropTypes.object,
  src: PropTypes.string,
  type: PropTypes.string,
  scan: PropTypes.object,
  view: PropTypes.string,
  updateView: PropTypes.func,
  title: PropTypes.string,
  updateScan: PropTypes.func.isRequired,
  history: PropTypes.object,
}

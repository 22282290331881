import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const Description = ({ text }) => (
  <p>
    {text}
    <Link to="/glossary">&nbsp;Read More</Link>.
  </p>
)

Description.propTypes = {
  text: PropTypes.string,
}

// units US or Metric
export const metricData = (units) => ({
  bfp: {
    name: 'Body Fat Percent',
    units: '%',
    description: (
      <Description
        text={
          "Body fat percentage is the user's total fat mass divided by the total body mass. Fat is an essential component of the body, however, too much body fat can negatively affect overall health."
        }
      />
    ),
  },
  fatMass: {
    name: 'Fat Mass',
    units: ` ${units === 'US' ? 'lbs' : 'kg'}`,
    description: (
      <Description text="The total amount of fat in the body. There are multiple types of fat in the body which have very different effects on overall healthy. The body shape tab can provide more insight into how fat distribution factors into potential health risks." />
    ),
  },
  leanMass: {
    name: 'Lean Mass',
    units: ` ${units === 'US' ? 'lbs' : 'kg'}`,
    description: (
      <Description text="Lean mass is the muscle tissue, skeletal tissue, and water in the body. Lean mass burns more energy than fat mass, so increasing lean mass can help continue to reduce fat mass." />
    ),
  },
  weight: {
    name: 'Weight',
    units: ` ${units === 'US' ? 'lbs' : 'kg'}`,
    description: (
      <Description text={"Body weight is a person's mass or weight."} />
    ),
  },

  bodyShapeRating: {
    name: 'Body Shape Rating',
    units: '',
    description: (
      <Description text="Body Shape Rating (BSR) is a wellness score based on the relationship of your body shape to cardiovascular related risk factors. It answers the question, Is my body shape making it more likely for me potentially develop certain cardiovascular related health issues? BSR is on a 0-100 range where 50 is average and the higher the score the lower the risk." />
    ),
  },
  trunkLegVolRatio: {
    name: 'Trunk to Leg Volume Ratio',
    units: '',
    description: (
      <Description text="Similar to WHR where body shape is described using a ratio, but instead of waist and hips circumferences, body trunk/torso and leg volumes are used. By using volume it captures a more detailed view of body shape. A lower trunk to leg volume ratio generally means lower risk for certain health issues." />
    ),
  },

  wc: {
    name: 'Waist Circumference',
    units: ` ${units === 'US' ? 'in.' : 'cm'}`,
    description: (
      <Description text='A larger waist circumference can mean more "deep" (visceral) fat which is an unhealthy type of fat that wraps around internal organs and can lead to additional health risks. Generally a lower waist circumference is better. Waist circumference has been widely used in the past due to its simplicity.' />
    ),
  },
  whr: {
    name: 'Waist to Hip Ratio',
    units: '',
    description: (
      <Description text="Waist to hip ratio describes body shape using a ratio between waist and hips. A lower ratio generally means lower risk for certain health issues. If you have heard of body shapes described as apple or pear, these are two ends of the WHR spectrum where pear has a lower WHR ratio and therefore lower risk for certain health issues." />
    ),
  },

  bmr: {
    name: 'Basal Metabolic Rate (BMR)',
    units: '',
    description: (
      <Description text="The amount of energy your body is burning while at rest." />
    ),
  },
})

import React from 'react'

export default function PageSizeSelect({ value, onChange }) {
  return (
    <div className="d-flex page-size-select">
      <strong style={{ whiteSpace: 'nowrap', lineHeight: '35px' }}>
        Select Page Size: &nbsp;&nbsp;
      </strong>
      <select
        value={value}
        onChange={onChange}
        className="custom-select"
        style={{ height: '35px' }}
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="100">100</option>
      </select>
    </div>
  )
}

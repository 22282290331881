import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Row, NullTable } from './table'

const metricsKeyNameList = [
  { key: 'height', name: 'Height' },
  { key: 'bodyShapeRating', name: 'BSR' },
  { key: 'weight', name: 'Weight' },
  { key: 'bfp', name: 'BFP' },
]

const MetricsTable = ({ wellnessMetrics }) => {
  // const { wellnessMetrics } = this.props.rowData.stats;
  if (wellnessMetrics) {
    const table = metricsKeyNameList.map((metric) => {
      return <Row key={_.uniqueId()} datum={wellnessMetrics} data={metric} />
    })
    return (
      <div key={_.uniqueId()}>
        <table className="table p-0">
          <tbody>{table}</tbody>
        </table>
      </div>
    )
  } else {
    return <NullTable title="Wellness Metrics" />
  }
}

MetricsTable.propTypes = {
  wellnessMetrics: PropTypes.object,
}

export default MetricsTable

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import NoTranslate from '../../hocs/NoTranslate'
import FileDownload from '../../utilities/file_download'

export default class ClientInfo extends Component {
  renderStatus() {
    const { status } = this.props.rowData.stats
    let statusColor = 'text-warning'
    if (status === 'Complete') {
      statusColor = 'text-success'
    } else if (status === 'Failed' || status === 'No Scan Taken') {
      statusColor = 'text-danger'
    }
    return (
      <p id="status">
        Status: <span className={statusColor}>{status}</span>
      </p>
    )
  }

  renderActionButtons() {
    const { recordId, status } = this.props.rowData.stats
    const { clientId, accessLevel } = this.props.cellProperties
    const btnStyling = 'btn btn-primary btn-sm btn-block mx-auto'
    // {/* <form action={ `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/download` } style={ { marginTop: '5px' } }> */}
    if (status === 'Complete') {
      return (
        <div id="actionButtons" className="mt-4">
          <div className="mb-1">
            <Link
              to={`/staff/clients/${clientId}/records/${recordId}/report`}
              className={btnStyling}
            >
              View Scan Report
            </Link>
          </div>
          <div className="mb-3">
            <Link
              to={`/staff/clients/${clientId}/comparison/wellness`}
              className={btnStyling}
            >
              Client Comparison Page
            </Link>
          </div>
          {accessLevel === 'full' && (
            <FileDownload
              linkId={
                'clientHistory' + clientId + recordId + _.uniqueId() + 'obj'
              }
              src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/download?type=obj`}
              style={{ width: '100%' }}
            >
              <button
                id="downloadScanModel"
                className="btn btn-outline-primary btn-sm btn-block mx-auto mb-1"
              >
                <span className="icon icon-download" />
                &nbsp;Download Scan (OBJ)
              </button>
            </FileDownload>
          )}
          {accessLevel === 'full' && (
            <FileDownload
              linkId={
                'clientHistory' + clientId + recordId + _.uniqueId() + 'gif'
              }
              src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/download?type=gif`}
              style={{ width: '100%' }}
            >
              <button
                id="downloadScanModel"
                type="submit"
                className="btn btn-outline-primary btn-sm btn-block mx-auto mb-1"
              >
                <span className="icon icon-download" />
                &nbsp;Download Scan (GIF)
              </button>
            </FileDownload>
          )}
          {status.toLowerCase() === 'complete' && (
            <FileDownload
              linkId={'clientHistoryMeasurement' + clientId}
              src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/measurements/download`}
              // src={ `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/download?type=obj` }
              style={{ width: '100%' }}
            >
              <button
                id="downloadMeasurements"
                type="submit"
                className="btn btn-outline-primary btn-sm btn-block mx-auto"
              >
                <span className="icon icon-download" />
                &nbsp;Download Measurements
              </button>
            </FileDownload>
          )}
        </div>
      )
    }
    return null
  }

  render() {
    const { recordDate } = this.props.rowData.stats
    return (
      <div>
        <p id="date">
          Date Taken:&nbsp;
          <NoTranslate>
            {moment
              .utc(recordDate)
              .utcOffset(moment().utcOffset())
              .format('YYYY-MM-DD (hh:mm A)')}
          </NoTranslate>
        </p>
        {this.renderStatus()}
        {this.renderActionButtons()}
      </div>
    )
  }
}

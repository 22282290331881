import React from 'react'
import PropTypes from 'prop-types'

const ScanStatus = ({ value }) => {
  let colorText
  switch (value) {
    case 'Failed':
      colorText = 'text-danger'
      break
    case 'Complete':
      colorText = 'text-success'
      break
    default:
      colorText = 'text-warning'
  }

  return <div className={colorText}>{value}</div>
}

ScanStatus.propTypes = {
  value: PropTypes.string.isRequired,
}

export default ScanStatus

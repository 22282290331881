import * as AdminApi from '../../lib/api/admin'
import * as AdminUserApi from '../../lib/api/admin/users'
import { setPageInfo } from '../pagination'
import action from '../middleware'
import { getQueryString } from './utils/functions'

export const ADMIN_FETCH_USERS = 'ADMIN_FETCH_USERS'
export const ADMIN_FETCH_USERS_SUCCESS = 'ADMIN_FETCH_USERS_SUCCESS'
export const ADMIN_FETCH_USERS_FAILURE = 'ADMIN_FETCH_USERS_FAILURE'

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS'
export const UPDATE_USER_EMAIL_FAILURE = 'UPDATE_USER_EMAIL_FAILURE'

export const UPDATE_USER_FACILITY = 'UPDATE_USER_FACILITY'
export const UPDATE_USER_FACILITY_SUCCESS = 'UPDATE_USER_FACILITY_SUCCESS'
export const UPDATE_USER_FACILITY_FAILURE = 'UPDATE_USER_FACILITY_FAILURE'

export const UPDATE_USER_ENTERPRISE = 'UPDATE_USER_ENTERPRISE'
export const UPDATE_USER_ENTERPRISE_SUCCESS = 'UPDATE_USER_ENTERPRISE_SUCCESS'
export const UPDATE_USER_ENTERPRISE_FAILURE = 'UPDATE_USER_ENTERPRISE_FAILURE'

export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE'

export const REMOVE_ROLE = 'REMOVE_ROLE'
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS'
export const REMOVE_ROLE_FAILURE = 'REMOVE_ROLE_FAILURE'

export const MERGE_USER = 'MERGE_USER'
export const MERGE_USER_SUCCESS = 'MERGE_USER_SUCCESS'
export const MERGE_USER_FAILURE = 'MERGE_USER_FAILURE'

export const CLEAR_USERS_SEARCHED = 'CLEAR_USERS_SEARCHED'

export const REPROCESS = 'REPROCESS'
export const REPROCESS_SUCCESS = 'REPROCESS_SUCCESS'
export const REPROCESS_FAILURE = 'REPROCESS_FAILURE'

export const FETCH_ADMIN_USERS_SEARCH = 'FETCH_ADMIN_USERS_SEARCH'
export const FETCH_ADMIN_USERS_SEARCH_SUCCESS =
  'FETCH_ADMIN_USERS_SEARCH_SUCCESS'
export const FETCH_ADMIN_USERS_SEARCH_FAILURE =
  'FETCH_ADMIN_USERS_SEARCH_FAILURE'

export const fetchUsers = (params) => (dispatch) => {
  dispatch({ type: ADMIN_FETCH_USERS })

  AdminApi.fetchUsers(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: ADMIN_FETCH_USERS_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: ADMIN_FETCH_USERS_FAILURE, error }))
}

export const updateUserEmail = ({ userId, data }) => (dispatch) => {
  dispatch({ type: UPDATE_USER_EMAIL })

  AdminUserApi.updateUserEmail(userId, data)
    .then((result) => {
      dispatch({
        type: UPDATE_USER_EMAIL_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_USER_EMAIL_FAILURE,
        error:
          error.status === 409
            ? 'E-mail already exists.'
            : 'Failed to update e-mail.',
      })
    })
}

export const updateUserFacility = ({ userId, data }) => (dispatch) => {
  dispatch({ type: UPDATE_USER_FACILITY })

  AdminApi.updateUserFacility(userId, data)
    .then((result) => {
      dispatch({
        type: UPDATE_USER_FACILITY_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_FACILITY_FAILURE, error })
    })
}

export const updateUserEnterprise = ({ data, userId }) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/users/${userId}/enterprise`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: UPDATE_USER_ENTERPRISE,
        successType: UPDATE_USER_ENTERPRISE_SUCCESS,
        failureType: UPDATE_USER_ENTERPRISE_FAILURE,
      },
      meta: { userId },
    },
    dispatch
  )

export const mergeUserRecords = (data) => (dispatch) => {
  dispatch({ type: UPDATE_ROLE })

  AdminApi.mergeUserRecords(data)
    .then((result) => {
      dispatch({
        type: UPDATE_ROLE_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({ type: UPDATE_ROLE_FAILURE, error })
    })
}

// export const mergeUserRecords = (data) => (dispatch) =>
//   action(
//     {
//       endpoint: '/v1/admin/users/merge',
//       method: 'POST',
//       data,
//       actionTypes: {
//         requestType: MERGE_USER,
//         successType: MERGE_USER_SUCCESS,
//         failureType: MERGE_USER_FAILURE,
//       },
//     },
//     dispatch
//   )

export const updateRole = ({ userId, data }) => (dispatch) => {
  dispatch({ type: UPDATE_ROLE })

  AdminApi.updateRole(userId, data)
    .then((result) => {
      dispatch({
        type: UPDATE_ROLE_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({ type: UPDATE_ROLE_FAILURE, error })
    })
}

export const removeRole = ({ userId, data }) => (dispatch) => {
  dispatch({ type: REMOVE_ROLE })

  AdminApi.removeRole(userId, data)
    .then((result) => {
      dispatch({
        type: REMOVE_ROLE_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({ type: REMOVE_ROLE_FAILURE, error })
    })
}

export const fetchAdminUsersSearch = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/users?${getQueryString(params)}`,
      method: 'GET',
      actionTypes: {
        requestType: FETCH_ADMIN_USERS_SEARCH,
        successType: FETCH_ADMIN_USERS_SEARCH_SUCCESS,
        failureType: FETCH_ADMIN_USERS_SEARCH_FAILURE,
      },
    },
    dispatch
  )

export const clearUsersSearched = () => ({
  type: CLEAR_USERS_SEARCHED,
  payload: 'clear',
})

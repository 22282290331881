import React from 'react'

export default function SubmitButton(props) {
  return (
    <button
      {...props}
      className="btn btn-primary ml-3"
      style={{ marginTop: 'auto', height: 36 }}
    >
      Deprecate
    </button>
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchClientReport } from '../../../actions/coach/client_report'

import NoTranslate from '../../hocs/NoTranslate'
import ScanDate from '../../scans/scan_dates'
import ScanImage from '../../scans/scan_image'

import { formatDateTime } from '../../../functions/text_format'
import '../../newWellness/wellness_history.css'

class ClientWellnessHistory extends Component {
  constructor(props) {
    super(props)

    this.changeScan = this.changeScan.bind(this)
    this._handleScroll = this._handleScroll.bind(this)

    this.state = { displayImage: false }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _handleScroll() {
    const node = document.getElementById('report-wellness-grid')
    if (!this.state.displayImage && window.scrollY >= node.offsetTop) {
      this.setState({ displayImage: true })
    } else if (this.state.displayImage && window.scrollY < node.offsetTop) {
      this.setState({ displayImage: false })
    }
  }

  changeScan(record) {
    const { clientId } = this.props.match.params
    const recordId = record.get('id')

    this.props.fetchClientReport({ clientId, recordId }).then((response) => {
      if (response && response.payload && response.payload.status === 200) {
        this.props.history.push(
          `/staff/clients/${clientId}/records/${recordId}/report`
        )
      }
    })
  }

  renderImage() {
    const { clientId, recordId } = this.props.match.params
    const accessLevel = this.props.clientReport.get('accessLevel').toLowerCase()
    const status = this.props.clientReport.get('status').toLowerCase()

    let image

    if (accessLevel === 'full' && status === 'complete') {
      image = (
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/blank`}
          imageId={this.props.clientReport.get('scanPackageId') + 'history'}
          style={{ width: '100%' }}
        />
      )
    } else if (accessLevel === 'limited' && status === 'complete') {
      image = (
        <ScanImage
          src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/slice`}
          imageId={this.props.clientReport.get('scanPackageId') + 'history'}
          style={{ width: '100%' }}
        />
      )
    }
    return image
  }

  render() {
    const { clientReport } = this.props
    const scanDateProps = {
      scan: clientReport,
      records: this.props.records,
      type: 'current',
      updateScan: this.changeScan,
    }

    return (
      <div className="text-center wellness-history">
        <h1 className="text-uppercase mb-3 wellness-history-header">
          Your Scans
        </h1>
        <div className="mb-3">
          <ScanDate {...scanDateProps} />
        </div>
        <div className="card pt-3">
          <h1 className="text-uppercase wellness-history-header">Scan Info</h1>
          <div
            className="mx-auto mb-2"
            style={{ display: this.state.displayImage ? 'block' : 'none' }}
          >
            {this.renderImage()}
          </div>
          <div className="mb-3">
            <NoTranslate>
              {formatDateTime(clientReport.get('recordDate'))}
            </NoTranslate>
          </div>
          <table className="table text-left mb-0">
            <tbody>
              <tr>
                <td>
                  <span style={{ fontWeight: 400 }}>Status:</span>
                </td>
                <td>{clientReport.get('status')}</td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontWeight: 400 }}>Posture:</span>
                </td>
                <td>{clientReport.get('postureStatus')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

ClientWellnessHistory.propTypes = {
  clientReport: PropTypes.object,
  records: PropTypes.object,
  fetchClientReport: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string,
      recordId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchClientReport }, dispatch)

export default connect(null, mapDispatchToProps)(ClientWellnessHistory)

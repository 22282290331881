import * as types from './types'

export const updateVideoRef = (videoProps) => ({
  type: types.UPDATE_VIDEO_REF,
  payload: videoProps,
})

export const updateRangeValue = (value) => ({
  type: types.UPDATE_RANGE_VALUE,
  payload: value,
})

export const updateCurrentTime = (currentTime) => ({
  type: types.UPDATE_CURRENT_TIME,
  payload: currentTime,
})

export const toggleAnimation = (animationStatus) => ({
  type: types.TOGGLE_ANIMATION,
  payload: animationStatus,
})

export const clearVideo = () => ({
  type: types.CLEAR_VIDEO,
})

import * as types from './types'
import action from '../middleware'

// const url = process.env.REACT_APP_API_BASEURL ? process.env.REACT_APP_API_BASEURL : '/';

export const fetchClientRecords = (params) => (dispatch) => {
  const queryStringParams = []
  for (const key in params) {
    if (key === 'search') {
      const { searchParam, searchString } = params[key]
      queryStringParams.push(`${key}=${searchParam}:${searchString}`)
    } else {
      queryStringParams.push(`${key}=${params[key]}`)
    }
  }
  const queryString = queryStringParams.join('&')

  return action(
    {
      endpoint: `/v1/facility/records?${queryString}`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_CLIENT_RECORDS,
        successType: types.FETCH_CLIENT_RECORDS_SUCCESS,
        failureType: types.FETCH_CLIENT_RECORDS_FAILURE,
      },
    },
    dispatch
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadScanRecords } from '../../actions/records'
import { loadMetrics } from '../../actions/wellness'
import currentScanSelector from '../../selectors/current_scan'
import visibleRecordsSelector from '../../selectors/visible_records'

import ScanPanel from '../wellness/scan_panel'
import MeasurementInfo from './measurement_info'
import MeasurementImage from './measurement_image'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

class Measurements extends Component {
  componentDidMount() {
    this.props.loadScanRecords()
    this.props.loadMetrics()
  }

  renderScanPanel(className) {
    return (
      <div className={`${className} d-flex col`}>
        <ScanPanel
          customStyle="measurement-scan-panel"
          currentScan={this.props.currentScan}
          records={this.props.visibleRecords}
          videoId="v1"
        />
      </div>
    )
  }

  renderMeasurementInfo(className) {
    return (
      <div className={`${className} d-flex col`}>
        <MeasurementInfo
          user={this.props.user}
          records={this.props.visibleRecords}
          currentScan={this.props.currentScan}
        />
      </div>
    )
  }

  renderMeasurementImage(className) {
    return (
      <div className={`${className} d-flex col`}>
        <MeasurementImage
          width={this.props.width}
          history={this.props.history}
        />
      </div>
    )
  }

  render() {
    if (
      this.props.current.size === 0 ||
      !this.props.user.get('user') ||
      this.props.loadingRecords
    ) {
      return <Loading />
    } else if (this.props.visibleRecords.size === 0) {
      return (
        <Unavailable title="No viewable scans">
          <div>
            Click&nbsp;
            <u onClick={() => this.props.history.push('/history')}>here</u>
            &nbsp;to view your scan history.
          </div>
        </Unavailable>
      )
    } else if (this.props.width < 590) {
      return (
        <div className="measurement-container">
          <div className="container">
            <div className="row">{this.renderScanPanel()}</div>
            <div className="row">{this.renderMeasurementImage()}</div>
            <div className="row">{this.renderMeasurementInfo()}</div>
          </div>
        </div>
      )
    } else if (this.props.width < 992) {
      return (
        <div className="measurement-container">
          <div className="container">
            <div className="row row-eq-height equal">
              {this.renderScanPanel('col-sm-6')}
              {this.renderMeasurementImage('col-sm-6')}
            </div>
            <div className="row">{this.renderMeasurementInfo('col-sm-4')}</div>
          </div>
        </div>
      )
    }
    return (
      <div className="measurement-container">
        <div className="container">
          <div className="row row-eq-height equal">
            {this.renderScanPanel('col-sm-4')}
            {this.renderMeasurementInfo('col-sm-4')}
            {this.renderMeasurementImage('col-sm-4')}
          </div>
        </div>
      </div>
    )
  }
}

Measurements.propTypes = {
  width: PropTypes.number,
  loadingRecords: PropTypes.bool,
  visibleRecords: PropTypes.object,
  current: PropTypes.object,
  currentScan: PropTypes.object,
  user: PropTypes.object,
  loadScanRecords: PropTypes.func,
  loadMetrics: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  loadingRecords: state.records.get('loading'),
  visibleRecords: visibleRecordsSelector(state),
  current: state.current,
  currentScan: currentScanSelector(state),
  user: state.user,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadScanRecords, loadMetrics }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Measurements)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from '../../actions/user'
import { updateEmail, clearEditMessage } from '../../actions/users'
import { loadScanRecords } from '../../actions/records'
import { loadMetrics } from '../../actions/wellness'

import './profile.css'

import ProfileCard from './profile_card'
import EditForm from './edit/profile_edit'
import PasswordForm from './edit/password_edit'
// import EmailForm from './edit/email_edit';
import Loading from '../utilities/loading'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.updateUserData = this.updateUserData.bind(this)
    this.updateSelectedView = this.updateSelectedView.bind(this)

    let initialState = { selected: 'overview' }

    if (props.user.get('user')) {
      initialState = Object.assign({}, initialState, {
        userData: {
          firstName: props.user.get('user').get('firstName'),
          lastName: props.user.get('user').get('lastName'),
          gender: props.user.get('user').get('gender'),
          birthDate: props.user.get('user').get('birthDate'),
          ethnicity: props.user.get('user').get('ethnicity'),
          email: props.user.get('user').get('email'),
          units: props.user.get('user').get('units'),
          timezone: props.user.get('user').get('timezone'),
          zipCode: props.user.get('user').get('zipCode'),
        },
      })
    }

    this.state = initialState
  }

  componentWillMount() {
    this.props.loadScanRecords()
    this.props.loadMetrics()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.get('user')) {
      this.updateUserData(nextProps.user.get('user').toJS())
    }
  }

  updateSelectedView(view) {
    this.setState({ selected: view })
  }

  updateUserData(data) {
    this.setState({
      userData: {
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        birthDate: data.birthDate,
        ethnicity: data.ethnicity,
        email: data.email,
        units: data.units,
        timezone: data.timezone,
        zipCode: data.zipCode,
      },
    })
  }

  renderSelectedView() {
    switch (this.state.selected) {
      case 'editForm':
        return (
          <EditForm
            cancelEdit={() => this.updateSelectedView('overview')}
            updateUserData={this.updateUserData}
          />
        )
      case 'passwordForm':
        return (
          <PasswordForm
            selected={this.state.selected}
            updatePassword={this.props.updatePassword}
            cancelPasswordEdit={() => this.updateSelectedView('overview')}
          />
        )
      // case 'emailForm':
      //   return (
      //     <EmailForm
      //       updateEmail={ this.props.updateEmail }
      //       cancelEmailEdit={ () => this.updateSelectedView('overview') } />
      //   );
      default:
        return (
          <ProfileCard
            userData={this.state.userData}
            facility={this.props.user.get('user').get('facility')}
          />
        )
    }
  }

  renderActionTabs() {
    const actionTabs = [
      { key: 'overview', title: 'Profile Overview' },
      { key: 'editForm', title: 'Edit Profile' },
      { key: 'passwordForm', title: 'Change Password' },
      // { key: 'emailForm', title: 'Change Email' }
    ]

    const { selected } = this.state

    return actionTabs.map(({ key, title }) => (
      <div
        key={key}
        onClick={() => this.updateSelectedView(key)}
        className={`${
          selected === key
            ? 'profile-action-tab-selected'
            : 'profile-action-tab'
        }`}
      >
        {selected === key && <span className="mr-2 fas fa-caret-right" />}{' '}
        {title}
      </div>
    ))
  }

  render() {
    if (!this.props.user.get('user')) {
      return <Loading />
    }
    return (
      <div className="container mt-5">
        <div
          className="hr-divider mb-4"
          style={{ fontFamily: 'Lato, sans-serif', letterSpacing: '5px' }}
        >
          <h1 className="hr-divider-content" style={{ fontSize: '44px' }}>
            Manage Profile
          </h1>
        </div>
        <div className="card card-default">
          <div className="card-body px-0">
            <div className="row mx-0">
              <div className="col-sm-4 px-0">
                <div className="mt-3">{this.renderActionTabs()}</div>
              </div>
              <div className="col-sm-8 profile-card">
                <div style={{ padding: '25px 15px 5px 15px' }}>
                  {this.renderSelectedView()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Profile.propTypes = {
  loadScanRecords: PropTypes.func,
  loadMetrics: PropTypes.func,
  updatePassword: PropTypes.func,
  updateEmail: PropTypes.func,
  clearEditMessage: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = (state) => ({ user: state.user })

const actions = {
  updateEmail,
  clearEditMessage,
  loadMetrics,
  loadScanRecords,
  ...UserActions,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

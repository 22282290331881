import React from 'react'

export default function AdminRecordsHeader(props) {
  return (
    <>
      <div className="dashhead mt-3">
        <div className="dashhead-titles">
          <h6 className="dashhead-subtitle">Fit3D Admin</h6>
          <h3 className="dashhead-title">Records</h3>
        </div>
      </div>
      <hr className="mt-0 mb-4" />
    </>
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'
import RedrawOnPrint from 'react-highcharts/dist/RedrawOnPrint'

import * as ChartUtils from './chartUtils'

// import data from '../../../helpers/assessment.js';
import './chart.css'

export default class BSRChart extends Component {
  constructor(props) {
    super(props)
    // let height = (props.width > 989 && props.width < 1200) ? 120 : 180;
    //     height = (props.width < 414) ? 150 : height;
    //     height = (props.width < 321) ? 110 : height;

    // const data = props.data.toJS().map(point => {
    //   if (point[0] === props.value) {
    //     return ChartUtils.makeMarker(point[0], point[1], '#1686b0');
    //   }
    //   return point;
    // });

    // console.log(props.metric);
    // console.log(ChartUtils.generatePlotBands(props.ranges.toJS(), props.metric));

    const parsedData = ChartUtils.convertPercentage(props.data.toJS())

    this.config = {
      chart: {
        type: 'areaspline',
        height: 300,
        style: {
          fontFamily: 'Helvetica',
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: '%',
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        title: {
          text: props.title,
        },
        plotBands: ChartUtils.generatePlotBands(
          props.ranges.toJS(),
          props.metric
        ),
        plotLines: ChartUtils.generatePlotLines(props.value),
      },
      yAxis: {
        gridLineColor: 'transparent',
        title: {
          text: '% of Scans',
        },
        max: ChartUtils.findMax(parsedData) + 5,
        tickInterval: Math.round((ChartUtils.findMax(parsedData) + 1) / 4),
      },
      series: [
        {
          name: 'Distribution',
          data: parsedData,
          color: '#9e9e9e',
          fillColor: 'rgba(0, 0, 0, 0.2)',
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps) {
    const data = nextProps.data.toJS()
    const parsedData = ChartUtils.convertPercentage(data)

    this.config.xAxis.title.text = nextProps.title
    this.config.xAxis.plotLines = ChartUtils.generatePlotLines(nextProps.value)
    this.config.xAxis.plotBands = ChartUtils.generatePlotBands(
      nextProps.ranges.toJS(),
      nextProps.metric
    )

    this.config.yAxis.max = ChartUtils.findMax(parsedData) + 5
    this.config.yAxis.tickInterval = Math.round(
      (ChartUtils.findMax(parsedData) + 1) / 4
    )

    this.config.series = [
      {
        name: 'Distribution',
        data: parsedData,
        color: '#9e9e9e',
        fillColor: 'rgba(0, 0, 0, 0.2)',
      },
    ]
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data || this.props.value !== nextProps.value
    )
  }

  render() {
    return (
      <div className="histogram">
        <RedrawOnPrint>
          <ReactHighcharts config={this.config} />
        </RedrawOnPrint>
      </div>
    )
  }
}

BSRChart.propTypes = {
  metric: PropTypes.string,
  ranges: PropTypes.object,
  width: PropTypes.number,
  value: PropTypes.number,
  data: PropTypes.object,
  title: PropTypes.string,
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ClientNav from './client'
import SubNavContainer from '../../components/navbar/sub_nav_container'
import NavItem from '../../components/navbar/nav_item'
import SubNav from '../../components/navbar/sub_nav'

import clientCurrentSelector from '../../selectors/client_current_scan'

// const subIconColor = '#729ab3';

const parsePathname = (pathname) => {
  const data = {
    clientId: null,
    recordId: null,
  }

  const params = pathname.split('/')
  for (let i = 0; i < params.length; i++) {
    if (params[i].indexOf('usr-') !== -1) {
      data.clientId = params[i]
    } else if (!isNaN(parseInt(params[i], 10))) {
      data.recordId = params[i]
    }
  }
  return data
}

const FacilityNav = (props) => {
  // const activitySubNav = [
  //   {
  //     text: 'Coach (Aug)',
  //     clickHandler: props.redirectPage('/facility/coach/activity'),
  //     isSelected: props.pathname === '/facility/coach/activity'
  //   },
  //   {
  //     text: 'Facility (July)',
  //     clickHandler: props.redirectPage('/facility/activity'),
  //     isSelected: props.pathname === '/facility/activity'
  //   }
  // ];

  const myClientsSubNav = [
    {
      text: 'Client Scans',
      clickHandler: props.redirectPage('/staff/clients/records'),
      isSelected: props.pathname === '/staff/clients/records',
    },
    {
      text: 'Demo Report',
      clickHandler: props.redirectPage(
        '/staff/clients/sample-female/records/0/report'
      ),
      isSelected:
        props.pathname === '/staff/clients/sample-female/records/0/report',
    },
    {
      text: 'Demo Comparison',
      clickHandler: props.redirectPage(
        '/staff/clients/sample-female/records/0/report/comparison'
      ),
      isSelected:
        props.pathname ===
        '/staff/clients/sample-female/records/0/report/comparison',
    },
  ]

  return (
    <SubNavContainer header="Facility">
      <ul
        className="nav-pills flex-md-column list-unstyled"
        style={{ padding: '15px 0px' }}
      >
        <NavItem
          isSelected={props.pathname === '/facility/dashboard'}
          text="Dashboard"
          clickHandler={props.redirectPage('/facility/dashboard')}
        />
        <NavItem
          isSelected={
            props.selectedSubPage === 'coachClients' ||
            props.pathname.indexOf('/staff') !== -1
          }
          text="My Clients"
          clickHandler={props.redirectPage('/staff/clients/records')}
        >
          <SubNav subNavProps={myClientsSubNav} />
        </NavItem>
        {props.facilityAdmin && (
          <NavItem
            isSelected={props.pathname === '/facility/users'}
            text="Manage Coaches"
            clickHandler={props.redirectPage('/facility/users')}
          />
        )}
        {/* <NavItem
          isSelected={ props.pathname === '/facility/coach/activity' }
          text='Activity Challenge'
          clickHandler={ props.redirectPage('/facility/coach/activity') } /> */}
      </ul>
      {parsePathname(props.pathname).clientId &&
        (props.currentScan || props.coach.get('clientReport')) && (
          <ClientNav {...props} />
        )}
    </SubNavContainer>
  )
}

FacilityNav.propTypes = {
  coach: PropTypes.object.isRequired,
  currentScan: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  facilityAdmin: PropTypes.bool.isRequired,
  selectedSubPage: PropTypes.string,
  redirectPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  coach: state.coach,
  currentScan: clientCurrentSelector(state),
})

export default connect(mapStateToProps)(FacilityNav)

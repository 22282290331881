import React from 'react'
import PropTypes from 'prop-types'

import '../wellness/button_group.css'

const ViewButtons = ({ view, updateView }) => {
  const selectedStyle =
    'text-uppercase btn btn-outline-primary btn-square active buttonActive'
  const defaultStyle =
    'text-uppercase btn btn-outline-primary btn-square custom-button-outline'

  return (
    <div className="btn-group button-group" style={{ width: '100%' }}>
      <button
        type="button"
        value="front"
        className={view === 'front' ? selectedStyle : defaultStyle}
        onClick={() => updateView('front')}
      >
        Front
      </button>
      <button
        type="button"
        value="back"
        className={view === 'back' ? selectedStyle : defaultStyle}
        onClick={() => updateView('back')}
      >
        Back
      </button>
      <button
        type="button"
        value="side"
        className={view === 'side' ? selectedStyle : defaultStyle}
        onClick={() => updateView('side')}
      >
        Side
      </button>
    </div>
  )
}

ViewButtons.propTypes = {
  view: PropTypes.string,
  updateView: PropTypes.func,
}

export default ViewButtons

import * as AdminApi from '../../lib/api/admin'
import { setPageInfo } from '../pagination'

export const FETCH_QC_RECORDS = 'FETCH_QC_RECORDS'
export const FETCH_QC_RECORDS_SUCCESS = 'FETCH_QC_RECORDS_SUCCESS'
export const FETCH_QC_RECORDS_FAILURE = 'FETCH_QC_RECORDS_FAILURE'

export const SET_SCAN_VALID_STATUS = 'SET_SCAN_VALID_STATUS'
export const SET_SCAN_VALID_STATUS_SUCCESS = 'SET_SCAN_VALID_STATUS_SUCCESS'
export const SET_SCAN_VALID_STATUS_FAILURE = 'SET_SCAN_VALID_STATUS_FAILURE'

export const fetchQcRecords = (params) => (dispatch) => {
  dispatch({ type: FETCH_QC_RECORDS })

  AdminApi.fetchQcRecords(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: FETCH_QC_RECORDS_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: FETCH_QC_RECORDS, error }))
}

export const updateScanValid = (params) => (dispatch) => {
  dispatch({ type: SET_SCAN_VALID_STATUS })

  AdminApi.updateScanValidStatus(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: SET_SCAN_VALID_STATUS_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: SET_SCAN_VALID_STATUS, error }))
}

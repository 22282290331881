import React from 'react'
import PropTypes from 'prop-types'

import './navbar.css'
export default function SubNavContainer({ children, header, subheader }) {
  return (
    <div className="iconav-slider custom-iconav-slider">
      <div className="text-center text-uppercase subnav-header">{header}</div>
      {subheader && (
        <div className="text-center subnav-subheader">{subheader}</div>
      )}
      {children}
    </div>
  )
}

SubNavContainer.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
}

import Immutable from 'immutable'

import {
  FETCH_WELLNESS_REPORT,
  FETCH_WELLNESS_REPORT_SUCCESS,
  FETCH_WELLNESS_REPORT_FAILURE,
  UPDATE_SELECTED_METRIC,
} from '../actions/wellness/types'

const reducers = {
  [FETCH_WELLNESS_REPORT]: (state) => {
    return state.set('fetchingWellnessReport', true)
  },
  [FETCH_WELLNESS_REPORT_SUCCESS]: (state, action) =>
    state
      .set('report', Immutable.fromJS(action.payload.data))
      .set('fetchingWellnessReport', false),
  [FETCH_WELLNESS_REPORT_FAILURE]: (state) =>
    state.set('fetchingWellnessReport', false),
  // [FETC]: (state) => state.set('loading', false)
  [UPDATE_SELECTED_METRIC]: (state, action) =>
    state.set('selectedMetric', action.payload),
}

const defaultState = new Immutable.Map({
  fetchingWellnessReport: false,
  selectedMetric: null, // id of the DOM element, will enable scrolling when on wellness report page
  // records: new Immutable.List()
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    // Call the given reducer from action.type with the current state
    return reducers[action.type](state, action)
  }
  // If there was no reducer function to modify state return our existing state
  return state
}

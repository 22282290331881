import queryString from './api/queryString'

const apiUrls = {
  v1: process.env.REACT_APP_API_BASEURL,
  v2Admin: process.env.REACT_APP_ADMIN_API_V2_BASEURL,
  v2User: process.env.REACT_APP_USER_API_V2_BASEURL,
}

export const GET = (uri, params, apiVersion = 'v1') => {
  let url = `${apiUrls[apiVersion]}${uri}`

  if (params) {
    const qs = queryString(params)
    url += '?' + qs
  }

  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          return response.json().then((json) =>
            resolve({
              headers: response.headers,
              status: response.status,
              json,
            })
          )
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const PUT = (uri, body, apiVersion = 'v1') => {
  const url = `${apiUrls[apiVersion]}${uri}`
  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const POST = (uri, body, apiVersion = 'v1') => {
  const url = `${apiUrls[apiVersion]}${uri}`
  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const DELETE = (uri, body, apiVersion = 'v1') => {
  const url = `${apiUrls[apiVersion]}${uri}`

  const req = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  }
  if (body) {
    req.body = JSON.stringify(body)
  }
  return new Promise((resolve, reject) => {
    window
      .fetch(url, req)
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

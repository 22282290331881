import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import FileDownload from '../../../utilities/file_download'

function DownloadButton({ linkId, src, text }) {
  return (
    <FileDownload linkId={linkId} src={src} style={{ width: '100%' }}>
      <button id="depth" className="btn btn-primary btn-block">
        {text}
      </button>
    </FileDownload>
  )
}

export default function DownloadModal({
  scanPackageId,
  isDownloadOpen,
  closeDownloadModal,
}) {
  return (
    <Modal size="sm" isOpen={isDownloadOpen} toggle={closeDownloadModal}>
      <ModalHeader toggle={closeDownloadModal}>Select Download</ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <DownloadButton
            linkId={'adminRecords' + scanPackageId + 'depth'}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/download?downloadType=depth`}
            text="Depth"
          />
        </div>
        <div className="mb-2">
          <DownloadButton
            linkId={'adminRecords' + scanPackageId + 'reconstruct-raw'}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/download?downloadType=scan-reconstruct&scanType=raw`}
            text="Reconstruct / Raw"
          />
        </div>
        <div className="mb-2">
          <DownloadButton
            linkId={'adminRecords' + scanPackageId + 'reconstruct-watertight'}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/download?downloadType=scan-reconstruct&scanType=watertight`}
            text="Reconstruct / Watertight"
          />
        </div>
        <div className="mb-2">
          <DownloadButton
            linkId={'adminRecords' + scanPackageId + 'clean-raw'}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/download?downloadType=scan-clean&scanType=raw`}
            text="Clean / Raw"
          />
        </div>
        <div className="mb-2">
          <DownloadButton
            linkId={'adminRecords' + scanPackageId + 'clean-watertight'}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/download?downloadType=scan-clean&scanType=watertight`}
            text="Clean / Watertight"
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

DownloadModal.propTypes = {
  scanPackageId: PropTypes.string,
  isDownloadOpen: PropTypes.bool.isRequired,
  closeDownloadModal: PropTypes.func.isRequired,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

class ScanGif extends Component {
  constructor(props) {
    super(props)

    this._mounted = false
    this.imgId = `${this.props.currentScan.get('scanPackageId')}gif`
  }

  componentDidMount() {
    this._mounted = true

    const uri = `${
      process.env.REACT_APP_API_BASEURL
    }/v1/records/${this.props.currentScan.get('scanPackageId')}/scan/gif`
    this.fetchScanGif(uri)
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.currentScan.get('scanPackageId') !==
      nextProps.currentScan.get('scanPackageId')
    ) {
      this.imgId = `${nextProps.currentScan.get('scanPackageId')}gif`
      const nextURI = `${
        process.env.REACT_APP_API_BASEURL
      }/v1/records/${nextProps.currentScan.get('scanPackageId')}/scan/gif`

      this.fetchScanGif(nextURI)
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  fetchScanGif(uri) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    }

    return new Promise((resolve, reject) => {
      window.window
        .fetch(uri, options)
        .then((response) => response.blob())
        .then((blob) => {
          if (this._mounted) {
            const url = URL.createObjectURL(blob)

            const imgSrc = document.getElementById(this.imgId)
            imgSrc.setAttribute('src', url)
          }
          resolve()
        })
        .catch((err) => reject(err))
    })
  }

  render() {
    return <img id={this.imgId} style={this.props.style} alt="" />
  }
}

ScanGif.propTypes = {
  currentScan: ImmutablePropTypes.mapContains({
    scanPackageId: PropTypes.string.isRequired,
  }),
  style: PropTypes.object,
}

export default ScanGif

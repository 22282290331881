import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BodyTable extends Component {
  renderMetricData() {
    return this.props.wellnessMetrics[this.props.selected].map((metric) => {
      const key = Object.keys(metric)[0]
      const baselineMetric = this.props.baselineMetric.get(key)
      const currentMetric = this.props.currentMetric.get(key)

      const difference = Math.max(
        ((currentMetric - baselineMetric) * 10) / 10
      ).toFixed(1)

      return (
        <tr key={key}>
          <td>
            <span isolate="true">{metric[key]}</span>
            <span isolate="true">{` ${metric.units}`}</span>
          </td>
          <td>{baselineMetric}</td>
          <td>{currentMetric}</td>
          <td>{difference}</td>
        </tr>
      )
    })
  }

  render() {
    return (
      <table
        className="table table-bordered table-hover"
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Baseline</th>
            <th>Current</th>
            <th>Diff</th>
          </tr>
        </thead>
        <tbody>{this.renderMetricData()}</tbody>
      </table>
    )
  }
}

BodyTable.propTypes = {
  currentScan: PropTypes.object,
  baselineScan: PropTypes.object,
  wellnessMetrics: PropTypes.object,
  selected: PropTypes.string,
  currentMetric: PropTypes.object,
  baselineMetric: PropTypes.object,
}

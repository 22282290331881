import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ClientActions from '../../../actions/coach/client_report'
import { clearMessage } from '../../../actions/coach/index'
import { manageClient } from '../../../actions/coach/client'
import {
  fetchHistogramData,
  fetchHealthRanges,
} from '../../../actions/datasets/index'
import moment from 'moment'
import Scroll from 'react-scroll'

import NoTranslate from '../../hocs/NoTranslate'
import ReportImages from './images'
import Wellness from './wellness'
import PrintWellness from './print/wellness'
import PostureGrid from './posture/grid'
import PrintPosture from './print/posture'
import Balance from './balance'
import Measurements from './measurements'
import MockReport from '../mockReports'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

import { capitalize, formatDateTime } from '../../../functions/text_format'

class ClientReport extends Component {
  constructor(props) {
    super(props)

    this.redirectPage = this.redirectPage.bind(this)
    this.handleImageDisplay = this.handleImageDisplay.bind(this)
    this.handleAccessRequest = this.handleAccessRequest.bind(this)
    this.handleDownload = this.handleDownload.bind(this)

    this.state = {
      currentScanId: props.match.params.recordId,
      showImages: true,
    }
  }

  componentDidMount() {
    const { clientId, recordId } = this.props.match.params

    this.props.fetchHistogramData('male')
    this.props.fetchHistogramData('female')
    this.props.fetchHealthRanges(clientId)
    this.props.fetchClientReport({ clientId, recordId })
    this.props.fetchClientSummary(clientId)

    this.handleScroll(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const currentReport = this.props.coach.get('clientReport')
    const nextReport = nextProps.coach.get('clientReport')

    if (
      currentReport &&
      nextReport &&
      currentReport.get('id') !== nextReport.get('id')
    ) {
      this.setState({ currentScanId: nextReport.get('id') })
    }

    // navigating between mock reports
    const nextClientId = nextProps.match.params.clientId
    if (
      this.props.match.params !== nextProps.match.params &&
      nextClientId.indexOf('sample') !== -1
    ) {
      this.props.fetchClientReport({ clientId: nextClientId, recordId: '0' })
    }

    this.handleScroll(nextProps)
  }

  handleScroll(props) {
    if (props.selectedMetric) {
      const node = document.getElementById(props.selectedMetric)
      // console.log(node.offsetTop - 50);
      if (node) {
        Scroll.scroller.scrollTo(props.selectedMetric, {
          duration: 1000,
          delay: 100,
          smooth: true,
        })
      }
    }
  }

  // routing
  redirectPage(route) {
    if (route === '/staff/clients/records') {
      this.props.resetClientReport()
    }
    this.props.history.push(route)
  }

  // handle actions and ui updates
  handleAccessRequest() {
    const { clientId } = this.props.match.params
    const currentTime = new Date()
    const data = {
      clientId,
      accessRequestDate: moment
        .utc(currentTime)
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
    }

    this.props.manageClient(data)
  }

  handleImageDisplay() {
    this.setState({ showImages: !this.state.showImages })
  }

  handleDownload(event) {
    event.preventDefault()
    window.ga('send', 'event', 'Button', 'click', 'Click on Download Report')
    window.print()
  }

  render() {
    const clientReport = this.props.coach.get('clientReport')
    const clientSummary = this.props.coach.get('clientSummary')
    const pageError = this.props.coach.get('pageError')

    if (
      this.props.coach.get('loading') ||
      this.props.datasets.get('loading') ||
      this.props.datasets.get('clientHealthRanges').size === 0
    ) {
      return <Loading />
    }

    if (
      pageError ||
      (clientReport && clientReport.get('status') !== 'Complete')
    ) {
      return (
        <Unavailable title="Client Scan Report is not available">
          <div>
            Click{' '}
            <u onClick={() => this.redirectPage('/staff/clients/records')}>
              here
            </u>{' '}
            to return to all clients' records page.
          </div>
        </Unavailable>
      )
    }

    if (!clientReport || !clientSummary) {
      return <Loading />
    }

    const { clientId, recordId } = this.props.match.params
    const { showImages } = this.state

    const client = clientReport.get('user')
    const fullName = `${capitalize(client.get('firstName'))} ${capitalize(
      client.get('lastName')
    )}`
    const accessLevel = clientReport.get('accessLevel')
    const title = (
      <span>
        <span notranslate>{`${fullName}'s`}</span>&nbsp;
        {`Report (${capitalize(accessLevel)})`}
      </span>
    )

    const gender = clientReport.get('user').get('gender')
    const units = this.props.user.get('user').get('units')
    const bfpAccess = this.props.user
      .get('user')
      .get('facility')
      .get('bfpAccess')

    const wellnessMetrics = clientReport.get('wellnessMetrics')
    const balance = clientReport.get('balance')
    const measurements = clientReport.get('measurements')

    const handleAccessRequest = this.handleAccessRequest

    return (
      <div className="pb-3">
        <div className="dashhead my-3">
          <div className="dashhead-titles">
            <h6 className="dashhead-subtitle">Success Hub</h6>
            <h3 className="dashhead-title">{title}</h3>
          </div>
          <div className="dashhead-toolbar">
            <div className="btn-group print-none">
              <div
                className="btn btn-outline-primary"
                onClick={this.handleImageDisplay}
                style={{ width: '110px' }}
              >
                {showImages ? 'Hide Images' : 'Show Images'}
              </div>
              <div
                className="btn btn-outline-primary"
                onClick={this.handleDownload}
              >
                Download Report
              </div>
              <div
                className="btn btn-outline-primary"
                onClick={() => this.redirectPage('/staff/clients/records')}
              >
                Return to All Scans
              </div>
            </div>
          </div>
        </div>
        <div className="print mb-4">
          <h4>
            Scan Date:&nbsp;
            <NoTranslate>
              {formatDateTime(clientReport.get('recordDate'))}
            </NoTranslate>
          </h4>
        </div>

        {showImages && (
          <div
            id="report-images-grid"
            ref={(images) => {
              this.images = images
            }}
          >
            <ReportImages
              clientId={clientId}
              recordId={recordId}
              accessLevel={accessLevel}
              scanPackageId={clientReport.get('scanPackageId')}
              name={fullName}
              gender={gender}
              accessRequestDate={clientReport.get('accessRequestDate')}
              handleAccessRequest={handleAccessRequest}
              message={this.props.coach.get('message')}
              clearMessage={this.props.clearMessage}
              currentScan={clientReport}
            />
          </div>
        )}
        {wellnessMetrics && (
          <div id="report-wellness-grid">
            <div
              className="print-none"
              ref={(wellnessGrid) => {
                this.wellnessGrid = wellnessGrid
              }}
            >
              <Wellness
                histograms={this.props.datasets.get('histograms')}
                healthRanges={this.props.datasets.get('clientHealthRanges')}
                wellnessMetrics={wellnessMetrics}
                scanPackageId={clientReport.get('scanPackageId')}
                facility={this.props.user.get('user').get('facility')}
                gender={gender}
                units={units}
                bfpAccess={bfpAccess}
              />
            </div>
            <div className="print print-break-after">
              <PrintWellness
                histograms={this.props.datasets.get('histograms')}
                healthRanges={this.props.datasets.get('clientHealthRanges')}
                wellnessMetrics={wellnessMetrics}
                scanPackageId={clientReport.get('scanPackageId')}
                facility={this.props.user.get('user').get('facility')}
                gender={gender}
                units={units}
              />
            </div>
          </div>
        )}
        {clientReport.get('posture') &&
          this.props.user.get('user').get('facility').get('postureAccess') ===
            1 && (
            <div id="report-posture-grid">
              <div
                className="print-none"
                ref={(postureGrid) => {
                  this.postureGrid = postureGrid
                }}
              >
                <PostureGrid
                  clientId={clientId}
                  recordId={recordId}
                  clientReport={clientReport}
                  accessLevel={accessLevel}
                  gender={gender}
                  units={this.props.user.get('user').get('units')}
                />
              </div>
              <div className="print print-break-after">
                <PrintPosture
                  clientId={clientId}
                  recordId={recordId}
                  clientReport={clientReport}
                  accessLevel={accessLevel}
                  gender={gender}
                  units={this.props.user.get('user').get('units')}
                />
              </div>
            </div>
          )}
        {balance && (
          <div
            id="report-balance-grid print-break-after"
            ref={(balanceGrid) => {
              this.balanceGrid = balanceGrid
            }}
          >
            <Balance balanceData={balance} gender={gender} />
          </div>
        )}
        {measurements && (
          <div
            id="report-measurements-grid"
            ref={(measurementGrid) => {
              this.measurementGrid = measurementGrid
            }}
          >
            <Measurements
              scanMeasurements={measurements}
              scanPackageId={clientReport.get('scanPackageId')}
              gender={gender}
              units={units}
            />
          </div>
        )}

        <div className="d-flex justify-content-center mb-3 float-right print-none">
          <div className="mr-3 print-none">
            <MockReport redirectPage={this.redirectPage} />
          </div>
          <div className="mr-3" style={{ borderLeft: '1px #ccc solid' }} />
          <div className="print-none">
            <div
              className="btn btn-primary btn-block"
              onClick={() => this.redirectPage('/staff/clients/records')}
            >
              Return to All Scans
            </div>
            <div
              className="btn btn-outline-primary btn-block"
              onClick={this.handleDownload}
            >
              Download Report
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClientReport.propTypes = {
  coach: PropTypes.object.isRequired,
  datasets: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchHistogramData: PropTypes.func.isRequired,
  fetchHealthRanges: PropTypes.func.isRequired,
  fetchClientReport: PropTypes.func.isRequired,
  fetchClientSummary: PropTypes.func.isRequired,
  resetClientReport: PropTypes.func.isRequired,
  manageClient: PropTypes.func.isRequired,
  clearMessage: PropTypes.func,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const actions = {
  fetchHistogramData,
  fetchHealthRanges,
  ...ClientActions,
  manageClient,
  clearMessage,
}

const mapStateToProps = (state) => ({
  coach: state.coach,
  datasets: state.datasets,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClientReport)

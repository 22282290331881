import * as types from './types'
import action from '../middleware'

// NEW STUFF
export const fetchWellnessReport = (recordId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/records/${recordId}/report`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_WELLNESS_REPORT,
        successType: types.FETCH_WELLNESS_REPORT_SUCCESS,
        failureType: types.FETCH_WELLNESS_REPORT_FAILURE,
      },
    },
    dispatch
  )

export const updateSelectedMetric = (metric) => ({
  type: types.UPDATE_SELECTED_METRIC,
  payload: metric,
})

// OLD STUFF
export const loadMetrics = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/records/wellnessmetrics',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_METRICS,
        successType: types.FETCH_METRICS_SUCCESS,
        failureType: types.FETCH_METRICS_FAILURE,
      },
    },
    dispatch
  )

// export function fetchMetrics() {
//   var response = axios.get('process.env.REACT_APP_API_BASEURL/v1/records/wellnessmetrics');
//   return {
//     type: FETCH_METRICS,
//     payload: response
//   };
// }

// export function loadMetrics() {
//   return (dispatch) => {
//     // const metrics = getState().metrics;
//     //
//     // if (metrics && metrics.get('metrics')) {
//     //   return null;
//     // }
//
//     return dispatch(fetchMetrics());
//   };
// }

export const updateMetric = (recordId, data) => (dispatch) => {
  // const data = {
  //   id: params.id,
  //   [params.type]: params.value
  // };

  return action(
    {
      endpoint: `/v1/records/${recordId}/wellnessmetrics`,
      method: 'POST',
      data,
      actionTypes: {
        requestType: types.UPDATE_METRIC,
        successType: types.UPDATE_METRIC_SUCCESS,
        failureType: types.UPDATE_METRIC_FAILURE,
      },
    },
    dispatch
  )
}
//
// export function updateMetric(params) {
//   const data = {
//     id: params.id,
//     [params.type]: params.value
//   };
//
//   var response = axios({
//     method: 'post',
//     url: `process.env.REACT_APP_API_BASEURL/v1/records/${params.id}/wellnessmetrics/${params.type}`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data: data
//   });
//
//   return {
//     type: UPDATE_METRIC,
//     payload: response
//   };
// }

// NOTE: See what additional metrics the facility has
export const fetchFacilityAdditional = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/records/wellnessmetrics/additional/types',
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_FACILITY_ADDITIONAL,
        successType: types.FETCH_FACILITY_ADDITIONAL_SUCCESS,
        failureType: types.FETCH_FACILITY_ADDITIONAL_FAILURE,
      },
    },
    dispatch
  )

// export function fetchFacilityAdditional() {
//   var response = axios.get('process.env.REACT_APP_API_BASEURL/v1/records/wellnessmetrics/additional/types');
//
//   return {
//     type: FETCH_FACILITY_ADDITIONAL,
//     payload: response
//   };
// }

// export function loadFacilityAdditional() {
//   return (dispatch, getState) => {
//     const metrics = getState().metrics;
//
//     return dispatch(fetchFacilityAdditional());
//   };
// }

// NOTE: Actual user's facility metrics
export const fetchAdditionalMetrics = (id) => (dispatch) =>
  action(
    {
      endpoint: `/v1/records/${id}/wellnessmetrics/additional`,
      method: 'GET',
      actionTypes: {
        requestType: types.FETCH_USER_ADDITIONAL,
        successType: types.FETCH_USER_ADDITIONAL_SUCCESS,
        failureType: types.FETCH_USER_ADDITIONAL_FAILURE,
      },
    },
    dispatch
  )

// export function fetchAdditionalMetrics(id) {
//   var response = axios.get(`process.env.REACT_APP_API_BASEURL/v1/records/${id}/wellnessmetrics/additional`);
//   return {
//     type: FETCH_ADDITIONAL,
//     payload: response
//   };
// }

export const updateAdditionalMetrics = (params) => (dispatch) =>
  action(
    {
      endpoint: `/v1/records/${params.recordId}/wellnessmetrics/additional/${params.id}`,
      method: 'POST',
      data: params,
      actionTypes: {
        requestType: types.UPDATE_ADDITIONAL,
        successType: types.UPDATE_ADDITIONAL_SUCCESS,
        failureType: types.UPDATE_ADDITIONAL_FAILURE,
      },
    },
    dispatch
  )

// export function updateAdditionalMetrics(params) {
//   const data = {
//     typeId: params.typeId,
//     recordId: params.recordId,
//     value: params.value,
//     id: params.id
//   };
//
//   var response = axios({
//     method: 'post',
//     url: 'process.env.REACT_APP_API_BASEURL/v1/records/${params.recordId}/wellnessmetrics/additional/${params.id}',
//     data: data
//   });
//
//   return {
//     type: UPDATE_ADDITIONAL,
//     payload: response
//   };
// }

export const addAdditionalMetrics = (data, recordId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/records/${recordId}/wellnessmetrics/additionals`,
      method: 'POST',
      data,
      meta: {
        recordId,
      },
      actionTypes: {
        requestType: types.ADD_ADDITIONAL,
        successType: types.ADD_ADDITIONAL_SUCCESS,
        failureType: types.ADD_ADDITIONAL_FAILURE,
      },
    },
    dispatch
  )

export const clearStatusMessage = () => ({
  type: types.CLEAR_METRICS_STATUS_MESSAGE,
})

// export function addAdditionalMetrics(params) {
//   var response = axios({
//     method: 'post',
//     url: `process.env.REACT_APP_API_BASEURL/v1/records/${params.recordId}/wellnessmetrics/additional`,
//     data: params
//   });
//
//   return {
//     type: ADD_ADDITIONAL,
//     payload: response
//   };
// }

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ScanDate from './scan_dates'
import ScanVideo from './scan_video'
import ScanGif from './scan_gif'
import ScanImage from './scan_image'
import ScanControl from './scan_control'

import './panel.css'

// TODO: take multi-rendering logic elsewhere, convert to functional component
class ScanPanelContainer extends Component {
  render() {
    let scan
    if (
      this.props.clientProps &&
      this.props.clientProps.accessLevel !== 'full'
    ) {
      scan = (
        <ScanImage
          style={{ width: '100%' }}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${this.props.clientProps.clientId}/records/${this.props.clientProps.recordId}/scan/img/0/type/slice`}
        />
      )
    } else if (this.props.windowDimensions.width <= 768) {
      scan = <ScanGif currentScan={this.props.scan} style={{ width: '100%' }} />
    } else if (this.props.videoId) {
      scan = (
        <ScanVideo
          {...this.props}
          scanPackageId={this.props.scan.get('scanPackageId')}
          src={
            this.props.src ||
            `/v1/records/${this.props.scan.get('scanPackageId')}/scan`
          }
        />
      )
    } else {
      scan = <ScanImage style={{ width: '100%' }} src={this.props.src} />
    }

    return (
      <div
        className="card text-center customPanel"
        key={this.props.scan.get('scanPackageId')}
        style={this.props.style}
      >
        <div className="card-body m-xs-0 px-3">
          <h3 className="mb-3">{this.props.header}</h3>
          <div className="mx-auto" style={{ width: '80%' }}>
            <div className="d-flex justify-content-center align-items-center">
              {this.props.scanDateProps && (
                <ScanDate {...this.props.scanDateProps} />
              )}
            </div>
          </div>
          {scan}
          {!this.props.hideControl && <ScanControl />}
        </div>
      </div>
    )
  }
}

ScanPanelContainer.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  gender: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
  body: PropTypes.node,
  header: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  scan: PropTypes.object,
  currentScan: PropTypes.object,
  notificationMessageProps: PropTypes.objectOf(PropTypes.any),
  notificationProps: PropTypes.object,
  scanDateProps: PropTypes.object,
  clientProps: PropTypes.shape({
    accessLevel: PropTypes.string,
    clientId: PropTypes.string,
    recordId: PropTypes.number,
  }),
  videoId: PropTypes.string,
  hideControl: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  windowDimensions: state.windowDimensions,
  gender: state.user.get('user').get('gender'),
})

export default connect(mapStateToProps)(ScanPanelContainer)

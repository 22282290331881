import * as AdminApi from '../../lib/api/admin'
import { setPageInfo } from '../pagination'

export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const FETCH_FACILITIES_SUCCESS = 'FETCH_FACILITIES_SUCCESS'
export const FETCH_FACILITIES_FAILURE = 'FETCH_FACILITIES_FAILURE'

export const CREATE_FACILITY = 'CREATE_FACILITY'
export const CREATE_FACILITY_SUCCESS = 'CREATE_FACILITY_SUCCESS'
export const CREATE_FACILITY_FAILURE = 'CREATE_FACILITY_FAILURE'

export const UPDATE_FACILITY = 'UPDATE_FACILITY'
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS'
export const UPDATE_FACILITY_FAILURE = 'UPDATE_FACILITY_FAILURE'

export const fetchFacilities = (params) => (dispatch) => {
  dispatch({ type: FETCH_FACILITIES })

  AdminApi.fetchFacilities(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: FETCH_FACILITIES_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: FETCH_FACILITIES_FAILURE, error }))
}

export const createFacility = (data) => (dispatch) => {
  dispatch({ type: CREATE_FACILITY })

  AdminApi.createFacility(data)
    .then((result) => {
      console.log('create facility success', result)
      dispatch({
        type: CREATE_FACILITY_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({
        type: CREATE_FACILITY_FAILURE,
        error:
          error.status === 409
            ? 'Facility name already exists.'
            : 'Failed to create facility.',
      })
    })
}

export const updateFacility = ({ data, facilityId }) => (dispatch) => {
  dispatch({ type: UPDATE_FACILITY })

  AdminApi.updateFacility(facilityId, data)
    .then((result) => {
      dispatch({
        type: UPDATE_FACILITY_SUCCESS,
        payload: result,
      })
    })
    .catch(() => {
      dispatch({
        type: UPDATE_FACILITY_FAILURE,
        error: 'Failed to update facility.',
      })
    })
}

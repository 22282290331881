import React from 'react'
import PropTypes from 'prop-types'

import SubNavContainer from '../../components/navbar/sub_nav_container'
import NavItem from '../../components/navbar/nav_item'
import SubNav from '../../components/navbar/sub_nav'
import FileDownload from '../../components/utilities/file_download'

// const subIconColor = '#729ab3';

// TODO: add hocs for client access AND interest level requests

const parsePathname = (pathname) => {
  const data = {
    clientId: null,
    recordId: null,
  }

  const params = pathname.split('/')
  for (let i = 0; i < params.length; i++) {
    if (params[i].indexOf('usr-') !== -1) {
      data.clientId = params[i]
    } else if (!isNaN(parseInt(params[i], 10))) {
      data.recordId = params[i]
    }
  }
  return data
}

const ClientNav = ({ coach, currentScan, pathname, redirectPage }) => {
  let { clientId, recordId } = parsePathname(pathname)
  const scan = currentScan || coach.get('clientReport')
  recordId = recordId || (scan ? scan.get('id') : null)

  const comparisonSubNav = [
    {
      text: 'Wellness',
      clickHandler: redirectPage(
        `/staff/clients/${clientId}/comparison/wellness`
      ),
      isSelected: pathname === `/staff/clients/${clientId}/comparison/wellness`,
    },
    {
      text: 'Measurements',
      clickHandler: redirectPage(
        `/staff/clients/${clientId}/comparison/measurements`
      ),
      isSelected:
        pathname === `/staff/clients/${clientId}/comparison/measurements`,
    },
    {
      text: 'Posture',
      clickHandler: redirectPage(
        `/staff/clients/${clientId}/comparison/posture`
      ),
      isSelected: pathname === `/staff/clients/${clientId}/comparison/posture`,
    },
    {
      text: 'Overlay',
      clickHandler: redirectPage(
        `/staff/clients/${clientId}/comparison/overlay`
      ),
      isSelected: pathname === `/staff/clients/${clientId}/comparison/overlay`,
    },
  ]

  return (
    <SubNavContainer header="Client">
      <ul
        className="nav-pills flex-md-column list-unstyled"
        style={{ padding: '15px 0px' }}
      >
        <NavItem
          isSelected={
            pathname.indexOf('/staff/clients') !== -1 &&
            pathname.indexOf('/report') !== -1
          }
          text="Report"
          clickHandler={redirectPage(
            `/staff/clients/${clientId}/records/${recordId}/report`
          )}
        />
        <NavItem
          isSelected={pathname === `/staff/clients/${clientId}/records`}
          text="History"
          clickHandler={redirectPage(`/staff/clients/${clientId}/records`)}
        />
        <NavItem
          isSelected={
            pathname.indexOf(`/staff/clients/${clientId}/comparison/`) !== -1
          }
          text="Comparison"
          clickHandler={redirectPage(
            `/staff/clients/${clientId}/comparison/wellness`
          )}
        >
          <SubNav subNavProps={comparisonSubNav} />
        </NavItem>
        {/* <NavItem
          isSelected={ pathname === '/facility/dashboard' }
          text='Request Client Access'
          clickHandler={ redirectPage('/facility/dashboard') } /> */}
        {scan.get('status').toLowerCase() === 'complete' && (
          <FileDownload
            linkId={'clientHistory' + clientId + recordId}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/measurements/download`}
            style={{ width: '100%' }}
          >
            <button
              className="text-left p-0"
              type="submit"
              style={{
                background: 'transparent',
                border: 'none',
                fontWeight: 300,
              }}
            >
              <NavItem text="Download Measurements" />
            </button>
          </FileDownload>
        )}
        {/* <NavItem
          isSelected={ pathname === '/facility/dashboard' }
          text='Interest Level'
          clickHandler={ redirectPage('/facility/dashboard') } /> */}
      </ul>
    </SubNavContainer>
  )
}

ClientNav.propTypes = {
  coach: PropTypes.object,
  currentScan: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  facilityAdmin: PropTypes.bool.isRequired,
  selectedSubPage: PropTypes.string,
  redirectPage: PropTypes.func.isRequired,
}

export default ClientNav

import React from 'react'

export default function ScannerEditButton({ rowData, cellProperties }) {
  const handleClick = () => {
    cellProperties.onOpen()

    cellProperties.setScannerEditDefaults({
      scannerId: rowData.actions.id,
      scannerCode: rowData.scanner,
      captureType: parseInt(rowData.actions.captureType, 10),
      hwVersion: parseInt(rowData.hwVersion, 10),
      systemId: rowData.systemId,
      sliderSn: rowData.sliderSn,
      turntableSn: rowData.turntableSn,
      controlModuleId: rowData.controlModuleId,
      status: parseInt(rowData.actions.status, 10),
      additionalMetricsEnabled: parseInt(
        rowData.actions.additionalMetricsEnabled,
        10
      ),
    })
  }

  return (
    <button
      className="btn btn-outline-primary btn-xs py-0"
      onClick={handleClick}
    >
      Edit Scanner
    </button>
  )
}

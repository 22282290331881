import { createSelector } from 'reselect'
import visibleRecordsSelector from '../visible_records'

export default createSelector(
  (state) => visibleRecordsSelector(state),
  (records) => {
    if (!records) {
      return undefined
    }
    return records.filter((record) => record.get('posture'))
  }
)

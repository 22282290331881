import { UPDATE_RANGE, HANDLE_ANIMATION, FETCH_IMAGE } from './types'

export function updateRange(rangeValue) {
  return {
    type: UPDATE_RANGE,
    payload: rangeValue,
  }
}

export function changeAnimation(status) {
  return {
    type: HANDLE_ANIMATION,
    payload: status,
  }
}

export function selectImage(imageNumber) {
  return {
    type: FETCH_IMAGE,
    payload: imageNumber,
  }
}

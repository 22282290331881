import React from 'react'
import { connect } from 'react-redux'

import { updateFacility } from '../../../../actions/admin/facilities'

import FacilityEditModal from './Modal'
import FacilityEditForm from '../Form'

const FacilityEdit = ({
  facilities,
  toggleEditModalOpen,
  updateForm,
  updateFacility,
  closeModal,
}) => {
  const {
    isSubmitting,
    editForm,
    list,
    editModalOpen,
    errorMessage,
  } = facilities

  React.useEffect(() => {
    if (list.find((facility) => facility.id === editForm.id)) {
      closeModal()
    }
  }, [list])

  const handleUpdate = (event) => {
    const { name, value } = event.target

    updateForm({ [name]: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const { scannerCode, ...dataToSubmit } = editForm

    updateFacility({
      facilityId: editForm.id,
      data: dataToSubmit,
    })
  }

  return (
    <FacilityEditModal
      body={
        <FacilityEditForm
          isSubmitting={isSubmitting}
          data={editForm}
          submitText="Update Facility"
          onClose={toggleEditModalOpen}
          onUpdate={handleUpdate}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        />
      }
      modalOpen={editModalOpen}
      onClose={toggleEditModalOpen}
    />
  )
}

const mapStateToProps = (state) => ({
  facilities: state.newAdmin.facilities,
})

const mapDispatchToProps = (dispatch) => ({
  updateFacility: (params) => dispatch(updateFacility(params)),
  updateForm: (params) =>
    dispatch({ type: 'UPDATE_ADMIN_FACILITY_FORM', payload: params }),
  toggleEditModalOpen: () => dispatch({ type: 'TOGGLE_FACILITY_EDIT_MODAL' }),
  closeModal: () => dispatch({ type: 'CLOSE_FACILITY_EDIT_MODAL' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FacilityEdit)

import { createStore, applyMiddleware, compose } from 'redux'
// import { autoRehydrate } from 'redux-persist';
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import ReduxPromise from 'redux-promise'
import createHistory from 'history/createHashHistory'
import { routerMiddleware } from 'react-router-redux'

import { AUTH_LOGOUT } from './actions/auth'

const history = createHistory()

const sessionCheck = (store) => (next) => (action) => {
  // const token = window.localStorage.getItem("token");
  const is401 =
    action.payload &&
    action.payload.response &&
    action.payload.response.status === 401
  const expiration = window.localStorage.getItem('expires_at')

  if (is401 || (expiration && expiration < Date.now())) {
    window.localStorage.clear()
    store.dispatch({ type: AUTH_LOGOUT })
  }
  return next(action)
}

const middleware = [
  routerMiddleware(history),
  ReduxPromise,
  thunk,
  sessionCheck,
]

const enhancer = compose(
  applyMiddleware(...middleware)
  // autoRehydrate(),
)

const store = createStore(rootReducer, undefined, enhancer)

export default store

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'

import { updateCurrentScan } from '../../actions/records'

import { ModalHOC } from '../hocs/with_modal'
import NoTranslate from '../hocs/NoTranslate'
import Additional from './additional'
import VideoModal from '../scans/hocs/video_modal'
import ScanDate from '../scans/scan_dates'
import ScanImage from '../scans/scan_image'
import HistoryModal from './history_modal'

import { formatDateTime } from '../../functions/text_format'
import './wellness_history.css'

const MIN_HEIGHT_FROM_TOP = 90

class WellnessHistory extends Component {
  constructor(props) {
    super(props)

    this._handleScroll = this._handleScroll.bind(this)

    const reportHeader = document.getElementById('wellness-report-header')

    this.state = {
      displayImage: false,
      positionFromTop: reportHeader
        ? reportHeader.offsetTop
        : MIN_HEIGHT_FROM_TOP,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.surveyClosed && this.props.surveyClosed) {
      this.setState({ positionFromTop: MIN_HEIGHT_FROM_TOP })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll)
  }

  _handleScroll() {
    const reportHeader = document.getElementById('wellness-report-header')

    const wellnessGrid = document.getElementById('report-wellness-grid')

    if (wellnessGrid) {
      // handles image display when scrolling past the image section for report
      if (
        !this.state.displayImage &&
        window.scrollY >= wellnessGrid.offsetTop
      ) {
        this.setState({ displayImage: true })
      } else if (
        this.state.displayImage &&
        window.scrollY < wellnessGrid.offsetTop
      ) {
        this.setState({ displayImage: false })
      }
    }

    // handles panel position fluidly if people scroll w/ typeform
    if (reportHeader && reportHeader.getBoundingClientRect().top > 60) {
      this.setState({
        positionFromTop: reportHeader.getBoundingClientRect().top,
      })
    }
  }

  render() {
    // iterate through each and show a scan card
    const { currentScan } = this.props
    const scanDateProps = {
      scan: currentScan,
      records: this.props.records,
      type: 'current',
      updateScan: this.props.updateCurrentScan,
    }

    const handleOpen = this.props.openModal

    // NOTE: css for positioning of this side panel is super bad, should figure out a way to cleanly implement
    return (
      <div
        style={{
          position: 'fixed',
          right: '3rem',
          top: this.state.positionFromTop,
        }}
      >
        <div className="text-center wellness-history">
          <h1 className="text-uppercase mb-3 wellness-history-header">
            Your Scans
          </h1>
          <div className="mb-3">
            <ScanDate {...scanDateProps} />
          </div>
          <div className="mb-3">
            <button className="btn btn-primary btn-block" onClick={handleOpen}>
              View History
            </button>
          </div>
          <div className="mb-3">
            <Additional {...this.props} />
          </div>
          <div className="card pt-3">
            <h1 className="text-uppercase wellness-history-header">
              Scan Info
            </h1>
            <VideoModal
              shouldOpen
              header={formatDateTime(currentScan.get('recordDate'))}
              videoId="report-video"
              scanPackageId={currentScan.get('scanPackageId')}
              src={`/v1/records/${currentScan.get('scanPackageId')}/scan`}
            >
              <div
                className="mx-auto mb-2"
                style={{ display: this.state.displayImage ? 'block' : 'none' }}
              >
                <ScanImage
                  style={{ width: '85%' }}
                  imageId={currentScan.get('scanPackageId') + 'history'}
                  src={`${
                    process.env.REACT_APP_API_BASEURL
                  }/v1/records/${currentScan.get(
                    'scanPackageId'
                  )}/scan/img/0/type/blank`}
                />
                <span
                  className="fas fa-video"
                  style={{ position: 'absolute', top: '18px', right: '18px' }}
                />
              </div>
            </VideoModal>
            <div className="mb-3">
              <NoTranslate>
                {formatDateTime(currentScan.get('recordDate'))}
              </NoTranslate>
            </div>
            <table className="table text-left mb-0">
              <tbody>
                <tr>
                  <td>
                    <span style={{ fontWeight: 400 }}>Status:</span>
                  </td>
                  <td>{currentScan.get('status')}</td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontWeight: 400 }}>Posture:</span>
                  </td>
                  <td>{currentScan.get('postureStatus')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <HistoryModal {...this.props} selectedScan={currentScan} />
        </div>
      </div>
    )
  }
}

WellnessHistory.propTypes = {
  currentScan: PropTypes.object,
  records: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  surveyClosed: PropTypes.bool.isRequired,
  updateCurrentScan: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  surveyClosed: state.notifications.surveyClosed,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateCurrentScan }, dispatch)

const withHOCs = compose(ModalHOC, connect(mapStateToProps, mapDispatchToProps))

export default withHOCs(WellnessHistory)

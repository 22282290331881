import React from 'react'
import PropTypes from 'prop-types'

import NoTranslate from '../hocs/NoTranslate.js'

export const CustomColumn = ({ value }) => (
  <NoTranslate>
    <span>{value}</span>
  </NoTranslate>
)

CustomColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

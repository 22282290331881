import React from 'react'
import PropTypes from 'prop-types'

const InterestLevel = ({
  interestLevelSelected,
  updateInterestLevelSelected,
}) => (
  <div className="btn-group">
    <button
      type="button"
      className={`btn btn-outline-danger ${
        interestLevelSelected === 'no' ? 'active' : null
      }`}
      onClick={updateInterestLevelSelected('no')}
    >
      <span className="icon icon-thumbs-down" />
    </button>
    <button
      type="button"
      className={`btn btn-outline-warning ${
        interestLevelSelected === 'maybe' ? 'active' : null
      }`}
      onClick={updateInterestLevelSelected('maybe')}
    >
      <span className="icon icon-dots-three-horizontal" />
    </button>
    <button
      type="button"
      className={`btn btn-outline-success ${
        interestLevelSelected === 'yes' ? 'active' : null
      }`}
      onClick={updateInterestLevelSelected('yes')}
    >
      <span className="icon icon-thumbs-up" />
    </button>
  </div>
)

InterestLevel.propTypes = {
  interestLevelSelected: PropTypes.string,
  updateInterestLevelSelected: PropTypes.func.isRequired,
}

export default InterestLevel

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { changeAnimation } from '../../../../actions/status'

import SnapImage from '../../../scans/snap_image'
import ScanImage from '../../../scans/scan_image'
import AccessRequest from '../../action_menu/access_request'
import ScanModal from './scan_modal'

class ReportImages extends Component {
  constructor(props) {
    super(props)

    this.handleToggle = this.handleToggle.bind(this)
    this.handleModalState = this.handleModalState.bind(this)

    this.state = {
      showFullAccessImage: props.accessLevel === 'full',
      isAccessRequestModalOpen: false,
      isScanModalOpen: false,
    }
  }

  handleModalState(nextState) {
    const type = Object.keys(nextState)[0]
    if (type === 'isAccessRequestModalOpen') {
      this.props.clearMessage()
    }
    // pause video when closing the modal
    if (type === 'isScanModalOpen' && !nextState[type]) {
      this.props.changeAnimation('off')
    }
    this.setState(nextState)
  }

  handleToggle() {
    this.setState({ showFullAccessImage: !this.state.showFullAccessImage })
  }

  renderImages() {
    const { clientId, recordId, gender, accessLevel, type } = this.props
    const { showFullAccessImage } = this.state

    if (typeof type === 'string' && type.toLowerCase() === 'snap') {
      return (
        <div className="col-sm-3 mx-auto print-image">
          <SnapImage
            style={{ width: '100%' }}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/snap/img`}
            placeholderSrc={`measurement-guide-${gender.toLowerCase()}.png`}
          />
        </div>
      )
    } else if (showFullAccessImage && accessLevel === 'full') {
      return [0, 45, 180, 270].map((degree) => (
        <div key={degree} className="col-sm-3 mx-auto print-image">
          <ScanImage
            style={{ width: '85%' }}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${degree}/type/blank`}
          />
        </div>
      ))
    } else if (!showFullAccessImage) {
      const imageParams = [
        { degree: 0, type: 'slice' },
        { degree: 90, type: 'slice' },
        { degree: 0, type: 'silhouette' },
        { degree: 90, type: 'silhouette' },
      ]
      return imageParams.map((param) => (
        <div
          key={param.degree + param.type}
          className="col-sm-3 mx-auto print-image"
        >
          <ScanImage
            style={{ width: '85%' }}
            src={`${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/${param.degree}/type/${param.type}`}
          />
        </div>
      ))
    }
    return null
  }

  renderToggleButton() {
    const {
      status,
      clientId,
      recordId,
      name,
      accessRequestDate,
      handleAccessRequest,
      message,
    } = this.props
    const {
      showFullAccessImage,
      isAccessRequestModalOpen,
      isScanModalOpen,
    } = this.state
    const text = showFullAccessImage ? 'Show Silhouettes' : 'Show Full Scans'
    const style = showFullAccessImage ? 'btn-outline-primary' : 'btn-primary'

    if (this.props.accessLevel === 'full') {
      return (
        <div className="mr-3 ml-auto">
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => this.handleModalState({ isScanModalOpen: true })}
          >
            View 3D Scan
          </button>
          <button
            onClick={this.handleToggle}
            className={`btn btn-sm ${style} ml-3`}
          >
            {text}
          </button>
          <ScanModal
            status={status}
            clientId={clientId}
            recordId={recordId}
            name={name}
            isScanModalOpen={isScanModalOpen}
            closeScanModal={() =>
              this.handleModalState({ isScanModalOpen: false })
            }
          />
        </div>
      )
    }
    return (
      <div className="mr-3 ml-auto">
        <button
          className={`btn btn-sm ${style}`}
          onClick={() =>
            this.handleModalState({ isAccessRequestModalOpen: true })
          }
        >
          Request Full Access
        </button>
        <AccessRequest
          clientId={clientId}
          name={name}
          isAccessRequestModalOpen={isAccessRequestModalOpen}
          closeAccessRequestModal={() =>
            this.handleModalState({ isAccessRequestModalOpen: false })
          }
          accessRequestDate={accessRequestDate}
          message={message}
          handleAccessRequest={handleAccessRequest}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="mb-5 px-3 py-3" style={{ border: '1px #cecece solid' }}>
        <div className="row text-center mb-3 print-image-container">
          {this.renderImages()}
        </div>
        <div className="row print-none">{this.renderToggleButton()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ status: state.status })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeAnimation }, dispatch)

ReportImages.propTypes = {
  status: PropTypes.object,
  changeAnimation: PropTypes.func,
  clientId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  accessLevel: PropTypes.string.isRequired,
  name: PropTypes.string,
  accessRequestDate: PropTypes.string,
  handleAccessRequest: PropTypes.func.isRequired,
  message: PropTypes.string,
  clearMessage: PropTypes.func,
  type: PropTypes.string,
  currentScan: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportImages)

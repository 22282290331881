import { createSelector } from 'reselect'

import completeClientRecordsSelector from './complete_client_records'

export default createSelector(
  (state) => state.current.get('baselineScan'),
  (state) => completeClientRecordsSelector(state),
  (baselineScan, clientRecords) => {
    if (!clientRecords || clientRecords.size === 0) {
      return undefined
    }

    if (
      baselineScan &&
      clientRecords.find(
        (record) => record.get('id') === baselineScan.get('id')
      )
    ) {
      return baselineScan
    } else {
      return clientRecords.last()
    }
  }
)

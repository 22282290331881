import {
  UPDATE_CURRENT_SCAN,
  UPDATE_BASELINE_SCAN,
  SCAN_RECORDS_REQUEST,
  SCAN_RECORDS_SUCCESS,
  SCAN_RECORDS_FAILURE,
  FINISH_SCAN_UPDATES,
} from './types'

import * as types from './types'
import action from './middleware'
import { orderedRecords } from '../selectors/ordered_records'
import { getValidRecord } from '../functions/records'

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const updateBaselineScan = (scan) => (dispatch) => {
  return delay(10).then(() => {
    dispatch({
      type: UPDATE_BASELINE_SCAN,
      payload: scan,
    })
  })
}

export const updateCurrentScan = (scan) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_CURRENT_SCAN,
      payload: scan,
    })

    const baselineScan = getState().current.get('baselineScan')
    if (baselineScan) {
      return null
    }
    const oldestScan = orderedRecords(getState()).last()
    return delay(10).then(() => {
      dispatch(updateBaselineScan(oldestScan))
    })
  }
}

// Scan Records
export const fetchScanRecords = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/records',
      method: 'GET',
      actionTypes: {
        requestType: SCAN_RECORDS_REQUEST,
        successType: SCAN_RECORDS_SUCCESS,
        failureType: SCAN_RECORDS_FAILURE,
      },
    },
    dispatch
  )

// NOTE: use this for future test purposes when we want to test custom JSON on client side
// export const fetchScanRecords = () => dispatch => {
//   return new Promise((resolve) => {
//     // resolve();
//     resolve(dispatch({
//       type: SCAN_RECORDS_SUCCESS,
//       payload: {
//         data: testJSON
//       }
//     }));
//   });
// };

export const loadScanRecords = () => {
  return (dispatch, getState) => {
    const scanRecords = getState().records.get('records')
    if (scanRecords.size !== 0) {
      return null
    }

    return dispatch(fetchScanRecords()).then(() => {
      const currentScan = getState().current.get('currentScan')
      if (currentScan) {
        return null
      }

      const newestScan = getValidRecord(orderedRecords(getState()))

      return dispatch(updateCurrentScan(newestScan)).then(() => {
        const baselineScan = getState().current.get('baselineScan')
        if (baselineScan) {
          return null
        }
        const oldestScan = getValidRecord(orderedRecords(getState()).reverse())
        return dispatch(updateBaselineScan(oldestScan)).then(() =>
          dispatch({ type: FINISH_SCAN_UPDATES })
        )
      })
    })
  }
}

// NOTE: MIGHT NOT WORK, LOOK AT HOW IT'S CALLED
export const updateScanRecord = (params) => (dispatch) => {
  return action(
    {
      endpoint: `/v1/records/${params.id}`,
      method: 'POST',
      data: params,
      meta: {
        recordId: params.id,
      },
      actionTypes: {
        successType: types.UPDATE_SCAN_RECORD,
        failureType: types.UPDATE_SCAN_RECORD_FAILURE,
      },
    },
    dispatch
  ).then(() => {
    return dispatch(updateCurrentScan(params))
  })
}

export const deleteScanRecord = (recordId) => (dispatch) =>
  action(
    {
      endpoint: `/v1/records/${recordId}`,
      method: 'DELETE',
      actionTypes: {
        successType: types.DELETE_SCAN_RECORD,
        failureType: types.DELETE_SCAN_RECORD_FAILURE,
      },
      meta: { recordId },
    },
    dispatch
  )

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { deprecateScanner } from '../../../../actions/admin/scanners'

import DeprecateStatusMessage from './StatusMessage'

const DeprecateModal = ({
  scannerCode,
  modalOpen,
  onClose,
  deprecate,
  resetDeprecateStatus,
}) => {
  const [clicked, setClicked] = React.useState(false)

  const handleClose = () => {
    onClose()
    setClicked(false)
    resetDeprecateStatus()
  }

  const handleDeprecate = () => {
    setClicked(true)
    deprecate(scannerCode)
  }

  return (
    <Modal isOpen={modalOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <h5>Deprecate Scanner</h5>
      </ModalHeader>
      <ModalBody>
        <div>
          You are about to deprecate scanner code&nbsp;
          <span style={{ color: '#1686b0' }}>{scannerCode}</span>.
        </div>
        <DeprecateStatusMessage />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={handleClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          disabled={clicked}
          onClick={handleDeprecate}
        >
          Deprecate
        </button>
      </ModalFooter>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({
  deprecate: (scannerCode) => dispatch(deprecateScanner(scannerCode)),
  resetDeprecateStatus: () => dispatch({ type: 'RESET_DEPRECATE_STATUS' }),
})

export default connect(null, mapDispatchToProps)(DeprecateModal)

import React, { Component } from 'react'

import MetricPanel from './metric_panel'

import './report.css'

export default class MetricsInfo extends Component {
  render() {
    return (
      <div className="metrics-panel">
        <div
          className="metrics-header-container"
          style={{ marginBottom: '60px' }}
        >
          <h1 className="hr-divider-content">Metrics</h1>
        </div>
        {/* <div className='bss'>
          <h3>{ `Your Body Shape Rating: ${this.props.currentMetric.get('bodyShapeRating')}` }</h3>
        </div> */}
        <MetricPanel
          title="Body Shape"
          currentMetric={this.props.currentMetric}
          user={this.props.user}
        />
        <MetricPanel
          title="Body Composition"
          currentMetric={this.props.currentMetric}
          user={this.props.user}
        />
        <MetricPanel
          title="Additional"
          currentMetric={this.props.currentMetric}
          user={this.props.user}
        />
      </div>
    )
  }
}

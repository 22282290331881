import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { updateCurrentScan } from '../../actions/records'

import currentScanSelector from '../../selectors/current_scan'
import currentMetricSelector from '../../selectors/current_metric'
import baselineMetricSelector from '../../selectors/baseline_metric'
import visibleRecordsSelector from '../../selectors/visible_records'

import NoTranslate from '../hocs/NoTranslate'
import ScanDate from '../scans/scan_dates'
import ReportImages from './images'
import MeasurementInfo from '../measurements/measurement_info'
import MetricsInfo from './metrics_list'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

import './report.css'

class Report extends Component {
  constructor(props) {
    super(props)

    this.toggleImageDisplay = this.toggleImageDisplay.bind(this)
    this.handleDownload = this.handleDownload.bind(this)

    this.state = { showImages: true }
  }

  toggleImageDisplay() {
    if (this.state.showImages) {
      this.setState({ showImages: false })
    } else {
      this.setState({ showImages: true })
    }
  }

  handleDownload(event) {
    event.preventDefault()
    window.ga('send', 'event', 'Button', 'click', 'Click on Download Report')
    window.print()
  }

  render() {
    const user = this.props.user.get('user')
    const { showImages } = this.state
    const { records, metrics, current, currentScan, currentMetric } = this.props
    const { width } = this.props.windowDimensions

    if (
      current.size === 0 ||
      !metrics.get('metrics') ||
      !user ||
      this.props.loadingRecords
    ) {
      return <Loading />
    } else if (records.size === 0 || !currentScan || !currentMetric) {
      return (
        <Unavailable title="No available scans for report">
          <div>
            Click <u onClick={() => this.props.history.push('/')}>here</u> to
            return to your dashboard.
          </div>
        </Unavailable>
      )
    }
    const handleToggle = this.toggleImageDisplay
    return (
      <div className="container py-4">
        <div className="hr-divider header-container">
          <h1 className="hr-divider-content">
            <NoTranslate>{user.get('firstName')}</NoTranslate>
            's Fit3D Report
          </h1>
        </div>
        <div className="row mb-3 text-center date-selector">
          <div className="mb-3 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 d-flex justify-content-center">
            <div className="col-6">
              <h3 className="mt-0">Select a scan to view report:</h3>
            </div>
            <div className="col-6">
              <ScanDate
                report
                records={records}
                scan={currentScan}
                updateScan={this.props.updateCurrentScan}
              />
            </div>
          </div>
          <div
            className={`col-sm-8 col-sm-offset-2 col-lg-8 col-lg-offset-2 ${
              width && width < 1024 ? 'hidden' : ''
            }`}
          >
            <div className="btn-group">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={handleToggle}
              >
                {showImages ? 'Hide Images' : 'Show Images'}
              </button>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={this.handleDownload}
              >
                Download Report
              </button>
              {records.size >= 2 && (
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push('/report/comparison')
                  }}
                >
                  Comparison Report
                </button>
              )}
            </div>
          </div>
        </div>
        <ReportImages
          packageId={currentScan.get('scanPackageId')}
          showImages={showImages}
        />
        <div className="row mx-0">
          <div className="col-sm-6">
            <MeasurementInfo
              user={this.props.user}
              records={this.props.records}
              currentScan={this.props.currentScan}
            />
          </div>
          <div className="col-sm-6">
            <MetricsInfo user={this.props.user} currentMetric={currentMetric} />
          </div>
        </div>
      </div>
    )
  }
}

Report.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  loadingRecords: PropTypes.bool,
  records: PropTypes.object,
  metrics: PropTypes.object,
  current: PropTypes.object,
  currentScan: PropTypes.object,
  baselineMetric: PropTypes.object,
  currentMetric: PropTypes.object,
  updateCurrentScan: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    windowDimensions: state.windowDimensions,
    loadingRecords: state.records.get('loading'),
    records: visibleRecordsSelector(state),
    metrics: state.metrics,
    current: state.current,
    currentScan: currentScanSelector(state),
    baselineMetric: baselineMetricSelector(state),
    currentMetric: currentMetricSelector(state),
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateCurrentScan }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)

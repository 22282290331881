// NEW WELLNESS
export const FETCH_WELLNESS_REPORT = 'FETCH_WELLNESS_REPORT'
export const FETCH_WELLNESS_REPORT_SUCCESS = 'FETCH_WELLNESS_REPORT_SUCCESS'
export const FETCH_WELLNESS_REPORT_FAILURE = 'FETCH_WELLNESS_REPORT_FAILURE'

export const UPDATE_SELECTED_METRIC = 'UPDATE_SELECTED_METRIC'

// OLD
export const FETCH_METRICS = 'FETCH_METRICS'
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS'
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE'

export const UPDATE_METRIC = 'UPDATE_METRIC'
export const UPDATE_METRIC_SUCCESS = 'UPDATE_METRIC_SUCCESS'
export const UPDATE_METRIC_FAILURE = 'UPDATE_METRIC_FAILURE'

export const FETCH_FACILITY_ADDITIONAL = 'FETCH_FACILITY_ADDITIONAL'
export const FETCH_FACILITY_ADDITIONAL_SUCCESS =
  'FETCH_FACILITY_ADDITIONAL_SUCCESS'
export const FETCH_FACILITY_ADDITIONAL_FAILURE =
  'FETCH_FACILITY_ADDITIONAL_FAILURE'

export const FETCH_USER_ADDITIONAL = 'FETCH_USER_ADDITIONAL'
export const FETCH_USER_ADDITIONAL_SUCCESS = 'FETCH_USER_ADDITIONAL_SUCCESS'
export const FETCH_USER_ADDITIONAL_FAILURE = 'FETCH_USER_ADDITIONAL_FAILURE'

export const UPDATE_ADDITIONAL = 'UPDATE_ADDITIONAL'
export const UPDATE_ADDITIONAL_SUCCESS = 'UPDATE_ADDITIONAL_SUCCESS'
export const UPDATE_ADDITIONAL_FAILURE = 'UPDATE_ADDITIONAL_FAILURE'

export const ADD_ADDITIONAL = 'ADD_ADDITIONAL'
export const ADD_ADDITIONAL_SUCCESS = 'ADD_ADDITIONAL_SUCCESS'
export const ADD_ADDITIONAL_FAILURE = 'ADD_ADDITIONAL_FAILURE'

export const CLEAR_METRICS_STATUS_MESSAGE = 'CLEAR_METRICS_STATUS_MESSAGE'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const MAX_BUTTONS = 8

class HistoryPagination extends Component {
  constructor(props) {
    super(props)
    this.handlePageSelect = this.handlePageSelect.bind(this)
  }

  handlePageSelect(event) {
    this.props.setPage(parseInt(event.target.getAttribute('value'), 10) - 1)
  }

  renderPagination() {
    const {
      pageProperties: { maxPages, currentPage },
    } = this.props
    const totalButtons = maxPages < MAX_BUTTONS ? maxPages : MAX_BUTTONS

    const delta = 4
    const upperbound =
      currentPage + delta > totalButtons
        ? currentPage + delta - 1
        : totalButtons
    const range = []
    const pages = []

    for (let i = currentPage - delta; i <= upperbound; i++) {
      if (i <= maxPages && i >= 1) range.push(i)
    }

    for (const i of range) {
      pages.push(
        <PaginationItem
          key={i}
          active={currentPage === i}
          value={i}
          onClick={this.handlePageSelect}
        >
          <PaginationLink
            value={i}
            style={{
              border:
                currentPage === i ? '1px #1997c6 solid' : '1px #ced4da solid',
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return pages
  }

  render() {
    const {
      pageProperties: { maxPages, currentPage },
    } = this.props
    return (
      <div className="paginate">
        <div className="pagination-container" style={{ marginLeft: '20px' }}>
          <Pagination>
            {/* See setPage function in /admin/grid_wrapper.js to for explanation for weird arguments lol */}
            <PaginationItem
              disabled={currentPage === 1}
              onClick={currentPage > 1 ? () => this.props.setPage(0) : null}
            >
              <PaginationLink style={{ border: '1px #ced4da solid' }}>
                First
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              disabled={currentPage === 1}
              onClick={
                currentPage > 1
                  ? () => this.props.setPage(currentPage - 2)
                  : null
              }
            >
              <PaginationLink
                previous
                style={{ border: '1px #ced4da solid' }}
              />
            </PaginationItem>
            {this.renderPagination()}
            <PaginationItem
              disabled={currentPage === maxPages}
              onClick={
                currentPage < maxPages
                  ? () => this.props.setPage(currentPage)
                  : null
              }
            >
              <PaginationLink next style={{ border: '1px #ced4da solid' }} />
            </PaginationItem>
            <PaginationItem
              disabled={currentPage === maxPages}
              onClick={
                currentPage < maxPages
                  ? () => this.props.setPage(maxPages - 1)
                  : null
              }
            >
              <PaginationLink style={{ border: '1px #ced4da solid' }}>
                Last
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    )
  }
}

HistoryPagination.propTypes = {
  records: PropTypes.object,
  setPage: PropTypes.func.isRequired,
  pageProperties: PropTypes.shape({
    maxPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }),
}

export default HistoryPagination

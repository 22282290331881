export const FETCH_ALL_COMPANIES = 'FETCH_ALL_COMPANIES'
export const FETCH_ALL_COMPANIES_SUCCESS = 'FETCH_ALL_COMPANIES_SUCCESS'
export const FETCH_ALL_COMPANIES_FAILURE = 'FETCH_ALL_COMPANIES_FAILURE'

export const FETCH_REFERRAL_URL = 'FETCH_REFERRAL_URL'
export const FETCH_REFERRAL_URL_SUCCESS = 'FETCH_REFERRAL_URL_SUCCESS'
export const FETCH_REFERRAL_URL_FAILURE = 'FETCH_REFERRAL_URL_FAILURE'

export const FETCH_ALL_REFERRAL_URLS = 'FETCH_ALL_REFERRAL_URLS'
export const FETCH_ALL_REFERRAL_URLS_SUCCESS = 'FETCH_ALL_REFERRAL_URLS_SUCCESS'
export const FETCH_ALL_REFERRAL_URLS_FAILURE = 'FETCH_ALL_REFERRAL_URLS_FAILURE'

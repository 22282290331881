import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImageCol from '../../../newWellness/posture/image'
import PostureDataTable from '../../../newWellness/posture/data_table'

class PrintPosture extends Component {
  constructor(props) {
    super(props)

    const posture = props.clientReport.get('posture')

    this.displayProps = {
      front: {
        degree: 0,
        direction: 'front',
        data: posture.get('front'),
      },
      side: {
        degree: 90,
        direction: 'side',
        data: posture.get('side'),
      },
      back: {
        degree: 180,
        direction: 'back',
        data: posture.get('front'),
      },
    }
  }

  render() {
    const { clientId, recordId } = this.props
    const accessLevel = this.props.clientReport.get('accessLevel')
    return (
      <div>
        <div>
          <h2>Posture</h2>
        </div>
        <div className="row">
          <h4 className="col-12">Front/Back</h4>
          <div className="col-4">
            <ImageCol
              {...this.displayProps.front}
              {...this.props}
              src={
                accessLevel === 'full'
                  ? `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/posture`
                  : `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/0/type/posture-silhouette`
              }
              imageId={clientId + recordId + 0 + 'print'}
              packageId={this.props.clientReport.get('scanPackageId')}
            />
          </div>
          <div className="col-8">
            <PostureDataTable {...this.displayProps.front} {...this.props} />
          </div>
        </div>
        <div className="row">
          <h4 className="col-12">Side</h4>
          <div className="col-4">
            <ImageCol
              {...this.displayProps.front}
              {...this.props}
              src={
                accessLevel === 'full'
                  ? `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/90/type/posture`
                  : `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${clientId}/records/${recordId}/scan/img/90/type/posture-silhouette`
              }
              imageId={clientId + recordId + 90 + 'print'}
              packageId={this.props.clientReport.get('scanPackageId')}
            />
          </div>
          <div className="col-8">
            <PostureDataTable {...this.displayProps.side} {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

PrintPosture.propTypes = {
  clientId: PropTypes.string,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  clientReport: PropTypes.object,
  accessLevel: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
}

export default PrintPosture

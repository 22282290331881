import React from 'react'

import stateOptions from './codes/stateCodes'
import countryOptions from './codes/countryCodes'

export default function FacilityEditForm({
  isSubmitting,
  data,
  submitText,
  errorMessage,
  onClose,
  onUpdate,
  onSubmit,
}) {
  return (
    <form style={{ boxSizing: 'border-box' }}>
      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Facility Name</label>
          <input
            className="form-control"
            name="name"
            value={data.name}
            onChange={onUpdate}
          />
        </div>
        <div className="col-sm-6">
          <label>Company</label>
          <input
            className="form-control"
            name="company"
            value={data.company}
            onChange={onUpdate}
          />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>BFP Access</label>
          <select
            className="form-control custom-select"
            name="bfpAccess"
            value={data.bfpAccess}
            onChange={onUpdate}
          >
            <option value={0}>Off</option>
            <option value={1}>On</option>
          </select>
        </div>
        <div className="col-sm-6">
          <label>Posture Access</label>
          <select
            className="form-control custom-select"
            name="postureAccess"
            value={data.postureAccess}
            onChange={onUpdate}
          >
            <option value={0}>Off</option>
            <option value={1}>On</option>
          </select>
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Coach Filter</label>
          <select
            className="form-control custom-select"
            name="defaultCoachFilter"
            value={data.defaultCoachFilter}
            onChange={onUpdate}
          >
            <option value="all">All</option>
            <option value="clients">Clients</option>
          </select>
        </div>
        <div className="col-sm-6">
          <label>Enterprise</label>
          <select
            className="form-control custom-select"
            name="enterprise"
            value={!!data.enterprise}
            onChange={onUpdate}
          >
            <option value={null}>None</option>
            <option value="2030">20/30</option>
            <option value="crunch">Crunch</option>
            <option value="youfit">YouFit</option>
          </select>
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Processing Priority</label>
          <select
            className="form-control custom-select"
            name="pipelinePriority"
            value={data.pipelinePriority}
            onChange={onUpdate}
          >
            <option value="standard">SPE v2 (default)</option>
            <option value="basic">SPE v1</option>
          </select>
        </div>
        {/* <div className="col-sm-6"></div> */}
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>City</label>
          <input
            className="form-control"
            name="addressCity"
            value={data ? data.addressCity : null}
            onChange={onUpdate}
          />
        </div>
        <div className="col-sm-6">
          <label>State/Province</label>
          <select
            className="form-control custom-select"
            name="addressState"
            value={data ? data.addressState : null}
            onChange={onUpdate}
          >
            {stateOptions}
          </select>
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Zip/Postal Code</label>
          <input
            className="form-control"
            name="addressZip"
            value={data.addressZip}
            onChange={onUpdate}
          />
        </div>
        <div className="col-sm-6">
          <label>Country</label>
          <select
            className="form-control custom-select"
            name="addressCountry"
            value={data.addressCountry}
            onChange={onUpdate}
          >
            {countryOptions}
          </select>
        </div>
      </div>

      {errorMessage && (
        <div className="row d-flex justify-content-center mx-0 py-3">
          <span className="text-danger">{errorMessage}</span>
        </div>
      )}

      <div className="row mx-0 px-3 pt-3">
        <div className="ml-auto">
          <button
            className="btn btn-default mr-3"
            onClick={onClose}
            type="button"
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            {submitText || 'Update Facility'}
          </button>
        </div>
      </div>
    </form>
  )
}

// export * as records from './records';
// export * as status from './status';
// export * as marketplace from './marketplace';
// export * as VideoActions from './scan/video';

export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS'

export const updateWindowDimensions = (dimensions) => ({
  type: UPDATE_DIMENSIONS,
  payload: dimensions,
})

// Plotlines
// NOTE: later make it so that it accounts for array of values (multiple plotlines)
export const generatePlotLines = (value) => {
  const plotLines = []

  plotLines.push({
    color: '#1686b0',
    value: value,
    width: 2,
    zIndex: 4,
    dashStyle: 'ShortDash',
    label: {
      text: `You (${value})`,
      rotation: 0,
      style: {
        color: 'white',
        background: '#1686b0',
        border: '1px #1686b0 solid',
        borderRadius: '2px',
        padding: '3px 15px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.18)',
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.20)',
      },
      useHTML: true,
      x: -40,
      y: 130,
    },
  })

  return plotLines
}

// Plotbands
// NOTE: make sure we can take in multiple ranges of data, not just the wellnessmetrics
let positions = {
  needsImprovement: 3,
  average: 2,
  ideal: 1,
  lean: 0,
}

const colors = {
  needsImprovement: 'rgba(235, 54, 29, 0.5)',
  average: 'rgba(255, 204, 92, 0.5)',
  ideal: 'rgba(83, 174, 133, 0.5)',
  lean: 'rgba(57, 203, 222, 0.6)',
}

const titles = {
  needsImprovement: 'Needs Improvement',
  average: 'Average',
  ideal: 'Ideal',
  lean: 'Lean',
}

const isReverseGradient = {
  bodyShapeRating: true,
  leanMass: true,
  bmr: true,
}

export const generatePlotBands = (ranges, metric) => {
  if (isReverseGradient[metric]) {
    positions = {
      needsImprovement: 0,
      average: 1,
      ideal: 2,
      lean: 3,
    }
  }

  const plotBands = []

  const orderedRanges = Object.keys(ranges).sort((a, b) => {
    if (positions[a] < positions[b]) {
      return -1
    } else if (positions[a] > positions[b]) {
      return 1
    }
    return 0
  })

  orderedRanges.forEach((range, idx) => {
    let color = colors[range]
    if (idx < orderedRanges.length - 1) {
      color = {
        linearGradient: isReverseGradient[metric]
          ? { x1: 0, x2: 1, y1: 0, y2: 0 }
          : { x1: 1, x2: 0, y1: 0, y2: 0 },
        stops: [
          [0, colors[range]],
          [0.5, colors[range]],
          [1, colors[orderedRanges[idx + 1]]],
        ],
      }
    }

    const plotBand = {
      color,
      from: ranges[range][0],
      to: ranges[range][1],
      label: {
        text: titles[range],
        style: {
          zIndex: 9999,
          color: '#484848',
          letterSpacing: '2px',
        },
        userHTML: true,
        y: 20,
      },
    }

    plotBands.push(plotBand)
  })

  return plotBands
}

// Misc Chart Rendering
export const findMax = (data) => {
  let currentMax = 0
  data.forEach((point) => {
    if (point[1] > currentMax) {
      currentMax = point[1]
    }
  })
  return Math.round(currentMax)
}

export const makeMarker = (x, y, fillColor) => ({
  x,
  y,
  marker: {
    enabled: true,
    fillColor,
  },
})

export const convertPercentage = (data) => {
  let sum = 0
  data.forEach((point) => {
    if (point.y) {
      sum = sum + point.y
    } else {
      sum = sum + point[1]
    }
  })

  data.forEach((point) => {
    if (point.y) {
      point.y = parseFloat(((point.y / sum) * 100).toFixed(1))
    } else {
      point[1] = parseFloat(((point[1] / sum) * 100).toFixed(1))
    }
  })
  return data
}

import { createSelector } from 'reselect'
import _ from 'lodash'
import Immutable from 'immutable'

const scanSelector = (state) => state.entities.get('scans')
const scanResultSelector = (state) => state.result.get('scans')

const getOrderedScans = (scans, scanResults) => {
  var orderedScans = scanResults.toJS().map((value) => {
    return _.find(scans.toJS(), (scan) => scan.scanId === value)
  })
  return Immutable.fromJS(orderedScans)
}

export default createSelector(scanSelector, scanResultSelector, getOrderedScans)

import action from '../middleware'
// import axios from 'axios';

export const VALIDATE_RECORD_SUCCESS = 'VALIDATE_RECORD_SUCCESS'
export const VALIDATE_RECORD_FAILURE = 'VALIDATE_RECORD_FAILURE'

export const validateRecord = ({ recordId, data }) => (dispatch) =>
  action(
    {
      endpoint: `/v1/admin/records/${recordId}/validate/manual`,
      method: 'POST',
      data,
      actionTypes: {
        successType: VALIDATE_RECORD_SUCCESS,
        failureType: VALIDATE_RECORD_FAILURE,
      },
      meta: { recordId },
    },
    dispatch
  )

// export const validateRecord = ({ recordId, data }) => dispatch => {
//   return axios({
//     method: 'post',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/admin/records/${recordId}/validate/manual`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data
//   })
//   .then(response => dispatch({
//     type: VALIDATE_RECORD_SUCCESS,
//     payload: response,
//     meta: { recordId }
//   }))
//   .catch(error => dispatch({
//     type: VALIDATE_RECORD_FAILURE,
//     payload: error
//   }));
// };

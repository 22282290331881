import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { resetSearchState } from '../actions/search'

import AdminValidation from '../components/admin/validation'
import AdminRecords from '../components/admin/records'
import AdminPostureQC from '../components/admin/posture/qc'
import QC from '../components/admin/qc'
import AdminCoaches from '../components/admin/coaches'
import AdminUsers from '../components/admin/users'
import AdminFacilities from '../components/admin/facilities'
import AdminScanners from '../components/admin/scanners'

const AdminPage = ({ user, history, resetSearchState }) => {
  React.useEffect(() => {
    if (!user.get('user').get('superAdmin')) {
      history.push('/')
    }
    return () => {
      resetSearchState()
    }
  }, [])

  return (
    <div className="py-4">
      <Switch>
        <Route exact path="/admin/validation" component={AdminValidation} />
        <Route exact path="/admin/records" component={AdminRecords} />
        <Route
          exact
          path="/admin/records/posture/qc"
          component={AdminPostureQC}
        />
        <Route exact path="/admin/records/qc" component={QC} />
        <Route exact path="/admin/coaches" component={AdminCoaches} />
        <Route exact path="/admin/users" component={AdminUsers} />
        <Route exact path="/admin/facilities" component={AdminFacilities} />
        <Route exact path="/admin/scanners" component={AdminScanners} />
      </Switch>
    </div>
  )
}

AdminPage.propTypes = {
  resetSearchState: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  resetSearchState: () => dispatch(resetSearchState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BodyTable extends Component {
  getCurrentMeasurementData(key) {
    const recordId = this.props.currentScan.get('id')
    return this.getSingleMeasurement(recordId, key)
  }

  getBaselineMeasurementData(key) {
    const baselineScan = this.props.baselineScan
    const baselineScanId = baselineScan.get('id')

    return !baselineScan
      ? this.getCurrentMeasurementData(key)
      : this.getSingleMeasurement(baselineScanId, key)
  }

  getSingleMeasurement(recordId, key) {
    const measurements = this.props.records
      .find((record) => {
        return record.get('id') === recordId
      })
      .get('scanMeasurement')

    return measurements.get(key)
  }

  renderMeasurementData() {
    return this.props.bodySegments[this.props.selectedSegment].map(
      (segment) => {
        const key = Object.keys(segment)[0]
        const baselineMeasurement = this.getBaselineMeasurementData(key)
        const currentMeasurement = this.getCurrentMeasurementData(key)
        const difference = Math.max(
          ((currentMeasurement - baselineMeasurement) * 10) / 10
        ).toFixed(1)

        return (
          <tr key={key}>
            <td>{segment[key]}</td>
            <td>{baselineMeasurement}</td>
            <td>{this.getCurrentMeasurementData(key)}</td>
            <td>{difference}</td>
          </tr>
        )
      }
    )
  }

  render() {
    const units =
      this.props.user.get('user').get('units') === 'US' ? 'Inches' : 'cm'
    return (
      <table
        className="table table-bordered table-hover"
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>
              <span isolate="true">Baseline</span>
              <span isolate="true">{` (${units})`}</span>
            </th>
            <th>
              <span isolate="true">Current</span>
              <span isolate="true">{` (${units})`}</span>
            </th>
            <th>
              <span isolate="true">Diff</span>
              <span isolate="true">{` (${units})`}</span>
            </th>
          </tr>
        </thead>
        <tbody>{this.renderMeasurementData()}</tbody>
      </table>
    )
  }
}

BodyTable.propTypes = {
  records: PropTypes.object,
  currentScan: PropTypes.object,
  baselineScan: PropTypes.object,
  bodySegments: PropTypes.object,
  selectedSegment: PropTypes.string,
  user: PropTypes.object,
}

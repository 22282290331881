import Immutable from 'immutable'

import * as types from '../../actions/datasets/types'

const reducers = {
  [types.FETCH_HISTOGRAM_DATA]: (state) => state.set('loading', true),
  [types.FETCH_HISTOGRAM_DATA_SUCCESS]: (state, action) => {
    const { gender } = action.meta
    return state
      .setIn(['histograms', gender], action.payload.data)
      .set('loading', false)
  },
  [types.FETCH_HISTOGRAM_DATA_FAILURE]: (state) => state.set('loading', false),
  // Client Health Ranges
  [types.FETCH_HEALTH_RANGES]: (state) => state.set('loading', true),
  [types.FETCH_HEALTH_RANGES_SUCCESS]: (state, action) => {
    return state
      .set('clientHealthRanges', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [types.FETCH_HEALTH_RANGES_FAILURE]: (state) => state.set('loading', false),
  // User's own health ranges
  [types.FETCH_WELLNESS_RANGES]: (state) => state.set('loading', true),
  [types.FETCH_WELLNESS_RANGES_SUCCESS]: (state, action) => {
    return state
      .set('healthRanges', Immutable.fromJS(action.payload.data))
      .set('loading', false)
  },
  [types.FETCH_WELLNESS_RANGES_FAILURE]: (state) => state.set('loading', false),
}

const defaultState = new Immutable.Map({
  histograms: new Immutable.Map({ male: null, female: null }),
  healthRanges: new Immutable.Map(),
  clientHealthRanges: new Immutable.Map(),
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

import moment from 'moment'

export const capitalize = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : string

export const parseComma = (string) =>
  string ? string.toString().replace(/,/g, '.') : string

export const formatDateTime = (date) =>
  moment
    .utc(date)
    .utcOffset(moment().utcOffset())
    .format('YYYY-MM-DD (hh:mm A)')

export const convertUTC = (dateObject) => moment(dateObject).valueOf()

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { capitalize } from '../../lib/formatText'

export default class Actions extends Component {
  constructor(props) {
    super(props)

    this.handleValidation = this.handleValidation.bind(this)
    // this.state = { value: props.rowData.scanValid };
  }

  handleValidation(event) {
    const { recordId } = this.props.rowData.metadata
    const data = {
      id: recordId,
      scanValid: event.target.value,
    }

    // this.setState({ value: event.target.value });
    this.props.cellProperties.handleValidation(data)
  }

  renderButtons() {
    const { scanValid } = this.props.rowData
    return ['unknown', 'invalid', 'valid'].map((value) => {
      const style =
        scanValid === value
          ? 'btn-outline-primary style active'
          : 'btn-outline-primary'
      return (
        <button
          key={_.uniqueId()}
          type="button"
          value={value}
          onClick={this.handleValidation}
          className={`btn btn-sm btn-block ${style}`}
        >
          {capitalize(value)}
        </button>
      )
    })
  }

  render() {
    return <div>{this.renderButtons()}</div>
  }
}

Actions.propTypes = {
  rowData: PropTypes.object,
  cellProperties: PropTypes.object,
}

// export const validate = values => {
//   const errors = {};
//   if (!values.firstName) {
//     errors.firstName = 'First name is required';
//   }
//
//   if (!values.lastName) {
//     errors.lastName = 'Last name is required';
//   }
//
//   if (!values.email) {
//     errors.email = 'Email is required';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = 'Invalid email address';
//   }
//
//   return errors;
// };
//
// import { SubmissionError } from 'redux-form';
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
//
// function submit(values) {
//   return sleep(1000) // simulate server latency
//     .then(() => {
//       if (![ 'john', 'paul', 'george', 'ringo' ].includes(values.username)) {
//         throw new SubmissionError({ username: 'User does not exist', _error: 'Login failed!' })
//       } else if (values.password !== 'redux-form') {
//         throw new SubmissionError({ password: 'Wrong password', _error: 'Login failed!' })
//       } else {
//         window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
//       }
//     })
// }
//
// export default submit

export const validate = (values) => {
  const errors = {}
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/

  if (!regex.test(values.birthDate)) {
    errors.birthDate =
      'Birth date is required in the format (YYYY-MM-DD). Please submit a valid birth date.'
  }

  return errors
}

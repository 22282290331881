import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Scroll from 'react-scroll'

import './glossary.css'

class Glossary extends Component {
  constructor(props) {
    super(props)

    this._handleBrowserScroll = this._handleScroll.bind(this)
    this.scrollTop = this.scrollTop.bind(this)

    this.state = { showReturnTop: false, navFixed: false }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleBrowserScroll)
    if (this.props.location.query) {
      const { code } = this.props.location.query
      if (code) {
        this.handleScroll(code)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleBrowserScroll)
  }

  _handleScroll() {
    const { showReturnTop, navFixed } = this.state
    const headerHeight = this.getHeaderHeight()

    if (!showReturnTop && window.scrollY >= headerHeight) {
      this.setState({ showReturnTop: true })
    } else if (showReturnTop && window.scrollY < headerHeight) {
      this.setState({ showReturnTop: false })
    }

    if (!navFixed && window.scrollY > headerHeight) {
      this.setState({ navFixed: true })
    } else if (navFixed && window.scrollY <= headerHeight) {
      this.setState({ navFixed: false })
    }
  }

  handleScroll(id) {
    Scroll.scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: true,
    })
  }

  scrollTop() {
    Scroll.animateScroll.scrollToTop()
  }

  getHeaderHeight() {
    return document.getElementById('glossary-header').offsetHeight
  }

  render() {
    const handleScroll = this.scrollTop
    return (
      <div>
        <div id="glossary-header" className="docs-header">
          <div>
            <h1 className="mb-2">Fit3D Wellness Metrics Glossary</h1>
            <p>Gain a more in-depth understanding about each metric.</p>
          </div>
        </div>

        <div className="d-flex">
          <div className="docs-content">
            <h1 id="body-shape">Body Shape</h1>
            <h3 id="bsr">Body Shape Rating (BSR)</h3>
            <h4>Summary</h4>
            <p>
              Fit3D extracted SBSI, ABSI, Trunk to Leg Volume Ratio, body fat
              percentage, and BMI from more than 26,000 scans. Fit3D then
              evaluated the correlation between each algorithm and calculated
              the weighted values for the health risk outcomes based on the
              overall Fit3D user population. This results in a Body Shape Rating
              (BSR). A user can then understand how their body shape wellness
              compares with the rest of the Fit3D population.
            </p>
            <h4>Ways to Improve</h4>
            <p>
              The primary way to improve your BSR is to increase the density of
              your body, build the muscle in your legs, and decrease your waist
              circumference.
            </p>
            <p>
              This can be done through a balanced mix of good nutrition and
              exercise. Consult with your trainer, nutritionist, coaches, or
              doctors to set up a plan.
            </p>
            <h4>References</h4>
            <p>
              To better understand ABSI, SBSI, Body Fat Percentage and Trunk to
              Leg Volume Ratio, please refer to the descriptions below or the
              research from the following links:
              <br />
            </p>
            <ul className="reference">
              <li>
                <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0039504">
                  http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0039504
                </a>
              </li>
              <li>
                <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0144639">
                  http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0144639
                </a>
              </li>
              <li>
                <a href="http://journals.plos.org/plosone/article?id=10.1371%2Fjournal.pone.0068716">
                  http://journals.plos.org/plosone/article?id=10.1371%2Fjournal.pone.0068716
                </a>
              </li>
            </ul>
            <h4>Formula</h4>
            <p className="formula">$$Proprietary$$</p>

            {/* <h3 id='absi'>ABSI</h3>
            <h4>Summary</h4>
            <p>A Body Shape Index (ABSI) is a simple way to evaluate total body shape to avoid health and wellness risks associated with obesity. ABSI is a factor of the roundness of the waist and is associated with Body Mass Index (BMI) and height. The lower this number is, the better.</p>
            <h4>Ways to Improve</h4>
            <p>To most improve your ABSI, you can either keep your waist circumference value steady and increase your weight or keep your weight steady and decrease your waist circumference. For the most aggressive ABSI improvement, you would want to reduce your waist circumference while increasing your mass. For example, you can make your body leaner or more muscular while maintaining the same weight.</p>
            <p>This can be done through a balanced mix of good nutrition and exercise that includes higher intensity interval training with a healthy mix of cardio. Consult with your trainer, nutritionist, coaches, and/or doctors to set up a plan</p>
            <h4>References</h4>
            <ul className='reference'>
              <li><a href='http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0039504'>http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0039504</a></li>
              <li><a href='http://www.mytecbits.com/tools/medical/absi-calculator'>http://www.mytecbits.com/tools/medical/absi-calculator</a>&nbsp;(with target ranges)</li>
            </ul>
            <h4>Formula</h4>
            <p className='formula'>{ '$$\\frac{WC}{(BMI^\\frac{2}{3} \\times Height^\\frac{1}{2})}$$ ' }</p>
            <ul>
              <li><b>WC:</b> Maximum circumference of the waist that can be measured using a tape measure</li>
              <li><b>Height:</b> Height of body</li>
              <li><b>BMI:</b> Body Mass Index</li>
            </ul>

            <h3 id='sbsi'>SBSI</h3>
            <h4>Summary</h4>
            <p>Surface-based Body Shape Index (SBSI) is a more complex way to evaluate total body shape. It is used to avoid health and wellness risks associated with obesity. The lower your SBSI score, the less likely you are to suffer early health and wellness-related risks associated with obesity. SBSI compares the amount of mass in your torso to amount of mass in the rest of your body.</p>
            <h4>How to Improve</h4>
            <p>To best improve your SBSI, you will want to establish a program to decrease your waist circumference without sacrificing body surface area (BSA). For example, you will want a program that redistributes mass in your body away from your waist and towards your chest, shoulders, arms, and legs, which should hold your BSA relatively steady.</p>
            <p>This can be done through a balanced mix of good nutrition and exercise that includes higher intensity interval training. Consult with your trainer, nutritionist, coaches, and/or doctors to set up a plan.</p>
            <h4>References</h4>
            <ul className='reference'>
              <li><a href='http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0144639'>http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0144639</a></li>
            </ul>
            <h4>Formula</h4>
            <p className='formula'>{ '$$\\frac{Height^\\frac{7}{4} \\times WC^\\frac{5}{6}}{BSA \\times VTC}$$' }</p>
            <ul>
              <li><b>WC:</b> Maximum circumference of the waist that can be measured using a tape measure</li>
              <li><b>VTC:</b> (Vertical Trunk Circumference) Measurement is taken by threading a tape from the shoulder, through the crotch, and back to the shoulder while the subject stands fully erect with the weight distributed equally on both feet and the arms hanging freely downwards</li>
              <li><b>BSA:</b> Body Surface Area</li>
            </ul> */}

            <h3 id="trunkLegVolRatio">Trunk to Leg Volume Ratio</h3>
            <h4>Summary</h4>
            <p>
              Trunk to Leg Volume Ratio compares the volume of your trunk with
              the volume of your legs. This can generally only be done with some
              type of body scanning, whether it be Fit3D or more costly and
              complex scanners like DXA or MRI. Research claims that having a
              high percentage of your body’s volume in your torso compared to
              your legs increases the likelihood of you experiencing
              prediabetes, diabetes, high triglyceride (fat) counts, high blood
              pressure, metabolic syndromes, and other severe health
              complications.
            </p>
            <p>
              Weight in your mid-section is highly correlated with visceral fat,
              which is the unhealthy fat around your organs. If you have a big
              belly and smaller legs, it is a good assumption that your weight
              is centered around your midsection and is therefore visceral fat.
            </p>
            <h4>Ways to Improve</h4>
            <p>
              This formula is dependent on volume, not weight. The overly simple
              way to improve your Trunk to Leg Volume score is to decrease the
              volume of your midsection and increase the volume of your legs.
            </p>
            <p>
              This can be done through a balanced mix of good nutrition and
              exercise that includes higher intensity interval training. Consult
              with your trainer, nutritionist, coaches, and/or doctors to set up
              a plan
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="http://journals.plos.org/plosone/article?id=10.1371%2Fjournal.pone.0068716">
                  http://journals.plos.org/plosone/article?id=10.1371%2Fjournal.pone.0068716
                </a>
              </li>
            </ul>
            <h4>Formula</h4>
            <p className="formula">
              {
                '$$\\frac{Trunk Volume}{(Right Leg Volume + Left Leg Volume)}$$ '
              }
            </p>

            <h1 id="body-comp">Body Composition</h1>
            {this.props.bfpAccess === 1 && (
              <div>
                <h3 id="bfp">Body Fat Percentage (BFP)</h3>
                <h4>Summary</h4>
                <p>
                  Through research partnerships with public and private
                  institutions, Fit3D has scanned thousands of people with Fit3D
                  and DXA systems side by side. Fit3D has subsequently created
                  algorithms that utilize hundreds of measurements automatically
                  extracted from these body scans to calculate a user’s body fat
                  percentage.
                </p>
                <p>
                  Body fat percentage is the user’s total fat mass divided by
                  the total body mass. Fat is an essential component of the body
                  and is necessary to maintain life and reproductive functions;
                  however, too much body fat can negatively affect overall
                  health or hormone levels. It also serves as an indicator of
                  more serious health problems that could potentially be faced
                  in the future. The <u>essential body fat</u> (fat that is
                  required to maintain life functions) is generally 10-13% for
                  women and 2-5% for men (referenced through NASM).
                </p>
                <h4>Ways to Improve</h4>
                <p>
                  You can decrease your body fat percentage through a balanced
                  mix of good nutrition and exercise that includes higher
                  intensity interval training and cardio. Consult with your
                  trainer, nutritionist, coaches, and/or doctors to set up a
                  plan.
                </p>
                <h4>References</h4>
                <ul className="reference">
                  <li>
                    <a href="https://en.wikipedia.org/wiki/Body_fat_percentage">
                      https://en.wikipedia.org/wiki/Body_fat_percentage
                    </a>
                  </li>
                  <li>
                    <a href="https://www.acefitness.org/acefit/healthy-living-article/60/112/what-are-the-guidelines-for-percentage-of-body-fat">
                      https://www.acefitness.org/acefit/healthy-living-article/60/112/what-are-the-guidelines-for-percentage-of-body-fat
                    </a>
                  </li>
                </ul>
              </div>
            )}

            <h3 id="weight">Weight</h3>
            <h4>Summary</h4>
            <p>
              Body weight is a person’s mass or weight. This is probably the
              easiest metric to measure on a human and is therefore a part of
              our primary assessment metrics; however, the body shape makeup and
              body composition is a much more real mechanism to determine the
              health of an individual.
            </p>
            <h4>References</h4>
            <ul>
              <li>
                <a href="https://en.wikipedia.org/wiki/Human_body_weight">
                  https://en.wikipedia.org/wiki/Human_body_weight
                </a>
              </li>
            </ul>

            {this.props.bfpAccess === 1 && (
              <div>
                <h3 id="fatMass">Fat Mass</h3>
                <h4>Summary</h4>
                <ul>
                  <li>
                    Fat mass is also known as adipose tissue within the body.
                    This loose connective tissue is primarily composed of{' '}
                    <a href="https://en.wikipedia.org/wiki/Adipocyte">
                      adipocytes (fat cells)
                    </a>
                    . Its main role is to store energy in the form of lipids. It
                    also cushions and insulates the body.
                  </li>
                  <li>
                    Body fat is primarily made up of three types of fat:
                    <ul>
                      <li>
                        <b>Epicardial Adipose Tissue (EAT)</b>: EAT is a
                        particular form of visceral fat deposited around the
                        heart and is found to be a metabolically active organ
                        that generates various bioactive molecules, which may
                        significantly affect cardiac function.
                      </li>
                      <li>
                        <b>Subcutaneous Fat</b>: The fat that is generally
                        stored just below the surface of the skin. Subcutaneous
                        fat is not related to many of the classic
                        obesity-related pathologies, such as heart disease,
                        cancer, and stroke and is generally a protective fat.
                      </li>
                      <li>
                        <b>Ectopic Fat</b>: The storage of triglycerides (fats)
                        in tissues other than adipose tissue that are only
                        supposed to contain small amounts of fat, such as the
                        liver, skeletal muscle, heart, and pancreas. These fats
                        can interfere with cellular function. The cause for
                        accumulation of Ectopic fat is unknown.
                      </li>
                    </ul>
                  </li>
                </ul>
                <h4>Ways to Improve</h4>
                <p>
                  You can decrease your body fat percentage through a balanced
                  mix of good nutrition and exercise that includes higher
                  intensity interval training and cardio. Consult with your
                  trainer, nutritionist, coaches, and/or doctors to set up a
                  plan.
                </p>
                <h4>References</h4>
                <ul className="reference">
                  <li>
                    <a href="https://en.wikipedia.org/wiki/Adipose_tissue">
                      https://en.wikipedia.org/wiki/Adipose_tissue
                    </a>
                  </li>
                  <li>
                    <a href="https://www.acefitness.org/acefit/healthy-living-article/60/112/what-are-the-guidelines-for-percentage-of-body-fat">
                      https://www.acefitness.org/acefit/healthy-living-article/60/112/what-are-the-guidelines-for-percentage-of-body-fat
                    </a>
                  </li>
                </ul>
                <h4>Formula</h4>
                <p>
                  The mass of the adipose tissue within the body is given by:
                </p>
                <p className="formula">
                  {'$$Fat Mass = Total Body Mass \\times Body Fat \\%$$ '}
                </p>
              </div>
            )}

            {this.props.bfpAccess === 1 && (
              <div>
                <h3 id="leanMass">Lean Mass</h3>
                <h4>Research Description</h4>
                <p>
                  The 2-component model, which consists of fat mass and lean
                  mass calculations, is widely used to determine body
                  composition. Fat mass is the mass of the adipose tissue in the
                  body. Lean mass is the muscle tissue, skeletal tissue, and
                  water in the body. In 3- and 4-component models, skeletal
                  tissue and water are drawn out as isolated measurements.
                </p>
                <h4>Ways to Improve</h4>
                <p>
                  You can increase your lean mass by participating in fitness
                  programs that include weight training. You can also talk with
                  your nutritionist to develop a nutrition program that will
                  effectively build lean mass. If you are simply looking to
                  build lean mass, but not necessarily lose fat mass, your
                  program may be quite different than a program that is geared
                  towards a more balanced fat percentage reduction. Consult with
                  your trainer, nutritionist, coaches, and/or doctors to set up
                  a plan.
                </p>
                <h4>References</h4>
                <ul>
                  <li>
                    <a href="http://jap.physiology.org/content/85/1/238">
                      http://jap.physiology.org/content/85/1/238
                    </a>
                  </li>
                </ul>
                <h4>Formula</h4>
                <p>
                  Lean mass is the mass of muscle, skeletal tissue, and water.
                  In a 2-component model, where water and skeletal tissue are
                  both included in lean mass, the formula is:
                </p>
                <p className="formula">
                  {'$$Lean Mass = Total Body Mass \\times (1 - Body Fat \\%)$$'}
                </p>
              </div>
            )}

            <h1 id="medical">Medical</h1>
            <h3 id="wc">Waist Circumference</h3>
            <h4>Summary</h4>
            <ul>
              <li>
                Waist circumference (WC) provides a prediction of risk
                independent of BMI. WC is particularly useful for patients who
                are categorized as normal or overweight on the BMI scale as a
                measurement associated with visceral fat and overall body shape.
              </li>
              <li>
                High risk is associated for men with WC &gt; 102 cm and for
                women where WC &gt; 88 cm.
              </li>
              <li>
                WC is the circumference measurement taken at the small of the
                back.
              </li>
            </ul>
            <h4>Ways to Improve</h4>
            <p>
              You can decrease your body fat percentage through a balanced mix
              of good nutrition and exercise that includes higher intensity
              interval training and cardio. Consult with your trainer,
              nutritionist, coaches, and/or doctors to set up a plan.
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="http://www.nhlbi.nih.gov/health-pro/guidelines/current/obesity-guidelines/e_textbook/txgd/4142.htm">
                  http://www.nhlbi.nih.gov/health-pro/guidelines/current/obesity-guidelines/e_textbook/txgd/4142.htm
                </a>
              </li>
            </ul>

            <h3 id="wh">Waist to Hip Ratio</h3>
            <h4>Research Description</h4>
            <ul>
              <li>
                The waist to hip (WHR) ratio is obtained from dividing the
                circumference of the waist (the narrowest point between the hips
                and ribs) by the circumference of the hips (the widest part of
                the hips).
              </li>
              <li>
                WHR is a ratio generally used to evaluate whether the individual
                is at high risk of obesity, diabetes, coronary heart disease,
                and/or high blood pressure. The measurement evaluates body shape
                in the midsection of the body.
              </li>
              <li>
                This ratio as a health metric is extremely limited, but it is an
                easy way to try to evaluate the level of visceral fat one may
                have.
              </li>
            </ul>
            <h4>Ways to Improve</h4>
            <p>
              While you cannot spot reduced fat mass throughout your body, you
              can still work with your coaches to reduce the amount throughout
              your body, which will generally decrease the circumference of your
              mid-section. WHR is extremely limited in scope, but is widely used
              as a metric of health.
            </p>
            <p>
              We suggest that you consult with your trainer, nutritionist,
              coaches, and/or doctors to set up a plan before focusing solely on
              your WHR.
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="http://resources.hwb.wales.gov.uk/VTC/2012-13/22032013/hsc/cym/unit-4/u5-ioph/unit-4-waist-to-hip-ratio.htm">
                  http://resources.hwb.wales.gov.uk/VTC/2012-13/22032013/hsc/cym/unit-4/u5-ioph/unit-4-waist-to-hip-ratio.htm
                </a>
              </li>
            </ul>
            <h4>Formula</h4>
            <p className="formula">{'$$WHR = \\frac{WC}{HC}$$'}</p>
            <ul>
              <li>
                <b>WC:</b> Waist Circumference
              </li>
              <li>
                <b>HC:</b> Hip Circumference
              </li>
            </ul>

            <h1 id="additional">Additional</h1>
            <h3 id="bmr">Basal Metabolic Rate (BMR)</h3>
            <h4>Summary</h4>
            <p>
              Basal Metabolic Rate (BMR) is the amount of energy expended while
              at rest in a neutrally temperate environment and in the
              post-absorptive state. In this state, the digestive system is
              inactive, which is usually achieved by about twelve hours of
              fasting. Furthermore, BMR is the amount of energy expressed in
              calories that a person needs to keep the body functioning (i.e.
              breathing, blood circulation, controlling body temperature, cell
              growth, brain and nervous function, and contraction of muscles).
              BMR affects the rate at which a person burns calories and
              ultimately affects whether that individual maintains, gains, or
              loses weight.
            </p>
            <h4>Ways to Improve</h4>
            <p>
              You can increase your BMR by participating in fitness programs
              that include high intensity interval training or lifting weights
              to build muscle. You can further increase your BMR by eating more
              protein, eating many smaller-portioned meals per day, and staying
              hydrated. The rule of thumb here is that muscle requires more
              energy to survive&mdash;the more lean muscle you have on your
              body, the higher your BMR. Consult with your trainer,
              nutritionist, coaches, and/or doctors to set up a plan.
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="https://en.wikipedia.org/wiki/Basal_metabolic_rate">
                  https://en.wikipedia.org/wiki/Basal_metabolic_rate
                </a>
              </li>
            </ul>

            <h3 id="bp">Blood Pressure</h3>
            <h4>Summary</h4>
            <p>
              Blood pressure is the{' '}
              <a href="https://en.wikipedia.org/wiki/Pressure">pressure</a>{' '}
              exerted by circulating{' '}
              <a href="https://en.wikipedia.org/wiki/Pressure">blood</a> upon
              the walls of{' '}
              <a href="https://en.wikipedia.org/wiki/Blood_vessel">
                blood vessels
              </a>
              . "Blood pressure" usually refers to the{' '}
              <a href="https://en.wikipedia.org/wiki/Artery">arterial</a>{' '}
              pressure in the{' '}
              <a href="https://en.wikipedia.org/wiki/Circulatory_system">
                systemic circulation
              </a>
              . Blood pressure varies depending on the environment, activity,
              and disease states and is regulated by the nervous and endocrine
              systems.{' '}
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="https://en.wikipedia.org/wiki/Blood_pressure">
                  https://en.wikipedia.org/wiki/Blood_pressure
                </a>
              </li>
            </ul>

            <h3 id="hr">Heart Rate</h3>
            <h4>Summary</h4>
            <p>
              Heart rate is the speed of the heartbeat measured by the number of
              contractions of the heart per minute (bpm). The heart rate can
              vary according to the body's{' '}
              <a href="https://en.wikipedia.org/wiki/Human_body">physical</a>{' '}
              needs, including the need to absorb oxygen and excrete{' '}
              <a href="https://en.wikipedia.org/wiki/Carbon_dioxide">
                carbon dioxide
              </a>
              . The normal resting adult heart rate ranges from 60-100 bpm.
            </p>
            <h4>References</h4>
            <ul className="reference">
              <li>
                <a href="https://en.wikipedia.org/wiki/Heart_rate">
                  https://en.wikipedia.org/wiki/Heart_rate
                </a>
              </li>
            </ul>
          </div>

          <div className="docs-links">
            <ul
              className={
                this.state.navFixed ? 'markdown-toc-fixed' : 'markdown-toc'
              }
            >
              <li>
                <a onClick={this.handleScroll.bind(this, 'bsr')}>
                  Body Shape Rating (BSR)
                </a>
              </li>
              {/* <li><a onClick={ this.handleScroll.bind(this, 'absi') }>ABSI</a></li> */}
              {/* <li><a onClick={ this.handleScroll.bind(this, 'sbsi') }>SBSI</a></li> */}
              <li>
                <a onClick={this.handleScroll.bind(this, 'trunkLegVolRatio')}>
                  Trunk to Leg
                </a>
              </li>
              <li>
                <a onClick={this.handleScroll.bind(this, 'body-comp')}>
                  Body Composition
                </a>
              </li>
              {this.props.bfpAccess === 1 && (
                <li>
                  <a onClick={this.handleScroll.bind(this, 'bfp')}>
                    Body Fat Percent
                  </a>
                </li>
              )}
              <li>
                <a onClick={this.handleScroll.bind(this, 'weight')}>Weight</a>
              </li>
              {this.props.bfpAccess === 1 && (
                <li>
                  <a onClick={this.handleScroll.bind(this, 'fatMass')}>
                    Fat Mass
                  </a>
                </li>
              )}
              {this.props.bfpAccess === 1 && (
                <li>
                  <a onClick={this.handleScroll.bind(this, 'leanMass')}>
                    Lean Mass
                  </a>
                </li>
              )}
              <li>
                <a onClick={this.handleScroll.bind(this, 'wc')}>
                  Waist Circumference
                </a>
              </li>
              <li>
                <a onClick={this.handleScroll.bind(this, 'wh')}>
                  Waist to Hip Ratio
                </a>
              </li>
              <li>
                <a onClick={this.handleScroll.bind(this, 'bmr')}>
                  Basal Metabolic Rate (BMR)
                </a>
              </li>
              <li>
                <a onClick={this.handleScroll.bind(this, 'bp')}>
                  Blood Pressure
                </a>
              </li>
              <li>
                <a onClick={this.handleScroll.bind(this, 'hr')}>Heart Rate</a>
              </li>
            </ul>

            <div
              className={`${
                this.state.showReturnTop ? 'scroll-top' : 'hidden'
              } icon-up`}
              onClick={handleScroll}
            >
              Return to Top&nbsp;&nbsp;
              <span className="icon icon-arrow-with-circle-up" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Glossary.propTypes = {
  width: PropTypes.number,
  location: PropTypes.object,
  bfpAccess: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  bfpAccess:
    state.user.get('user').get('facility') &&
    state.user.get('user').get('facility').get('bfpAccess'),
})

export default connect(mapStateToProps)(Glossary)

import React from 'react'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import MaskedFormControl from 'react-bootstrap-maskedinput'

const DateField = ({ input, meta: { touched, error } }) => (
  <div className="input-row">
    <FormGroup>
      <ControlLabel>Birthdate</ControlLabel>
      <MaskedFormControl
        type="text"
        name="birthDate"
        placeholder="Date (YYYY-MM-DD)"
        value={input.value}
        {...input}
        mask="1111-11-11"
      />
    </FormGroup>
    {touched && error && <span className="text-danger">{error}</span>}
  </div>
)

export default DateField

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as AuthActions from '../../actions/auth'

import SubNavContainer from '../../components/navbar/sub_nav_container'
import NavItem from '../../components/navbar/nav_item'

const pageText = '#51565b'

const AccountNav = ({ pathname, redirectPage, oauth2Logout }) => {
  return (
    <SubNavContainer header="Account">
      <ul
        className="nav-pills flex-md-column list-unstyled"
        style={{ padding: '15px 0px' }}
      >
        <NavItem
          isSelected={pathname === '/profile'}
          text="My Profile"
          clickHandler={redirectPage('/profile')}
        />
        {/* <NavItem
          isSelected={ pathname === '/history' }
          text='Scan History'
          clickHandler={ redirectPage('/history') } /> */}
        <NavItem
          isSelected={pathname === '/user/coaches'}
          text="My Coaches"
          clickHandler={redirectPage('/user/coaches')}
        />
        <li className="nav-item" style={{ borderBottom: '1px #b6cdcd solid' }}>
          <a
            href="http://support.fit3d.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link d-flex"
            style={{ padding: '0.75rem 1rem', color: pageText }}
          >
            <div style={{ fontSize: '1rem' }}>
              <span>Help</span>
            </div>
          </a>
        </li>
        <li className="nav-item" style={{ borderBottom: '1px #b6cdcd solid' }}>
          <div
            className="nav-link d-flex"
            style={{ padding: '0.75rem 1rem', color: pageText }}
            onClick={oauth2Logout}
          >
            <div style={{ fontSize: '1rem' }}>
              <span>Logout</span>
            </div>
          </div>
        </li>
      </ul>
    </SubNavContainer>
  )
}

AccountNav.propTypes = {
  pathname: PropTypes.string.isRequired,
  redirectPage: PropTypes.func.isRequired,
  oauth2Logout: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  oauth2Logout: () => dispatch(AuthActions.oauth2Logout()),
})

export default connect(null, mapDispatchToProps)(AccountNav)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateScanRecord } from '../../actions/records'

const Visibility = (props) => {
  return (
    <button
      className="btn btn-primary btn-block btn-square text-uppercase include-button mx-auto mb-2"
      onClick={() =>
        props.updateScanRecord(
          props.record
            .set(
              'visibility',
              props.record.get('visibility') === 'visible'
                ? 'hidden'
                : 'visible'
            )
            .toJS()
        )
      }
    >
      {props.record.get('visibility') === 'visible' ? 'Hide' : 'Show'}&nbsp;Scan
      In Report
    </button>
  )
}

Visibility.propTypes = {
  record: PropTypes.object,
  updateScanRecord: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateScanRecord }, dispatch)
}

export default connect(null, mapDispatchToProps)(Visibility)

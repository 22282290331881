import Immutable from 'immutable'

import {
  UPDATE_SEARCH_STATE,
  RESET_SEARCH_STATE,
} from '../../actions/search/types'
// import { REHYDRATE } from 'redux-persist/constants';

const reducers = {
  [UPDATE_SEARCH_STATE]: (state, action) => state.set('search', action.payload),
  [RESET_SEARCH_STATE]: (state) => {
    return state.set('search', {
      searchParam: '',
      searchString: null,
    })
  },
  // [REHYDRATE]: (state, action) => {
  //   if (action.payload && action.payload.search) {
  //     return state.set('search', action.payload.search.get('search'));
  //   }
  //   return state;
  // }
}

const defaultState = new Immutable.Map({
  search: {
    searchParam: '',
    searchString: null,
  },
})

const searchReducer = (state = defaultState, action) => {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

export default searchReducer

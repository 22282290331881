import {
  // FETCH_USER,
  // UPDATE_USER,
  // UPDATE_USER_SUCCESS,
  // UPDATE_USER_FAILURE,
  // UPDATE_PASSWORD_REQUEST,
  // UPDATE_PASSWORD_SUCCESS,
  // UPDATE_PASSWORD_FAILURE,
  UPDATE_EMAIL_SUCCESS,
  CLEAR_MESSAGE,
} from './types'
import axios from 'axios'

// export const fetchUser = () => {
//   const response = axios.get(`${process.env.REACT_APP_API_BASEURL}api/v1/user`, { withCredentials: `${ process.env.INClUDE_CREDS }` });
//   return {
//     type: FETCH_USER,
//     payload: response
//   };
// };

// export const loadUser = () => (dispatch, getState) => {
//   const user = getState().user.get('user');
//
//   if (user) {
//     return null;
//   }
//   return dispatch(fetchProfile());
// };

// export function updateUserRequest(params) {
//   return {
//     type: UPDATE_USER,
//     payload: params
//   };
// }
//
// export function updateUser(params) {
//   return dispatch => {
//     dispatch(updateUserRequest(params));
//     return axios({
//       method: 'post',
//       url: `${process.env.REACT_APP_API_BASEURL}api/v1/user`,
//       withCredentials: `${ process.env.INClUDE_CREDS }`,
//       data: params
//     }).then(response => {
//       dispatch({
//         type: UPDATE_USER_SUCCESS,
//         payload: response
//       });
//     }).catch(error => {
//       dispatch({
//         type: UPDATE_USER_FAILURE,
//         payload: error
//       });
//     });
//   };
// }

export function clearEditMessage() {
  return {
    type: CLEAR_MESSAGE,
    payload: null,
  }
}

// export const updatePasswordRequest = () => ({
//   type: UPDATE_PASSWORD_REQUEST,
//   payload: 'fetching'
// });
//
// export const updatePassword = passwordData => dispatch => {
//   dispatch(updatePasswordRequest());
//   return axios({
//     method: 'post',
//     url: `${process.env.REACT_APP_API_BASEURL}api/v1/user/password`,
//     withCredentials: `${ process.env.INClUDE_CREDS }`,
//     data: passwordData
//   })
//   .then(response => dispatch({
//     type: UPDATE_PASSWORD_SUCCESS,
//     payload: response
//   }))
//   .catch(error => dispatch({
//     type: UPDATE_PASSWORD_FAILURE,
//     payload: error
//   }));
// };

export const updateEmail = (emailData) => (dispatch) => {
  // dispatch(updatePasswordRequest());
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASEURL}api/v1/user/email`,
    withCredentials: `${process.env.INClUDE_CREDS}`,
    data: emailData,
  }).then((response) =>
    dispatch({
      type: UPDATE_EMAIL_SUCCESS,
      payload: response,
    })
  )
}

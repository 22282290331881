import Immutable from 'immutable'

import { SCANS_PER_PAGE } from '../scans'

export const generateRecords = (records) => {
  const result = {}
  let page = 0

  const jsRecords = records.toJS()

  for (var i = 0; i < jsRecords.length; i++) {
    if (!result[page]) {
      result[page] = []
    }
    result[page].push(jsRecords[i])

    // i === 7 is the 8th element in the array, SCANS_PER_PAGE == 8
    if (i > SCANS_PER_PAGE - 2 && (i + 1) % SCANS_PER_PAGE === 0) {
      page += 1
    }
  }
  return Immutable.fromJS(result)
}

import parse from 'parse-link-header'

export const SET_PAGINATION_PAGE = 'SET_PAGINATION_PAGE'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_PAGE_INFO = 'SET_PAGE_INFO'

export const setPage = (page) => (dispatch) =>
  dispatch({
    type: SET_PAGINATION_PAGE,
    payload: page,
  })

export const setPageSize = (param) => (dispatch) =>
  dispatch({
    type: SET_PAGE_SIZE,
    payload: param,
  })

export const setPageInfo = (link) => (dispatch) => {
  dispatch({
    type: SET_PAGE_INFO,
    payload: link ? parse(link) : {},
  })
}

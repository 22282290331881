import React from 'react'
import PropTypes from 'prop-types'

import ScanImage from '../scans/scan_image'
import './report.css'

const ReportImages = ({ packageId, showImages }) => {
  const images = [0, 120, 270, 315].map((degree) => {
    return (
      <ScanImage
        key={degree}
        src={`${process.env.REACT_APP_API_BASEURL}/v1/records/${packageId}/scan/img/${degree}/type/blank`}
      />
    )
  })

  return <div className={showImages ? 'img-container' : 'hidden'}>{images}</div>
}

ReportImages.propTypes = {
  packageId: PropTypes.string,
  showImages: PropTypes.bool,
}

export default ReportImages

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { updateScanner } from '../../../../actions/admin/scanners'

import ScannerEdit from './Form'

const ScannerEditModal = ({ scanners, onUpdate, onSubmit, onClose }) => {
  const { list, editModalOpen, editForm } = scanners

  React.useEffect(() => {
    if (list.find((scanner) => scanner.id === editForm.scannerId)) {
      onClose()
    }
  }, [list])

  const handleUpdate = (event) => {
    const { name, value } = event.target
    const isNumber = name === 'hwVersion' || name === 'captureType'

    onUpdate({ [name]: isNumber ? parseInt(value, 10) : value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const { scannerId, scannerCode, ...dataToSubmit } = editForm

    onSubmit({
      scannerId,
      data: dataToSubmit,
    })
  }

  return (
    <Modal isOpen={editModalOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Update Scanner</ModalHeader>
      <ModalBody>
        <ScannerEdit
          data={editForm}
          onClose={onClose}
          onUpdate={handleUpdate}
          onSubmit={handleSubmit}
        />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  scanners: state.newAdmin.scanners,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdate: (params) =>
    dispatch({ type: 'UPDATE_FACILITY_SCANNER', payload: params }),
  onSubmit: (params) => dispatch(updateScanner(params)),
  onClose: () => dispatch({ type: 'CLOSE_SCANNER_EDIT' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScannerEditModal)

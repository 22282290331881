import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import ReactGA from 'react-ga'
// import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
// import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import Dashboard from '../components/dashboard';
import Marketplace from '../components/marketplace'
import NotFound from '../components/notFound'
import Measurements from '../components/measurements'
import ScanHistory from '../components/history'
import Profile from '../components/profile'
import Glossary from '../components/glossary'

import StaffPage from './staff'
import AdminPage from './AdminPage'
import FacilityPage from './facility'
import ComparisonPage from './comparison_page'
import WellnessPage from './wellness_page'
import ReportPage from './report_page'
// import PosturePage from './posture_page';
import UserCoachPage from './user_coach_page'

import Chartio from '../components/chartio'

// const userIsAuthenticated = connectedRouterRedirect({
//   // The url to redirect user to if they fail
//   redirectPath: '/auth',
//   // Determine if the user is authenticated or not
//   authenticatedSelector: state => state.user.get('auth').isLoggedIn,
//   // A nice display name for this check
//   wrapperDisplayName: 'UserIsAuthenticated'
// });
//
// // const locationHelper = locationHelperBuilder({});
// // tslint:disable-next-line:no-any
// const userIsNotAuthenticated = connectedRouterRedirect({
//   // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
//   redirectPath: () => '/',
//   // This prevents us from adding the query parameter when we send the user away from the login page
//   allowRedirectBack: false,
//   // This prevents us from adding the query parameter when we send the user away from the login page
//   // Determine if the user is authenticated or not
//   authenticatedSelector: state => !state.user.get('auth').isLoggedIn,
//   // A nice display name for this check
//   wrapperDisplayName: 'UserIsNotAuthenticated'
// });

class Routes extends Component {
  componentDidMount() {
    ReactGA.ga('fit3d-web.set', 'page', this.props.location.pathname)
    ReactGA.ga('fit3d-web.send', 'pageview')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      ReactGA.ga('fit3d-web.set', 'page', this.props.location.pathname)
      ReactGA.ga('fit3d-web.send', 'pageview')
    }
  }

  render() {
    const user = this.props.user.get('user')
    let redirectUrl
    if (user.get('superAdmin')) {
      redirectUrl = '/admin/records'
    } else if (user.get('qcAdmin')) {
      redirectUrl = '/admin/records/qc'
    } else if (user.get('facilityAdmin') || user.get('coach')) {
      redirectUrl = '/facility/dashboard'
    } else {
      redirectUrl = '/wellness'
    }
    // const DashboardPage = (props) => <Dashboard { ...props } />;
    const MarketplacePage = (props) => <Marketplace {...props} />
    // const PrintPage = (props) => <Print { ...props } />;
    const ScanHistoryPage = (props) => <ScanHistory {...props} />
    const GlossaryPage = (props) => <Glossary {...props} />

    return (
      <Switch>
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/history" component={ScanHistoryPage} />
        <Route path="/wellness" component={WellnessPage} />
        <Route exact path="/measurements" component={Measurements} />
        <Route path="/comparison" component={ComparisonPage} />
        {/* <Route path='/posture' component={ PosturePage } /> */}
        <Route exact path="/profile" component={Profile} />
        <Route path="/staff" component={StaffPage} />
        <Route path="/facility" component={FacilityPage} />
        <Route path="/user/coaches" component={UserCoachPage} />
        <Route path="/admin" component={AdminPage} />
        <Route exact path="/glossary" component={GlossaryPage} />
        <Route path="/report" component={ReportPage} />
        <Route path="/enterprise" component={Chartio} />
        <Redirect from="/" to={redirectUrl} />
        <Route path="/*" component={NotFound} />
      </Switch>
    )
  }
}

Routes.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default withRouter(Routes)

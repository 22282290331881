import * as AdminApi from '../../lib/api/admin'
import * as AdminScannerApi from '../../lib/api/admin/scanners'
import { setPageInfo } from '../pagination'

export const FETCH_SCANNERS = 'FETCH_SCANNERS'
export const FETCH_SCANNERS_SUCCESS = 'FETCH_SCANNERS_SUCCESS'
export const FETCH_SCANNERS_FAILURE = 'FETCH_SCANNERS_FAILURE'

export const UPDATE_SCANNER = 'UPDATE_SCANNER'
export const UPDATE_SCANNER_SUCCESS = 'UPDATE_SCANNER_SUCCESS'
export const UPDATE_SCANNER_FAILURE = 'UPDATE_SCANNER_FAILURE'

export const DEPRECATE_SCANNER = 'DEPRECATE_SCANNER'
export const DEPRECATE_SCANNER_SUCCESS = 'DEPRECATE_SCANNER_SUCCESS'
export const DEPRECATE_SCANNER_FAILURE = 'DEPRECATE_SCANNER_FAILURE'

export const fetchScanners = (params) => (dispatch) => {
  dispatch({ type: FETCH_SCANNERS })

  AdminApi.fetchScanners(params)
    .then((result) => {
      dispatch(setPageInfo(result.headers.get('link')))

      dispatch({
        type: FETCH_SCANNERS_SUCCESS,
        payload: result.json,
      })
    })
    .catch((error) => dispatch({ type: FETCH_SCANNERS_FAILURE, error }))
}

export const updateScanner = ({ scannerId, data }) => (dispatch) => {
  dispatch({ type: UPDATE_SCANNER })

  AdminApi.updateScanner(scannerId, data)
    .then((result) => {
      dispatch({
        type: UPDATE_SCANNER_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({ type: UPDATE_SCANNER_FAILURE, error })
    })
}

export const deprecateScanner = (scannerCode) => (dispatch) => {
  dispatch({ type: DEPRECATE_SCANNER })

  AdminScannerApi.deprecateScanner(scannerCode)
    .then((result) => {
      dispatch({
        type: DEPRECATE_SCANNER_SUCCESS,
        payload: result,
      })
    })
    .catch((error) => {
      dispatch({
        type: DEPRECATE_SCANNER_FAILURE,
        error: error.status === 404 ? 'Scanner does not exist' : 'failure',
      })
    })
}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export default function ReprocessModal({
  handleReprocessAction,
  isReprocessOpen,
  closeReprocessModal,
}) {
  return (
    <Modal size="sm" isOpen={isReprocessOpen} toggle={closeReprocessModal}>
      <ModalHeader toggle={closeReprocessModal}>
        Select Reprocess Method
      </ModalHeader>
      <ModalBody>
        <p>Click Reprocess to reprocess images or fix failed scans.</p>
        <p>
          Click Delete and Reprocess to delete measurements and reprocess
          images.
        </p>
        <div>
          <button
            className="btn btn-primary btn-block"
            onClick={() => handleReprocessAction({ reprocessType: 'all' })}
          >
            All
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() => handleReprocessAction({ reprocessType: 'clean' })}
          >
            Clean
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() => handleReprocessAction({ reprocessType: 'img' })}
          >
            Image / Blank
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() => handleReprocessAction({ reprocessType: 'slice' })}
          >
            Slice / Silhouette
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() => handleReprocessAction({ reprocessType: 'overlay' })}
          >
            Overlay
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() =>
              handleReprocessAction({ reprocessType: 'posture-generate' })
            }
          >
            Posture / Generate
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

ReprocessModal.propTypes = {
  handleReprocessAction: PropTypes.func.isRequired,
  isReprocessOpen: PropTypes.bool.isRequired,
  closeReprocessModal: PropTypes.func.isRequired,
}

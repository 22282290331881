import React from 'react'
import { connect } from 'react-redux'
import { setPage, setPageSize } from '../../actions/pagination'

import PageSizeSelect from './PageSizeSelect'
import ReloadButton from './ReloadButton'
import PaginationButtons from './PaginationButtons'

const CustomPagination = ({
  pagination,
  setPage,
  setPageSize,
  onSizeSelect,
  onPageSelect,
  onReload,
  resetPagination,
}) => {
  const { page: currentPage, pageSize, maxPages } = pagination

  React.useEffect(() => {
    return () => {
      resetPagination()
    }
  }, [])

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value
    setPageSize(newPageSize)
    onSizeSelect(parseInt(newPageSize))
  }

  const handleFirstPage = () => {
    onPageSelect(0)
    setPage(0)
  }

  const handleLastPage = () => {
    onPageSelect(maxPages - 1)
    setPage(maxPages - 1)
  }

  const handlePrevious = () => {
    if (currentPage > 0) {
      const prevPage = currentPage - 1
      onPageSelect(prevPage)
      setPage(prevPage)
    }
  }

  const handleNext = () => {
    if (currentPage + 1 < maxPages) {
      const nextPage = currentPage + 1
      onPageSelect(nextPage)
      setPage(nextPage)
    }
  }

  const handlePageSelect = (event) => {
    const newPage = parseInt(event.target.getAttribute('value'), 10) - 1
    onPageSelect(newPage)
    setPage(newPage)
  }

  return (
    <div className="paginate">
      <PageSizeSelect value={pageSize} onChange={handlePageSizeChange} />
      <PaginationButtons
        currentPage={currentPage}
        maxPages={maxPages}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handlePageSelect={handlePageSelect}
      />
      {onReload && <ReloadButton onClick={onReload} />}
    </div>
  )
}

const mapStateToProps = (state) => ({ pagination: state.pagination })

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setPage(page)),
  setPageSize: (pageSize) => dispatch(setPageSize(pageSize)),
  resetPagination: () => dispatch({ type: 'RESET_PAGINATION' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomPagination)

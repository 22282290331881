import React from 'react'
import PropTypes from 'prop-types'

const ValidateAllButton = ({ title, validateAll }) => (
  <button
    className="btn btn-sm btn-outline-primary"
    style={{ width: '80px' }}
    onClick={validateAll}
  >
    {title}
  </button>
)

ValidateAllButton.propTypes = {
  title: PropTypes.string.isRequired,
  validateAll: PropTypes.func.isRequired,
}

const ValidateAllButtons = (props) => (
  <div className="btn-group">
    <ValidateAllButton validateAll={() => props.validateAll(0)} title="N/A" />
    <ValidateAllButton validateAll={() => props.validateAll(1)} title="Yes" />
    <ValidateAllButton validateAll={() => props.validateAll(2)} title="No" />
  </div>
)

ValidateAllButtons.propTypes = {
  validateAll: PropTypes.func.isRequired,
}

export default ValidateAllButtons

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchClientData, resetClientData } from '../../../actions/coach/client'
import { updateCurrentScan, updateBaselineScan } from '../../../actions/records'
import completeClientRecordsSelector from '../../../selectors/complete_client_records'
import clientBaselineSelector from '../../../selectors/client_baseline_scan'
import clientCurrentSelector from '../../../selectors/client_current_scan'

import ScanPanel from '../../scans/panel_container'
import ScanDate from '../../scans/scan_dates'
import MeasurementComparisonInfo from '../../comparison/measurement_info'
import Loading from '../../utilities/loading'
import Unavailable from '../../utilities/unavailable'

import '../../comparison/comparison.css'

class ClientOverlayComparison extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bodySegments: {
        torso: [
          { chestAtBladesGirth: 'Chest' },
          { bustGirth: 'Bust' },
          { waistSmallBackGirth: 'Waist' },
          { seatGirth: 'Hips' },
        ],
        arms: [
          { bicepsLeftGirth: 'Left Biceps' },
          { bicepsRightGirth: 'Right Biceps' },
          { forearmLeftGirth: 'Left Forearm' },
          { forearmRightGirth: 'Right Forearm' },
        ],
        legs: [
          { thighLeftMaxGirth: 'Left Thigh' },
          { thighRightMaxGirth: 'Right Thigh' },
          { calfLeftGirth: 'Left Calf' },
          { calfRightGirth: 'Right Calf' },
        ],
        torsoAdditional: [
          { chestAtBladesGirth: 'Chest' },
          { bustGirth: 'Bust' },
          { waistSmallBackGirth: 'Waist' },
          { hipsAtGirthMaxGirth: 'Max Waist' },
          { waistNaturalGirth: 'Natural Waist' },
          { seatGirth: 'Hips' },
          { thighLeftMaxGirth: 'Left Thigh' },
          { thighRightMaxGirth: 'Right Thigh' },
        ],
      },
    }
  }

  componentDidMount() {
    this.props.fetchClientData(this.props.match.params.userId)
  }

  renderSideScanPanel(className) {
    const { userId } = this.props.match.params
    const recordId = this.props.currentScan.get('id')

    const basePackageId = this.props.baselineScan.get('scanPackageId')
    const src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/90/overlay?basePackageId=${basePackageId}`

    return (
      <div key="side" className={className}>
        <ScanPanel
          hideControl
          header="Side"
          scan={this.props.currentScan}
          records={this.props.clientRecords}
          customStyle="measurement-scan-panel"
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  renderFrontScanPanel(className) {
    const { userId } = this.props.match.params
    const recordId = this.props.currentScan.get('id')

    const basePackageId = this.props.baselineScan.get('scanPackageId')
    const src = `${process.env.REACT_APP_API_BASEURL}/v1/facility/clients/${userId}/records/${recordId}/scan/img/0/overlay?basePackageId=${basePackageId}`

    return (
      <div key="front" className={className}>
        <ScanPanel
          hideControl
          header="Front"
          scan={this.props.currentScan}
          records={this.props.clientRecords}
          customStyle="measurement-scan-panel"
          src={src}
          history={this.props.history}
        />
      </div>
    )
  }

  render() {
    const {
      windowDimensions: { width },
      coach,
      clientRecords,
      currentScan,
      baselineScan,
    } = this.props

    if (coach.get('loading') || !clientRecords) {
      return <Loading />
    }

    if (clientRecords && clientRecords.size < 2) {
      return (
        <Unavailable title="Not enough visible scans">
          <div>
            There needs to be at least 2 different visible scans.&nbsp; Click{' '}
            <u
              onClick={() => this.props.history.push('/staff/clients/records')}
            >
              here
            </u>{' '}
            to return to all client records.
          </div>
        </Unavailable>
      )
    } else if (!currentScan || !baselineScan) {
      return <Loading />
    }

    const baselineDateProps = {
      scan: this.props.baselineScan,
      records: this.props.clientRecords,
      type: 'baseline',
      updateScan: this.props.updateBaselineScan,
    }

    const currentDateProps = {
      scan: this.props.currentScan,
      records: this.props.clientRecords,
      type: 'current',
      updateScan: this.props.updateCurrentScan,
    }

    return (
      <div className="pt-3 pt-sm-4 px-3 px-sm-5">
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Overlay Comparison
          </div>
          {/* <div style={ { color: '#137499', fontSize: '18px' } }>
            Compare your measurements (placeholder text to look better)
          </div> */}
        </div>
        <div className="row equal">
          <div className="col-lg-8 pl-0">
            <div className="row mx-0" style={{ marginBottom: '1.25rem' }}>
              {this.renderSideScanPanel('col-sm-6')}
              {this.renderFrontScanPanel('col-sm-6')}
            </div>
            <div className="row mx-0">
              <div
                className="d-flex"
                style={{
                  margin: '0 0.6rem',
                  padding: '15px 10px 15px 20px',
                  width: '100%',
                  border: '1px #ddd solid',
                }}
              >
                <div className="mx-auto">
                  <h5 className="text-center mb-2">Baseline</h5>
                  <ScanDate {...baselineDateProps} />
                </div>
                <div className="mx-auto">
                  <h5 className="text-center mb-2">Current</h5>
                  <ScanDate {...currentDateProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pr-md-0">
            <div className="measurement-panel">
              <MeasurementComparisonInfo
                bodySegments={this.state.bodySegments}
                records={this.props.clientRecords}
                currentScan={this.props.currentScan}
                baselineScan={this.props.baselineScan}
                units={this.props.user.get('user').get('units')}
                width={width}
                user={this.props.user}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ClientOverlayComparison.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
  }),
  coach: PropTypes.object,
  clientRecords: PropTypes.object,
  baselineScan: PropTypes.object,
  currentScan: PropTypes.object,
  user: PropTypes.object,
  fetchClientData: PropTypes.func,
  resetClientData: PropTypes.func,
  updateCurrentScan: PropTypes.func.isRequired,
  updateBaselineScan: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    windowDimensions: state.windowDimensions,
    clientRecords: completeClientRecordsSelector(state),
    baselineScan: clientBaselineSelector(state),
    currentScan: clientCurrentSelector(state),
    coach: state.coach,
    user: state.user,
  }
}

const actions = {
  fetchClientData,
  resetClientData,
  updateCurrentScan,
  updateBaselineScan,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientOverlayComparison)

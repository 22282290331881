export const FETCH_RECORDS = 'FETCH_RECORDS'
export const FETCH_RECORDS_FAILURE = 'FETCH_RECORDS_FAILURE'
export const FETCH_FIRST_SCAN = 'FETCH_FIRST_SCAN'
export const UPDATE_RANGE = 'UPDATE_RANGE'
export const HANDLE_ANIMATION = 'HANDLE_ANIMATION'
export const FETCH_IMAGE = 'FETCH_IMAGE'
export const FETCH_PRINT = 'FETCH_PRINT'

// fetch records (user, in records.js)
export const SCAN_RECORD_REQUEST = 'SCAN_RECORD_REQUEST'
export const SCAN_RECORD_SUCCESS = 'SCAN_RECORD_SUCCESS'
export const SCAN_RECORD_FAILURE = 'SCAN_RECORD_FAILURE'

// selected scan data
export const SET_CURRENT_SCAN = 'SET_CURRENT_SCAN'
export const UPDATE_CURRENT_SCAN = 'UPDATE_CURRENT_SCAN'
export const UPDATE_BASELINE_SCAN = 'UPDATE_BASELINE_SCAN'
export const UPDATE_CURRENT_POSTURE = 'UPDATE_CURRENT_POSTURE'
export const UPDATE_BASELINE_POSTURE = 'UPDATE_BASELINE_POSTURE'
export const UPDATE_SCAN_RECORD = 'UPDATE_SCAN_RECORD'
export const UPDATE_SCAN_RECORD_FAILURE = 'UPDATE_SCAN_RECORD_FAILURE'
export const DELETE_SCAN_RECORD = 'DELETE_SCAN_RECORD'
export const DELETE_SCAN_RECORD_FAILURE = 'DELETE_SCAN_RECORD_FAILURE'
export const FINISH_SCAN_UPDATES = 'FINISH_SCAN_UPDATES'
export const RESET_SCAN_UPDATES = 'RESET_SCAN_UPDATES'

export const SCAN_RECORDS_REQUEST = 'SCAN_RECORDS_REQUEST'
export const SCAN_RECORDS_SUCCESS = 'SCAN_RECORDS_SUCCESS'
export const SCAN_RECORDS_FAILURE = 'SCAN_RECORDS_FAILURE'

export const FETCH_METRICS = 'FETCH_METRICS'
export const UPDATE_METRIC = 'UPDATE_METRIC'
export const FETCH_FACILITY_ADDITIONAL = 'FETCH_FACILITY_ADDITIONAL'
export const FETCH_ADDITIONAL = 'FETCH_ADDITIONAL'
export const UPDATE_ADDITIONAL = 'UPDATE_ADDITIONAL'
export const ADD_ADDITIONAL = 'ADD_ADDITIONAL'

// User Profile Types
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

export const FETCH_POSTURE_DATA = 'FETCH_POSTURE_DATA'
export const FETCH_POSTURE_DATA_SUCCESS = 'FETCH_POSTURE_DATA_SUCCESS'
export const FETCH_POSTURE_DATA_FAILURE = 'FETCH_POSTURE_DATA_FAILURE'

// Session
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

import React from 'react'
import PropTypes from 'prop-types'

import '../parq.css'

// export default class RadioGroup extends Component {
//     static propTypes = {
//       name: PropTypes.string,
//       label: PropTypes.string,
//       value: PropTypes.string
//     }
//
//     field = ({ input, options }) => {
//       const { name } = input;
//
//       // create a radio button for each option
//       const radioButtons = options.map(({ label, value }) => {
//         const id = `${name}-${label}-${value}`;
//         const checked = input.value === value;
//         const activeRadioStyling = checked ? styles.activeRadioHolder : '';
//
//         return (
//           <label key={ id } className={ `mr-3 ${styles.radioHolder} ${activeRadioStyling}` }>
//             <span className={ `mr-3 ${styles.tick}` } />
//             <input type='radio' { ...input } value={ value } checked={ checked } />
//             { label }
//           </label>
//         );
//       });
//
//       // code for the radio group
//       return (
//         <div className='input-row'>
//           <div>{ radioButtons }</div>
//         </div>
//       );
//     };
//
//     render() {
//       return <Field { ...this.props } component={ this.field } />;
//     }
// }

const RadioGroup = ({ input, options }) => {
  const { name } = input

  // create a radio button for each option
  const radioButtons = options.map(({ label, value }) => {
    const id = `${name}-${label}-${value}`
    const checked = input.value === value
    const activeRadioStyling = checked ? 'activeRadioHolder' : ''

    return (
      <label key={id} className={`mr-3 radioHolder ${activeRadioStyling}`}>
        <span className="mr-3 tick" />
        <input type="radio" {...input} value={value} checked={checked} />
        {label}
      </label>
    )
  })

  // code for the radio group
  return (
    <div className="input-row">
      <div>{radioButtons}</div>
    </div>
  )
}

RadioGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RadioGroup

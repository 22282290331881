import React from 'react'
import PropTypes from 'prop-types'

import BalanceCell from './balance_cell'

const balanceKeyNameList = {
  weightFrontLeft: 'Front Left',
  weightFrontRight: 'Front Right',
  weightBackLeft: 'Back Left',
  weightBackRight: 'Back Right',
}

const TableBody = ({ balanceData, selected, updateSelected }) =>
  [
    'weightFrontLeft',
    'weightFrontRight',
    'weightBackLeft',
    'weightBackRight',
  ].map((direction) => (
    <tr key={direction}>
      <td>{balanceKeyNameList[direction]}</td>
      <BalanceCell
        name={direction}
        selected={selected}
        updateSelected={updateSelected}
        value={balanceData.get(`${direction}Percent`)}
      />
    </tr>
  ))

const BalanceDataTable = (props) => (
  <div>
    <div className="mb-3 text-center">
      <h4>Balance</h4>
    </div>
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Location</th>
            <th>Percent (%)</th>
          </tr>
        </thead>
        <tbody>{TableBody(props)}</tbody>
      </table>
    </div>
  </div>
)

BalanceDataTable.propTypes = {
  balanceData: PropTypes.object,
  selected: PropTypes.object,
  updateSelected: PropTypes.func,
}

export default BalanceDataTable

import { combineReducers } from 'redux'

import {
  FETCH_SCANNERS,
  FETCH_SCANNERS_SUCCESS,
  FETCH_SCANNERS_FAILURE,
  UPDATE_SCANNER,
  UPDATE_SCANNER_SUCCESS,
  UPDATE_SCANNER_FAILURE,
  DEPRECATE_SCANNER,
  DEPRECATE_SCANNER_SUCCESS,
  DEPRECATE_SCANNER_FAILURE,
} from '../../actions/admin/scanners'
import {
  updateScannerList,
  updateDeprecatedScannerList,
} from './helpers/scanners'

const fetchingScanners = (state = false, action) => {
  switch (action.type) {
    case FETCH_SCANNERS:
      return true
    case FETCH_SCANNERS_SUCCESS:
    case FETCH_SCANNERS_FAILURE:
      return false
    default:
      return state
  }
}

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case UPDATE_SCANNER:
      return true
    case UPDATE_SCANNER_SUCCESS:
    case UPDATE_SCANNER_FAILURE:
      return false
    default:
      return state
  }
}

const list = (state = [], action) => {
  switch (action.type) {
    case FETCH_SCANNERS_SUCCESS:
      return action.payload
    case UPDATE_SCANNER_SUCCESS:
      return updateScannerList(state, action.payload)
    case DEPRECATE_SCANNER_SUCCESS: {
      const { oldScannerCode, deprecatedScannerCode } = action.payload
      return updateDeprecatedScannerList(
        state,
        oldScannerCode,
        deprecatedScannerCode
      )
    }
    default:
      return state
  }
}

const initialFormState = {
  scannerId: '',
  scannerCode: '',
  captureType: '',
  hwVersion: '',
  systemId: '',
  sliderSn: '',
  turntableSn: '',
  controlModuleId: '',
  status: '',
  additionalMetricsEnabled: '',
}

const editForm = (state = initialFormState, action) => {
  switch (action.type) {
    case 'UPDATE_FACILITY_SCANNER':
      return {
        ...state,
        ...action.payload,
      }
    case 'CLOSE_SCANNER_EDIT':
      return initialFormState
    default:
      return state
  }
}

const editModalOpen = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_SCANNER_EDIT':
      return true
    case 'CLOSE_SCANNER_EDIT':
      return false
    default:
      return state
  }
}

const errorMessage = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SCANNER:
      return ''
    case UPDATE_SCANNER_FAILURE:
      return action.error
    default:
      return state
  }
}

const deprecatedScanner = (state = {}, action) => {
  switch (action.type) {
    case DEPRECATE_SCANNER:
    case 'RESET_DEPRECATE_STATUS':
      return {}
    case DEPRECATE_SCANNER_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const deprecateStatus = (state = '', action) => {
  switch (action.type) {
    case DEPRECATE_SCANNER:
      return 'loading'
    case DEPRECATE_SCANNER_SUCCESS:
      return 'success'
    case DEPRECATE_SCANNER_FAILURE:
      return action.error // either error message or failure
    case 'RESET_DEPRECATE_STATUS':
      return ''
    default:
      return state
  }
}

export default combineReducers({
  fetchingScanners,
  isSubmitting,
  list,
  editForm,
  editModalOpen,
  errorMessage,
  deprecateStatus,
  deprecatedScanner,
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux';
// import { Modal, ModalBody, ModalFooter } from 'reactstrap';

// import { ModalHOC } from '../hocs/with_modal';
import ScanImage from '../scans/scan_image'
// import ScanVideo from '../scans/scan_video';

class Image extends Component {
  constructor(props) {
    super(props)

    this.showVideo = this.showVideo.bind(this)
    this.state = {
      loading: true,
    }
  }

  showVideo() {
    this.setState({ loading: false })
  }

  render() {
    const { scanPackageId } = this.props.rowData.metadata
    const { degree, type } = this.props.cellProperties
    // const imgSrc = `${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/img/${degree}/type/${type}`;
    return (
      <div className="p-0">
        {/* <div onClick={ this.props.openModal }> */}
        <ScanImage
          imageId={`img-${scanPackageId}-${degree}-${type}`}
          src={`${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/img/${degree}/type/${type}`}
          style={{ width: '100%', minWidth: '200px' }}
        />
        {/* </div> */}
        {/* <Modal isOpen={ this.props.isModalOpen } toggle={ this.props.closeModal }>
          <ModalBody className='text-center'>
            <ScanVideo
              { ...this.props }
              videoId='adminVideo'
              scanPackageId={ scanPackageId }
              src={ `${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/` } />
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={ this.props.closeModal }>Close</button>
          </ModalFooter>
        </Modal> */}
      </div>
    )
  }
}

Image.propTypes = {
  rowData: PropTypes.shape({
    metadata: PropTypes.shape({
      scanPackageId: PropTypes.string,
    }),
  }),
  cellProperties: PropTypes.shape({
    degree: PropTypes.number,
    type: PropTypes.string,
  }),
  // isModalOpen: PropTypes.bool.isRequired,
  // openModal: PropTypes.func.isRequired,
  // closeModal: PropTypes.func.isRequired
}

// export default ModalHOC(Image);
export default Image

import React from 'react'
import PropTypes from 'prop-types'

import CategoryTabs from './CategoryTabs'
import ScanVideos from './ScanVideos'
import SilhouetteImages from './SilhouetteImages'
import PostureImages from './PostureImages'
import PostureSilhouetteImages from './PostureSilhouetteImages'
import PreviewImages from './PreviewImages'

// NOTE: overlay url: `${process.env.REACT_APP_API_BASEURL}/v1/admin/records/${scanPackageId}/scan/img/0/overlay/posture-silhouette`
const MediaContent = ({ selectedMedia, scanPackageId }) => {
  switch (selectedMedia) {
    case 'scan':
      return <ScanVideos scanPackageId={scanPackageId} />
    case 'silhouette':
      return <SilhouetteImages scanPackageId={scanPackageId} />
    case 'posture':
      return <PostureImages scanPackageId={scanPackageId} />
    case 'postureSilhouette':
      return <PostureSilhouetteImages scanPackageId={scanPackageId} />
    case 'preview':
      return <PreviewImages scanPackageId={scanPackageId} />
    default:
      return null
  }
}

export default function ReferenceMedia({ scanPackageId }) {
  const [selectedMedia, setSelectedMedia] = React.useState('scan')

  return (
    <div className="col-md-7 px-3" style={{ marginBottom: 70 }}>
      <CategoryTabs
        selectedMedia={selectedMedia}
        setSelectedMedia={setSelectedMedia}
      />
      <MediaContent
        selectedMedia={selectedMedia}
        scanPackageId={scanPackageId}
      />
    </div>
  )
}

ReferenceMedia.propTypes = {
  scanPackageId: PropTypes.string,
}

import React from 'react'
import PropTypes from 'prop-types'

export const inputField = ({
  input,
  label,
  type,
  style,
  meta: { touched, error },
}) => (
  <div>
    <label htmlFor={input.name} style={{ fontWeight: 400 }}>
      {label}
    </label>
    <input
      {...input}
      id={input.name}
      className="form-control"
      type={type || 'text'}
      placeholder={label}
      style={style}
    />
    {touched && error && <div className="text-danger mt-2">{error}</div>}
  </div>
)

inputField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  label: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
}

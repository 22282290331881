import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import _ from 'lodash'

import PostureCell from './posture_cell'

import { capitalize } from '../../../functions/text_format'

const postureUnits = (units) => ({
  shift: units === 'US' ? 'Inches' : 'cm',
  tilt: '%',
})

const positionMapping = {
  head: 0,
  shoulder: 1,
  underbust: 2,
  hip: 3,
  knee: 4,
}

const PostureDataTable = ({
  direction,
  data,
  units,
  selected,
  updateSelected,
}) => {
  const orientations = data.get('head').keySeq() // orientation = shift/tilt
  const headersList = new Immutable.List(['name']).concat(orientations) // all front/side/back data have head data

  const headers = headersList.map((header) => {
    const formattedHeader = postureUnits(units)[header]
      ? `${capitalize(header)} (${postureUnits(units)[header]})`
      : `${capitalize(header)}`
    return (
      <th key={header} style={{ padding: '0.25rem 0.5rem' }}>
        {formattedHeader}
      </th>
    )
  })

  const sortedPositions = data
    .keySeq()
    .map((datum) => positionMapping[datum])
    .toJS()
    .sort()
  const sortedParts = sortedPositions.map((position) =>
    _.find(
      Object.keys(positionMapping),
      (key) => positionMapping[key] === position
    )
  )
  const body = sortedParts.map((metricType) => {
    // iterate through shift/tilt (if possible) to get get data based on the metricType
    const metricData = orientations.map((orientation) => (
      <PostureCell
        key={metricType + orientation}
        metricType={metricType}
        orientation={orientation}
        direction={direction}
        value={data.get(metricType).get(orientation)}
        selected={selected}
        updateSelected={updateSelected}
      />
    ))

    return (
      <tr key={metricType}>
        <td style={{ padding: '0.25rem 0.5rem' }}>{capitalize(metricType)}</td>
        {metricData}
      </tr>
    )
  })

  return (
    <div>
      <table className="table table-bordered" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  )
}

PostureDataTable.propTypes = {
  direction: PropTypes.string.isRequired,
  data: ImmutablePropTypes.map,
  units: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    metricType: PropTypes.string,
    orientation: PropTypes.string,
    value: PropTypes.number,
  }),
  updateSelected: PropTypes.func,
}

export default PostureDataTable

import React from 'react'

export default function Table({ table }) {
  return (
    <table className="tabled table-bordered">
      <thead>
        <tr>
          <th
            style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}
          >
            Name
          </th>
          <th
            style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}
          >
            Value
          </th>
          <th
            style={{ width: '285px', padding: '4px 6px', borderRight: 'none' }}
          >
            Name
          </th>
          <th
            style={{ width: '285px', padding: '4px 6px', borderLeft: 'none' }}
          >
            Value
          </th>
        </tr>
      </thead>
      <tbody>{table}</tbody>
    </table>
  )
}

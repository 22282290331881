import React from 'react'
import { connect } from 'react-redux'

import { fetchQcRecords } from '../../../actions/admin/qc'

import QcRecordsHeader from './QcRecordsHeader'
import QcRecordsTable from './QcRecordsTable'
import Pagination from '../Pagination'

import '../admin.css'

const QcRecords = ({ loading, fetchQcRecords }) => {
  React.useEffect(() => {
    fetchQcRecords({
      page: 0,
      size: 10,
    })
  }, [])

  const handleFetch = (params) => {
    fetchQcRecords(params)
  }

  return (
    <div className="admin-container">
      <QcRecordsHeader />
      {!loading && (
        <>
          <QcRecordsTable />
          <div className="row mx-0 d-flex justify-content-end">
            <Pagination fetchData={handleFetch} />
          </div>
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchQcRecords: (packageId) => dispatch(fetchQcRecords(packageId)),
})

export default connect(null, mapDispatchToProps)(QcRecords)

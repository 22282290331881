import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import visibleRecordsSelector from '../../selectors/visible_records';
// import currentScanSelector from '../../selectors/current_scan';

import WellnessReport from './report'
import WellnessHistory from './history'

import './client_report.css'

const Wellness = (props) => (
  <div className="pt-3 pt-sm-4 px-3 px-sm-5">
    <div className="d-flex">
      <div className="client-report">
        <WellnessReport {...props} />
      </div>
      {props.width > 768 &&
        !!(
          props.coach.get('clientReport') && props.coach.get('clientSummary')
        ) && (
          <div
            className="print-none"
            style={{ position: 'fixed', right: '3rem', top: '85px' }}
          >
            <WellnessHistory
              {...props}
              clientReport={props.coach.get('clientReport')}
              records={props.coach.get('clientSummary')}
            />
          </div>
        )}
    </div>
  </div>
)

Wellness.propTypes = {
  width: PropTypes.number,
  coach: PropTypes.object,
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  coach: state.coach,
  datasets: state.datasets,
  user: state.user,
})

export default connect(mapStateToProps)(Wellness)

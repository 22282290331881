import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadScanRecords } from '../../actions/records'

import HistoryScans from './scans'
import InfoPanel from './info_panel'

import './history.css'

class History extends Component {
  constructor(props) {
    super(props)

    this.updateSelectedScan = this.updateSelectedScan.bind(this)

    this.state = {
      selectedScan: null,
      currentPage: 0,
      maxPages: null,
    }
  }

  componentDidMount() {
    this.props.loadScanRecords()
  }

  // NOTE: probably better to just use currentScan in reducer as the selectedScan later
  componentWillReceiveProps(nextProps) {
    const { selectedScan } = this.state
    if (selectedScan) {
      const nextRecord = nextProps.records.find(
        (record) => record.get('id') === selectedScan.get('id')
      )
      if (
        nextRecord &&
        nextRecord.get('visibility') !== selectedScan.get('visibility')
      ) {
        this.setState({ selectedScan: nextRecord })
      }
    }
  }

  updateSelectedScan(scan) {
    this.setState({ selectedScan: scan })
  }

  render() {
    if (!this.props.records) {
      return <div>fetching records</div>
    }

    const { selectedScan } = this.state
    return (
      <div className="py-4 px-2 px-md-4">
        <div
          className="mb-4"
          style={{ fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
        >
          <div
            className="text-uppercase text-xs-center text-sm-left"
            style={{
              marginBottom: '5px',
              color: '#51565b',
              fontSize: '1.75rem',
              letterSpacing: '3px',
            }}
          >
            Scan History
          </div>
        </div>
        <div className="d-flex">
          <div className="col-xs-12 col-md-9 pl-0 pr-0 pr-sm-3">
            <HistoryScans
              width={this.props.width}
              records={this.props.records}
              selectedScan={selectedScan}
              scanClickHandler={this.updateSelectedScan}
            />
          </div>
          {this.props.width >= 768 && (
            <div
              className="col-xs-12 col-md-3 pr-0"
              style={{ margin: '5px 0' }}
            >
              {selectedScan ? (
                <InfoPanel record={selectedScan} />
              ) : (
                <div className="card p-3">
                  Click a scan on the left to view more information!
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

History.propTypes = {
  width: PropTypes.number,
  records: PropTypes.object,
  loadScanRecords: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  width: state.windowDimensions.width,
  records: state.records.get('records'),
})

const actions = {
  loadScanRecords,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(History)

import React from 'react'
import './loading.css'

export default () => (
  <div className="loading">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
)

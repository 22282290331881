import React from 'react'
import PropTypes from 'prop-types'

export default function AdminUserActions({ cellProperties, value, rowData }) {
  const handleAdminStatusUpdate = () => {
    const { updateRole, removeRole } = cellProperties
    const action = value ? removeRole : updateRole

    action({
      userId: rowData.userId,
      data: {
        role: 'facilityadmin',
      },
    })
  }

  return (
    <div>
      <button
        className={`btn ${
          value ? 'btn-outline-danger' : 'btn-outline-primary'
        } btn-xs py-0`}
        style={{ width: '100%' }}
        onClick={handleAdminStatusUpdate}
      >
        <span
          className={`icon ${
            value ? 'icon-circle-with-minus' : 'icon-circle-with-plus'
          }`}
        />
        &nbsp;
        <span>{value ? 'Remove Admin' : 'Make Admin'}</span>
      </button>
    </div>
  )
}

AdminUserActions.propTypes = {
  cellProperties: PropTypes.shape({
    updateRole: PropTypes.func.isRequired,
    removeRole: PropTypes.func.isRequired,
  }),
  rowData: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }),
  value: PropTypes.bool,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class MeasurementInfo extends Component {
  constructor(props) {
    super(props)

    this.measurementKeyNameList = {
      neckCollarGirth: 'Neck',
      chestAtBladesGirth: 'Chest',
      bustGirth: 'Bust',
      waistSmallBackGirth: 'Waist',
      seatGirth: 'Hips',
      bicepsLeftGirth: 'Left Biceps',
      bicepsRightGirth: 'Right Biceps',
      forearmLeftGirth: 'Left Forearm',
      forearmRightGirth: 'Right Forearm',
      thighLeftMaxGirth: 'Left Thigh',
      thighRightMaxGirth: 'Right Thigh',
      calfLeftGirth: 'Left Calf',
      calfRightGirth: 'Right Calf',
    }

    const gender = props.user.get('user').get('gender')
    const keyToDelete = gender === 'Female' ? 'chestAtBladesGirth' : 'bustGirth'

    delete this.measurementKeyNameList[keyToDelete]
  }

  getMeasurementData() {
    const measurements = this.props.records
      .find((record) => {
        return record.get('id') === this.props.currentScan.get('id')
      })
      .get('scanMeasurement')

    var measurementData = []
    for (var key in this.measurementKeyNameList) {
      measurementData.push({ [key]: measurements.get(key) })
    }
    return measurementData
  }

  renderMeasurementData() {
    var data = this.getMeasurementData()
    return data.map((measurement) => {
      var key = Object.keys(measurement)[0]
      var value = measurement[key]
      return (
        <tr key={key}>
          <td isolate>{this.measurementKeyNameList[key]}</td>
          <td className="text-center">{value}</td>
        </tr>
      )
    })
  }

  render() {
    const units =
      this.props.user.get('user').get('units') === 'US' ? 'Inches' : 'cm'
    return (
      <div className="d-flex measurement-panel">
        <div className="text-center text-uppercase header-container">
          <h3>Measurements</h3>
        </div>
        <div
          id="table"
          className="card-body m-xs-0 px-3s-0"
          style={{ width: '100%' }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>
                  <span isolate>Value</span>
                  <span isolate>{` (${units})`}</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.renderMeasurementData()}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

MeasurementInfo.propTypes = {
  records: PropTypes.object,
  currentScan: PropTypes.object,
  user: PropTypes.object,
}

import React from 'react'

export default function EmailEdit({
  rowData: { userId, email, firstName, lastName },
  cellProperties: { onOpen, setEmailEditDefaults },
}) {
  const handleClick = () => {
    onOpen()
    setEmailEditDefaults({ userId, email, firstName, lastName })
  }
  return (
    <div>
      {email}
      <span className="icon icon-pencil float-right" onClick={handleClick} />
    </div>
  )
}

import React from 'react'

export default function MergeButton({ data, onClick }) {
  return (
    <button
      className="btn btn-primary mt-3 ml-3"
      style={{ width: '200px' }}
      disabled={!(data.to && data.from)}
      onClick={onClick}
    >
      Merge
    </button>
  )
}

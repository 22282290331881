import React from 'react'
import PropTypes from 'prop-types'

import '../wellness/wellness.css'

const className = 'col-sm-4 text-center'

const Categories = (props) => (
  <div className="row mx-0 wellness-header">
    <div
      className={`${className} ${String(props.selectedCategory === 'front')}`}
      onClick={() => props.updateSelected('front')}
    >
      Front
    </div>
    <div
      className={`${className} ${String(props.selectedCategory === 'side')}`}
      onClick={() => props.updateSelected('side')}
    >
      Side
    </div>
    <div
      className={`${className} ${String(props.selectedCategory === 'back')}`}
      onClick={() => props.updateSelected('back')}
    >
      Back
    </div>
  </div>
)

Categories.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  updateSelected: PropTypes.func.isRequired,
}

export default Categories

import React from 'react'

const CategoryTabs = ({ selectedMedia, setSelectedMedia }) => {
  return (
    <div className="btn-group">
      <button
        className={`btn btn-outline-primary ${
          selectedMedia === 'scan' ? 'active' : null
        }`}
        onClick={() => setSelectedMedia('scan')}
      >
        Scan
      </button>
      <button
        className={`btn btn-outline-primary ${
          selectedMedia === 'silhouette' ? 'active' : null
        }`}
        onClick={() => setSelectedMedia('silhouette')}
      >
        Silhouette
      </button>
      <button
        className={`btn btn-outline-primary ${
          selectedMedia === 'posture' ? 'active' : null
        }`}
        onClick={() => setSelectedMedia('posture')}
      >
        Posture
      </button>
      <button
        className={`btn btn-outline-primary ${
          selectedMedia === 'postureSilhouette' ? 'active' : null
        }`}
        onClick={() => setSelectedMedia('postureSilhouette')}
      >
        Posture Silhouette
      </button>
      <button
        className={`btn btn-outline-primary ${
          selectedMedia === 'preview' ? 'active' : null
        }`}
        onClick={() => setSelectedMedia('preview')}
      >
        Preview
      </button>
      {/* <button
            className={ `btn btn-outline-primary ${selectedMedia === OVERLAY ? 'active' : null}` }
            onClick={ () => this.changeSelectedMedia(OVERLAY) }>
            Overlay
          </button> */}
    </div>
  )
}

export default CategoryTabs

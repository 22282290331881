export default (params) => {
  const queryStringParams = []
  for (const key in params) {
    if (key === 'search') {
      const { searchParam, searchString } = params[key]
      queryStringParams.push(
        `${key}=${searchParam}:${encodeURIComponent(searchString)}`
      )
    } else {
      queryStringParams.push(`${key}=${params[key]}`)
    }
  }
  return queryStringParams.join('&')
}

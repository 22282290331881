import React from 'react'
import AccessInstruction from '../utilities/facility/access_instruction'

export const AccessInstructions = () => (
  <div>
    <div className="mb-3">
      <AccessInstruction
        title="Full Access"
        body={"Coach is able to see client's results data and scan images"}
      />
    </div>
    <div className="mb-3">
      <AccessInstruction
        title="Limited Access"
        body={"Coach is able to see client's result data, but not scan images"}
      />
    </div>
    <div>
      <AccessInstruction
        title="No Access"
        body="Coach cannot see client results"
      />
    </div>
  </div>
)

export default AccessInstructions

import Immutable from 'immutable'

import * as types from '../actions/wellness/types'

const reducers = {
  [types.FETCH_METRICS_SUCCESS]: (state, action) => {
    return state
      .set('metrics', Immutable.fromJS(action.payload.data))
      .set('fetchingMetrics', false)
  },
  [types.FETCH_METRICS_FAILURE]: (state) => state.set('fetchingMetrics', false),

  // fetching list of facility additional metrics
  [types.FETCH_FACILITY_ADDITIONAL_SUCCESS]: (state, action) => {
    return state.set('facility', Immutable.fromJS(action.payload.data))
  },

  // fetching user's additional metrics
  [types.FETCH_USER_ADDITIONAL_SUCCESS]: (state, action) => {
    return state.set('additional', Immutable.fromJS(action.payload.data))
  },

  // updating additional metrics
  [types.ADD_ADDITIONAL]: (state) => {
    return state.set('statusMessage', null)
  },
  [types.ADD_ADDITIONAL_SUCCESS]: (state, action) => {
    return state
      .set('additional', Immutable.fromJS(action.payload.data))
      .set('statusMessage', 'Success!')
  },
  [types.ADD_ADDITIONAL_FAILURE]: (state) =>
    state.set('statusMessage', 'Failed to update metrics'),

  // statusMessage
  [types.CLEAR_METRICS_STATUS_MESSAGE]: (state) => {
    return state.set('statusMessage', null)
  },

  // [types.UPDATE_METRIC_SUCCESS]: (state, action) => {
  //   const { recordId } = action.payload.data;
  //   const index = state.get('metrics').findIndex(item => item.get('recordId') === recordId);
  //
  //   const updatedList = state.get('metrics').update(index, () => Immutable.fromJS(action.payload.data));
  //
  //   return state.set('metrics', updatedList);
  // }
}

const defaultState = new Immutable.Map({
  fetchingMetrics: true,
  statusMessage: null,
})

export default function (state = defaultState, action) {
  if (action && typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

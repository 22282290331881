import * as types from '../actions/nav/types'

const FITNESS = 'FITNESS'

const defaultState = {
  isOpen: false,
  selectedPage: FITNESS, // NOTE: this changes based on acc, do this in the bootstrapping phase when fecthing for user datas
}

const updateState = (state, action, key) =>
  Object.assign({}, state, { [key]: action.payload })

const reducers = {
  [types.UPDATE_SELECTED_PAGE]: (state, action) =>
    updateState(state, action, 'selectedPage'),
}

export default (state = defaultState, action) => {
  if (typeof reducers[action.type] === 'function') {
    return reducers[action.type](state, action)
  }
  return state
}

import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import WellnessComparison from '../components/wellness/comparison.js'
import MeasurementComparison from '../components/measurements/comparison.js'
import PostureComparison from '../components/posture/comparison'

class ComparisonPage extends Component {
  render() {
    return (
      <div className="pt-3 pt-sm-4 px-3 px-sm-5">
        <Switch>
          <Route
            exact
            path="/comparison/wellness"
            component={(props) => <WellnessComparison {...props} />}
          />
          <Route
            exact
            path="/comparison/measurements"
            component={(props) => <MeasurementComparison {...props} />}
          />
          <Route
            exact
            path="/comparison/posture"
            component={(props) => <PostureComparison {...props} />}
          />
        </Switch>
      </div>
    )
  }
}

export default ComparisonPage

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { logout } from '../../actions/user/index'
import * as routeHandlers from './route_groups'

// import SubNav from '../../components/navbar/sub_nav';
// import Logo from './logo';
// import PersonalNav from './personal';
import IconNav from './icon_nav'
import Fit3dAdminNav from './fit3d_admin'
import FacilityNav from './facility'
import FitnessNav from './fitness'
import AccountNav from './account'
// import EnterpriseNav from './enterprise';

import '../app.css'
import './nav.css'

const navBackground = 'rgb(203, 216, 228)'
// const subIconColor = '#729ab3';
const highlightedText = '#3c4444'
const headerText = '#657272'
const pageText = '#51565b'
// const subPageText = '#657272';
// const subPageTextSelected = '#4cb8c4';

// TODO: refactor code (no repeating components)
class NavBar extends Component {
  constructor(props) {
    super(props)

    this.logout = this.logout.bind(this)
    this.redirectPage = this.redirectPage.bind(this)
    this.toggle = this.toggle.bind(this)
    this.updateSelected = this.updateSelected.bind(this)

    // Find the default selected page, which can vary depending on user role what page people are currently on when they refresh
    const { pathname } = props.location

    const superAdmin = props.user.get('user').get('superAdmin')
    const facilityAdmin = props.user.get('user').get('facilityAdmin')
    const coach = props.user.get('user').get('coach')

    let selectedPage
    if (pathname === '/') {
      if (superAdmin) {
        selectedPage = routeHandlers.FIT3D_ADMIN
      } else if (facilityAdmin || coach) {
        selectedPage = routeHandlers.FACILITY
      } else {
        selectedPage = routeHandlers.FITNESS
      }
    } else {
      selectedPage = routeHandlers.fetchRouteGroup(pathname)
    }

    this.state = {
      isOpen: false,
      selectedPage,
      selectedSubPage: null,
    }
  }

  logout() {
    this.props.logout()
    window.localStorage.removeItem('token')
    window.localStorage.clear()
  }

  redirectPage(route) {
    return () => {
      if (route !== this.props.location.pathname) {
        this.props.history.push(route)
      }
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  updateSelected(page) {
    this.setState({ selectedPage: page })
  }

  updateSelectedSubPage(subPage) {
    this.setState({
      selectedSubPage: this.state.selectedSubPage !== subPage ? subPage : null,
    })
  }

  renderFit3dAdminNav() {
    return (
      <div className="iconav-slider">
        <div
          className="text-center text-uppercase"
          style={{
            color: headerText,
            fontWeight: 500,
            letterSpacing: '0.3rem',
            marginBottom: '10px',
            paddingTop: '24px',
            fontFamily: 'Lato',
            fontSize: '1rem',
          }}
        >
          <span>Fit3D Admin</span>
        </div>
        <ul
          className="nav-pills flex-md-column list-unstyled"
          style={{ padding: '15px 0px' }}
        >
          <li
            className="nav-item"
            style={{ color: pageText }}
            onClick={this.redirectPage('/marketplace')}
          >
            <a
              className="nav-link d-flex"
              style={{
                paddingTop: '0.75rem',
                paddingBottom:
                  this.state.selectedSubPage === 'fitnessResults'
                    ? '0.25rem'
                    : '0.75rem',
              }}
            >
              <div style={{ fontSize: '1rem' }}>
                {/* <span className='fas fa-shopping-bag' style={ { marginRight: '0.6rem', color: subIconColor } } /> */}
                <span
                  style={
                    this.props.location.pathname === '/marketplace'
                      ? { fontWeight: '500', color: highlightedText }
                      : null
                  }
                >
                  Admin
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  renderHistoryNav() {
    return (
      <div className="iconav-slider">
        <div
          className="text-center text-center text-uppercase"
          style={{
            color: headerText,
            fontWeight: 500,
            letterSpacing: '0.3rem',
            marginBottom: '10px',
            paddingTop: '24px',
            fontFamily: 'Lato',
            fontSize: '1rem',
          }}
        >
          <span>History</span>
        </div>
        <ul
          className="nav-pills flex-md-column list-unstyled"
          style={{ padding: '15px 0px' }}
        >
          <li
            className="nav-item"
            style={{ color: pageText }}
            onClick={this.redirectPage('/history')}
          >
            <a
              className="nav-link d-flex"
              style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}
            >
              <div style={{ fontSize: '1rem' }}>
                {/* <span className='fas fa-shopping-bag' style={ { marginRight: '0.6rem', color: subIconColor } } /> */}
                <span
                  style={
                    this.props.location.pathname === '/history'
                      ? { fontWeight: '500', color: highlightedText }
                      : null
                  }
                >
                  Scan History
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  renderMarketplaceNav() {
    return (
      <div className="iconav-slider">
        <div
          className="text-center text-center text-uppercase"
          style={{
            color: headerText,
            fontWeight: 500,
            letterSpacing: '0.3rem',
            marginBottom: '10px',
            paddingTop: '24px',
            fontFamily: 'Lato',
            fontSize: '1rem',
          }}
        >
          <span>Marketplace</span>
        </div>
        <ul
          className="nav-pills flex-md-column list-unstyled"
          style={{ padding: '15px 0px' }}
        >
          <li
            className="nav-item"
            style={{ color: pageText }}
            onClick={this.redirectPage('/marketplace')}
          >
            <a
              className="nav-link d-flex"
              style={{
                paddingTop: '0.75rem',
                paddingBottom:
                  this.state.selectedSubPage === 'fitnessResults'
                    ? '0.25rem'
                    : '0.75rem',
              }}
            >
              <div style={{ fontSize: '1rem' }}>
                {/* <span className='fas fa-shopping-bag' style={ { marginRight: '0.6rem', color: subIconColor } } /> */}
                <span
                  style={
                    this.props.location.pathname === '/marketplace'
                      ? { fontWeight: '500', color: highlightedText }
                      : null
                  }
                >
                  Marketplace
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  renderSubNav() {
    const {
      width,
      location: { pathname },
    } = this.props
    const { selectedSubPage } = this.state

    switch (this.state.selectedPage) {
      case routeHandlers.FIT3D_ADMIN:
        return (
          <Fit3dAdminNav
            isQcAdmin={this.props.user.get('user').get('qcAdmin')}
            pathname={pathname}
            redirectPage={this.redirectPage}
          />
        )
      case routeHandlers.FACILITY:
        return (
          <FacilityNav
            pathname={pathname}
            facilityAdmin={this.props.user.get('user').get('facilityAdmin')}
            selectedSubPage={selectedSubPage}
            redirectPage={this.redirectPage}
          />
        )
      case routeHandlers.HISTORY:
        if (width > 768) {
          return this.renderHistoryNav()
        }
        return null
      case routeHandlers.MARKETPLACE:
        if (width > 768) {
          return this.renderMarketplaceNav()
        }
        return null
      case routeHandlers.ACCOUNT:
        return (
          <AccountNav pathname={pathname} redirectPage={this.redirectPage} />
        )
      // case routeHandlers.ENTERPRISE:
      //   return (
      //     <EnterpriseNav
      //       pathname={ pathname }
      //       redirectPage={ this.redirectPage } />
      //   );
      case routeHandlers.FITNESS:
      default:
        return (
          <FitnessNav
            pathname={pathname}
            selectedSubPage={selectedSubPage}
            redirectPage={this.redirectPage}
          />
        )
    }
  }

  render() {
    const { isOpen, selectedPage } = this.state
    const {
      width,
      location: { pathname },
    } = this.props
    const user = this.props.user.get('user')

    const superAdmin = user.get('superAdmin')
    const qcAdmin = user.get('qcAdmin')
    const facilityAdmin = user.get('facilityAdmin')
    const coach = user.get('coach')

    const handleToggle = this.toggle

    let redirectUrl
    if (superAdmin) {
      redirectUrl = '/admin/records'
    } else if (qcAdmin) {
      redirectUrl = '/admin/records/qc'
    } else if (facilityAdmin || coach) {
      redirectUrl = '/facility/dashboard'
    } else {
      redirectUrl = '/wellness'
    }

    return (
      <div className="print-none">
        <nav className="iconav custom-iconav">
          {width > 768 ? (
            <a
              className="iconav-brand"
              style={{ background: navBackground }}
              onClick={this.redirectPage(redirectUrl)}
            >
              <img
                src="/images/favicon.png"
                style={{ width: '30px' }}
                alt="brand"
              />
            </a>
          ) : (
            <a
              className="d-flex align-items-center iconav-brand"
              style={{ background: navBackground }}
              onClick={handleToggle}
            >
              <span className="fas fa-bars" style={{ width: '30px' }} />
            </a>
          )}
          {width > 768 && (
            <IconNav
              selectedPage={selectedPage}
              user={user}
              updateSelected={this.updateSelected}
              pathname={pathname}
            />
          )}
        </nav>
        {width <= 768 && isOpen && (
          <IconNav
            width={width}
            selectedPage={selectedPage}
            user={user}
            redirectPage={this.redirectPage}
            updateSelected={this.updateSelected}
            pathname={pathname}
          />
        )}
        {(isOpen || width > 768) && (
          <nav className="sub-nav">{this.renderSubNav()}</nav>
        )}
      </div>
    )
  }
}

NavBar.propTypes = {
  width: PropTypes.number,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  user: state.user,
  width: state.windowDimensions.width,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)

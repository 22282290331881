import React from 'react'

export default function FacilityLabel({
  rowData: { userId, facility, firstName, lastName },
  cellProperties: { onOpen, setFacilityEditDefaults },
}) {
  const handleClick = () => {
    onOpen()
    setFacilityEditDefaults({
      userId,
      currentFacility: facility,
      facility,
      firstName,
      lastName,
    })
  }
  return (
    <div>
      {facility}
      <span className="icon icon-pencil float-right" onClick={handleClick} />
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import * as routeHandlers from './route_groups'
import './nav.css'

const iconColor = '#484848'

const IconNav = (props) => {
  const { width, user, pathname, selectedPage } = props

  const facility = props.user.get('facility')

  const superAdmin = user.get('superAdmin')
  const qcAdmin = user.get('qcAdmin')
  const facilityAdmin = user.get('facilityAdmin')
  const coach = user.get('coach')
  // const servicesAccess = user.get('facility') ? user.get('facility').get('servicesAccess') : false;

  // const enterpriseAccess = user.get('enterpriseRole');
  const hasFacilityAccess = (superAdmin || facilityAdmin || coach) && !qcAdmin

  return (
    <div className="iconav-slider">
      <ul className="nav-pills iconav-nav flex-md-column p-0 custom-nav-pills">
        {/* {
          enterpriseAccess && (

            <li
              className='nav-item'
              onClick={ () => props.updateSelected(routeHandlers.ENTERPRISE) }
              style={ (selectedPage === routeHandlers.ENTERPRISE || (!selectedPage && routeHandlers.fetchRouteGroup(pathname) === routeHandlers.ENTERPRISE)) ? { background: '#e5ebf1' } : null }>
              <a className='nav-link' style={ { padding: '10px', borderLeft: 'none' } }>
                <span className='fas fa-building' style={ { color: iconColor } } />
                <small className='iconav-nav-label d-md-none'>Enterprise</small>
              </a>
            </li>
          )
        } */}
        {superAdmin && (
          <li
            className="nav-item"
            onClick={() => props.updateSelected(routeHandlers.FIT3D_ADMIN)}
            style={
              selectedPage === routeHandlers.FIT3D_ADMIN ||
              (!selectedPage &&
                routeHandlers.fetchRouteGroup(pathname) ===
                  routeHandlers.FIT3D_ADMIN)
                ? { background: '#e5ebf1' }
                : null
            }
          >
            <a
              className="nav-link"
              style={{ padding: '10px', borderLeft: 'none' }}
            >
              <span className="fas fa-cogs" style={{ color: iconColor }} />
              <small className="iconav-nav-label d-md-none">Admin</small>
            </a>
          </li>
        )}
        {hasFacilityAccess && (
          <li
            className="nav-item"
            onClick={() => props.updateSelected(routeHandlers.FACILITY)}
            style={
              selectedPage === routeHandlers.FACILITY ||
              (!selectedPage &&
                routeHandlers.fetchRouteGroup(pathname) ===
                  routeHandlers.FACILITY)
                ? { background: '#e5ebf1' }
                : null
            }
          >
            <a
              className="nav-link"
              style={{ padding: '10px', borderLeft: 'none' }}
            >
              <span className="fas fa-users" style={{ color: iconColor }} />
              <small className="iconav-nav-label d-md-none">Facility</small>
            </a>
          </li>
        )}
        <li
          className="nav-item"
          onClick={() => props.updateSelected(routeHandlers.FITNESS)}
          style={
            selectedPage === routeHandlers.FITNESS ||
            (!selectedPage &&
              routeHandlers.fetchRouteGroup(pathname) === routeHandlers.FITNESS)
              ? { background: '#e5ebf1' }
              : null
          }
        >
          <a
            className="nav-link"
            style={{ padding: '10px', borderLeft: 'none' }}
          >
            <span className="fas fa-heartbeat" style={{ color: iconColor }} />
            <small className="iconav-nav-label d-md-none">Fitness</small>
          </a>
        </li>
        <li
          className="nav-item"
          onClick={() => {
            props.updateSelected(routeHandlers.HISTORY)
            if (width <= 768) props.redirectPage('/history')()
          }}
          style={
            selectedPage === routeHandlers.HISTORY ||
            (!selectedPage &&
              routeHandlers.fetchRouteGroup(pathname) === routeHandlers.HISTORY)
              ? { background: '#e5ebf1' }
              : null
          }
        >
          <a
            className="nav-link"
            style={{ padding: '10px', borderLeft: 'none' }}
          >
            <span className="fas fa-list-ul" style={{ color: iconColor }} />
            <small className="iconav-nav-label d-md-none">History</small>
          </a>
        </li>
        <li
          className="nav-item"
          onClick={() => {
            props.updateSelected(routeHandlers.MARKETPLACE)
            if (width <= 768) props.redirectPage('/marketplace')()
          }}
          style={
            selectedPage === routeHandlers.MARKETPLACE ||
            (!selectedPage &&
              routeHandlers.fetchRouteGroup(pathname) ===
                routeHandlers.MARKETPLACE)
              ? { background: '#e5ebf1', position: 'relative' }
              : { position: 'relative' }
          }
        >
          <a
            className="nav-link"
            style={{ padding: '10px', borderLeft: 'none' }}
          >
            <span
              className="fas fa-shopping-bag"
              style={{ color: iconColor }}
            />
            <small className="iconav-nav-label d-md-none">Market</small>
          </a>
          {/* {
            !window.localStorage.getItem('viewedMarketplace') && (
              <div style={ { position: 'absolute', top: '11px', right: '10px' } }>
                <div style={ {
                  height: '12px',
                  width: '12px',
                  background: 'red',
                  borderRadius: '50%',
                  border: (selectedPage === routeHandlers.MARKETPLACE || (!selectedPage && routeHandlers.fetchRouteGroup(pathname) === routeHandlers.MARKETPLACE)) ? '2px #e4ebf0 solid' : '2px #c8d8e5 solid'
                } } />
              </div>
            )
          } */}
        </li>
        {/* {
          servicesAccess === 1 && (
            <li
              className='nav-item'
              onClick={ () => {
                props.updateSelected(routeHandlers.MARKETPLACE);
                if (width <= 768) props.redirectPage('/marketplace')();
              } }
              style={ (selectedPage === routeHandlers.MARKETPLACE || (!selectedPage && routeHandlers.fetchRouteGroup(pathname) === routeHandlers.MARKETPLACE)) ? { background: '#e5ebf1', position: 'relative' } : { position: 'relative' } }>
              <a className='nav-link' style={ { padding: '10px', borderLeft: 'none' } }>
                <span className='fas fa-shopping-bag' style={ { color: iconColor } } />
                <small className='iconav-nav-label d-md-none'>Market</small>
              </a>
              {
                !window.localStorage.getItem('viewedMarketplace') && (
                  <div style={ { position: 'absolute', top: '11px', right: '10px' } }>
                    <div style={ {
                      height: '12px',
                      width: '12px',
                      background: 'red',
                      borderRadius: '50%',
                      border: (selectedPage === routeHandlers.MARKETPLACE || (!selectedPage && routeHandlers.fetchRouteGroup(pathname) === routeHandlers.MARKETPLACE)) ? '2px #e4ebf0 solid' : '2px #c8d8e5 solid'
                    } } />
                  </div>
                )
              }
            </li>
          )
        } */}
        {facility &&
          typeof facility.get('name') === 'string' &&
          !facility.get('name').toLowerCase().includes('equinox') && (
            <li className="nav-item" style={{ position: 'relative' }}>
              <a
                className="nav-link"
                style={{ padding: '10px', borderLeft: 'none' }}
                href="https://survey.fit3dlabs.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fas fa-flask" style={{ color: iconColor }} />
                <small className="iconav-nav-label d-md-none">Survey</small>
              </a>
            </li>
          )}
        <li
          className="nav-item"
          onClick={() => props.updateSelected(routeHandlers.ACCOUNT)}
          style={
            selectedPage === routeHandlers.ACCOUNT ||
            (!selectedPage &&
              routeHandlers.fetchRouteGroup(pathname) === routeHandlers.ACCOUNT)
              ? { background: '#e5ebf1' }
              : null
          }
        >
          <a
            className="nav-link"
            style={{ padding: '10px', borderLeft: 'none' }}
          >
            <span className="fas fa-user-circle" style={{ color: iconColor }} />
            <small className="iconav-nav-label d-md-none">Account</small>
          </a>
        </li>
      </ul>
    </div>
  )
}

IconNav.propTypes = {
  width: PropTypes.number,
  selectedPage: PropTypes.string,
  user: PropTypes.object.isRequired,
  redirectPage: PropTypes.func,
  updateSelected: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default IconNav

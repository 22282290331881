import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const Table = ({ table }) => (
  <div className="col-sm-6 col-md-6 col-lg-6">
    <table className="table p-0">
      <tbody>{table}</tbody>
    </table>
  </div>
)

Table.propTypes = {
  table: PropTypes.array,
}

export const Row = ({ datum, data }) => (
  <tr key={_.uniqueId()}>
    <td>{data.name}</td>
    <td>{datum[data.key]}</td>
  </tr>
)

Row.propTypes = {
  datum: PropTypes.object,
  data: PropTypes.object,
}

export const NullTable = ({ title }) => (
  <div className="mb-3 text-center" key={_.uniqueId()}>
    <h4>{title}</h4>
    <div>{`No Available ${title}`}</div>
  </div>
)

NullTable.propTypes = {
  title: PropTypes.string.isRequired,
}

import {
  FETCH_POSTURE_DATA,
  FETCH_POSTURE_DATA_SUCCESS,
  FETCH_POSTURE_DATA_FAILURE,
  UPDATE_CURRENT_POSTURE,
  UPDATE_BASELINE_POSTURE,
  FINISH_SCAN_UPDATES,
  RESET_SCAN_UPDATES,
} from './types'
import action from './middleware'
import postureData from '../selectors/posture/visible_posture_data'

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const fetchPostureData = () => (dispatch) =>
  action(
    {
      endpoint: '/v1/records/posture',
      method: 'GET',
      actionTypes: {
        requestType: FETCH_POSTURE_DATA,
        successType: FETCH_POSTURE_DATA_SUCCESS,
        failureType: FETCH_POSTURE_DATA_FAILURE,
      },
    },
    dispatch
  )

export const updateBaselinePosture = (posture) => (dispatch) => {
  return delay(10).then(() => {
    dispatch({
      type: UPDATE_BASELINE_POSTURE,
      payload: posture,
    })
  })
}

export const updateCurrentPosture = (posture) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_CURRENT_POSTURE,
      payload: posture,
    })

    const baselinePosture = getState().current.get('baselinePosture')
    if (baselinePosture) return null

    const oldestPosture = postureData(getState()).last()
    return delay(10).then(() => {
      dispatch(updateBaselinePosture(oldestPosture))
    })
  }
}

export const loadPostureData = () => {
  return (dispatch, getState) => {
    if (
      !getState().current.get('currentPosture') ||
      !getState().current.get('baselinePosture')
    ) {
      dispatch({ type: RESET_SCAN_UPDATES })
    }

    const postures = getState().posture.get('postureData')
    if (postures && postures.size !== 0) {
      return null
    }

    return dispatch(fetchPostureData()).then(() => {
      const currentPosture = getState().current.get('currentPosture')
      if (currentPosture) {
        return null
      }

      if (!postureData(getState())) {
        dispatch({ type: FINISH_SCAN_UPDATES })
        return null
      }
      const newestPosture = postureData(getState()).first()

      return dispatch(updateCurrentPosture(newestPosture)).then(() => {
        const baselinePosture = getState().current.get('baselinePosture')
        if (baselinePosture) {
          return null
        }

        const oldestPosture = postureData(getState()).last()
        return dispatch(updateBaselinePosture(oldestPosture)).then(() =>
          dispatch({ type: FINISH_SCAN_UPDATES })
        )
      })
    })
  }
}

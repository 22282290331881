import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { updateCurrentScan, updateBaselineScan } from '../../actions/records'

import visibleMeasurementSelector from '../../selectors/visible_measurements'
import currentScanSelector from '../../selectors/current_scan'
import baselineScanSelector from '../../selectors/baseline_scan'
// import currentMetricSelector from '../../selectors/current_metric';
// import baselineMetricSelector from '../../selectors/baseline_metric';
import visibleRecordsSelector from '../../selectors/visible_records'

import NoTranslate from '../hocs/NoTranslate'
import MeasurementComparisonInfo from './measurement_comparison'
import ComparisonImage from './comparison_images'
import WellnessComparisonInfo from './wellness_comparison'
import Loading from '../utilities/loading'
import Unavailable from '../utilities/unavailable'

import './report.css'

class ComparisonReport extends Component {
  constructor(props) {
    super(props)

    this.handleDownload = this.handleDownload.bind(this)
    this.toggleImageDisplay = this.toggleImageDisplay.bind(this)

    this.state = { showImages: true }
  }

  toggleImageDisplay() {
    if (this.state.showImages) {
      this.setState({ showImages: false })
    } else {
      this.setState({ showImages: true })
    }
  }

  handleDownload(e) {
    e.preventDefault()
    window.ga('send', 'event', 'Button', 'click', 'Click on Download Report')
    window.print()
  }

  render() {
    const user = this.props.user.get('user')
    const { showImages } = this.state
    const { width } = this.props
    const {
      current,
      currentScan,
      baselineScan,
      records,
      loadingRecords,
    } = this.props

    if (
      current.size === 0 ||
      loadingRecords ||
      !current.get('finishSettingScans')
    ) {
      return <Loading />
    } else if (records.size === 0) {
      return (
        <Unavailable title="No available scans for report">
          <div>
            Click <u onClick={() => this.props.history.push('/')}>here</u> to
            return to your dashboard.
          </div>
        </Unavailable>
      )
    } else if (records.size < 2) {
      return (
        <Unavailable title="Not enough scans to compare (minimum 2)">
          <div>
            Click <u onClick={() => this.props.history.push('/report')}>here</u>{' '}
            to return to the single report page.
          </div>
        </Unavailable>
      )
    }

    const imgColSize = width && width < 1200 && width > 420 ? 12 : 4
    const infoColSize =
      (width && width < 1200 && width > 420) || !this.state.showImages ? 12 : 8

    const handleToggle = this.toggleImageDisplay

    return (
      <div className="py-4">
        <div className="hr-divider header-container">
          <h1 className="hr-divider-content">
            <NoTranslate>{user.get('firstName')}</NoTranslate>'s Fit3D
            Comparison Report
          </h1>
        </div>
        <div className="return-single">
          Click <u onClick={() => this.props.history.push('/report')}>here</u>{' '}
          to return to the single scan report |&nbsp;
          <span
            onClick={handleToggle}
            style={{ color: '#1997c6', cursor: 'pointer' }}
          >
            {showImages ? (
              <span>
                Hide Image <span className="icon icon-circle-with-minus" />
              </span>
            ) : (
              <span>
                Show Image <span className="icon icon-circle-with-plus" />
              </span>
            )}
          </span>
          <span className={width && width < 1024 ? 'hidden' : null}>
            &nbsp;|{' '}
            <span
              onClick={this.handleDownload}
              style={{ color: '#ff8a8a', cursor: 'pointer' }}
            >
              Download <span className="icon icon-download" />
            </span>
          </span>
        </div>
        <div className="container">
          <div className="row">
            <div className={`col-sm-${imgColSize}`}>
              {showImages && (
                <ComparisonImage
                  showImages={showImages}
                  baselineScan={baselineScan}
                  currentScan={currentScan}
                />
              )}
            </div>
            <div className={`col-sm-${infoColSize}`}>
              <WellnessComparisonInfo
                user={user}
                records={records}
                baselineScan={baselineScan}
                currentScan={currentScan}
                baselineMetric={baselineScan.get('wellnessMetrics')}
                currentMetric={currentScan.get('wellnessMetrics')}
                updateCurrentScan={this.props.updateCurrentScan}
                updateBaselineScan={this.props.updateBaselineScan}
              />
              <MeasurementComparisonInfo
                user={user}
                baselineScan={baselineScan}
                currentScan={currentScan}
                records={records}
                updateCurrentScan={this.props.updateCurrentScan}
                updateBaselineScan={this.props.updateBaselineScan}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    width: state.windowDimensions.width,
    records: visibleRecordsSelector(state),
    loadingRecords: state.records.get('loading'),
    scanMeasurements: visibleMeasurementSelector(state),
    current: state.current,
    currentScan: currentScanSelector(state),
    baselineScan: baselineScanSelector(state),
    // currentMetric: currentMetricSelector(state),
    // baselineMetric: baselineMetricSelector(state),
    user: state.user,
    metrics: state.metrics,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateCurrentScan, updateBaselineScan }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonReport)

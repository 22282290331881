import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as VideoActions from '../../actions/scan/video'

// import ScanControl from './scan_control';
import Loading from '../utilities/loading'

import './scans.css'

class ScanVideo extends Component {
  constructor(props) {
    super(props)

    this.updateTime = this.updateTime.bind(this)
    this.updateCurrentTime = this.updateCurrentTime.bind(this)

    this.videoSourceWebm = `webm${this.props.videoId}`
    this.videoSourceMP4 = `mp4${props.videoId}`
    this._mounted = false
    // NOTE: this._mounted = hacky flag to prevent errors on multiple instances, ideally we want to have to render this component only once

    this.state = {
      loading: true,
      error: false,
    }
  }

  componentDidMount() {
    this._mounted = true

    this.props.updateVideoRef({
      videoId: this.props.videoId,
      video: this.video,
    })

    const types = {
      webm: {
        src: `${process.env.REACT_APP_API_BASEURL}${this.props.src}/webm`,
        sourceId: this.videoSourceWebm,
        found: false,
      },
      mp4: {
        src: `${process.env.REACT_APP_API_BASEURL}${this.props.src}/mp4`,
        sourceId: this.videoSourceMP4,
        found: false,
      },
    }

    this.fetchVideo(types)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.scanPackageId !== nextProps.scanPackageId) {
      this.setState({ loading: true })

      const videoSourceWebm = document.getElementById(this.videoSourceWebm)
      const videoSourceMP4 = document.getElementById(this.videoSourceMP4)

      if (this.video) {
        this.props.updateVideoRef({
          videoId: this.props.videoId,
          video: this.video,
        })
      }

      if (videoSourceMP4 || videoSourceWebm) {
        const types = {
          webm: {
            src: `${process.env.REACT_APP_API_BASEURL}${nextProps.src}/webm`,
            sourceId: this.videoSourceWebm,
            found: false,
          },
          mp4: {
            src: `${process.env.REACT_APP_API_BASEURL}${nextProps.src}/mp4`,
            sourceId: this.videoSourceMP4,
            found: false,
          },
        }
        this.fetchVideo(types)
        // videoSourceMP4.setAttribute('src', `${process.env.REACT_APP_API_BASEURL}${nextProps.src}/mp4`);
        // videoSourceWebm.setAttribute('src', `${process.env.REACT_APP_API_BASEURL}${nextProps.src}/webm`);
        // video.load();
        // video.currentTime = nextProps.video.get('currentTime');
      }
      this.props.updateRangeValue('0')
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  fetchVideo(types) {
    // NOTE: we want to iterate through media types (webm/mp4) for cross-browser purposes
    const promises = []
    Object.keys(types).forEach((type) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': `video/${type}`,
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }

      promises.push(
        new Promise((resolve, reject) => {
          window.window
            .fetch(types[type].src, options)
            .then((response) => {
              if (response.status === 200) {
                this.setState({ error: false })
                return response.blob()
              }
              this.setState({
                loading: false,
                error: true,
              })
              reject(new Error('Failed to fetch video'))
              return null
            })
            .then((blob) => {
              if (this._mounted) {
                const url = URL.createObjectURL(blob)

                const videoSource = document.getElementById(
                  types[type].sourceId
                )

                if (videoSource) {
                  types[type].found = true
                  videoSource.setAttribute('src', url)
                }
              }
              resolve()
            })
            .catch((err) => reject(err))
        })
      )
    })

    Promise.all(promises)
      .then(() => {
        if (this._mounted) {
          const video = document.getElementById(this.props.videoId)
          video.load()
          video.currentTime = this.props.video.get('currentTime')
        }
      })
      .catch((err) => console.error(err))
  }

  updateCurrentTime() {
    this.setState({ loading: false })
    if (this.props.video.get('currentTime')) {
      document.getElementById(
        this.props.videoId
      ).currentTime = this.props.video.get('currentTime')
    }
  }

  updateTime() {
    let value = Math.ceil(
      (100 / document.getElementById(this.props.videoId).duration) *
        document.getElementById(this.props.videoId).currentTime
    ).toString()
    if (!value || isNaN(value)) {
      value = '0'
    }
    // do this when parent component has range value (will eventually only use this when we take out controls)
    this.props.updateRangeValue(value)
  }

  renderLoading() {
    const video = document.getElementById(this.props.videoId)
    return video && this.state.loading ? <Loading /> : null
  }

  render() {
    if (!this.props.scanPackageId) {
      return <div />
    }
    const handleTimeUpdate = this.updateTime
    const handleMetaDataLoad = this.updateCurrentTime
    return (
      <div>
        {this.renderLoading()}
        {this.state.error && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '400px' }}
          >
            <div>
              <div className="text-center mb-2">
                <span
                  className="fas fa-video-slash"
                  style={{ color: '#484848', fontSize: '35px' }}
                />
              </div>
              <div>No video available</div>
            </div>
          </div>
        )}
        <video
          key={this.props.videoId}
          id={this.props.videoId}
          ref={(video) => {
            this.video = video
          }}
          loop
          onTimeUpdate={handleTimeUpdate}
          // onLoadedData={ this.showVideo }
          onLoadedMetadata={handleMetaDataLoad}
          style={{
            width: '100%',
            display: this.state.error ? 'none' : 'block',
          }}
        >
          <source id={this.videoSourceWebm} type="video/webm" />
          <source
            id={this.videoSourceMP4}
            type='video/mp4; codecs="avc1.64001E, mp4a.40.2"'
          />
        </video>
      </div>
    )
  }
}

ScanVideo.propTypes = {
  // From store
  video: ImmutablePropTypes.mapContains({
    animation: PropTypes.string.isRequired,
    rangeValue: PropTypes.string.isRequired,
    currentTime: PropTypes.number,
  }).isRequired,
  updateVideoRef: PropTypes.func.isRequired,
  updateRangeValue: PropTypes.func.isRequired,
  // Needed from parent components
  scanPackageId: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  video: state.video,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(VideoActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ScanVideo)

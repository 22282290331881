import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

// import HistoryBody from './history_body';
import HistoryScans from '../history/scans'

const HistoryModal = (props) => {
  const handleClose = props.closeModal
  return (
    <Modal size="lg" isOpen={props.isModalOpen} toggle={props.closeModal}>
      <ModalHeader
        className="pb-0"
        toggle={props.closeModal}
        style={{ borderBottom: 'none' }}
      >
        <div
          style={{
            marginBottom: '5px',
            color: 'rgb(81, 86, 91)',
            fontWeight: 400,
          }}
        >
          Your Scan History
        </div>
        <div style={{ color: '#484848', fontSize: '16px' }}>
          Click on a scan panel to change reports!
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="row mx-0">
          <HistoryScans
            {...props}
            scanClickHandler={(scan) => {
              props.updateCurrentScan(scan)
              props.closeModal()
            }}
          />
        </div>
        <div className="row mx-0">
          <button
            className="btn btn-outline-primary ml-auto"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

HistoryModal.propTypes = {
  updateCurrentScan: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default HistoryModal

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateMetric } from '../../../../actions/wellness'
import EditForm from './edit_form'

import { parseComma } from '../../../../functions/text_format'

class BiometricsForm extends Component {
  constructor(props) {
    super(props)

    this.handleMetricSubmit = this.handleMetricSubmit.bind(this)
  }

  // handleError(value) {
  //   const regex = /^(\d+)?([,.]?\d{0,1})?$/;
  //   if (!value || value === '') {
  //     this.setState({ errorMessage: 'please enter a value'});
  //     return false;
  //   } else if (!regex.test(value)) {
  //     this.setState({ errorMessage: 'enter a number up to one decimal point' });
  //     return false;
  //   } else if (this.props.type === 'Weight' && this.props.units === 'US' && (parseFloat(value) > 600 || parseFloat(value) < 40)) {
  //     this.setState({ errorMessage: 'invalid weight value' });
  //     return false;
  //   } else if ((this.props.type === 'Weight' && this.props.units !== 'US' && (parseFloat(value) > 272.1 || parseFloat(value) < 18.1))) {
  //     this.setState({ errorMessage: 'invalid weight value' });
  //     return false;
  //   } else if (this.props.type === 'Height' && this.props.units === 'US' && (parseFloat(value) > 96 || parseFloat(value) < 36)) {
  //     this.setState({ errorMessage: 'invalid height value' });
  //     return false;
  //   } else if ((this.props.type === 'Height' && this.props.units !== 'US' && (parseFloat(value) > 240 || parseFloat(value) < 91))) {
  //     this.setState({ errorMessage: 'invalid height value' });
  //     return false;
  //   }
  //   this.setState({ errorMessage: null });
  //   return true;
  // }

  handleMetricSubmit(data) {
    const parsedData = {
      height: parseFloat(parseComma(data.height)),
      weight: parseFloat(parseComma(data.weight)),
    }

    this.props.updateMetric(this.props.recordId, parsedData).then((result) => {
      if (result.error) {
        this.setState({ errorMessage: 'edit failed' })
      }
    })
    window.ga('send', 'event', 'Button', 'click', `Edited ${this.props.type}`)
  }

  render() {
    const handleSubmit = this.handleMetricSubmit
    return <EditForm handleMetricSubmit={handleSubmit} {...this.props} />
  }
}

BiometricsForm.propTypes = {
  updateMetric: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  units: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateMetric }, dispatch)
}

export default connect(null, mapDispatchToProps)(BiometricsForm)

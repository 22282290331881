import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchFacilityUsers,
  updateFacilityUser,
} from '../../../../actions/facility/index'

// import { BootstrapPager, GriddleBootstrap } from 'griddle-react-bootstrap';
import Griddle, {
  plugins,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react'
import { adminGridWrapper } from '../../../admin/grid_wrapper'
import { enhancedWithRowData } from '../../../griddle/rowdata_wrapper'
import { CustomColumn } from '../../../griddle/table_components'
import Filter from '../../../griddle/search'

import Actions from './actions'
import Loading from '../../../utilities/loading'

import './manage.css'
import { formatDateTime } from '../../../../functions/text_format'

class FacilityManage extends Component {
  componentDidMount() {
    if (
      (this.props.user.get('coach') || this.props.user.get('facilityAdmin')) &&
      window._cio &&
      window._cio.page
    ) {
      window._cio.page('/facility/users')
    }
  }

  parseData(user) {
    const type = user.get('coach') ? 'Coach' : 'User'
    return {
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      email: user.get('email'),
      signupDate: formatDateTime(user.get('signupDate')),
      type,
      actions: { userId: user.get('userId') },
    }
  }

  render() {
    const { data, pageProperties, components, styleConfig } = this.props
    const results = data.map(this.parseData.bind(this)).toJS()

    styleConfig.classNames.Table += ' table-hover'
    components.Filter = () => null

    const searchOptions = [
      { value: 'email', label: 'E-mail' },
      { value: 'firstName', label: 'First Name' },
      { value: 'lastName', label: 'Last Name' },
      { value: 'iscoach', label: 'Coaches Only', disabled: true },
    ]

    return (
      <div>
        <div style={{ marginBottom: '25px', borderBottom: '1px #eee solid' }}>
          <h6 className="dashhead-subtitle mt-4">Success Hub</h6>
          <h3 className="dashhead-title">Add/Remove Coaches</h3>
        </div>
        <Filter
          submitFilteredSearch={this.props.submitFilteredSearch}
          search={this.props.search}
          searchOptions={searchOptions}
          resetSearchState={this.props.resetSearchState}
          placeholder="Search Clients (Name or E-mail)"
        />
        {this.props.loading ? (
          <Loading />
        ) : (
          <Griddle
            data={results}
            pageProperties={pageProperties}
            plugins={[plugins.LocalPlugin]}
            components={components}
            styleConfig={styleConfig}
          >
            <RowDefinition>
              <ColumnDefinition
                id="firstName"
                title="First Name"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="lastName"
                title="Last Name"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="email"
                title="E-mail"
                customComponent={CustomColumn}
              />
              <ColumnDefinition
                id="signupDate"
                title="Signup Date"
                customComponent={CustomColumn}
              />
              <ColumnDefinition id="type" title="Type" />
              <ColumnDefinition
                id="actions"
                title="Actions"
                updateUser={this.props.updateFacilityUser}
                customComponent={enhancedWithRowData(Actions)}
              />
            </RowDefinition>
          </Griddle>
        )}
      </div>
    )
  }
}

FacilityManage.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  fetchFacilityUsers: PropTypes.func,
  updateFacilityUser: PropTypes.func,
  submitFilteredSearch: PropTypes.func,
  search: PropTypes.shape({
    searchParam: PropTypes.string,
    searchString: PropTypes.string,
  }),
  resetSearchState: PropTypes.func.isRequired,
  data: PropTypes.object,
  capitalize: PropTypes.func,
  pageProperties: PropTypes.object,
  components: PropTypes.object,
  styleConfig: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchFacilityUsers, updateFacilityUser },
    dispatch
  )
}

const gridOptions = {
  key: 'facility',
  recordName: 'users',
  newSearch: true,
  customPageSize: 10,
}

export default adminGridWrapper(
  connect(null, mapDispatchToProps)(FacilityManage),
  gridOptions
)

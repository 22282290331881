import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'
import { Modal, ModalBody } from 'reactstrap'

import '../comparison.css'

class ComparisonChartModal extends Component {
  constructor(props) {
    super(props)

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.state = { modalIsOpen: false }
  }

  openModal() {
    window.ga('send', 'event', 'Button', 'click', 'Zoomed in on chart ')
    this.setState({ modalIsOpen: true })
    // this.config.chart.height = 400;
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
    // this.config.chart.height = 240;
  }

  render() {
    const handleOpen = this.openModal
    const handleClose = this.closeModal
    return (
      <div>
        {this.props.children}
        {this.props.width > 700 && (
          <div className="mt-3">
            <button
              onClick={handleOpen}
              className="btn btn-square text-uppercase zoom"
            >
              <span className="icon icon-magnifying-glass" /> Click to Enlarge
              Chart
            </button>
          </div>
        )}
        <Modal bsSize="large" isOpen={this.state.modalIsOpen}>
          <ModalBody>
            <h1 className="chart-header">{this.props.header}</h1>
            <ReactHighcharts config={this.props.config} />
            <div className="text-center button-container" onClick={handleClose}>
              <button className="text-uppercase">Click to Close</button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

ComparisonChartModal.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  config: PropTypes.object,
  header: PropTypes.string,
}

export default ComparisonChartModal
